import { CUSTOMER_FETCH_SUCCESS } from 'Customers/constants'
import { conditionalFieldsValidation } from 'Customers/actions'

const customerProfileConditionalFieldsUpdater = store => next => action => {
  if (action.type === CUSTOMER_FETCH_SUCCESS) {
    const result = next(action)
    store.dispatch(conditionalFieldsValidation())
    return result
  }

  return next(action)
}

export default customerProfileConditionalFieldsUpdater
