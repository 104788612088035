import moment from 'moment'

import {
  FILTER_NONE,
  DATE_FILTER_LAST_30,
  DATE_FILTER_LAST_MONTH,
  DATE_FILTER_THIS_MONTH,
  DATE_FILTER_NEXT_MONTH,
  DATE_FILTER_THIS_QUARTER,
  DATE_FILTER_LAST_7,
  DATE_FILTER_LAST_WEEK,
  DATE_FILTER_THIS_WEEK,
  DATE_FILTER_NEXT_WEEK,
  DATE_FILTER_YESTERDAY,
  DATE_FILTER_TODAY,
  DATE_FILTER_TOMORROW,
  DATE_FILTER_FUTURE,
  DATE_FILTER_PAST,
  DATE_FILTER_OLDER_THAN,
  DATE_FILTER_CUSTOM,
  DATE_FILTER_LAST_YEAR,
  DATE_FILTER_THIS_YEAR,
  DATE_FILTER_YEAR_TO_DATE,
  DATE_FILTER_LAST_12_MONTHS
} from 'Profiles/constants/filters'

export const isDateTime = date => moment(date, 'YYYY/MM/DD H:m:s Z', true).isValid()

export const isLast30Days = date => moment(date).isBetween(
  moment().subtract(30, 'days'),
  moment(),
  'day',
  '[]'
)

export const isLastMonth = date => moment(date).isBetween(
  moment().subtract(1, 'month').startOf('month'),
  moment().subtract(1, 'month').endOf('month'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isThisMonth = date => moment(date).isBetween(
  moment().startOf('month'),
  moment().endOf('month'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isNextMonth = date => moment(date).isBetween(
  moment().add(1, 'month').startOf('month'),
  moment().add(1, 'month').endOf('month'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isThisQuarter = date => moment(date).isBetween(
  moment().startOf('quarter'),
  moment().endOf('quarter'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isLast7Days = date => moment(date).isBetween(
  moment().subtract(7, 'days'),
  moment(),
  'day',
  '[]'
)

export const isLastWeek = date => moment(date).isBetween(
  moment().subtract(1, 'week').startOf('week'),
  moment().subtract(1, 'week').endOf('week'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isThisWeek = date => moment(date).isBetween(
  moment().startOf('week'),
  moment().endOf('week'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isNextWeek = date => moment(date).isBetween(
  moment().add(1, 'week').startOf('week'),
  moment().add(1, 'week').endOf('week'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isYesterday = date => moment(date).isBetween(
  moment().subtract(1, 'day').startOf('day'),
  moment().subtract(1, 'day').endOf('day'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isToday = date => moment(date).isBetween(
  moment().startOf('day'),
  moment().endOf('day'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isTomorrow = date => moment(date).isBetween(
  moment().add(1, 'day').startOf('day'),
  moment().add(1, 'day').endOf('day'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isFuture = date => {
  const granularity = isDateTime(date) ? undefined : 'day'
  return moment(date).isAfter(moment(), granularity)
}

export const isPast = date => {
  const granularity = isDateTime(date) ? undefined : 'day'
  return moment(date).isBefore(moment(), granularity)
}

export const isYearToDate = date => moment(date).isBetween(
  moment().startOf('year'),
  moment().endOf('day'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isLast12Months = date => moment(date).isBetween(
  moment().subtract(12, 'months'),
  moment(),
  'day',
  '[]'
)

export const isOlderThan = (date, nDays) => moment(date).isBefore(
  moment().subtract(nDays, 'day')
)

export const isCustom = (date, range) => moment(date).isBetween(
  moment(range.from_date),
  moment(range.to_date),
  'day',
  '[]'
)

export const isNext30Days = date => moment(date).isBetween(
  moment(),
  moment().add(30, 'days'),
  'day',
  '[]'
)

export const isThisYear = date => moment(date).isBetween(
  moment().startOf('year'),
  moment().endOf('year'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export const isLastYear = date => moment(date).isBetween(
  moment().subtract(1, 'year').startOf('year'),
  moment().subtract(1, 'year').endOf('year'),
  undefined, // can be year, month .... the granularity of your comaprison
  '[]'
)

export default ({ dateAttribute, filter, objects }) => {
  switch (filter.selection) {
    case FILTER_NONE:
      return objects.filter(object => !object[dateAttribute])
    case DATE_FILTER_LAST_30:
      return objects.filter(object => isLast30Days(object[dateAttribute]))
    case DATE_FILTER_LAST_MONTH:
      return objects.filter(object => isLastMonth(object[dateAttribute]))
    case DATE_FILTER_THIS_MONTH:
      return objects.filter(object => isThisMonth(object[dateAttribute]))
    case DATE_FILTER_NEXT_MONTH:
      return objects.filter(object => isNextMonth(object[dateAttribute]))
    case DATE_FILTER_THIS_QUARTER:
      return objects.filter(object => isThisQuarter(object[dateAttribute]))
    case DATE_FILTER_LAST_7:
      return objects.filter(object => isLast7Days(object[dateAttribute]))
    case DATE_FILTER_LAST_WEEK:
      return objects.filter(object => isLastWeek(object[dateAttribute]))
    case DATE_FILTER_THIS_WEEK:
      return objects.filter(object => isThisWeek(object[dateAttribute]))
    case DATE_FILTER_NEXT_WEEK:
      return objects.filter(object => isNextWeek(object[dateAttribute]))
    case DATE_FILTER_YESTERDAY:
      return objects.filter(object => isYesterday(object[dateAttribute]))
    case DATE_FILTER_TODAY:
      return objects.filter(object => isToday(object[dateAttribute]))
    case DATE_FILTER_TOMORROW:
      return objects.filter(object => isTomorrow(object[dateAttribute]))
    case DATE_FILTER_FUTURE:
      return objects.filter(object => isFuture(object[dateAttribute]))
    case DATE_FILTER_PAST:
      return objects.filter(object => isPast(object[dateAttribute]))
    case DATE_FILTER_LAST_YEAR:
      return objects.filter(object => isLastYear(object[dateAttribute]))
    case DATE_FILTER_THIS_YEAR:
      return objects.filter(object => isThisYear(object[dateAttribute]))
    case DATE_FILTER_YEAR_TO_DATE:
      return objects.filter(object => isYearToDate(object[dateAttribute]))
    case DATE_FILTER_LAST_12_MONTHS:
      return objects.filter(object => isLast12Months(object[dateAttribute]))
    case DATE_FILTER_OLDER_THAN:
      return objects.filter(object => isOlderThan(object[dateAttribute], filter))
    case DATE_FILTER_CUSTOM:
      return objects.filter(object => isCustom(object[dateAttribute], filter))
    default:
      return objects
  }
}

export const rangeToday = () => ({ fromDate: moment().startOf('day').toDate(), toDate: moment().endOf('day').toDate() })
export const rangeYesterday = () => ({ fromDate: moment().subtract(1, 'days').startOf('day').toDate(), toDate: moment().subtract(1, 'days').endOf('day').toDate() })
export const rangeThisWeek = () => ({ fromDate: moment().startOf('week').toDate(), toDate: moment().endOf('week').toDate() })
export const rangeLastWeek = () => ({ fromDate: moment().subtract(1, 'weeks').startOf('week').toDate(), toDate: moment().subtract(1, 'weeks').endOf('week').toDate() })
export const rangeLast30Days = () => ({ fromDate: moment().subtract(29, 'days').startOf('day').toDate(), toDate: moment().endOf('day').toDate() })
export const rangeThisMonth = () => ({ fromDate: moment().startOf('month').toDate(), toDate: moment().endOf('month').toDate() })
export const rangeLastMonth = () => ({ fromDate: moment().subtract(1, 'months').startOf('month').toDate(), toDate: moment().subtract(1, 'months').endOf('month').toDate() })
export const rangeThisQuarter = () => ({ fromDate: moment().startOf('quarter').toDate(), toDate: moment().endOf('quarter').toDate() })
export const rangeLastQuarter = () => ({ fromDate: moment().subtract(1, 'quarters').startOf('quarter').toDate(), toDate: moment().subtract(1, 'quarters').endOf('quarter').toDate() })
export const rangeThisYear = () => ({ fromDate: moment().startOf('year').toDate(), toDate: moment().endOf('year').toDate() })
export const rangeLastYear = () => ({ fromDate: moment().subtract(1, 'years').startOf('year').toDate(), toDate: moment().subtract(1, 'years').endOf('year').toDate() })
