import { createSelector } from 'reselect'

import { getCurrentAccountSelector, getCurrentUserSelector } from 'selectors/index'

export const getSearchUserCanCustomize = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.global_search_user_can_customize
)

export const getSearchOwnerDefaultFilter = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.global_search_owner_default_filter
)

export const searchAttributesSelector = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.global_search_attributes || {}
)

export const searchAttributesUserSelector = createSelector(
  getCurrentUserSelector,
  searchAttributesSelector,
  getSearchUserCanCustomize,
  (currentUser, searchAttributes, userCanCustomize) => ({ ...searchAttributes, ...(userCanCustomize ? currentUser.global_search_attributes : {}) })
)

export const getSearchAttributesUrl = createSelector(
  getSearchUserCanCustomize,
  canCustomize => canCustomize ? '/user/search/person' : '/admin/modern/search/person'
)

export const getSearchSettings = createSelector(
  getCurrentUserSelector,
  currentUser => currentUser.global_search_settings || {}
)

export const getSearchShowTips = createSelector(
  getCurrentUserSelector,
  currentUser => currentUser.global_search_settings?.tips !== false
)

export const getSearchShowFilters = createSelector(
  getCurrentUserSelector,
  currentUser => currentUser.global_search_settings?.filters === true
)
