import {
  CALENDAR_ENTRY_PRIORITY_CREATE,
  CALENDAR_ENTRY_PRIORITY_CREATE_FAILURE,
  CALENDAR_ENTRY_PRIORITY_UPDATE,
  CALENDAR_ENTRY_PRIORITY_UPDATE_FAILURE,
  CALENDAR_ENTRY_PRIORITY_DELETE,
  CALENDAR_ENTRY_PRIORITY_DELETE_FAILURE
} from '../constants'

export const calendarEntryPriorityCreate = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_CREATE })
export const calendarEntryPriorityCreateFailure = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_CREATE_FAILURE })

export const calendarEntryPriorityUpdate = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_UPDATE })
export const calendarEntryPriorityUpdateFailure = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_UPDATE_FAILURE })

export const calendarEntryPriorityDelete = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_DELETE })
export const calendarEntryPriorityDeleteFailure = payload => ({ payload, type: CALENDAR_ENTRY_PRIORITY_DELETE_FAILURE })
