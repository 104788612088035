/* global App, jQuery */

App.Stores.Email = {
  send: function (data) {
    window.trackableEvent.email.sendEmail({ isCampaign: data.is_campaign, isTracked: data.is_tracked })
    return jQuery.ajax({
      type: 'POST',
      url: '/connect/emails',
      data: { email: data },
      dataType: 'json',
      xhrFields: { withCredentials: true }
    })
  },

  sendTestEmail: function (data) {
    return jQuery.ajax({
      type: 'POST',
      url: '/connect/emails/test_email',
      data: { email: data },
      dataType: 'json',
      xhrFields: { withCredentials: true }
    })
  },

  validateEmailUrls: function (emailBody) {
    return jQuery.ajax({
      type: 'POST',
      url: 'connect/emails/validate_urls',
      data: { email_body: emailBody },
      dataType: 'json',
      xhrFields: { withCredentials: true }
    })
  }
}

module.exports = App.Stores.Email
