import {
  TODO_TEMPLATE_CREATE,
  TODO_TEMPLATE_CREATE_FAILURE,
  TODO_TEMPLATE_UPDATE,
  TODO_TEMPLATE_UPDATE_FAILURE,
  TODO_TEMPLATE_DELETE,
  TODO_TEMPLATE_DELETE_FAILURE,
  TODO_TEMPLATE_IGNORE_WEEKENDS
} from './constants'

export const todoTemplateCreate = payload => ({ payload, type: TODO_TEMPLATE_CREATE })
export const todoTemplateCreateFailure = payload => ({ payload, type: TODO_TEMPLATE_CREATE_FAILURE })
export const todoTemplateUpdate = payload => ({ payload, type: TODO_TEMPLATE_UPDATE })
export const todoTemplateUpdateFailure = payload => ({ payload, type: TODO_TEMPLATE_UPDATE_FAILURE })
export const todoTemplateDelete = payload => ({ payload, type: TODO_TEMPLATE_DELETE })
export const todoTemplateDeleteFailure = payload => ({ payload, type: TODO_TEMPLATE_DELETE_FAILURE })
export const todoTemplateIgnoreWeekends = payload => ({ payload, type: TODO_TEMPLATE_IGNORE_WEEKENDS })
