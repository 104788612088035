import React, { } from 'react'

import { useBusinessCard } from 'components/BusinessCard/context'
import PeopleCarousel from 'components/BusinessCard/PeopleCarousel'

const DealFooter = () => {
  const {
    cardMeta: {
      showPeopleCarousel
    },
    callbacks: {
      onClose
    },
    resource: deal
  } = useBusinessCard()

  return (
    <>
      {showPeopleCarousel && (
        <>
          {deal.person_ids.length > 0 && <PeopleCarousel personIds={deal.person_ids} onClose={onClose} />}
          {deal.person_ids.length === 0 && <div className="empty" style={{ padding: '15px' }}> <p>No People</p> </div>}
        </>
      )}
    </>
  )
}

export default DealFooter
