export const checkDropdown = (trigger, value) => {
  const valueNumber = value && value !== '' ? parseInt(value) : null
  const operandNumber = parseInt(trigger.operand)

  switch (trigger.name) {
    case 'Is':
      return dropdownIs(valueNumber, operandNumber)
    case 'IsNot':
      return dropdownIsNot(valueNumber, operandNumber)
    case 'IsNotBlank':
      return dropdownIsNotBlank(valueNumber)
    case 'IsBlank':
      return dropdownIsBlank(valueNumber)
    default:
      return null
  }
}
export default checkDropdown

const dropdownIs = (value, operand) => {
  if (value === operand) {
    return {
      message: 'is equal to selected value'
    }
  }
  return null
}

const dropdownIsNot = (value, operand) => {
  if (value !== operand) {
    return {
      message: 'is not equal to selected value'
    }
  }
  return null
}

const dropdownIsNotBlank = (value) => {
  if (value !== null) {
    return {
      message: 'is not blank'
    }
  }
  return null
}

const dropdownIsBlank = (value) => {
  if (value === null) {
    return {
      message: 'is blank'
    }
  }
  return null
}
