import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  dealLossReasonCreate,
  dealLossReasonCreateFailure,
  dealLossReasonUpdate,
  dealLossReasonUpdateFailure,
  dealLossReasonDelete,
  dealLossReasonDeleteFailure,
  dealLossReasonRequiredChange,
  dealLossReasonRequiredChangeFailure,
  dealLossReasonShowDialogChange,
  dealLossReasonShowDialogChangeFailure,
  dealLossReasonShowDealProfileChange,
  dealLossReasonShowDealProfileChangeFailure
} from '../actions'

import {
  DEAL_LOSS_REASON_CREATE_SUBMIT,
  DEAL_LOSS_REASON_UPDATE_SUBMIT,
  DEAL_LOSS_REASON_DELETE_SUBMIT,
  DEAL_LOSS_REASON_REQUIRED_CHANGE_SUBMIT,
  DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_SUBMIT,
  DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT
} from '../constants'

export function * dealLossReasonCreateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_loss_reasons',
      {
        method: 'POST',
        body: JSON.stringify({
          deal_loss_reason: {
            name: payload.name
          }
        })
      }
    )
    yield put(dealLossReasonCreate(response))
  } catch (exception) {
    yield put(dealLossReasonCreateFailure(exception.message))
  }
}

export function * dealLossReasonUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/deal_loss_reasons/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          deal_loss_reason: {
            name: payload.name,
            id: payload.id
          }
        })
      }
    )
    yield put(dealLossReasonUpdate(response))
  } catch (exception) {
    yield put(dealLossReasonUpdateFailure(exception.message))
  }
}

export function * dealLossReasonDeleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/deal_loss_reasons/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(dealLossReasonDelete(payload))
  } catch (exception) {
    yield put(dealLossReasonDeleteFailure(exception.message))
  }
}

export function * dealLossReasonRequiredChangeSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher, 'admin/account',
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal_loss_reason_required: payload.dealLossReasonRequired
        })
      }
    )
    yield put(dealLossReasonRequiredChange(response))
    yield call(window.Helpers.notify, 'Deal Loss Reason', 'Your settings have been saved')
  } catch (exception) {
    yield put(dealLossReasonRequiredChangeFailure(exception.message))
  }
}

export function * dealLossReasonShowDialogChangeSaga ({ payload }) {
  try {
    const response = yield call(fetcher, 'admin/account', {
      method: 'PATCH',
      body: JSON.stringify({
        show_deal_loss_reason_dialog: payload.ShowDealLossReasonDialog
      })
    })
    yield put(dealLossReasonShowDialogChange(response))
    yield call(
      window.Helpers.notify,
      'Deal Loss Reason',
      'Your settings have been saved'
    )
  } catch (exception) {
    yield put(dealLossReasonShowDialogChangeFailure(exception.message))
  }
}

export function * dealLossReasonShowDealProfileChangeSaga ({ payload }) {
  try {
    const response = yield call(fetcher, 'admin/account', {
      method: 'PATCH',
      body: JSON.stringify({
        show_deal_loss_reason_deal_profile: payload.ShowDealLossReasonDealProfile
      })
    })
    yield put(dealLossReasonShowDealProfileChange(response))
    yield call(
      window.Helpers.notify,
      'Deal Loss Reason',
      'Your settings have been saved'
    )
  } catch (exception) {
    yield put(dealLossReasonShowDealProfileChangeFailure(exception.message))
  }
}

export function * watchDealLossReasonCreateSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_CREATE_SUBMIT, dealLossReasonCreateSaga)
}

export function * watchDealLossReasonUpdateSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_UPDATE_SUBMIT, dealLossReasonUpdateSaga)
}

export function * watchDealLossReasonDeleteSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_DELETE_SUBMIT, dealLossReasonDeleteSaga)
}

export function * watchDealLossReasonRequiredChangeSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_REQUIRED_CHANGE_SUBMIT, dealLossReasonRequiredChangeSaga)
}

export function * watchDealLossReasonShowDialogChangeSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_SUBMIT, dealLossReasonShowDialogChangeSaga)
}

export function * watchDealLossReasonShowDealProfileChangeSaga () {
  yield call(takeEvery, DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT, dealLossReasonShowDealProfileChangeSaga)
}
