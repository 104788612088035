import {
  CUSTOMER_COMPANY_FETCH_SUCCESS,
  CUSTOMER_COMPANY_UPDATE,
  CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  CUSTOMER_CONDITIONAL_FIELDS_VALIDATION,
  CUSTOMER_DEAL_NEW_SUCCESS,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH,
  CUSTOMER_PERSON_NEW_SUCCESS,
  CUSTOMER_PROFILE_FETCH,
  CUSTOMER_PROFILE_RESET,
  CUSTOMER_RELOAD_TODOS,
  CUSTOMER_FETCH_CHILDREN_COMPANIES,
  CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES,
  CUSTOMER_SORT_CHILDREN_COMPANIES,
  CUSTOMER_FILTER_CHILDREN_COMPANIES
} from 'Customers/constants'

export const customerProfileFetch = payload => ({ payload, type: CUSTOMER_PROFILE_FETCH })
export const customerFetch = payload => ({ payload, type: CUSTOMER_FETCH })
export const customerFetchSuccess = payload => ({ payload, type: CUSTOMER_FETCH_SUCCESS })
export const customerFetchError = payload => ({ payload, type: CUSTOMER_FETCH_ERROR })

export const customerProfileReset = () => ({ type: CUSTOMER_PROFILE_RESET })

export const customerCompanyFetchSuccess = payload => ({ payload, type: CUSTOMER_COMPANY_FETCH_SUCCESS })
export const customerDealNewSuccess = payload => ({ payload, type: CUSTOMER_DEAL_NEW_SUCCESS })
export const customerPersonNewSuccess = payload => ({ payload, type: CUSTOMER_PERSON_NEW_SUCCESS })
export const reloadTodos = payload => ({ payload, type: CUSTOMER_RELOAD_TODOS })

export const conditionalFieldsValidation = payload => ({ payload, type: CUSTOMER_CONDITIONAL_FIELDS_VALIDATION })
export const updateConditionalFieldsValidation = payload => ({ payload, type: CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE })

// TODO
export const loadTodos = payload => ({ payload, type: 'CUSTOMERS_LOAD_TODOS' })
export const updateTodo = payload => ({ payload, type: 'CUSTOMERS_UPDATE_TODO' })
export const deleteTodo = payload => ({ payload, type: 'CUSTOMERS_DELETE_TODO' })
export const updateTodoFilters = payload => ({ payload, type: 'CUSTOMERS_UPDATE_TODO_FILTERS' })

// VISIBLE USERS
export const loadVisibleUsers = payload => ({ payload, type: 'CUSTOMERS_LOAD_VISIBLE_USERS' })

// MILESTONE
export const loadMilestones = payload => ({ payload, type: 'CUSTOMERS_LOAD_MILESTONES' })
export const createMilestone = payload => ({ payload, type: 'CUSTOMERS_CREATE_MILESTONE' })
export const updateMilestone = payload => ({ payload, type: 'CUSTOMERS_UPDATE_MILESTONE' })
export const deleteMilestone = payload => ({ payload, type: 'CUSTOMERS_DELETE_MILESTONE' })

// PEOPLE
export const loadPeople = payload => ({ payload, type: 'CUSTOMERS_LOAD_PEOPLE' })
export const createPerson = payload => ({ payload, type: 'CUSTOMERS_CREATE_PERSON' })
export const updatePerson = payload => ({ payload, type: 'CUSTOMERS_UPDATE_PERSON' })
export const deletePerson = payload => ({ payload, type: 'CUSTOMERS_DELETE_PERSON' })
export const sortPeople = payload => ({ payload, type: 'CUSTOMERS_SORT_PEOPLE' })
export const filterPeople = payload => ({ payload, type: 'CUSTOMERS_FILTER_PEOPLE' })

// FILES
export const loadFiles = payload => ({ payload, type: 'CUSTOMERS_LOAD_FILES' })
export const createFile = payload => ({ payload, type: 'CUSTOMERS_CREATE_FILE' })
export const deleteFile = payload => ({ payload, type: 'CUSTOMERS_DELETE_FILE' })
export const filterFiles = payload => ({ payload, type: 'CUSTOMERS_FILTER_FILES' })
export const updateFile = payload => ({ payload, type: 'CUSTOMERS_UPDATE_FILE' })
export const sortFiles = payload => ({ payload, type: 'CUSTOMERS_SORT_FILES' })

// DEALS
export const loadDeals = payload => ({ payload, type: 'CUSTOMERS_LOAD_DEALS' })
export const createDeal = payload => ({ payload, type: 'CUSTOMERS_CREATE_DEAL' })
export const updateDeal = payload => ({ payload, type: 'CUSTOMERS_UPDATE_DEAL' })
export const deleteDeal = payload => ({ payload, type: 'CUSTOMERS_DELETE_DEAL' })
export const sortDeals = payload => ({ payload, type: 'CUSTOMERS_SORT_DEALS' })
export const filterDeals = payload => ({ payload, type: 'CUSTOMERS_FILTER_DEALS' })

// ACTIVITIES
export const updateActivityFilters = payload => ({ payload, type: 'CUSTOMERS_UPDATE_ACTIVITY_FILTERS' })

// CHILDREN COMPANIES
export const customerFetchChildrenCompanies = payload => ({ payload, type: CUSTOMER_FETCH_CHILDREN_COMPANIES })
export const customerFetchSuccessChildrenCompanies = payload => ({ payload, type: CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES })
export const sortChildrenCompanies = payload => ({ payload, type: CUSTOMER_SORT_CHILDREN_COMPANIES })
export const filterChildrenCompanies = payload => ({ payload, type: CUSTOMER_FILTER_CHILDREN_COMPANIES })

// COMPANY
export const customerCompanyUpdate = (payload, { successCallback, failureCallback, finallyCallback } = {}) => (
  { payload, type: CUSTOMER_COMPANY_UPDATE, successCallback, failureCallback, finallyCallback })
