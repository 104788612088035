import {
  CREATE,
  DELETE,
  MANAGE,
  UPDATE
} from 'permissions/actions'

export const MILESTONE = '@@permissions/policy/milestone'

export default {
  [CREATE]: (user, options) => !user.read_only,
  [DELETE]: (user, options) => !user.read_only,
  [MANAGE]: (user, options) => !user.read_only,
  [UPDATE]: (user, options) => !user.read_only
}
