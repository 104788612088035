export default {
  customer: [
    'convertedFromCompanyModal',
    'convertedFromDealStageChange',
    'convertedFromHomeTab',
    'convertedFromProfilePage',
    'revertCustomer'
  ],
  keyContact: [
    'added'
  ],
  milestone: [
    'added'
  ]
}
