/* global fetch */
import qs from 'qs'

import RequestError from './RequestError'

const raiseError = response => {
  return response.json().then(jsonError => {
    throw new RequestError(response, jsonError)
  })
}

const handleResponse = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status >= 400 && response.status < 500) {
    if ([401, 403].includes(response.status)) {
      const loggedInDataResponse = await fetch('/login/is_logged_in')
      const { logged_in: loggedIn } = await loggedInDataResponse.json()

      if (loggedIn) {
        return raiseError(response)
      } else {
        window.location.href = '/login'
      }
    } else {
      return raiseError(response)
    }
  } else {
    throw new RequestError(response)
  }
}

const param = query => qs.stringify(query, { arrayFormat: 'brackets' })

export const request = (path, options = {}) => {
  const { query, ...rest } = options
  const fetchUrl = url(path, query)

  return fetch(fetchUrl, {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, max-age=0, must-revalidate, no-store'
    },
    ...rest
  })
    .then(handleResponse)
    .then(toJSON)
}

const toJSON = response => response.status !== 204 ? response.json().catch(() => null) : null

const url = (path, query) =>
  (query !== null && query !== undefined)
    ? `${path}?${param(query)}`
    : path

export default request
