// Filter Constants
export const FILTER_NONE = '__NONE__'
export const FILTER_HAS_VALUE = '__HAS_VALUE__'

// Time Frames
export const DATE_FILTER_LAST_30 = 'LAST_30'
export const DATE_FILTER_LAST_MONTH = 'LAST_MONTH'
export const DATE_FILTER_THIS_MONTH = 'THIS_MONTH'
export const DATE_FILTER_NEXT_MONTH = 'NEXT_MONTH'
export const DATE_FILTER_THIS_QUARTER = 'THIS_QUARTER'
export const DATE_FILTER_LAST_7 = 'LAST_7'
export const DATE_FILTER_LAST_WEEK = 'LAST_WEEK'
export const DATE_FILTER_THIS_WEEK = 'THIS_WEEK'
export const DATE_FILTER_NEXT_WEEK = 'NEXT_WEEK'
export const DATE_FILTER_YESTERDAY = 'YESTERDAY'
export const DATE_FILTER_TODAY = 'TODAY'
export const DATE_FILTER_TOMORROW = 'TOMORROW'
export const DATE_FILTER_FUTURE = 'FUTURE'
export const DATE_FILTER_PAST = 'PAST'
export const DATE_FILTER_OLDER_THAN = 'OLDER_THAN'
export const DATE_FILTER_CUSTOM = 'CUSTOM'
export const DATE_FILTER_LAST_YEAR = 'LAST_YEAR'
export const DATE_FILTER_THIS_YEAR = 'THIS_YEAR'
export const DATE_FILTER_YEAR_TO_DATE = 'YEAR_TO_DATE'
export const DATE_FILTER_LAST_12_MONTHS = 'LAST_12_MONTHS'

export const DATE_FILTERS = {
  [DATE_FILTER_LAST_30]: 'Last 30 Days',
  [DATE_FILTER_LAST_MONTH]: 'Last Month',
  [DATE_FILTER_THIS_MONTH]: 'This Month',
  [DATE_FILTER_NEXT_MONTH]: 'Next Month',
  [DATE_FILTER_THIS_QUARTER]: 'This Quarter',
  [DATE_FILTER_LAST_7]: 'Last 7 Days',
  [DATE_FILTER_LAST_WEEK]: 'Last Week',
  [DATE_FILTER_THIS_WEEK]: 'This Week',
  [DATE_FILTER_NEXT_WEEK]: 'Next Week',
  [DATE_FILTER_YESTERDAY]: 'Yesterday',
  [DATE_FILTER_TODAY]: 'Today',
  [DATE_FILTER_TOMORROW]: 'Tomorrow',
  [DATE_FILTER_FUTURE]: 'Future',
  [DATE_FILTER_PAST]: 'Past',
  [DATE_FILTER_OLDER_THAN]: 'Older Than',
  [DATE_FILTER_CUSTOM]: 'Custom',
  [DATE_FILTER_LAST_YEAR]: 'Last Year',
  [DATE_FILTER_THIS_YEAR]: 'This Year',
  [DATE_FILTER_YEAR_TO_DATE]: 'Year to Date',
  [DATE_FILTER_LAST_12_MONTHS]: 'Last 12 Months'
}
