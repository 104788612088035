import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import { SUBSCRIPTION_REQUEST } from 'constants/Subscription'
import {
  subscriptionSuccess,
  subscriptionFailure
} from 'actions/Subscription'

export function * subscriptionRequestSaga ({ payload }) {
  try {
    const subscription = yield call(
      fetcher,
      `/api/internal/accounts/${payload.accountId}/subscription`
    )

    yield put(subscriptionSuccess(subscription))
  } catch (exception) {
    yield put(subscriptionFailure({ error: exception.message }))
  }
}

export function * watchSubscriptionRequestSaga () {
  yield call(takeEvery, SUBSCRIPTION_REQUEST, subscriptionRequestSaga)
}
