export default [
  'addNew',
  'addNewOnCalendar',
  'changeMonth',
  'changeTaskEventColor',
  'changeUserColor',
  'changeView',
  'createFilter',
  'deleteFilter',
  'filterByUser',
  'filterTasksAndEvents',
  'navigateToCalendarSettings',
  'saveFilter',
  'subscribe',
  'viewToday'
]
