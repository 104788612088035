// stable sort

export default (array, property = 'name', dir = 'asc', opts = {}) => {
  if (!Array.isArray(array)) return []
  if (array.length <= 1) return array

  const withIndex = array.map((value, index) => ({ index: index, value: value }))

  withIndex.sort((a, b) => {
    const aIndex = a.index
    const bIndex = b.index

    let aLower = a.value[property]
    let bLower = b.value[property]

    if (typeof aLower === 'string') aLower = aLower.toLowerCase()
    if (typeof bLower === 'string') bLower = bLower.toLowerCase()

    if (!aLower && !bLower) return 0

    // Put null values last if the options demand it
    if (opts.nullsLast && aLower === null) return 1
    if (opts.nullsLast && bLower === null) return -1

    // If the options demand it, put obj first when obj.default === true
    if (opts.defaultFirst && a.value.default) return -1
    if (opts.defaultFirst && b.value.default) return 1

    if (dir === 'desc') {
      if (!bLower || (aLower > bLower)) return -1
      if (!aLower || (aLower < bLower)) return 1

      return aIndex < bIndex ? 1 : -1
    }

    if (!bLower || (aLower > bLower)) return 1
    if (!aLower || (aLower < bLower)) return -1

    return aIndex < bIndex ? -1 : 1
  })

  return withIndex.map((obj) => array[obj.index])
}
