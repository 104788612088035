import React from 'react'
import PropTypes from 'prop-types'

const KeyContactIcon = ({ person }) => {
  return (
    <>
      {window.Features.customer_management && person.is_key_contact && <i className="fas fa-star key-contact" style={{ marginRight: '3px' }} />}
    </>
  )
}

KeyContactIcon.propTypes = {
  person: PropTypes.object.isRequired
}

export default KeyContactIcon
