import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BusinessCard, DEAL_TYPE, PERSON_TYPE, COMPANY_TYPE } from 'components/BusinessCard'

export const BusinessCardButton = ({ itemId, type, wrapperClassName = 'centered' }) => {
  const style = window.User.external ? { cursor: 'default' } : {}
  const id = `${type}_business_card_button_${itemId}`
  const [showBusinessCard, setShowBusinessCard] = useState(false)
  return (
    <div className={wrapperClassName}>
      <a
        onClick={() => {
          window.trackableEvent.listViews.viewContactCardFromListView()
          setShowBusinessCard(true)
        }}
        style={style}
        id={id}
      >
        <i className="fas fa-address-card" />
        {showBusinessCard &&
          <BusinessCard
            type={type}
            itemId={itemId}
            element={document.getElementById(id)}
            onClose={() => setShowBusinessCard(false)}
            showPeopleCarousel={type === DEAL_TYPE}
          />}
      </a>
    </div>
  )
}

BusinessCardButton.propTypes = {
  itemId: PropTypes.number.isRequired,
  type: PropTypes.oneOf([DEAL_TYPE, PERSON_TYPE, COMPANY_TYPE]),
  wrapperClassName: PropTypes.string
}
