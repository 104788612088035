/* global App */
/* eslint-disable no-undef, camelcase, new-cap, no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import addEditRecord from 'add_edit_modals'
import { ButtonGroup, Subheader, PrimaryButton, P, colors } from '@PipelineDeals/shared-react-components'
import styled from 'styled-components'

const createReactClass = require('create-react-class')

const { CALENDAR_ENTRY } = require('permissions')
const { UPDATE } = require('permissions')
const { PermissibleButton } = require('components/Permissible')

const ButtonsContainer = styled.div`
  text-align: center;
  margin-bottom: 15px;

  div {
    display: flex !important;
    width: 100%;
  }
`

const EmptyState = styled.div`
  text-align: center;
  padding: 80px 0;
`

const DashboardAgendaContainer = ({
  selectedUserId, updateLabel, page, perPage, onPageChange
}) => {
  const [todos, setTodos] = useState([])
  const [totals, setTotals] = useState(null)
  const [selectedTab, setSelectedTab] = useState('today')

  useEffect(() => {
    window.addEventListener('calendarTask:create', () => loadTodos(selectedTab, page, selectedUserId, perPage, 'replace'))
    window.addEventListener('calendarEvent:create', () => loadTodos(selectedTab, page, selectedUserId, perPage, 'replace'))
    window.addEventListener('click', bodyClickHandler)

    return () => {
      window.removeEventListener('calendarTask:create', loadTodos)
      window.removeEventListener('calendarEvent:create', loadTodos)
      window.removeEventListener('click', bodyClickHandler)
    }
  }, [])

  useEffect(() => {
    loadTodos(selectedTab, 1, selectedUserId, perPage, 'replace')
  }, [selectedUserId, perPage])

  const loadTodos = (tab, page, userId, perPage, type) => {
    App.Stores.Dashboard.agenda(tab, page, perPage, userId).done(
      data => {
        updateDueTodayLabel(data.totals.today)
        type === 'append' ? setTodos([...todos, ...data.todos]) : setTodos(data.todos)
        setTotals(data.totals)
        setSelectedTab(tab)
      }
    )
  }

  const updateDueTodayLabel = (count) => {
    count = parseInt(count) || 0
    const label = (count > 0 && `${count} due today`) || null
    return updateLabel(label)
  }

  const bodyClickHandler = (e) => {
    return $('.js-todo-dropdown').hide()
  }

  const renderTabs = () => {
    if (totals) {
      const { overdue, today, this_week } = totals

      return (
        <ButtonsContainer>
          <ButtonGroup
            buttons={[
              {
                content: `Past Due (${overdue})`,
                isActive: selectedTab === 'overdue',
                onClick: () => {
                  window.trackableEvent.dashboard.basic.viewPastDueTasks()
                  loadTab('overdue')
                },
                className: 'col-xs-4'
              },
              {
                content: `Today (${today})`,
                isActive: selectedTab === 'today',
                onClick: () => loadTab('today'),
                className: 'col-xs-4'
              },
              {
                content: `This Week (${this_week})`,
                isActive: selectedTab === 'this_week',
                onClick: () => {
                  window.trackableEvent.dashboard.basic.viewTasksDueThisWeek()
                  loadTab('this_week')
                },
                className: 'col-xs-4'
              }
            ]}
          />
        </ButtonsContainer>
      )
    }
  }

  const renderTodos = () => {
    if (todos?.length) {
      const nodes = todos.map(todo => {
        return (
          <App.Components.Dashboard.Agenda.TodoRow
            todo={todo}
            selectedTab={selectedTab}
            key={todo.id}
            refreshTodos={() => loadTodos(selectedTab, page, selectedUserId)}
            updateCountAfterCompletion={updateCount}
            selectedUserId={selectedUserId}
          />
        )
      })

      nodes.push(changeShown())

      return React.createElement('ul', { className: 'list-group' }, nodes)
    } else if (todos?.length === 0) {
      return renderEmptyState()
    }
  }

  const changeShown = () => {
    if (
      totals &&
      totals[selectedTab] > perPage * page
    ) {
      return (
        <li className="list-group-item pagination" key="changeShown">
          <P color={colors.text.tertiary}>
            {`Viewing ${amountShown()} of ${totals[selectedTab]}.`}
            <span style={{ marginLeft: '10px' }}>{viewMore()}</span>
            <span style={{ marginLeft: '10px' }}>{viewFewer()}</span>
          </P>
        </li>
      )
    }
  }

  const amountShown = () => {
    if (
      totals[selectedTab] >
      perPage * page
    ) {
      return perPage * page
    } else {
      return totals[selectedTab]
    }
  }

  const viewMore = () => {
    return (
      <a
        href="#"
        onClick={loadMore}
        className={`${(!todos || !totals || totals[selectedTab] <= perPage * page) ? 'disabled' : ''}`}
      >
        Load More <i className="fas fa-angle-down" />
      </a>
    )
  }

  const newTask = (e) => {
    e.preventDefault()
    window.trackableEvent.dashboard.basic.clickCreateNewTask()
    addEditRecord({ resourceType: 'calendar_task' })
  }

  const viewFewer = () => {
    return (
      <a href="#" onClick={removeItems} className={`${page <= 1 ? 'disabled' : ''}`}>
        Load Less <i className="fas fa-angle-up" />
      </a>
    )
  }

  const viewFullAgenda = () => {
    let url = '/to_dos?query[todo_complete][]=No'
    if (selectedUserId !== 'any') {
      url += `&query[todo_owner][]=${selectedUserId}`
    }
    return React.createElement('div', { className: 'footer text-center' }, React.createElement(
      'a',
      { href: url, onClick: window.trackableEvent.dashboard.basic.viewFullAgenda },
      'View Full Agenda ',
      React.createElement('i', { className: 'fas fa-arrow-right small' })
    ))
  }

  const loadMore = (e) => {
    e.preventDefault()
    const newPage = page + 1

    loadTodos(selectedTab, newPage, selectedUserId, perPage, 'append')
    onPageChange(newPage)
  }

  const loadTab = (tab) => {
    const initialPage = 1
    onPageChange(initialPage)
    return loadTodos(tab, initialPage, selectedUserId, perPage, 'replace')
  }

  const updateCount = (opts) => {
    if (opts == null) {
      opts = {}
    }
    const updated_totals = _.clone(totals)
    if (opts.today) {
      updated_totals.today = opts.complete
        ? updated_totals.today - 1
        : updated_totals.today + 1
    }
    if (opts.overdue) {
      updated_totals.overdue = opts.complete
        ? updated_totals.overdue - 1
        : updated_totals.overdue + 1
    }
    if (opts.dueThisWeek) {
      updated_totals.this_week = opts.complete
        ? updated_totals.this_week - 1
        : updated_totals.this_week + 1
    }

    updateDueTodayLabel(updated_totals.today)
    return setTotals(updated_totals)
  }

  const removeItems = (e) => {
    e.preventDefault()
    const newPage = page - 1

    const newTodos = todos.slice(0, todos.length - perPage)
    setTodos(newTodos)
    onPageChange(newPage)
  }

  const renderEmptyState = () => {
    return (
      <EmptyState>
        <i className="far fa-check-square fa-3x" />
        <div style={{ marginTop: '20px' }}>
          <Subheader>You have no tasks due in this timeframe</Subheader>
        </div>
        <div style={{ marginTop: '15px' }}>
          {createTaskLink()}
        </div>
      </EmptyState>
    )
  }

  const createTaskLink = () => {
    return (
      <PermissibleButton
        action={UPDATE}
        cypress="empty-agenda-create"
        policy={CALENDAR_ENTRY}
        user={window.User}
      >
        <PrimaryButton content="Create New Task" onClick={newTask} />
      </PermissibleButton>
    )
  }

  return (
    <div>
      {renderTabs()}
      {renderTodos()}
      {viewFullAgenda()}
    </div>
  )
}

DashboardAgendaContainer.defaultProps = {
  perPage: 5
}

DashboardAgendaContainer.propTypes = {
  selectedUserId: PropTypes.any,
  updateLabel: PropTypes.func,
  page: PropTypes.number,
  perPage: PropTypes.number,
  onPageChange: PropTypes.func
}

export default DashboardAgendaContainer
