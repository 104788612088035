import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { TertiaryButton } from '@PipelineDeals/shared-react-components'
import { useOnOutsideClick } from 'hooks/useOnOutsideClick'

const DropdownContainer = styled.ul`
  width: 220px;
`
const DealsSelector = ({ label, onDropdownItemClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  const node = useRef()
  useOnOutsideClick({ node, showComponent: isOpen, onOutsideClick: () => setIsOpen(false) })

  const handleClick = (e) => {
    onDropdownItemClick(e)
    setIsOpen(false)
  }

  const handleActiveClick = (e) => {
    window.trackableEvent.dashboard.basic.viewActiveDeals()
    handleClick(e)
  }

  const handleExpectedClick = (e) => {
    window.trackableEvent.dashboard.basic.viewDealsExpectedToClose()
    handleClick(e)
  }

  return (
    <div ref={node} className={classnames('dropdown dropdown-menu-pld', isOpen && 'open')} style={{ display: 'inline-block' }}>
      <TertiaryButton content={label} trailingContent={<i className="fas fa-caret-down" />} onClick={() => setIsOpen(!isOpen)} />

      <DropdownContainer className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
        <li key="active_deals" className="dropdown-list-item">
          <a tabIndex="-1" href="#" onClick={handleActiveClick} data-link-val="All active deals">Active</a>
        </li>
        <li key="expected_to_close" className="dropdown-list-item">
          <a tabIndex="-1" href="#" onClick={handleExpectedClick} data-link-val="expected to close">
            <div data-link-val="expected to close">Expected to close</div>
            <div data-link-val="expected to close" className="supplemental-info" style={{ marginTop: '-2px', color: '#949494', fontSize: '13px' }}>in the selected timeframe</div>
          </a>
        </li>
      </DropdownContainer>
    </div>
  )
}

DealsSelector.propTypes = {
  label: PropTypes.string.isRequired,
  onDropdownItemClick: PropTypes.func.isRequired
}

export default DealsSelector
