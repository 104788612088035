/* eslint-disable
    no-undef,
    no-useless-escape,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.regularMailtoAndBccPipelineUser = function (email) {
  return `<a class='non-vipecloud' href='mailto:${Helpers.escapeText(email)}?bcc=cc@app.pipelinecrm.com' target='_blank'>${Helpers.escapeText(email)}</a>`
}

Handlebars.registerHelper('regularMailtoAndBccPipelineUser', email => new Handlebars.SafeString(Helpers.regularMailtoAndBccPipelineUser(email)))

Helpers.composeEmail = email => `<a class='non-vipecloud js-compose-email track' data-user-action='Connect - compose from profile' data-email='${Helpers.escapeText(email)}'>${Helpers.escapeText(email)}</a>`

Handlebars.registerHelper('composeEmail', email => new Handlebars.SafeString(Helpers.composeEmail(email)))

Helpers.mailtoAndBccPipelineUser = function (email, options) {
  if (options == null) { options = {} }
  if (options.bounced) {
    return Helpers.mailtoBouncedUser(email)
  }

  if (User.connectSynced && User.pipelineEmailClient) {
    return Helpers.composeEmail(email)
  } else {
    return Helpers.regularMailtoAndBccPipelineUser(email)
  }
}

Handlebars.registerHelper('mailtoAndBccPipelineUser', (email, unsubscribed, bounced) => new Handlebars.SafeString(Helpers.mailtoAndBccPipelineUser(email, {unsubscribed, bounced})))

Helpers.mailtoBouncedUser = email => `<a onclick='Helpers.notify(\"Cannot send email\", \"Previous message to this person bounced by destination server.\")'>${Helpers.escapeText(email)}</a>`
