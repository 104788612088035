export default [
  'addMobile',
  'addWorkPhone',
  'changeCSVFormatting',
  'changeCalendarStartDay',
  'changeCalendarTruncationSettings',
  'changeComissionRate',
  'changeConnectNotificationType',
  'changePassword',
  'checkSendMeText',
  'clickDownloadExcel',
  'clickMicrosoftContact',
  'customizeEmailSignature',
  'deletePhoto',
  'disableCoffeeReport',
  'disableConnectEmailNotifications',
  'disableDealAssignedNotifications',
  'disableDealWonNotifications',
  'disableEmailSync',
  'disableEventReminders',
  'disableKeyboardShortcuts',
  'disableMFA',
  'disableMentionsNotifications',
  'disableMergeCompletionNotifications',
  'disableMilestoneNotifications',
  'disablePersonalUseReport',
  'disableRecordCreationNotifications',
  'disableRecordDeletionNotifications',
  'disableRecordRestoreNotifications',
  'disableTaskAssignedNotifications',
  'disableTaskCompleteNotifications',
  'disableTeamUseReport',
  'disableTrackableOpenNotifications',
  'disableTrackableViewNotifications',
  'editDateFormat',
  'editEmailAddress',
  'editFirstName',
  'editLastName',
  'editTimeFormat',
  'editTimeZone',
  'enableCoffeeReport',
  'enableConnectEmailNotifications',
  'enableDealAssignedNotifications',
  'enableDealWonNotifications',
  'enableEventReminders',
  'enableKeyboardShortcuts',
  'enableMFA',
  'enableMentionsNotifications',
  'enableMergeCompletionNotifications',
  'enableMilestoneNotifications',
  'enablePersonalUseReport',
  'enableRecordCreationNotifications',
  'enableRecordDeletionNotifications',
  'enableRecordRestoreNotifications',
  'enableTaskAssignedNotifications',
  'enableTaskCompleteNotifications',
  'enableTeamUseReport',
  'enableTrackableOpenNotifications',
  'enableTrackableViewNotifications',
  'replacePhoto',
  'resetAPIKey',
  'setCurrency',
  'useDefaultEmailClient',
  'usePipelineEmailClient'
]
