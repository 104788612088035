import React, { useState, createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { profilePush } from 'actions/index'

import { DEFAULT_FIELDS } from './constants'
const BusinessCardContext = createContext(undefined)

const BusinessCardProvider = ({ children, resource, resourceId, resourceType, ...props }) => {
  const dispatch = useDispatch()

  const [currentUser] = useState(props.currentUser)
  const [cardMeta, setCardMeta] = useState({
    element: props.element,
    fromCarousel: props.fromCarousel,
    hideOnLeave: props.hideOnLeave,
    highlighter: props.highlighter,
    isPrimary: props.isPrimary,
    linkifyName: props.linkifyName,
    markPrimary: props.markPrimary,
    showAssociationsCount: props.showAssociationsCount,
    showDeleteLink: props.showDeleteLink,
    showEditLink: true,
    showOnlyFields: (props.showOnlyFields || DEFAULT_FIELDS[resourceType]).concat(props.showAdditionalFields),
    showPeopleCarousel: props.showPeopleCarousel,
    overflowing: false
  })

  const onClick = (event) => {
    if (props.onClick) {
      props.onClick(event)
    } else {
      dispatch(profilePush({ event, type: resourceType, id: resourceId }))
    }
  }

  const [callbacks, setCallbacks] = useState({
    avatarUpdateCallback: props.avatarUpdateCallback,
    onClick: onClick,
    onClose: props.onClose,
    onDeleteLinkClicked: props.onDeleteLinkClicked,
    onMarkPrimary: props.onMarkPrimary,
    setIsOver: props.setIsOver
  })

  useEffect(() => {
    setCardMeta({
      ...cardMeta,
      element: props.element,
      fromCarousel: props.fromCarousel,
      hideOnLeave: props.hideOnLeave,
      highlighter: props.highlighter,
      isPrimary: props.isPrimary,
      linkifyName: props.linkifyName,
      markPrimary: props.markPrimary,
      showAssociationsCount: props.showAssociationsCount,
      showDeleteLink: props.showDeleteLink,
      showOnlyFields: (props.showOnlyFields || DEFAULT_FIELDS[resourceType]).concat(props.showAdditionalFields),
      showPeopleCarousel: props.showPeopleCarousel
    })

    setCallbacks({
      ...callbacks,
      avatarUpdateCallback: props.avatarUpdateCallback,
      onClose: props.onClose,
      onDeleteLinkClicked: props.onDeleteLinkClicked,
      onMarkPrimary: props.onMarkPrimary,
      setIsOver: props.setIsOver
    })
  }, [JSON.stringify({ ...props, element: null })])

  return (
    <BusinessCardContext.Provider value={[{ resource, resourceId, resourceType, cardMeta, setCardMeta, callbacks, setCallbacks, currentUser }]}>
      {children}
    </BusinessCardContext.Provider>
  )
}

BusinessCardProvider.propTypes = {
  children: PropTypes.node.isRequired,

  resource: PropTypes.object,
  resourceId: PropTypes.number,
  resourceType: PropTypes.string,

  // currentUser
  currentUser: PropTypes.object,

  // ui
  element: PropTypes.object,
  fromCarousel: PropTypes.bool,
  hideOnLeave: PropTypes.bool,
  highlighter: PropTypes.string,
  isPrimary: PropTypes.bool,
  linkifyName: PropTypes.bool,
  markPrimary: PropTypes.bool,
  showAdditionalFields: PropTypes.array,
  showAssociationsCount: PropTypes.bool,
  showDeleteLink: PropTypes.bool,
  showOnlyFields: PropTypes.array,
  showPeopleCarousel: PropTypes.bool,

  // callbacks
  avatarUpdateCallback: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteLinkClicked: PropTypes.func,
  onMarkPrimary: PropTypes.func,
  setIsOver: PropTypes.func
}

BusinessCardProvider.defaultProps = {
  currentUser: window.User,

  // ui
  fromCarousel: false,
  hideOnLeave: false,
  isPrimary: false,
  linkifyName: true,
  markPrimary: false,
  showAdditionalFields: [],
  showAssociationsCount: false,
  showDeleteLink: false,
  showPeopleCarousel: false
}

const useBusinessCard = () => {
  const [context] = useContext(BusinessCardContext)

  if (!context) {
    throw new Error('useBusinessCard must be used within a BusinessCardProvider')
  }

  return context
}

export { BusinessCardContext, BusinessCardProvider, useBusinessCard }
