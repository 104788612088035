import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import mailToPipelineUserLink from 'modules/mailToPipelineUserLink'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import { useBusinessCard } from 'components/BusinessCard/context'
import Highlighter from 'components/BusinessCard/components/Highlighter'

const ContactInfoEmails = ({ emailAttrs }) => {
  const { cardMeta: { highlighter, showOnlyFields }, resource, resourceId } = useBusinessCard()

  const emails = useMemo(() => {
    return emailAttrs.filter(emailType => showOnlyFields.includes(emailType)).map(key => resource[key]).filter(e => !!e)
  }, [emailAttrs, resource, showOnlyFields])

  if (!emails.length) return null

  return (
    <Section>
      {emails.map((email, index) =>
        <Row key={`${resourceId}-${index}-send-email-to-${email}`}>
          <Left><i className="fas fa-envelope" /></Left>
          <Right>
            <span>
              {mailToPipelineUserLink(email, <Highlighter term={highlighter}>{email}</Highlighter>)}
            </span>
          </Right>
        </Row>
      )}
    </Section>
  )
}

ContactInfoEmails.propTypes = {
  emailAttrs: PropTypes.array.isRequired
}

export default ContactInfoEmails
