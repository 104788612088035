import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import {
  productLineGoalsPersistSuccess,
  productLineGoalsPersistFailure
} from 'Goals/actions/ProductLines'

import {
  PRODUCT_LINE_GOALS_PERSIST
} from 'Goals/constants/ProductLines'

const normalizeGoalPayload = (payload) => {
  delete payload.mode

  payload.goal_config = payload.goal_config.map(config => {
    config.amount = parseFloat(config.amount)
    return config
  })

  return payload
}

export function * productLineGoalsPersistSaga (action) {
  try {
    const { payload } = action
    const { mode, id } = payload
    let response = null

    const normalizedPayload = normalizeGoalPayload(payload)

    // Select backend action based on persistence mode.
    switch (mode) {
      case 'create':
        response = yield call(
          fetcher,
          '/api/internal/goals/product_lines.json', {
            body: JSON.stringify({ goal: { ...normalizedPayload } }),
            method: 'POST'
          }
        )
        break
      case 'edit':
        response = yield call(
          fetcher,
          `/api/internal/goals/product_lines/${id}.json`, {
            body: JSON.stringify({ goal: { ...normalizedPayload } }),
            method: 'PUT'
          }
        )
        break
      case 'duplicate':
        response = yield call(
          fetcher,
          `/api/internal/goals/product_lines/${id}/duplicate.json`, {
            body: JSON.stringify({ goal: { ...normalizedPayload } }),
            method: 'PUT'
          }
        )
        break
      default:
        console.error(
          `Invalid Product Line goal persistence mode: ${mode}. Expected 'create', 'edit' or 'duplicate'`
        )
        break
    }
    yield put(productLineGoalsPersistSuccess({ goal: response.goal }))
  } catch (exception) {
    console.error(exception)

    yield put(productLineGoalsPersistFailure({ error: exception.message }))
  }
}

export function * watchProductLineGoalsPersistSaga () {
  yield call(takeEvery, PRODUCT_LINE_GOALS_PERSIST, productLineGoalsPersistSaga)
}
