import {
  ACTIVITY_CREATE,
  ACTIVITY_DELETE,
  ACTIVITY_UPDATE,
  AGENDA_CREATE,
  AVATAR_UPLOAD,
  CREATE,
  FILE_CREATE,
  FILE_UPDATE,
  FILE_DELETE,
  FILE_TAG,
  MANAGE,
  MILESTONE_CREATE,
  UPDATE,
  ALLOWED_PRIVATE_NOTES
} from 'permissions/actions'
import ownerId from 'permissions/ownerId'
import { userLevels } from 'permissions/userLevels'

export const CUSTOMER = '@@permissions/policy/customer'

export default {
  [ACTIVITY_CREATE]: user => true,
  [ACTIVITY_DELETE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [ACTIVITY_UPDATE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [AGENDA_CREATE]: user => !user.read_only,
  [AVATAR_UPLOAD]: user => !user.read_only,
  [CREATE]: user => !user.read_only,
  [FILE_CREATE]: user => !user.read_only,
  [FILE_UPDATE]: (user, { object, objectType }) => !user.read_only && (user.level > 1 || ownerId(object, objectType) === user.id),
  [FILE_DELETE]: (user, { object, objectType }) => !user.read_only && (user.level > 1 || ownerId(object, objectType) === user.id),
  [FILE_TAG]: user => !user.read_only,
  [MANAGE]: user => !user.read_only,
  [MILESTONE_CREATE]: user => !user.read_only,
  [UPDATE]: user => !user.read_only,
  [ALLOWED_PRIVATE_NOTES]: (user, options) => window.Features.private_notes && (user.level === userLevels.EXECUTIVE || user.is_account_admin)
}
