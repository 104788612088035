import update from 'immutability-helper'

import * as constants from './constants'
import { getCustomFieldLabels } from './selectors'

export const initialState = {
  groups: [],
  isFetching: false,
  labels: [],
  labelsInMovement: [],
  total: 0
}

export const editCustomFieldGroup = (state, group) => {
  if (state.id !== group.id) return state

  return {
    ...state,
    ...group
  }
}

export const editCustomFieldLabel = (state, label) => {
  if (state.id !== label.id) return state

  return {
    ...state,
    ...label
  }
}

export const updateCustomFieldLabels = (state, labels) => {
  return state.map(label => {
    const match = labels.find(l => label.id === l.id)
    if (!match) return label

    return editCustomFieldLabel(label, match)
  })
}

export const nullifyCustomFieldGroupId = (state, group, position) => {
  if (state.custom_field_group_id !== group.id) return state

  return {
    ...state,
    custom_field_group_id: null,
    position: position()
  }
}

export const repositionCustomFieldLabels = (labels, groupId, type, reposition) => {
  const labelsToReposition = getCustomFieldLabels({ customFields: { labels } }, { groupId, type })

  return labels.map(label => {
    if (!labelsToReposition.includes(label)) return label

    return {
      ...label,
      position: reposition(label)
    }
  })
}

const repositionOnUpdate = (moved, originalPosition) =>
  moved.position > originalPosition ? repositionOnPositiveUpdate(moved, originalPosition) : repositionOnNegativeUpdate(moved, originalPosition)

const repositionOnPositiveUpdate = (moved, originalPosition) => label => {
  if (label.id === moved.id || label.position < originalPosition || label.position > moved.position) {
    return label.position
  } else {
    return label.position - 1
  }
}

const repositionOnNegativeUpdate = (moved, originalPosition) => label => {
  if (label.id === moved.id || label.position < moved.position || label.position > originalPosition) {
    return label.position
  } else {
    return label.position + 1
  }
}

const repositionOnDelete = deleted => label => {
  if (label.position > deleted.position) {
    return label.position - 1
  } else {
    return label.position
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.FETCH_CUSTOM_FIELDS:
      return {
        ...state,
        isFetching: true
      }
    case constants.RECEIVE_CUSTOM_FIELDS:
      return {
        ...state,
        ...action.payload,
        isFetching: false
      }
    case constants.NEW_CUSTOM_FIELD_LABEL_FORM_SUCCESS:
      return {
        ...state,
        total: state.total + 1,
        labels: [...state.labels, action.payload.label]
      }
    case 'DELETE_CUSTOM_FIELD_GROUP_SUCCESS': {
      let nextPosition = action.payload.nextPosition
      const position = () => nextPosition++

      return {
        ...state,
        groups: state.groups.filter(group => group.id !== action.payload.group.id),
        labels: state.labels.map(label => nullifyCustomFieldGroupId(label, action.payload.group, position))
      }
    }
    case 'DELETE_CUSTOM_FIELD_LABEL_SUCCESS':
      return {
        ...state,
        total: state.total - 1,
        labels: repositionCustomFieldLabels(
          state.labels.filter(label => label.id !== action.payload.id),
          action.payload.custom_field_group_id,
          action.payload.type,
          repositionOnDelete(action.payload)
        )
      }
    case 'EDIT_CUSTOM_FIELD_GROUP_FORM_SUCCESS':
      return {
        ...state,
        groups: state.groups.map(group => editCustomFieldGroup(group, action.payload))
      }
    case constants.EDIT_CUSTOM_FIELD_LABEL_FORM_SUCCESS: {
      const { label, previous } = action.payload

      let labels = state.labels.map(l => editCustomFieldLabel(l, label))

      if (label.custom_field_group_id !== previous.custom_field_group_id) {
        labels = repositionCustomFieldLabels(
          labels,
          previous.custom_field_group_id,
          previous.type,
          repositionOnDelete(previous)
        )
      }

      return {
        ...state,
        labels
      }
    }
    case constants.MOVE_CUSTOM_FIELD_LABEL_CUSTOM_FIELD_GROUP:
      return {
        ...state,
        labelsInMovement: update(state.labelsInMovement, { $push: [action.payload.id] })
      }
    case constants.UPDATE_CUSTOM_FIELD_LABEL_SUCCESS: {
      let updateLabels = state.labels.map(l => editCustomFieldLabel(l, action.payload.label))

      if (action.payload.label.custom_field_group_id !== action.payload.previous.custom_field_group_id) {
        updateLabels = repositionCustomFieldLabels(
          updateLabels,
          action.payload.previous.custom_field_group_id,
          action.payload.previous.type,
          repositionOnDelete(action.payload.previous)
        )
      }

      return {
        ...state,
        labels: updateLabels,
        labelsInMovement: state.labelsInMovement.filter(id => id !== action.payload.label.id)
      }
    }
    case 'NEW_CUSTOM_FIELD_GROUP_FORM_SUCCESS':
      return {
        ...state,
        groups: [...state.groups, action.payload]
      }
    case constants.REPOSITION_CUSTOM_FIELD_LABEL_SUCCESS:
      return {
        ...state,
        labels: repositionCustomFieldLabels(
          state.labels.map(label => editCustomFieldLabel(label, action.payload.label)),
          action.payload.label.custom_field_group_id,
          action.payload.label.type,
          repositionOnUpdate(action.payload.label, action.payload.originalPosition)
        )
      }
    default:
      return state
  }
}
