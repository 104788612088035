import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  activityCategoryCreate,
  activityCategoryCreateFailure,
  activityCategoryUpdate,
  activityCategoryUpdateFailure,
  activityCategoryDelete,
  activityCategoryDeleteFailure
} from '../actions'

import {
  ACTIVITY_CATEGORY_CREATE_SUBMIT,
  ACTIVITY_CATEGORY_UPDATE_SUBMIT,
  ACTIVITY_CATEGORY_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/note_categories',
      {
        method: 'POST',
        body: JSON.stringify({
          note_category: {
            name: payload.name
          }
        })
      }
    )
    yield put(activityCategoryCreate(response))
  } catch (expection) {
    console.error(expection.message)
    yield put(activityCategoryCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, ACTIVITY_CATEGORY_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/note_categories/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          note_category: {
            name: payload.name
          }
        })
      }
    )
    yield put(activityCategoryUpdate(response))
  } catch (exception) {
    yield put(activityCategoryUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, ACTIVITY_CATEGORY_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/note_categories/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(activityCategoryDelete(payload))
  } catch (exception) {
    console.error(exception.message)
    yield put(activityCategoryDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, ACTIVITY_CATEGORY_DELETE_SUBMIT, deleteSaga)
}
