export const checkText = (trigger, value) => {
  const valueString = (value === null || value === undefined) ? '' : String(value)

  switch (trigger.name) {
    case 'Is':
      return textIs(valueString, trigger.operand)
    case 'IsNot':
      return textIsNot(valueString, trigger.operand)
    case 'Contains':
      return textContains(valueString, trigger.operand)
    case 'DoesNotContain':
      return textDoesNotContain(valueString, trigger.operand)
    case 'IsBlank':
      return textIsBlank(valueString)
    case 'IsNotBlank':
      return textIsNotBlank(valueString)
    default:
      return null
  }
}

export default checkText

const textIs = (value, operand) => {
  if (value === operand) {
    return {
      message: `${value} is ${operand}`
    }
  }
  return null
}

const textIsNot = (value, operand) => {
  if (value !== operand) {
    return {
      message: `${value} is not ${operand}`
    }
  }
  return null
}

const textContains = (value, operand) => {
  if (value.includes(operand)) {
    return {
      message: `${value} contains ${operand}`
    }
  }
  return null
}

const textDoesNotContain = (value, operand) => {
  if (!value.includes(operand)) {
    return {
      message: `${value} does not contain ${operand}`
    }
  }
  return null
}

const textIsBlank = value => {
  if (value === '') {
    return {
      message: 'is blank'
    }
  }
  return null
}

const textIsNotBlank = value => {
  if (value !== '') {
    return {
      message: 'is not blank'
    }
  }
  return null
}
