import {
  FILE_CREATE,
  FILE_UPDATE,
  FILE_DELETE,
  FILE_TAG
} from 'permissions/actions'

import { userLevels } from 'permissions/userLevels'

export const SHARED_FILES = '@@permissions/policy/sharedFiles'

export default {
  [FILE_CREATE]: (user) => !user.read_only && user.level === userLevels.EXECUTIVE,
  [FILE_UPDATE]: (user) => !user.read_only && user.level === userLevels.EXECUTIVE,
  [FILE_DELETE]: (user) => !user.read_only && user.level === userLevels.EXECUTIVE,
  [FILE_TAG]: (user) => !user.read_only && user.level === userLevels.EXECUTIVE
}
