import {
  BULK_ACTIONS,
  CREATE,
  LIST_VIEW_CREATE,
  LIST_VIEW_DELETE,
  LIST_VIEW_DUPLICATE,
  LIST_VIEW_SHARE,
  LIST_VIEW_STAR,
  LIST_VIEW_UPDATE,
  UPDATE,
  LIST_VIEW_CREATE_FOCUSED
} from 'permissions/actions'

export const RECIPIENT = '@@permissions/policy/recipient'

export default {
  [BULK_ACTIONS]: user => !user.read_only,
  [CREATE]: user => !user.read_only,
  [LIST_VIEW_CREATE]: user => !user.read_only,
  [LIST_VIEW_DELETE]: user => !user.read_only,
  [LIST_VIEW_DUPLICATE]: user => !user.read_only,
  [LIST_VIEW_SHARE]: user => !user.read_only,
  [LIST_VIEW_STAR]: user => !user.read_only,
  [LIST_VIEW_UPDATE]: user => !user.read_only,
  [UPDATE]: user => !user.read_only,
  [LIST_VIEW_CREATE_FOCUSED]: user => !user.read_only && !user.external
}
