/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.formatDealStatus = function (deal) {
  return `<span><i class='fas fa-circle' style='color: ${deal.status_color}'></i> ${deal.status_name}</span>`
}

Handlebars.registerHelper('formatDealStatus', function (status) {
  if ((status === null) || (typeof status === 'undefined')) {
    status = ''
  } else if (!(_.isString(status) || _.isNumber(status))) {
    status = status.name
  }

  return new Handlebars.SafeString(Helpers.formatDealStatus(status))
})
