import PropTypes from 'prop-types'
import React from 'react'

import { Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import Highlighter from 'components/BusinessCard/components/Highlighter'
import { useBusinessCard } from 'components/BusinessCard/context'

const PhoneNumber = ({ label, number, type }) => {
  const { cardMeta: { highlighter } } = useBusinessCard()

  return (
    <Row>
      {label &&
        <Left>
          <span className="title">{label}</span>
        </Left>}
      <Right>
        <a href={`tel:${number}`} data-cypress="phone-number-link"><Highlighter term={highlighter}>{number}</Highlighter></a>
      </Right>
    </Row>
  )
}

PhoneNumber.propTypes = {
  label: PropTypes.string,
  number: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default PhoneNumber
