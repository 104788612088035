const legacyColors = {
  blue_light: '#99c8e4',
  green: '#25b774',
  yellow: '#ffda58',
  red: '#ff6961',
  brown_light: '#a38f7e',
  orange: '#ffa43f',
  blue_dark: '#00588d',
  purple_light: '#b2a1db',
  blue: '#0076bc',
  green_dark: '#0d7f46',
  brown: '#6b645f',
  purple: '#886bc1',
  gray: '#ccc',
  pastel_34520: '#d5dcfc',
  pastel_34519: '#d9cbf9',
  pastel_34518: '#c8fdcc',
  pastel_34517: '#d3b9c5',
  pastel_34516: '#e7d9d3',
  pastel_34521: '#fee7ea',
  pastel_34515: '#cafee9',
  pastel_34506: '#f9edd7'
}

// Source - https://colorcet.holoviz.org/user_guide/Categorical.html
const newColors = {
  blue_bright: '#0094CB',
  red_bright: '#F03B2B',
  gold_bright: '#CE9239',
  green_vivid: '#56B142',
  teal_deep: '#008F9C',
  purple_dark: '#694888',
  red_dark: '#B0121F',
  blue_deep: '#005886',
  pink_muted: '#B25391',
  brown_dark: '#653A34',
  purple_very_dark: '#3a0182',
  green_dark: '#004200',
  mint: '#0FFFA8',
  burgundy: '#5D003F',
  lavender_light: '#C6BCFF',
  magenta_deep: '#B80080',
  pink_soft: '#FFB6B3',
  red_deep_dark: '#7C0100',
  blue_violet: '#6026FF',
  yellow_light: '#FFFF9A',
  green_grayish: '#AEC8AA',
  teal_dark: '#00857C',
  brown_very_dark: '#543A00',
  cyan_light: '#93FBFF',
  green_bright: '#00BF00',
  purple: '#7C00A0',
  lime: '#90FF00',
  teal: '#01BD89',
  blue_darker: '#00447B',
  beige: '#C8826E',
  pink_bright: '#FF1F82',
  magenta: '#DD00FF',
  green_deep: '#057400',
  lavender: '#878EFF',
  pink_pale: '#FFB5F4',
  green_dark_muted: '#526236',
  purple_light: '#CD85FF',
  gray_pink: '#BDB3BD',
  pink_grayish: '#A56089',
  blue_sky: '#95D3FF',
  blue_deep_bright: '#0100F7',
  orange_bright: '#FF8001',
  red_purple: '#8A2844',
  khaki: '#ACA06D'
}

const colors = () => window.Features.reporting_v3 ? newColors : legacyColors

// http://stackoverflow.com/a/25709983
// We should generate colours based on input (e.g. DealLossReason name)
const letters = '0123456789ABCDEF'.split('')

export const getRandomColor = () => {
  let color = '#'

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }

  return color
}

export const getColor = (index) => {
  const availableColors = Object.values(colors())
  if (index < availableColors.length) {
    return availableColors[index]
  } else {
    return getRandomColor()
  }
}

export const getColors = (num) => {
  const colors = []

  for (let i = 0; i < num; i++) {
    colors.push(getColor(i))
  }

  return colors
}

export const getColorByName = (name) => {
  return colors()[name.toLowerCase()]
}

export const getDealStatusColor = (dealStatusId, dealStatuses) => {
  const dealStatus = dealStatuses.find(ds => ds.id === dealStatusId)
  return dealStatus ? `#${dealStatus.hex_color}` : getColor(0)
}
