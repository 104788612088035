export const ACTIVITY_CATEGORY_CREATE = 'ACTIVITY_CATEGORY_CREATE'
export const ACTIVITY_CATEGORY_CREATE_SUBMIT = 'ACTIVITY_CATEGORY_CREATE_SUBMIT'
export const ACTIVITY_CATEGORY_CREATE_FAILURE = 'ACTIVITY_CATEGORY_CREATE_FAILURE'

export const ACTIVITY_CATEGORY_UPDATE = 'ACTIVITY_CATEGORY_UPDATE'
export const ACTIVITY_CATEGORY_UPDATE_SUBMIT = 'ACTIVITY_CATEGORY_UPDATE_SUBMIT'
export const ACTIVITY_CATEGORY_UPDATE_FAILURE = 'ACTIVITY_CATEGORY_UPDATE_FAILURE'

export const ACTIVITY_CATEGORY_DELETE = 'ACTIVITY_CATEGORY_DELETE'
export const ACTIVITY_CATEGORY_DELETE_SUBMIT = 'ACTIVITY_CATEGORY_DELETE_SUBMIT'
export const ACTIVITY_CATEGORY_DELETE_FAILURE = 'ACTIVITY_CATEGORY_DELETE_FAILURE'
