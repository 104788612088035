export default [
  'createCompany',
  'createDeal',
  'createEvent',
  'createPerson',
  'createTask',
  'editCompany',
  'editDeal',
  'editEvent',
  'editPerson',
  'editTask'
]
