import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import {
  externalUserSearchesFetchSuccess,
  externalUserSearchesFetchFailure
} from './actions'

import {
  EXTERNAL_USER_SEARCHES_FETCH_REQUEST
} from './constants'

export function * searchesFetchSaga ({ payload }) {
  try {
    const searches = yield call(
      fetcher,
      '/api/internal/external_user_shared_searches'
    )

    const { pagination: { pages, per_page } } = searches

    let { entries: concatenatedSearches, pagination: { page } } = searches

    while (page <= pages) {
      if (page > 1) {
        const response = yield call(
          fetcher,
          '/api/internal/external_user_shared_searches',
          { query: { page, per_page } }
        )
        concatenatedSearches = concatenatedSearches.concat(response.entries)
      }

      page++
    }
    yield put(externalUserSearchesFetchSuccess(concatenatedSearches))
  } catch (exception) {
    console.error(exception)
    yield put(externalUserSearchesFetchFailure({ _error: exception.message }))
  }
}

export function * watchSearchesFetchSaga () {
  yield call(takeEvery, EXTERNAL_USER_SEARCHES_FETCH_REQUEST, searchesFetchSaga)
}
