import sanitizeHtml from 'sanitize-html'

export default (content) => {
  if (!content) return content
  return sanitizeText(content)
}

const sanitizeText = (content) => {
  let sanitizedContent = content
  if (sanitizedContent.indexOf('<html>') === -1 && sanitizedContent.indexOf('<!DOCTYPE') === -1) {
    sanitizedContent = sanitizedContent.replace(/(\n\r?)/g, '<br />')
  }
  sanitizedContent = sanitizedContent.replace(/(<\s*br\s*\/?\s*>\s*){2,}/g, '<br /><br />')
  return sanitizeHtml(sanitizedContent, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'center'])
  })
}
