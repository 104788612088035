import {
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE
} from 'constants/Subscription'

const initialState = {
  plan: ''
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        plan: payload.plan
      }
    case SUBSCRIPTION_FAILURE:
      return {
        ...state,
        plan: ''
      }
    default:
      return state
  }
}
