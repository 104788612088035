export const CUSTOMER_COMPANY_FETCH_SUCCESS = 'CUSTOMER_COMPANY_FETCH_SUCCESS'
export const CUSTOMER_DEAL_ACTIVE_FILTER = 'CUSTOMER_DEAL_ACTIVE_FILTER'
export const CUSTOMER_DEAL_NEW_SUCCESS = 'CUSTOMER_DEAL_NEW_SUCCESS'
export const CUSTOMER_FETCH = 'CUSTOMER_FETCH'
export const CUSTOMER_FETCH_ERROR = 'CUSTOMER_FETCH_ERROR'
export const CUSTOMER_FETCH_SUCCESS = 'CUSTOMER_FETCH_SUCCESS'
export const CUSTOMER_FETCH_VISIBLE_USERS = 'CUSTOMER_FETCH_VISIBLE_USERS'
export const CUSTOMER_MILESTONES_FILTER_ALL = 'CUSTOMER_MILESTONES_FILTER_ALL'
export const CUSTOMER_MILESTONES_FILTER_MINE = 'CUSTOMER_MILESTONES_FILTER_MINE'
export const CUSTOMER_PERSON_FILTER_ALL = 'CUSTOMER_PERSON_FILTER_ALL'
export const CUSTOMER_COMPANY_FILTER_ALL = 'CUSTOMER_COMPANY_FILTER_ALL'
export const CUSTOMER_PERSON_NEW_SUCCESS = 'CUSTOMER_PERSON_NEW_SUCCESS'
export const CUSTOMER_PROFILE_FETCH = 'CUSTOMER_PROFILE_FETCH'
export const CUSTOMER_PROFILE_RESET = 'CUSTOMER_PROFILE_RESET'
export const CUSTOMER_RELOAD_TODOS = 'CUSTOMER_RELOAD_TODOS'
export const CUSTOMER_COMPANY_UPDATE = 'CUSTOMER_COMPANY_UPDATE'
export const CUSTOMER_COMPANY_RELOAD = 'CUSTOMER_COMPANY_RELOAD'
export const CUSTOMER_CONDITIONAL_FIELDS_VALIDATION = 'CUSTOMER_CONDITIONAL_FIELDS_VALIDATION'
export const CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE = 'CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE'
export const CUSTOMER_FETCH_CHILDREN_COMPANIES = 'CUSTOMER_FETCH_CHILDREN_COMPANIES'
export const CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES = 'CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES'
export const CUSTOMER_FILTER_CHILDREN_COMPANIES = 'CUSTOMER_FILTER_CHILDREN_COMPANIES'
export const CUSTOMER_SORT_CHILDREN_COMPANIES = 'CUSTOMER_SORT_CHILDREN_COMPANIES'
