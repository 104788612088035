/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
ppd.assetPath = function (path) {
  const fullPath = require(`images/${path}`)
  return fullPath
}

Handlebars.registerHelper('assetPath', path => new Handlebars.SafeString(ppd.assetPath(path)))
