import { createSelector } from 'reselect'
import { getTodoTemplates } from 'selectors/Account'
import { getCustomerChildrenCompanies } from './children_companies'
import { getCustomerCompanySelector } from './customer'

export * from './customer'

export const getCustomerTodoTemplates = createSelector(
  [getTodoTemplates],
  (templates) => {
    return templates.filter(template => template.template_type === 'customers' || template.todo_template_types.find((types) => types.template_type === 'customers'))
  }
)

export const getChildrenCompaniesAndSelf = createSelector(
  [getCustomerCompanySelector, getCustomerChildrenCompanies],
  (company, childrenCompanies) => {
    return [company, ...childrenCompanies]
  }
)
