/* global jQuery */

import store from 'store'
import {
  getMergeTags,
  getCompanyCustomFieldLabels,
  getDealCustomFieldLabels,
  getPersonCustomFieldLabels,
  getCompanyCustomFieldGroups,
  getDealCustomFieldGroups,
  getPersonCustomFieldGroups
} from 'selectors/Account'

jQuery.fn.setCursorPosition = function (pos) {
  this.each(function (_index, elem) {
    if (elem.setSelectionRange) {
      elem.setSelectionRange(pos, pos)
    } else if (elem.createTextRange) {
      var range = elem.createTextRange()
      range.collapse(true)
      range.moveEnd('character', pos)
      range.moveStart('character', pos)
      range.select()
    }
  })
  return this
}

export const insertAtCursor = (field, value) => {
  // IE support
  if (document.selection) {
    field.focus()
    const sel = document.selection.createRange()
    sel.text = value
    jQuery(field).trigger('focus')
  } else if (field.selectionStart || field.selectionStart === '0') {
    // MOZILLA and others
    const startPos = field.selectionStart
    const endPos = field.selectionEnd
    field.value = field.value.substring(0, startPos) + value + field.value.substring(endPos, field.value.length)
    jQuery(field).trigger('focus').setCursorPosition(startPos + value.length)
  } else {
    field.value += value
    jQuery(field).trigger('focus').setCursorPosition(-1)
  }
}

export const supportedMergeTags = (opts) => {
  const mergeTags = buildMergeTags()

  const forSubject = opts && opts.forSubject
  const forDeal = opts && opts.forDeal

  if (forSubject) {
    delete mergeTags.global
  }

  if (!forDeal) {
    delete mergeTags.deal
  }

  return mergeTags
}

const SUPPORTED_CUSTOM_FIELD_TYPES = ['numeric', 'text', 'currency', 'dropdown', 'date', 'multi_select']

const buildMergeTags = () => {
  const mergeTags = {
    global: { systemFields: [] },
    person: { systemFields: [] },
    company: { systemFields: [] },
    deal: { systemFields: [] }
  }

  const accountMergeTags = getMergeTags(store.getState())
  accountMergeTags.forEach(tag => {
    mergeTags[tag.type].systemFields.push({ label: tag.name, value: tag.tag })
  })

  const personCustomFieldLabels = getPersonCustomFieldLabels(store.getState())
  const companyCustomFieldLabels = getCompanyCustomFieldLabels(store.getState())
  const dealCustomFieldLabels = getDealCustomFieldLabels(store.getState())
  const personCustomFieldGroups = getPersonCustomFieldGroups(store.getState())
  const companyCustomFieldGroups = getCompanyCustomFieldGroups(store.getState())
  const dealCustomFieldGroups = getDealCustomFieldGroups(store.getState())

  return {
    ...mergeTags,
    person: { ...mergeTags.person, ...buildCustomFieldMergeTags(personCustomFieldLabels, personCustomFieldGroups) },
    company: { ...mergeTags.company, ...buildCustomFieldMergeTags(companyCustomFieldLabels, companyCustomFieldGroups) },
    deal: { ...mergeTags.deal, ...buildCustomFieldMergeTags(dealCustomFieldLabels, dealCustomFieldGroups) }
  }
}

const buildCustomFieldMergeTags = (customFieldLabels, customFieldGroups) => {
  const data = {}

  const supportedCustomFields = customFieldLabels
    .filter(cfl => SUPPORTED_CUSTOM_FIELD_TYPES.includes(cfl.field_type))
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())

  data.customFields = supportedCustomFields
    .filter(cfl => cfl.custom_field_group_id === null)
    .map(cfl => ({ label: cfl.name, value: customFieldMergeTagValue(cfl) }))

  customFieldGroups
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
    .forEach(cfg => {
      data[cfg.name] = supportedCustomFields
        .filter(cfl => cfl.custom_field_group_id === cfg.id)
        .map(cfl => ({ label: cfl.name, value: customFieldMergeTagValue(cfl) }))
    })

  return data
}

const customFieldMergeTagValue = (cfl) => (
  `{{${cfl.name}, id=${cfl.id}, fallback=}}`
)
