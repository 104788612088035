export const API_APPLICATION_UPDATE = 'API_APPLICATION_UPDATE'
export const API_APPLICATION_UPDATE_SUBMIT = 'API_APPLICATION_UPDATE_SUBMIT'
export const API_APPLICATION_UPDATE_FAILURE = 'API_APPLICATION_UPDATE_FAILURE'

export const API_APPLICATION_CREATE = 'API_APPLICATION_CREATE'
export const API_APPLICATION_CREATE_SUBMIT = 'API_APPLICATION_CREATE_SUBMIT'
export const API_APPLICATION_CREATE_FAILURE = 'API_APPLICATION_CREATE_FAILURE'

export const API_APPLICATION_DELETE = 'API_APPLICATION_DELETE'
export const API_APPLICATION_DELETE_SUBMIT = 'API_APPLICATION_DELETE_SUBMIT'
export const API_APPLICATION_DELETE_FAILURE = 'API_APPLICATION_DELETE_FAILURE'
