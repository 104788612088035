export const ensureHttp = function (url) {
  if (!url) {
    return ''
  }
  if (url.indexOf('http') === -1) {
    return `http://${url}`
  } else {
    return url
  }
}

export default ensureHttp

const linkifyLink = url => `<a href="${ensureHttp(url)}" target="_blank">${url}</a>`

export const linkifyText = text => {
  const types = {
    protocol_link: {
      regex:
        /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_'|!:,.;$*()]*[a-z0-9-+&@#/%=~_'|](?=[^>]*(<|$))(?![^<>]*>|[^"]*?<\/a)/gim,
      template: linkifyLink
    },

    www_link: {
      regex:
        /(^|[^/])(www\.[\S]+(\b|$))(?=[^>]*(<|$))(?![^<>]*>|[^"]*?<\/a)/gim,
      template (match, pre, url, post) {
        return pre + linkifyLink(url) + post
      }
    },

    domain_link: {
      regex:
        /(^|\s)((?!www\.)(?:[a-z0-9-]*[a-z0-9]\.)+(?:aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal)(?:\/\S*)?)($|\s)(?=[^>]*(<|$))(?![^<>]*>|[^"]*?<\/a)/gim,
      template (match, pre, url, post) {
        return pre + linkifyLink(url) + post
      }
    },

    hash: {
      regex: /(^|\s)#(\w+)/g,
      template:
        '$1<a href="http://twitter.com/#!/search?q=%23$2" target="_blank">#$2</a>'
    },

    email: {
      regex: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g,
      template: '<a href="mailto:$1" target="_blank">$1</a>'
    }
  }

  Object.keys(types).forEach(name => {
    text = text.replace(types[name].regex, types[name].template)
  })

  return text
}
