import makePermissible from './permissible'

import activity, { ACTIVITY } from 'permissions/Activity'
import calendar, { CALENDAR } from 'permissions/Calendar'
import calendarEntry, { CALENDAR_ENTRY } from './CalendarEntry'
import campaign, { CAMPAIGN } from 'permissions/Campaign'
import company, { COMPANY } from './Company'
import customer, { CUSTOMER } from 'permissions/Customer'
import customField, { CUSTOM_FIELD } from 'permissions/CustomField'
import deal, { DEAL } from './Deal'
import document, { DOCUMENT } from 'permissions/Document'
import email, { EMAIL } from './Email'
import goal, { GOAL } from './Goal'
import messageTemplate, { MESSAGE_TEMPLATE } from './MessageTemplate'
import milestone, { MILESTONE } from 'permissions/Milestone'
import person, { PERSON } from './Person'
import productLineGoal, { PRODUCT_LINE_GOAL } from './ProductLineGoal'
import recipient, { RECIPIENT } from 'permissions/Recipient'
import savedReport, { SAVED_REPORT } from './SavedReport'
import scoreboardReport, { SCOREBOARD_REPORT } from './ScoreboardReport'
import leadSource, { LEAD_SOURCE } from './LeadSource'
import dealStage, { DEAL_STAGE } from './DealStage'
import dealLossReason, { DEAL_LOSS_REASON } from './DealLossReason'
import navigation, { NAVIGATION } from './Navigation'
import sharedFiles, { SHARED_FILES } from './SharedFiles'
import leadStatus, { LEAD_STATUS } from './LeadStatus'
import customDashboard, { CUSTOM_DASHBOARD } from './CustomDashboard'
import personTag, { PERSON_TAG } from './PersonTag'
import report, { REPORT } from './Report'
import duplicateCheck, { DUPLICATE_CHECK } from './DuplicateCheck'
import reportingView, { REPORTING_VIEW } from './ReportingView'
import documentTemplate, { DOCUMENT_TEMPLATE } from './DocumentTemplate'

const policies = {
  [ACTIVITY]: activity,
  [CALENDAR]: calendar,
  [CALENDAR_ENTRY]: calendarEntry,
  [CAMPAIGN]: campaign,
  [COMPANY]: company,
  [CUSTOMER]: customer,
  [CUSTOM_DASHBOARD]: customDashboard,
  [CUSTOM_FIELD]: customField,
  [DEAL]: deal,
  [DEAL_LOSS_REASON]: dealLossReason,
  [DEAL_STAGE]: dealStage,
  [DOCUMENT]: document,
  [DOCUMENT_TEMPLATE]: documentTemplate,
  [DUPLICATE_CHECK]: duplicateCheck,
  [EMAIL]: email,
  [GOAL]: goal,
  [LEAD_SOURCE]: leadSource,
  [LEAD_STATUS]: leadStatus,
  [MESSAGE_TEMPLATE]: messageTemplate,
  [MILESTONE]: milestone,
  [NAVIGATION]: navigation,
  [PERSON]: person,
  [PERSON_TAG]: personTag,
  [PRODUCT_LINE_GOAL]: productLineGoal,
  [RECIPIENT]: recipient,
  [REPORT]: report,
  [REPORTING_VIEW]: reportingView,
  [SAVED_REPORT]: savedReport,
  [SCOREBOARD_REPORT]: scoreboardReport,
  [SHARED_FILES]: sharedFiles
}

export const permissible = makePermissible(policies)

export * from './actions'
export {
  ACTIVITY,
  CALENDAR,
  CALENDAR_ENTRY,
  CAMPAIGN,
  COMPANY,
  CUSTOMER,
  CUSTOM_DASHBOARD,
  CUSTOM_FIELD,
  DEAL,
  DEAL_LOSS_REASON,
  DEAL_STAGE,
  DOCUMENT,
  DOCUMENT_TEMPLATE,
  DUPLICATE_CHECK,
  EMAIL,
  GOAL,
  LEAD_SOURCE,
  MESSAGE_TEMPLATE,
  MILESTONE,
  NAVIGATION,
  PERSON,
  PRODUCT_LINE_GOAL,
  RECIPIENT,
  REPORT,
  REPORTING_VIEW,
  SAVED_REPORT,
  SCOREBOARD_REPORT,
  SHARED_FILES
}

export default permissible
