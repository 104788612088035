/* global fetch */

import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import pluralize from 'pluralize'

import { PermissibleLink } from 'components/Permissible'
import { AVATAR_UPLOAD, PERSON, COMPANY } from 'permissions'

import { HoverLabel, Left } from 'components/BusinessCard/components/StyledComponents'

const Avatar = ({ record, recordType, callback, children }) => {
  const fileUpload = useRef()
  const policy = useMemo(() => {
    return {
      person: PERSON,
      company: COMPANY
    }[recordType]
  }, [recordType])

  const uploadImage = () => {
    const formData = new FormData()
    formData.append(`${recordType}[image]`, fileUpload.current.files[0])

    fetch(`/${pluralize(recordType)}/${record.id}`, {
      body: formData,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json'
      },
      method: 'PATCH'
    }).then(response => {
      response.json().then(rec => {
        callback(rec)
      })
    })
  }

  const removeImage = () => {
    fetch(`/${pluralize(recordType)}/${record.id}/destroy_avatar`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }).then(response => {
      response.json().then(rec => {
        callback(rec)
      })
    })
  }

  return (
    <Left className="dropdown">
      <PermissibleLink action={AVATAR_UPLOAD} cypress={`${recordType}-avatar`} policy={policy}>
        <a className="avatar dropdown-toggle" data-toggle="dropdown">
          {children}
        </a>
      </PermissibleLink>

      <ul className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
        <li>
          <HoverLabel htmlFor="file-upload">Upload</HoverLabel>
          <input
            id="file-upload"
            ref={fileUpload}
            onChange={uploadImage}
            type="file"
            hidden
          />
        </li>
        {!/missing/.test(record.image_thumb_url) &&
          <li><a onClick={removeImage} tabIndex="-1">Remove</a></li>}
      </ul>
    </Left>
  )
}

Avatar.propTypes = {
  callback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  record: PropTypes.object.isRequired,
  recordType: PropTypes.string.isRequired
}

export default Avatar
