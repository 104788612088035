export const CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE = 'CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_SUBMIT = 'CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_SUBMIT'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_FAILURE = 'CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_FAILURE'

export const CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE = 'CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_SUBMIT = 'CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_SUBMIT'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_FAILURE = 'CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_FAILURE'

export const CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS = 'CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS_SUBMIT = 'CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS_SUBMIT'
export const CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS_FAILURE = 'CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS_FAILURE'

export const CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE = 'CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE'
