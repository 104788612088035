import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import fetcher from 'modules/fetcher'
import { getNonReadOnlyUsers } from 'selectors/Account'

class ConvertToCustomerForm extends Component {
  constructor (props) {
    super(props)

    const { company } = this.props

    this.state = {
      ownerId: (company && company.owner_id) || window.ppd.User.id,
      conversionNotes: ''
    }

    this.handleOwnerChange = this.handleOwnerChange.bind(this)
    this.handleNotesChange = this.handleNotesChange.bind(this)
  }

  handleOwnerChange (event) {
    this.setState({ ownerId: event.target.value })
  }

  handleNotesChange (event) {
    this.setState({ conversionNotes: event.target.value })
  }

  renderOwnerSelectOpts () {
    const { nonReadOnlyUsers } = this.props
    return nonReadOnlyUsers.filter(owner => owner.id !== window.ppd.User.id).map(owner => {
      return <option key={'owner-option-' + owner.id} value={owner.id}>{owner.full_name}</option>
    })
  }

  payload () {
    return {
      owner_id: this.state.ownerId,
      conversion_notes: this.state.conversionNotes
    }
  }

  doNotAskAgain (event) {
    fetcher('/api/v3/user_settings/disable_convert_to_customer_modal', {
      method: 'PUT',
      body: JSON.stringify({ user_setting: { value: event.target.checked.toString() } })
    })
  }

  render () {
    const { handleSubmit, onCancel, company } = this.props

    return (
      <form className="form-vertical">
        <div className="form-group">
          <label htmlFor="name">CUSTOMER (COMPANY) NAME</label>
          <input className="input-block-level" name="name" type="text" placeholder="Company Name" value={company && company.name} disabled />
        </div>
        <div className="form-group">
          <label htmlFor="owner_id">CUSTOMER OWNER*</label>
          <select className="input-block-level" onChange={this.handleOwnerChange} name="owner_id" defaultValue={(company && company.owner_id) || window.ppd.User.id}>
            <option key="Me" value={window.ppd.User.id}>Me</option>
            {this.renderOwnerSelectOpts()}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="conversion_notes">Notes</label>
          <textarea className="textarea-block" value={this.state.conversionNotes} onChange={this.handleNotesChange} rows="5" name="conversion_notes" placeholder="Any hand-off notes or items of interest?" />
        </div>
        <div style={{ marginTop: '5px', marginBottom: '-5px' }}>
          <input type="checkbox" id="do-not-ask-again" onClick={this.doNotAskAgain} />
          <label htmlFor="do-not-ask-again" style={{ color: '#999999', display: 'inline-block', paddingLeft: '5px', verticalAlign: 'text-top' }}>Do not ask me again</label>
        </div>
        <div className="cta-container-sm row-pld">
          <div className="col-xs-8">
            <button type="button" className="btn btn-block btn-pld-blue" onClick={() => handleSubmit(this.payload())}>Convert to customer</button>
          </div>
          <div className="col-xs-4">
            <button type="button" className="btn btn-block btn-link btn-gray" onClick={onCancel}>Not right now</button>
          </div>
        </div>
      </form>
    )
  }
}

ConvertToCustomerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  nonReadOnlyUsers: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    nonReadOnlyUsers: getNonReadOnlyUsers(state)
  }
}

export default connect(mapStateToProps)(ConvertToCustomerForm)
