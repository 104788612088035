import {
  CREATE,
  INDEX
} from 'permissions/actions'

export const SAVED_REPORT = '@@permissions/policy/savedReport'

export default {
  [CREATE]: (user, options) => !user.read_only,
  [INDEX]: (user, options) => !user.read_only
}
