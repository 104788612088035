import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'
import hasSearchParam from 'modules/hasSearchParam'

import { USER_EDIT_FORM_SUBMIT } from 'constants/User'

import {
  teamCreate,
  teamDelete,
  teamUpdate,

  teamDeleteFormFailure,
  teamEditFormFailure,
  teamNewFormFailure,

  teamUsersFormFailure,

  teamFetchFailure,
  teamFetchSuccess
} from 'admin/Teams/actions'
import {
  TEAM_DELETE_FORM_SUBMIT,
  TEAM_EDIT_FORM_SUBMIT,
  TEAM_NEW_FORM_SUBMIT,
  TEAM_USERS_FORM_SUBMIT,

  TEAM_FETCH_REQUEST
} from 'admin/Teams/constants'
import { incoming, outgoing } from 'admin/Teams/modules/payload'

import { userUpdate } from 'actions/User'

export function * teamFetchSaga ({ payload }) {
  try {
    const teams = yield call(
      fetcher,
      '/api/v3/admin/teams'
    )

    const { pagination: { pages, per_page } } = teams
    let { entries: concatenatedTeams, pagination: { page } } = teams

    while (page <= pages) {
      if (page > 1) {
        const response = yield call(
          fetcher,
          '/api/v3/admin/teams',
          { query: { page, per_page } }
        )
        concatenatedTeams = concatenatedTeams.concat(response.entries)
      }

      page++
    }

    yield put(teamFetchSuccess(concatenatedTeams.map(team => incoming(team))))
  } catch (exception) {
    console.error(exception)

    yield put(teamFetchFailure({ _error: exception.message }))
  }
}

export function * watchTeamFetchSaga () {
  yield call(takeEvery, TEAM_FETCH_REQUEST, teamFetchSaga)
}

export function * teamDeleteFormSubmitSaga ({ payload: { id, users = [] } }) {
  try {
    yield users.map(({ id, team_id }) => call(
      fetcher,
      `/api/v3/admin/users/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ user: { team_id } })
      }
    ))

    yield call(
      fetcher,
      `/api/v3/admin/teams/${id}`,
      {
        method: 'DELETE'
      }
    )

    yield put(teamDelete({ id, users }))
  } catch (exception) {
    console.error(exception)

    yield put(teamDeleteFormFailure({ _error: exception.message }))
  }
}

export function * watchTeamDeleteFormSubmitSaga () {
  yield call(takeEvery, TEAM_DELETE_FORM_SUBMIT, teamDeleteFormSubmitSaga)
}

export function * teamEditFormSubmitSaga ({ payload: { id, ...payload } }) {
  try {
    const response = yield call(
      fetcher,
      `/api/v3/admin/teams/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ team: outgoing(payload) })
      }
    )

    yield put(teamUpdate(incoming(response)))
  } catch (exception) {
    console.error(exception)

    yield put(teamEditFormFailure({ _error: exception.message }))
  }
}

export function * watchTeamEditFormSubmitSaga () {
  yield call(takeEvery, TEAM_EDIT_FORM_SUBMIT, teamEditFormSubmitSaga)
}

export function * teamNewFormSubmitSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      '/api/v3/admin/teams',
      {
        method: 'POST',
        body: JSON.stringify({ team: outgoing(payload) })
      }
    )

    yield put(teamCreate(incoming(response)))

    const moverUserId = hasSearchParam('move_user_id')
    if (moverUserId) {
      yield put({
        type: USER_EDIT_FORM_SUBMIT,
        payload: { id: moverUserId, team_id: response.id }
      })
    }
  } catch (exception) {
    console.error(exception)

    yield put(teamNewFormFailure({ _error: exception.message }))
  }
}

export function * watchTeamNewFormSubmitSaga () {
  yield call(takeEvery, TEAM_NEW_FORM_SUBMIT, teamNewFormSubmitSaga)
}

export function * teamUsersFormSubmitSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `/api/v3/users/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ user: payload })
      }
    )

    yield put(userUpdate(response))
  } catch (exception) {
    console.error(exception)

    yield put(teamUsersFormFailure({ _error: exception.message }))
  }
}

export function * watchTeamUsersFormSubmitSaga () {
  yield call(takeEvery, TEAM_USERS_FORM_SUBMIT, teamUsersFormSubmitSaga)
}
