export const SOURCE_CREATE = 'SOURCE_CREATE'
export const SOURCE_CREATE_SUBMIT = 'SOURCE_CREATE_SUBMIT'
export const SOURCE_CREATE_FAILURE = 'SOURCE_CREATE_FAILURE'

export const SOURCE_UPDATE = 'SOURCE_UPDATE'
export const SOURCE_UPDATE_SUBMIT = 'SOURCE_UPDATE_SUBMIT'
export const SOURCE_UPDATE_FAILURE = 'SOURCE_UPDATE_FAILURE'

export const SOURCE_DELETE = 'SOURCE_DELETE'
export const SOURCE_DELETE_SUBMIT = 'SOURCE_DELETE_SUBMIT'
export const SOURCE_DELETE_FAILURE = 'SOURCE_DELETE_FAILURE'
