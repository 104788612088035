/* global fetch, trackableEvent */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import ConvertToCustomerForm from './ConvertToCustomerForm'

Modal.defaultStyles.overlay.backgroundColor = 'rgba(15, 17, 20, 0.75)'
Modal.defaultStyles.overlay.zIndex = 1040

export const submitHandler = ({ values, company, dealId, eventSource }) => {
  fetch('/customers', {
    body: JSON.stringify({ customer: Object.assign({}, values, { company_id: company && company.id, deal_id: dealId }) }),
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST'
  }).then(response => {
    response.json().then(customer => {
      switch (eventSource) {
        case 'FIRST_DEAL_WON':
          trackableEvent.customerManagement.customer.convertedFromDealStageChange()
          break
        case 'COMPANY_PAGE':
          trackableEvent.customerManagement.customer.convertedFromProfilePage()
          break
        default:
          console.log('no eventSource provided')
      }
      window.location = `/customers/${customer.id}`
    })
  })
}

export const bodyText = eventSource => {
  let content
  switch (eventSource) {
    case 'FIRST_DEAL_WON':
      content = 'Congrats on the win! Do you want to convert this company to a customer so you and your team can keep better track of your post-sales relationship?'
      break
    case 'COMPANY_PAGE':
      content = 'Convert this company to a customer so you and your team can keep better track of your entire relationship, including post-sales.'
      break
    default:
      console.log('no eventSource provided')
  }

  return content
}

class ConvertToCustomerModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: true
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  UNSAFE_componentWillMount () {
    Modal.setAppElement('body')
  }

  UNSAFE_componentWillReceiveProps () {
    this.setState({ open: true })
  }

  closeModal () {
    this.setState({ open: false })
  }

  onSubmit (values) {
    const { company, dealId, eventSource } = this.props
    submitHandler({ values, company, dealId, eventSource })
  }

  renderBodyText () {
    const { eventSource } = this.props
    return bodyText(eventSource)
  }

  render () {
    const { open } = this.state
    const { company } = this.props

    return (
      <Modal
        className="modal modal-lite"
        contentLabel="Convert to Customer Modal"
        isOpen={open}
        onRequestClose={() => this.closeModal()}
        parentSelector={() => document.querySelector('#convert-company-to-customer-modal')}
      >
        <div className="modal-header header-combo">
          <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.closeModal()} />
          <h4 className="subtitle">New customer</h4>
          <h3 className="title">Convert company to customer</h3>
        </div>
        <div className="modal-body">
          <p className="text-center">{this.renderBodyText()}</p>
          <div className="col-xs-10 col-xs-offset-1">
            <div className="row-pld">
              <div className="form-holder">
                <ConvertToCustomerForm company={company} handleSubmit={this.onSubmit} onCancel={() => this.closeModal()} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ConvertToCustomerModal.propTypes = {
  eventSource: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  dealId: PropTypes.number
}

export default ConvertToCustomerModal
