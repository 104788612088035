import { createSelector } from 'reselect'
import { getTodoTemplates, getProductLines } from 'selectors/Account'

export const getDealSelector = state => state.deals.deal
export const getDealIdSelector = state => state.deals.dealId
export const getDealFetchStatus = state => state.deals.fetching
export const getDealReloadingSelector = state => state.deals.reloading

// TODO test this - confirming closed deal == existing deal.closed_time
export const isDealClosedSelector = createSelector(
  [getDealSelector],
  (deal) => {
    return !!deal.closed_time
  }
)

// TODO test this - confirming this is the definition of a won deal
export const isDealWonSelector = createSelector(
  [getDealSelector, isDealClosedSelector],
  (deal, isClosed) => {
    return isClosed && deal.deal_stage && deal.deal_stage.percent === 100
  }
)

export const getDealTodoTemplates = createSelector(
  [getTodoTemplates],
  (templates) => {
    return templates.filter(template => template.template_type === 'deals' || template.todo_template_types.find((types) => types.template_type === 'deals'))
  }
)

export const getDealProductLines = createSelector(
  [getProductLines],
  (productLines) => {
    return productLines.filter(line => !!line.enabled_for_deals)
  }
)

export const getDealStage = createSelector(
  [getDealSelector],
  (deal) => {
    return deal.deal_stage
  }
)

export const getDealDaysInStage = createSelector(
  [getDealSelector],
  (deal) => {
    return deal.days_in_stage
  }
)

export const getLatestDealStageChange = createSelector(
  [getDealSelector],
  (deal) => {
    return deal.latest_deal_stage_change
  }
)
