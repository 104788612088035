export const QUICK_SEARCH_TOGGLE_VISIBILITY = 'QUICK_SEARCH_TOGGLE_VISIBILITY'
export const UPDATE_SEARCH_SETTINGS = 'UPDATE_SEARCH_SETTINGS'

export const quickSearch = (state = { isVisible: false }, action) => {
  switch (action.type) {
    case QUICK_SEARCH_TOGGLE_VISIBILITY:
      return { ...state, isVisible: action.payload || !state.isVisible }
    default:
      return state
  }
}

export const toggleQuickSearchVisibility = payload => ({ payload, type: QUICK_SEARCH_TOGGLE_VISIBILITY })
export const updateSearchSettings = payload => ({ payload, type: UPDATE_SEARCH_SETTINGS })
