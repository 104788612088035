import sanitizeText from 'modules/sanitizeHtml'
import { linkifyText } from 'modules/link'

export default (content) => {
  if (!content) return content
  return sanitizeAndLinkifyHtml(content)
}

const sanitizeAndLinkifyHtml = (content) => {
  const sanitized = sanitizeText(content)
  const linkified = linkifyText(sanitized)
  return linkified
}
