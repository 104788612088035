/* global Handlebars, jQuery */

import content from 'permissions/Handlebars/content'
import permissibleMapper from 'permissions/mapper'

import hasSearchParam from 'modules/hasSearchParam'

export default ({ action, disabled, html, object, objectType, options, policy, user }) => {
  const element = jQuery(html)
  let result

  if (hasSearchParam('logging')) console.log(policy, action, user.read_only, permissibleMapper(user, policy, action))

  if (permissibleMapper(user, policy, action, { object, objectType })) {
    element.attr('data-cypress', options.cypress || 'ah-ah-ah-you-didnt-say-the-magic-word')
    result = element.prop('outerHTML')
  } else {
    result = content(element, options, user)
  }

  return new Handlebars.SafeString(result)
}
