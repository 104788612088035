export const PERSON_STATUS_CREATE = 'PERSON_STATUS_CREATE'
export const PERSON_STATUS_CREATE_SUBMIT = 'PERSON_STATUS_CREATE_SUBMIT'
export const PERSON_STATUS_CREATE_FAILURE = 'PERSON_STATUS_CREATE_FAILURE'

export const PERSON_STATUS_UPDATE = 'PERSON_STATUS_UPDATE'
export const PERSON_STATUS_UPDATE_SUBMIT = 'PERSON_STATUS_UPDATE_SUBMIT'
export const PERSON_STATUS_UPDATE_FAILURE = 'PERSON_STATUS_UPDATE_FAILURE'

export const PERSON_STATUS_DELETE = 'PERSON_STATUS_DELETE'
export const PERSON_STATUS_DELETE_SUBMIT = 'PERSON_STATUS_DELETE_SUBMIT'
export const PERSON_STATUS_DELETE_FAILURE = 'PERSON_STATUS_DELETE_FAILURE'
