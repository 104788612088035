export default {
  basic: [
    'agendaDropDownAction',
    'choosePipeline',
    'clickAddNewButton',
    'clickCreateNewTask',
    'clickPipelineStage',
    'clickStarredList',
    'completeTaskOnAgenda',
    'dealsSlippingAwayFollowUp',
    'dealsSlippingAwayView',
    'dismissDealSlippingAway',
    'editLayout',
    'filterDashboardTimePeriod',
    'filterPipelineByUser',
    'latestEmailInteractionsAddContact',
    'latestEmailInteractionsOpenEmail',
    'latestEmailInteractionsReply',
    'latestEmailInteractionsStartHereSync',
    'latestEmailInteractionsViewAllEmailActivity',
    'loadMoreStarredLists',
    'postponeTaskOnAgenda',
    'viewActiveDeals',
    'viewContactCardOnAgenda',
    'viewDealsExpectedToClose',
    'viewFullAgenda',
    'viewPastDueTasks',
    'viewTasksDueThisWeek'
  ],

  customizable: [
    'addNewDashboard',
    'addToDashboardFromDashboard',
    'addToDashboardFromGoals',
    'addToDashboardFromListView',
    'addToDashboardFromReports',
    'applyUsersFilterOnTile',
    'copyTileToAnotherDashboard',
    'deleteDashboard',
    'duplicateDashboard',
    'filterPipeline',
    'goToTileOnDashboard',
    'makeDashboardDefault',
    'moveTileOnDashboard',
    'moveTileToAnotherDashboard',
    'refreshTileOnDashboard',
    'removeTileFromDashboard',
    'renameDashboard',
    'resizeTileOnDashboard',
    'saveDashboard',
    'shareDashboard',
    'starDashboard'
  ]
}
