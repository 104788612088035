/* global App */

import store from 'store'
import { showModal } from 'modals/ducks'
import { EMAIL_MODAL } from 'Email/constants'

export default (messageId) => {
  App.Stores.Connect.Message
    .fetch(messageId)
    .done(msg => {
      store.dispatch(showModal({ type: EMAIL_MODAL, props: { isFromInbox: true, email: msg } }))
    })
    .fail(data => {
      window.Helpers.notify('Email', 'Email not found.')
    })
}
