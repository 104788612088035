import {
  ACTIVITY_CREATE,
  ACTIVITY_DELETE,
  ACTIVITY_UPDATE,
  AGENDA_CREATE,
  ALLOWED_PRIVATE_NOTES,
  AVATAR_UPLOAD,
  BULK_ACTIONS,
  CREATE,
  FILE_CREATE,
  FILE_UPDATE,
  FILE_DELETE,
  FILE_TAG,
  LIST_VIEW_CREATE,
  LIST_VIEW_CREATE_FOCUSED,
  LIST_VIEW_DELETE,
  LIST_VIEW_DUPLICATE,
  LIST_VIEW_SHARE,
  LIST_VIEW_STAR,
  LIST_VIEW_UPDATE,
  LIST_VIEW_ADD_REMOVE_COLUMNS,
  MANAGE,
  MERGE,
  UPDATE,
  CAMPAIGN_SUBSCRIBE
} from 'permissions/actions'
import ownerId from 'permissions/ownerId'
import { userLevels } from 'permissions/userLevels'

export const PERSON = '@@permissions/policy/person'

export default {
  [ACTIVITY_CREATE]: (user, options) => true,
  [ACTIVITY_DELETE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [ACTIVITY_UPDATE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [AGENDA_CREATE]: (user, options) => !user.read_only,
  [ALLOWED_PRIVATE_NOTES]: (user, options) => window.Features.private_notes && (user.level === userLevels.EXECUTIVE || user.is_account_admin),
  [AVATAR_UPLOAD]: (user, options) => !user.read_only,
  [BULK_ACTIONS]: (user, options) => !user.read_only,
  [CREATE]: (user, options) => !user.read_only,
  [FILE_CREATE]: (user, options) => !user.read_only && !user.external,
  [FILE_UPDATE]: (user, options) => !user.read_only && !user.external,
  [FILE_DELETE]: (user, options) => !user.read_only && !user.external,
  [FILE_TAG]: (user, options) => !user.read_only,
  [LIST_VIEW_CREATE]: (user, options) => !user.read_only,
  [LIST_VIEW_CREATE_FOCUSED]: user => !user.read_only && !user.external,
  [LIST_VIEW_DELETE]: (user, options) => !user.read_only,
  [LIST_VIEW_DUPLICATE]: (user, options) => !user.read_only,
  [LIST_VIEW_SHARE]: (user, options) => !user.read_only,
  [LIST_VIEW_STAR]: (user, options) => !user.read_only,
  [LIST_VIEW_UPDATE]: (user, options) => !user.read_only,
  [LIST_VIEW_ADD_REMOVE_COLUMNS]: (user, options) => !user.external,
  [MANAGE]: (user, options) => !user.read_only,
  // eslint-disable-next-line camelcase
  [MERGE]: (user, options) => canMerge(user, options.record),
  [UPDATE]: (user, options) => !user.read_only,
  [CAMPAIGN_SUBSCRIBE]: (user, options) => !user.read_only && !user.external
}

const canMerge = (user, record) =>
  // eslint-disable-next-line camelcase
  [userLevels.EXECUTIVE, userLevels.MANAGER].includes(user.level) || user.is_allowed_to_merge_records || user.is_account_admin || record?.can_be_reassigned_by_current_user
