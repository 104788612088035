import React, { Component } from 'react'
import PropTypes from 'prop-types'

class PulseStatsTooltip extends Component {
  render () {
    const { hint } = this.props

    if (!hint) { return null }

    return (
      <i
        className="fas fa-question-circle btn-icon-tooltip"
        data-toggle="tooltip"
        data-container="body"
        title={hint}
        data-html="true"
        data-placement="top"
      />
    )
  }
}

PulseStatsTooltip.propTypes = {
  hint: PropTypes.string
}

export default PulseStatsTooltip
