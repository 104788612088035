/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const DateRangePickers = []

const DateRangePicker = {
  setupPicker (element, range, options) {
    if (range == null) { range = {} }
    this.options = options
    this.element = element

    this.setupRanges()
    range = this.addDatesToRange(range)

    const caretHtml = (this.options && this.options.includeCaret) ? "<span class='caret' style='margin-left:5px'></span>" : ''

    const opts = {
      ranges: this.ranges,
      startDate: this.getDate(range.from_date),
      endDate: this.getDate(range.to_date),
      opens: 'right',
      format: this.dateFormat(),
      buttonClasses: '',
      cancelClass: 'hidden',
      applyClass: 'btn',
      showDropdowns: true
    }

    const callback = (start, end, humanLabel, e) => {
      if (humanLabel === 'Custom Range') {
        jQuery(this.element).data('value', {from_date: start.format('YYYY/MM/DD'), to_date: end.format('YYYY/MM/DD'), selection: 'custom'})
        jQuery(this.element).html(this.humanDateRange(start, end) + caretHtml)
      } else {
        const valueLabel = this.ranges[humanLabel][2]
        jQuery(this.element).data('value', { selection: valueLabel })
        jQuery(this.element).html(humanLabel + caretHtml)
      }

      jQuery(this.element).trigger('change', e)
      return null
    }

    this.element.daterangepicker(opts, callback)
    jQuery(this.element).data('datepicker-initialized', true)
    return this.element.html(this.describeDateToHuman(range) + caretHtml)
  },

  setupRanges () {
    this.ranges = {
      'any time': [Date.today().add({ days: -30 }), moment(), 'all'],
      'today': [moment(), moment(), 'today'],
      'yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days'), 'yesterday'],

      'this week': [Date.today().setWeek(Date.today().getWeek()), Date.today().setWeek(Date.today().getWeek()).add({days: 6}), 'this_week'],
      'last week': [Date.today().setWeek(Date.today().getWeek() - 1), Date.today().setWeek(Date.today().getWeek() - 1).add({days: 6}), 'last_week'],
      'next week': [Date.today().setWeek(Date.today().getWeek() + 1), Date.today().setWeek(Date.today().getWeek() + 1).add({days: 6}), 'next_week'],

      'the last 30 Days': [Date.today().add({ days: -29 }), moment(), 'last_30'],
      'this month': [Date.today().moveToFirstDayOfMonth(), Date.today().moveToLastDayOfMonth(), 'this_month'],
      'last month': [Date.today().add({months: -1}).moveToFirstDayOfMonth(), Date.today().add({months: -1}).moveToLastDayOfMonth(), 'last_month'],
      'next month': [Date.today().add({months: 1}).moveToFirstDayOfMonth(), Date.today().add({months: 1}).moveToLastDayOfMonth(), 'next_month'],

      'any time in future': ['today', Date.today().add({ days: 30 }), 'future']
    }

    if (this.options.showFutureSelections !== true) {
      delete this.ranges['next week']
      delete this.ranges['next month']
      delete this.ranges['any time in future']
    }
    return this.ranges
  },

  describeDateToHuman (range) {
    if (range) {
      if (range.selection === 'custom') {
        return this.humanDateRange(Date.parse(range.from_date), Date.parse(range.to_date))
      } else if (!range.selection) {
        return 'any time'
      } else {
        return _.compact(_.map(this.ranges, function (value, key) { if (value[2] === range.selection) { return key } }))[0]
      }
    } else {
      return 'any time'
    }
  },

  dateFormat () {
    if (ppd.dateFormat === 'mm/dd/yyyy') { return 'MM/DD/YY' } else { return 'DD/MM/YY' }
  },

  humanDateRange (start, end) {
    return `${moment(start).format(this.dateFormat())} to ${moment(end).format(this.dateFormat())}`
  },

  addDatesToRange (range) {
    if (range.from_date == null) {
      const item = (range.selection != null)
               ? _.find(this.ranges, r => r[2] === range.selection)
             : this.ranges['any time']
      range.from_date = moment(item[0]).toDate()
      range.to_date = moment(item[1]).toDate()
    }
    return range
  },

  getDate (date) { return Date.parse(date) || new Date(date) }
}

Helpers.hasDateRangePicker = element => jQuery(element).data('datepicker-initialized')
Helpers.setupDateRangePicker = function (element, range, options) {
  if (options == null) { options = {showFutureSelections: false} }
  const datePicker = _.clone(DateRangePicker)
  datePicker.setupPicker(element, range, options)
  return DateRangePickers.push(datePicker)
}
