export const DEAL_PIPELINES_FETCH_REQUEST = 'DEAL_PIPELINES_FETCH_REQUEST'
export const DEAL_PIPELINES_FETCH_SUCCESS = 'DEAL_PIPELINES_FETCH_SUCCESS'
export const DEAL_PIPELINES_FETCH_FAILURE = 'DEAL_PIPELINES_FETCH_FAILURE'

export const DEAL_PIPELINE_CREATE = 'DEAL_PIPELINE_CREATE'
export const DEAL_PIPELINE_CREATE_SUBMIT = 'DEAL_PIPELINE_CREATE_SUBMIT'
export const DEAL_PIPELINE_CREATE_FAILURE = 'DEAL_PIPELINE_CREATE_FAILURE'

export const DEAL_PIPELINE_UPDATE = 'DEAL_PIPELINE_UPDATE'
export const DEAL_PIPELINE_UPDATE_SUBMIT = 'DEAL_PIPELINE_UPDATE_SUBMIT'
export const DEAL_PIPELINE_UPDATE_FAILURE = 'DEAL_PIPELINE_UPDATE_FAILURE'

export const DEAL_PIPELINE_DELETE = 'DEAL_PIPELINE_DELETE'
export const DEAL_PIPELINE_DELETE_SUBMIT = 'DEAL_PIPELINE_DELETE_SUBMIT'
export const DEAL_PIPELINE_DELETE_FAILURE = 'DEAL_PIPELINE_DELETE_FAILURE'

export const DEAL_PIPELINE_QUOTA_SUCCESS = 'DEAL_PIPELINE_QUOTA_SUCCESS'
export const DEAL_PIPELINE_QUOTA_FAILURE = 'DEAL_PIPELINE_QUOTA_FAILURE'

export const DEAL_PIPELINE_CAN_ALLOCATE = 'DEAL_PIPELINE_CAN_ALLOCATE'
export const DEAL_PIPELINE_CAN_ALLOCATE_FAILURE = 'DEAL_PIPELINE_CAN_ALLOCATE_FAILURE'

export const CHANGE_DEFAULT_PIPELINE = 'CHANGE_DEFAULT_PIPELINE'

export const DEAL_PIPELINE_SELECT = 'DEAL_PIPELINE_SELECT'
