import {
  DOCUMENT_TAG_CREATE,
  DOCUMENT_TAG_CREATE_FAILURE,
  DOCUMENT_TAG_UPDATE,
  DOCUMENT_TAG_UPDATE_FAILURE,
  DOCUMENT_TAG_DELETE,
  DOCUMENT_TAG_DELETE_FAILURE
} from '../constants'

export const documentTagCreate = payload => ({ payload, type: DOCUMENT_TAG_CREATE })
export const documentTagCreateFailure = payload => ({ payload, type: DOCUMENT_TAG_CREATE_FAILURE })
export const documentTagUpdate = payload => ({ payload, type: DOCUMENT_TAG_UPDATE })
export const documentTagUpdateFailure = payload => ({ payload, type: DOCUMENT_TAG_UPDATE_FAILURE })
export const documentTagDelete = payload => ({ payload, type: DOCUMENT_TAG_DELETE })
export const documentTagDeleteFailure = payload => ({ payload, type: DOCUMENT_TAG_DELETE_FAILURE })
