/* eslint-disable
    no-return-assign,
    no-undef,
    no-useless-escape,
*/

const Password = require('modules/validPassword');

// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
jQuery(() => {
  jQuery.validator.methods.notIn = function (value, element, param) {
    // extend jquery.validate to check for uniquness using string comparison
    let needle
    return this.optional(element) || (needle = value, !Array.from(_.map(param, el => `${el}`)).includes(needle))
  }
  jQuery.validator.messages.notIn = 'Another template already has this name.'

  jQuery.validator.methods.notInEmail = function (value, element, param) {
    // extend jquery.validate to check for uniquness using string comparison
    let needle
    return this.optional(element) || (needle = value, !Array.from(_.map(param, el => `${el}`)).includes(needle))
  }
  jQuery.validator.messages.notInEmail = 'Another user already has this email.'

  jQuery.validator.methods.notInCaseInsensitive = function (value, element, param) {
    // extend jquery.validate to check for uniquness using string comparison
    let needle
    return this.optional(element) || (needle = value.toLowerCase(), !Array.from(_.map(param, el => `${el.toLowerCase()}`)).includes(needle))
  }
  jQuery.validator.messages.notInCaseInsensitive = 'Already been taken.'

  jQuery.validator.methods.in = function (value, element, param) {
    let needle
    return this.optional(element) || (needle = value, Array.from(_.map(param, el => `${el}`)).includes(needle))
  }
  jQuery.validator.messages.in = 'Has invalid value'

  jQuery.validator.methods.hexColor = (value, element, param) => _.isArray(value.match(/#[a-f0-9]{6}/i))
  jQuery.validator.messages.hexColor = 'Is not a valid color code'

  jQuery.validator.methods.invalidDateTimeRange = function (value, element, param) {
    if (param[4]) {
      if (User.dateFormat === 'us') {
        return Date.parse(param[0]) <= Date.parse(param[1])
      } else {
        return Date.parse(Helpers.formatDate(param[0])) <= Date.parse(Helpers.formatDate(param[1]))
      }
    } else {
      if (User.dateFormat === 'us') {
        return Date.parse(param[0] + ' ' + param[2]) <= Date.parse(param[1] + ' ' + param[3])
      } else {
        return Date.parse(Helpers.formatDate(param[0]) + ' ' + param[2]) <= Date.parse(Helpers.formatDate(param[1]) + ' ' + param[3])
      }
    }
  }
  jQuery.validator.messages.invalidDateTimeRange = 'Start time should be before end time'

  jQuery.validator.methods.invalidDueDate = (value, element, param) => (param === "Doesn't Repeat") || ((param !== "Doesn't Repeat") && value.length)
  jQuery.validator.messages.invalidDueDate = 'Due date has to be specified if you choose to repeat this event'

  jQuery.validator.methods.invalidPhoneNumber = (value, element, param) => _.isArray(value.match(/(^[-0-9 ]+$)|(^$)/))
  jQuery.validator.messages.invalidPhoneNumber = 'Is not a valid phone number'

  jQuery.validator.methods.currency = function (value, element) {
    return this.optional(element) || /^-?\.?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value)
  }
  jQuery.validator.messages.currency = 'Please enter a valid number'

  jQuery.validator.methods.validPassword = function (value, element, params) {
    return this.optional(element) || Password.validPassword(value, element, params)
  }
  jQuery.validator.messages.validPassword = 'Please enter a valid password'

  jQuery.validator.methods.invalidTime = (value, element) => /^((0?[1-9]|1[012])(:[0-5]\d){1,2}(\ ?[AP]M))$/i.test(value) || /^([01]\d|2[0-3]|[0-9])(:[0-5]\d){1,2}$/.test(value)
  jQuery.validator.messages.invalidTime = 'Invalid Time Format. HH:MM AM/PM'
})
