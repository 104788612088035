import {
  isReadOnly,
  isInactive
} from 'modules/userFiltering'

const groupUsers = (users) => {
  const active = []
  const inactive = []
  const readOnly = []

  users.map(user => {
    if (isInactive(user)) {
      inactive.push(user)
    } else if (isReadOnly(user)) {
      readOnly.push(user)
    } else {
      active.push(user)
    }
  })

  return {
    active: active,
    inactive: inactive,
    readOnly: readOnly
  }
}

export default (users) => {
  const groupedUsers = groupUsers(users)
  const visibleGroups = []

  const activeGroup = { label: 'Active users', users: groupedUsers.active }
  const readOnlyGroup = { label: 'Read-only users', users: groupedUsers.readOnly }
  const inactiveGroup = { label: 'Inactive users', users: groupedUsers.inactive }

  if (activeGroup.users.length > 0) visibleGroups.push(activeGroup)
  if (readOnlyGroup.users.length > 0) visibleGroups.push(readOnlyGroup)
  if (inactiveGroup.users.length > 0) visibleGroups.push(inactiveGroup)

  return {
    meta: {
      hasMultiple: (() => visibleGroups.length > 1)()
    },
    visibleGroups: visibleGroups,
    active: activeGroup,
    readOnly: readOnlyGroup,
    inactive: inactiveGroup
  }
}
