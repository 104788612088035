export const incoming = ({ permissions, ...rest }) => {
  if (permissions) {
    return {
      ...rest,
      associated: permissions['*'].person.use_deal_association,
      permissions: Object.keys(permissions.member).map(permission => ({ permission: permissions.member[permission].visibility_level, type: permission }))
    }
  } else {
    return rest
  }
}

export const outgoing = ({ associated = false, permissions, ...rest }) => {
  if (!permissions) return rest

  if (associated === '') associated = false

  const permissionsMember = {}
  permissions.forEach(permission => {
    permissionsMember[permission.type] = { visibility_level: permission.permission }
  })

  const permissionsDocument = {
    member: permissionsMember,
    '*': {
      person: {
        use_deal_association: associated
      }
    }
  }

  return {
    ...rest,
    permissions: permissionsDocument
  }
}
