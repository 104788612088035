export default {
  general: [
    'addGoal',
    'addGoalFromCompanyGoals',
    'addGoalFromTeamGoals',
    'collapseGoalSection',
    'deleteGoal'
  ],
  performancePulse: {
    chart: [
      'productLineBarClicked'
    ],
    goalActions: [
      'actionMenuShown'
    ],
    goalBreakdown: [
      'sectionClicked'
    ],
    goalRefresh: [
      'clicked'
    ],
    goalSelected: [
      'reportFilter'
    ]
  }
}
