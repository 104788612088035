import { REHYDRATE } from 'redux-persist'

import guid from 'modules/guid'
import {
  NOTIFICATIONS_INLINE_ADD,
  NOTIFICATIONS_INLINE_KEEP_OPEN,
  NOTIFICATIONS_INLINE_REMOVE,
  NOTIFICATIONS_INLINE_REMOVE_ALL,
  NOTIFICATIONS_INLINE_REMOVE_TIMEOUT
} from 'Notifications/NotificationsInline/constants.js'

const initialNotificationState = {
  uuid: null,
  active: true,
  title: null,
  message: null,
  received_at: Date.now(),
  keep_opened: false
}

const initialState = {
  notifications: []
}

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case REHYDRATE:
      return {
        notifications: payload?.notificationsInline?.notifications
          ? payload.notificationsInline.notifications.map(n => ({
            ...n,
            active: false
          }))
          : initialState.notifications
      }
    case NOTIFICATIONS_INLINE_ADD:
      return {
        notifications: [
          ...((state.notifications.length >= 25) ? state.notifications.slice(25) : state.notifications),
          {
            ...initialNotificationState,
            uuid: guid(),
            received_at: Date.now(),
            ...payload
          }
        ]
      }
    case NOTIFICATIONS_INLINE_KEEP_OPEN:
      return {
        notifications: [
          ...state.notifications.map(notification => ({
            ...notification,
            keep_opened:
              notification.uuid === payload ? true : notification.keep_opened
          }))
        ]
      }
    case NOTIFICATIONS_INLINE_REMOVE_TIMEOUT:
      return {
        notifications: [
          ...state.notifications.map(notification => ({
            ...notification,
            active:
              !notification.keep_opened && notification.uuid === payload
                ? false
                : notification.active
          }))
        ]
      }
    case NOTIFICATIONS_INLINE_REMOVE:
      return {
        notifications: [
          ...state.notifications.map(notification => ({
            ...notification,
            active: notification.uuid === payload ? false : notification.active
          }))
        ]
      }
    case NOTIFICATIONS_INLINE_REMOVE_ALL:
      return {
        notifications: [
          ...state.notifications.map(notification => ({
            ...notification,
            active: false
          }))
        ]
      }
    default:
      return state
  }
}
