import store from 'store'

import conditionalFieldsSummary from 'ConditionalHelpers/summary'
import { checkEventAction } from 'ConditionalHelpers/rules'

export const validate = (model, object) => {
  const storeState = store.getState()
  const rules = storeState.modern.currentAccount.conditional_fields
  const rulesDefaults = storeState.modern.currentAccount.conditional_fields_defaults
  const isNewObject = object.id === undefined

  if (!rules || !rulesDefaults) return {}

  const filteredRules = rules.filter(rule => {
    return rule.event_model === model && checkEventAction(rule, isNewObject)
  })

  const filteredRulesDefaults = rulesDefaults.filter(rule => {
    return rule.event_model === model
  })

  return conditionalFieldsSummary([...filteredRulesDefaults, ...filteredRules], object)
}

export default validate
