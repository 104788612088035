export const setIsLoading = isLoading => {
  window.setGlobalHeaderIsLoading && window.setGlobalHeaderIsLoading(isLoading)
}

export const renderWithLoadingAnimation = renderFunc => {
  setTimeout(() => {
    renderFunc()
    setIsLoading(false)
  }, 0)

  return setIsLoading(true)
}

export const getRecordType = (type) => {
  switch (type) {
    case 'deals':
      return 'Deal'
    case 'companies':
      return 'Company'
    case 'people':
      return 'Person'
    case 'customers':
      return 'Customer'
    default:
      return type
  }
}

export const resourceToUrlMap = {
  person: '/people',
  deal: '/deals',
  company: '/companies',
  customer: '/companies',
  calendar_event: '/calendar_events',
  calendar_task: '/calendar_tasks'
}

export const isBackend = () => window.location.pathname.startsWith('/backend/')
