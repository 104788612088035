export default [
  'addSegment',
  'applyGroupingOption',
  'changeAggregationType',
  'changeFilter',
  'changeReportType',
  'changeDateRollup',
  'changeViewDateRange',
  'clickAddReportToView',
  'clickCancelReportEditor',
  'clickCreateNewReport',
  'clickCreateNewView',
  'clickEditReport',
  'clickNewPersonalReport',
  'clickReportOrView',
  'clickScheduleView',
  'clickScheduleViewSave',
  'clickSegmentToOpenLV',
  'clickViewOptions',
  'clickViewReport',
  'deleteReport',
  'editChartType',
  'editDateRange',
  'editSecondaryGrouping',
  'editXAxis',
  'editYAxis',
  'exported',
  'exportReportImage',
  'removeReportFromView',
  'removeSegment',
  'renameReport',
  'duplicateReport',
  'reportSelected',
  'saveAsNewReport',
  'saveReport',
  'saveAsNewView',
  'saveView',
  'shareReport',
  'showForecast',
  'toggleRatio',
  'toggleSchedule',
  'viewByDeals',
  'viewByDollar',
  'viewDrilldown'
]
