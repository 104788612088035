import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  eventTypeCreate,
  eventTypeCreateFailure,
  eventTypeUpdate,
  eventTypeUpdateFailure,
  eventTypeDelete,
  eventTypeDeleteFailure
} from '../actions'

import {
  EVENT_TYPE_CREATE_SUBMIT,
  EVENT_TYPE_UPDATE_SUBMIT,
  EVENT_TYPE_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/event_categories',
      {
        method: 'POST',
        body: JSON.stringify({
          event_category: {
            name: payload.name
          }
        })
      }
    )
    yield put(eventTypeCreate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(eventTypeCreateFailure(exception.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, EVENT_TYPE_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/event_categories/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          event_category: {
            name: payload.name
          }
        })
      }
    )
    yield put(eventTypeUpdate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(eventTypeUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, EVENT_TYPE_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/event_categories/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(eventTypeDelete(payload))
  } catch (exception) {
    console.error(exception.message)
    yield put(eventTypeDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, EVENT_TYPE_DELETE_SUBMIT, deleteSaga)
}
