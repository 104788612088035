import React, { useMemo } from 'react'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import { useBusinessCard } from 'components/BusinessCard/context'
import { PERSON_TYPE } from '../constants'

const Tags = () => {
  const { cardMeta: { showOnlyFields }, resource, resourceType } = useBusinessCard()

  const fieldName = resourceType === PERSON_TYPE ? 'predefined_contacts_tag_ids' : 'tags'
  if (!showOnlyFields.includes(fieldName)) return null

  const tags = useMemo(() => resourceType === PERSON_TYPE ? resource.predefined_contacts_tags : resource.tags, [resource, resourceType])

  if (!tags || !tags.length) return null

  return (
    <Section>
      <Row>
        <Left>tags</Left>
        <Right>
          {tags.map(t => t.name).join(', ')}
        </Right>
      </Row>
    </Section>
  )
}

export default Tags
