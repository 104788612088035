export const USER_CREATE = '@@constants/USER_CREATE'
export const USER_UPDATE = '@@constants/USER_UPDATE'

export const USER_DISABLE_MFA = '@@constants/USER_DISABLE_MFA'
export const USER_REQUEST_MFA = '@@constants/USER_REQUEST_MFA'
export const USER_BULK_ACTION = '@@constants/USER_BULK_ACTION'

export const USER_EDIT_FORM_FAILURE = '@@constants/USER_EDIT_FORM_FAILURE'
export const USER_EDIT_FORM_SUBMIT = '@@constants/USER_EDIT_FORM_SUBMIT'

export const USER_FETCH_FAILURE = '@@constants/USER_FETCH_FAILURE'
export const USER_FETCH_REQUEST = '@@constants/USER_FETCH_REQUEST'
export const USER_FETCH_SUCCESS = '@@constants/USER_FETCH_SUCCESS'

export const USER_MARK_INACTIVE_FORM_FAILURE = '@@constants/USER_MARK_INACTIVE_FORM_FAILURE'
export const USER_MARK_INACTIVE_FORM_SUBMIT = '@@constants/USER_MARK_INACTIVE_FORM_SUBMIT'

export const USER_REASSIGN_DATA_FORM_FAILURE = '@@constants/USER_REASSIGN_DATA_FORM_FAILURE'
export const USER_REASSIGN_DATA_FORM_SUBMIT = '@@constants/USER_REASSIGN_DATA_FORM_SUBMIT'

export const USER_REACTIVATE_FORM_FAILURE = '@@constants/USER_REACTIVATE_FORM_FAILURE'
export const USER_REACTIVATE_FORM_SUBMIT = '@@constants/USER_REACTIVATE_FORM_SUBMIT'

export const USER_PROFILES_LAYOUT_FETCH = '@@constants/USER_PROFILES_LAYOUT_FETCH'
export const USER_PROFILES_LAYOUT_FETCH_SUCCESS = '@@constants/USER_PROFILES_LAYOUT_FETCH_SUCCESS'

export const USER_PROFILES_LAYOUT_POSITIONS_UPDATE = '@@contants/USER_PROFILES_LAYOUT_POSITIONS_UPDATE'
export const USER_PROFILES_LAYOUT_HIDDEN_UPDATE = '@@contants/USER_PROFILES_LAYOUT_HIDDEN_UPDATE'
