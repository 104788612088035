export const validPassword = (value, element, params) => {
  /* Must be >= 8 chars long AND
   * Must contain at least one of each:
   * - upper case
   * - lower case
   * - digit
   * - special character
   */
  if (window.isSupershare) return true

  if (typeof params !== 'object') {
    params = {
      length: [8, 128],
      lower_case: 1,
      upper_case: 1,
      number: 1,
      special_character: 1
    }
  }

  return (
    longEnough(value, params.length) &&
    containsLowerCase(value, params.lower_case) &&
    containsUpperCase(value, params.upper_case) &&
    containsDigit(value, params.number) &&
    containsSpecialCharacter(value, params.special_character)
  )
}

export const containsLowerCase = (value, count) => {
  return new RegExp(`(?=(.*[a-z]){${count | 0},128})`).test(value)
}

export const containsUpperCase = (value, count) => {
  return new RegExp(`(?=(.*[A-Z]){${count | 0},128})`).test(value)
}

export const containsDigit = (value, count) => {
  return new RegExp(`(?=(.*[0-9]){${count | 0},128})`).test(value)
}

export const containsSpecialCharacter = (value, count) => {
  return new RegExp(`(?=(.*[!@#$%^&*]){${count | 0},128})`).test(value)
}

export const longEnough = (value, range) => {
  return value && value.length >= range[0]
}

export const passwordRules = (passwordRequirements) => {
  const rules = [{
    label: `${passwordRequirements.length[0]} character${passwordRequirements.length[0] > 1 ? 's' : ''} minimum`,
    test: val => longEnough(val, passwordRequirements.length),
    dataCypress: 'characters'
  }]

  if (passwordRequirements.lower_case && (passwordRequirements.lower_case | 0) > 0) {
    rules.push({
      label: `${passwordRequirements.lower_case} lowercase letter${passwordRequirements.lower_case > 1 ? 's' : ''}`,
      test: val => containsLowerCase(val, passwordRequirements.lower_case),
      dataCypress: 'lower-case'
    })
  }

  if (passwordRequirements.upper_case && (passwordRequirements.upper_case | 0) > 0) {
    rules.push({
      label: `${passwordRequirements.upper_case} uppercase letter${passwordRequirements.upper_case > 1 ? 's' : ''}`,
      test: val => containsUpperCase(val, passwordRequirements.upper_case),
      dataCypress: 'upper-case'
    })
  }

  if (passwordRequirements.number && (passwordRequirements.number | 0) > 0) {
    rules.push({
      label: `${passwordRequirements.number} number${passwordRequirements.number > 1 ? 's' : ''}`,
      test: val => containsDigit(val, passwordRequirements.number),
      dataCypress: 'number'
    })
  }

  if (passwordRequirements.special_character && (passwordRequirements.special_character | 0) > 0) {
    rules.push({
      label: `${passwordRequirements.special_character} special character${passwordRequirements.special_character > 1 ? 's' : ''}`,
      test: val => containsSpecialCharacter(val, passwordRequirements.special_character),
      dataCypress: 'special-character'
    })
  }

  return rules
}
