import { createSelector } from 'reselect'

import {
  getCustomFieldLabelIdSelector,
  getCustomFieldLabelsSelector,
  getCustomFieldTypeSelector,
  getCustomFieldGroupIdSelector
} from './Selectors'

import sortByPosition from 'modules/sortByPosition'

export const getCustomFieldLabel = createSelector(
  getCustomFieldLabelsSelector,
  getCustomFieldLabelIdSelector,
  (labels, id) => labels.filter(label => label.id === id)[0]
)

// labels.find(label => label.id === id) does not currently work in PhantomJS: https://github.com/ariya/phantomjs/issues/14211

export const getCustomFieldLabelsByType = createSelector(
  getCustomFieldLabelsSelector,
  getCustomFieldTypeSelector,
  (labels, type) => labels.filter(label => {
    return label.type.toLowerCase() === `${type}customfieldlabel`
  })
)

export const getCustomFieldLabels = createSelector(
  getCustomFieldLabelsSelector,
  getCustomFieldTypeSelector,
  getCustomFieldGroupIdSelector,
  (labels, type, groupId) => labels.filter(label => {
    if (type && groupId) {
      return label.type.toLowerCase() === `${type}customfieldlabel` &&
             label.custom_field_group_id === groupId
    } else {
      return label.type.toLowerCase() === `${type}customfieldlabel` &&
             !label.custom_field_group_id
    }
  })
)

export const getSortedCustomFieldLabels = createSelector(
  getCustomFieldLabels,
  labels => labels.sort(sortByPosition)
)

export const getNextCustomFieldLabelPosition = createSelector(
  getCustomFieldLabels,
  labels => labels.length + 1
)
