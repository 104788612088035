import {
  TEAM_USERS_FILTER_ALL,
  TEAM_USERS_FILTER
} from 'admin/Teams/constants'

const teamInitialState = {
  usersFilter: TEAM_USERS_FILTER_ALL
}

const team = (state = teamInitialState, { payload, type }) => {
  switch (type) {
    case TEAM_USERS_FILTER:
      return {
        usersFilter: payload.filter
      }
    default:
      return state
  }
}

export default (state = {}, action) => {
  return {
    team: team(state.team, action)
  }
}
