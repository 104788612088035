import { useEffect } from 'react'

export const useOnOutsideClick = ({ node, showComponent, onOutsideClick, opts = {} }) => {
  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    onOutsideClick(e)
  }

  useEffect(() => {
    if (showComponent) {
      document.addEventListener('mousedown', handleClickOutside, opts)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}
