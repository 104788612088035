import { takeEvery, takeLatest } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { getDealSelector } from './selectors'

import store from 'store'
import { anyFieldsToUpdate, updateResourceThroughModal } from 'ConditionalFields/helpers/updaters'
import conditionalFieldsValidator from 'ConditionalFields/validator'

import {
  dealFetchError,
  dealFetchSuccess,
  loadFiles,
  loadPeople,
  loadTodos,
  loadVisibleActiveUsersForDealOwner,
  loadVisibleUsers,
  updateConditionalFieldsValidation
} from 'Deals/actions'

import {
  DEAL_CONDITIONAL_FIELDS_VALIDATION,
  DEAL_COPY,
  DEAL_FETCH_FILES,
  DEAL_FETCH_VISIBLE_USERS_FOR_OWNER,
  DEAL_FETCH_VISIBLE_USERS,
  DEAL_FETCH,
  DEAL_PROFILE_FETCH,
  DEAL_RELOAD_TODOS,
  DEAL_RELOAD,
  DEAL_UPDATE,
  DEALS_FETCH_PEOPLE
} from './constants'

function * reloadDealSaga () {
  const deal = yield select(getDealSelector)
  setTimeout(() => store.dispatch(dealFetchSuccess(deal)), 0)
}

function * fetchDealSaga ({ payload }) {
  try {
    if (payload.window && payload.window.id === payload.dealId) {
      yield put(dealFetchSuccess(payload.window))
    } else {
      const response = yield call(
        fetcher,
        `/deals/${payload.dealId}`, {
          method: 'GET'
        }
      )
      yield put(dealFetchSuccess(response))
    }
  } catch (exception) {
    console.error(exception.message)
    const errorBody = yield exception.response.json()
    yield put(dealFetchError(errorBody.error))
  }
}

function * loadDealVisibleUsersSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `/api/internal/deals/${payload.dealId}/visible_users`, {
        method: 'GET'
      }
    )
    yield put(loadVisibleUsers(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * loadVisibleActiveUsersForDealOwnerSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `/api/internal/deals/${payload.dealId}/visible_active_users_for_deal_owner`, {
        method: 'GET'
      }
    )
    yield put(loadVisibleActiveUsersForDealOwner(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * reloadDealPeopleSaga ({ payload }) {
  const deal = yield select(getDealSelector)
  if (!deal) return
  try {
    const response = yield call(
      fetcher,
      `/api/internal/deals/${deal.id}/people`, {
        method: 'GET'
      }
    )
    yield put(loadPeople(response.people))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * reloadTodosSaga () {
  const deal = yield select(getDealSelector)
  try {
    const responseTodos = yield call(
      fetcher,
      `/api/internal/deals/${deal.id}/agenda`, {
        method: 'GET'
      }
    )
    yield put(loadTodos(responseTodos))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * updateDealSaga ({ payload, successCallback, failureCallback, finallyCallback }) {
  const deal = yield select(getDealSelector)
  const dealId = payload.id || deal.id

  const resource = dealId === deal.id ? deal : {}
  const conditionalFieldsParams = { resourceType: 'deal', resource, values: payload }

  if (anyFieldsToUpdate(conditionalFieldsParams)) {
    updateResourceThroughModal({
      ...conditionalFieldsParams,
      resourceFetchSuccess: dealFetchSuccess,
      closeCallback: () => {
        // Force misc components to reload with the correct data
        store.dispatch({ type: DEAL_RELOAD })
      },
      successCallback: () => {
        successCallback()
        store.dispatch({ type: DEALS_FETCH_PEOPLE })
      },
      finallyCallback
    })

    return
  }

  try {
    const response = yield call(
      fetcher,
      `/deals/${dealId}`, {
        body: JSON.stringify({ deal: { ...payload } }),
        method: 'PATCH'
      }
    )

    yield put(dealFetchSuccess(response))
    if (successCallback) {
      yield call(successCallback, response)
    }
  } catch (exception) {
    console.error(exception.message)

    if (failureCallback) {
      yield call(failureCallback, exception)
    }
  } finally {
    if (finallyCallback) {
      yield call(finallyCallback)
    }
  }
}

function * fetchFilesSaga ({ payload }) {
  const deal = yield select(getDealSelector)
  const dealId = (payload && payload.dealId) || deal.id

  try {
    const response = yield call(
      fetcher,
      `/api/internal/deals/${dealId}/files`, {
        method: 'GET'
      }
    )
    yield put(loadFiles(response))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * copyDealSaga ({ payload, successCallback }) {
  const deal = yield select(getDealSelector)

  try {
    const response = yield call(fetcher, '/api/v3/deals', {
      body: JSON.stringify({
        deal: {
          ...deal,
          source_deal_id: deal.id,
          is_copy_with_activities: false,
          created_at: null,
          updated_at: null,
          closed_time: null,
          ...payload
        }
      }),
      method: 'POST'
    })

    if (successCallback) {
      yield call(successCallback, response)
    }
  } catch (exception) {
    console.error(exception.message)
  }
}

function * updateConditionalFieldsValidationSaga () {
  const deal = yield select(getDealSelector)

  try {
    const conditionalFieldsValidations = conditionalFieldsValidator('deal', deal)
    yield put(updateConditionalFieldsValidation(conditionalFieldsValidations))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchReloadDealSaga () {
  yield call(takeEvery, DEAL_RELOAD, reloadDealSaga)
}

function * watchFetchDealSaga () {
  yield call(takeEvery, [DEAL_PROFILE_FETCH, DEAL_FETCH], fetchDealSaga)
}

function * watchReloadDealPeopleSaga () {
  yield call(takeEvery, DEALS_FETCH_PEOPLE, reloadDealPeopleSaga)
}

function * watchReloadDealTodosSaga () {
  yield call(takeEvery, DEAL_RELOAD_TODOS, reloadTodosSaga)
}

function * watchUpdateDealSaga () {
  yield call(takeEvery, DEAL_UPDATE, updateDealSaga)
}

function * watchLoadDealVisibleUsersSaga () {
  yield call(takeEvery, [DEAL_PROFILE_FETCH, DEAL_FETCH_VISIBLE_USERS], loadDealVisibleUsersSaga)
}

function * watchLoadVisibleActiveUsersForDealOwnerSaga () {
  yield call(takeEvery, [DEAL_PROFILE_FETCH, DEAL_FETCH_VISIBLE_USERS_FOR_OWNER], loadVisibleActiveUsersForDealOwnerSaga)
}

function * watchReloadDealFilesSaga () {
  yield call(takeLatest, [DEAL_PROFILE_FETCH, DEAL_FETCH_FILES], fetchFilesSaga)
}

function * watchCopyDealSaga () {
  yield call(takeEvery, DEAL_COPY, copyDealSaga)
}

function * watchUpdateConditionalFieldsValidationSaga () {
  yield call(takeEvery, DEAL_CONDITIONAL_FIELDS_VALIDATION, updateConditionalFieldsValidationSaga)
}

export const dealProfileSagas = [
  watchReloadDealSaga(),
  watchCopyDealSaga(),
  watchLoadDealVisibleUsersSaga(),
  watchLoadVisibleActiveUsersForDealOwnerSaga(),
  watchReloadDealFilesSaga(),
  watchReloadDealPeopleSaga(),
  watchFetchDealSaga(),
  watchReloadDealTodosSaga(),
  watchUpdateDealSaga(),
  watchUpdateConditionalFieldsValidationSaga()
]
