import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import {
  H1,
  H4,
  P
} from '@PipelineDeals/shared-react-components'

import { formatUserDate } from 'lib/helpers'

const AlreadyRequested = ({ startTime, dateFormat }) => {
  const formatTime = moment(startTime).format('h:mm A')

  return (
    <>
      <H1>Already Booked!</H1>
      <div style={{ padding: '30px 0' }}>
        <H4>You have already requested a demo</H4>
        <H4>on {formatUserDate(startTime, dateFormat)} at {formatTime}.</H4>
      </div>
      <P>If you want to cancel or reschedule, please email <a href="mailto:customercare@pipelinecrm.com">customercare@pipelinecrm.com</a> for more information.</P>
    </>
  )
}

AlreadyRequested.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired
}

export default AlreadyRequested
