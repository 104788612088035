export const PRODUCT_LINE_CREATE = 'PRODUCT_LINE_CREATE'
export const PRODUCT_LINE_CREATE_SUBMIT = 'PRODUCT_LINE_CREATE_SUBMIT'
export const PRODUCT_LINE_CREATE_FAILURE = 'PRODUCT_LINE_CREATE_FAILURE'

export const PRODUCT_LINE_UPDATE = 'PRODUCT_LINE_UPDATE'
export const PRODUCT_LINE_UPDATE_SUBMIT = 'PRODUCT_LINE_UPDATE_SUBMIT'
export const PRODUCT_LINE_UPDATE_FAILURE = 'PRODUCT_LINE_UPDATE_FAILURE'

export const PRODUCT_LINE_DELETE = 'PRODUCT_LINE_DELETE'
export const PRODUCT_LINE_DELETE_SUBMIT = 'PRODUCT_LINE_DELETE_SUBMIT'
export const PRODUCT_LINE_DELETE_FAILURE = 'PRODUCT_LINE_DELETE_FAILURE'
