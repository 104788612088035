const checkNumber = (trigger, value) => {
  const valueNumber = Number(value)
  const operandNumber = Number(trigger.operand || 0)

  switch (trigger.name) {
    case 'GreaterThanEqualTo':
      return numberGreaterThanEqualTo(valueNumber, operandNumber)
    case 'GreaterThan':
      return numberGreaterThan(valueNumber, operandNumber)
    case 'IsNot':
      return numberIsNot(valueNumber, operandNumber)
    case 'Is':
      return numberIs(valueNumber, operandNumber)
    case 'LessThanEqualTo':
      return numberLessThanEqualTo(valueNumber, operandNumber)
    case 'LessThan':
      return numberLessThan(valueNumber, operandNumber)
    default:
      return null
  }
}
export default checkNumber

const numberGreaterThanEqualTo = (value, operand) => {
  if (value >= operand) {
    return {
      message: `${value} is greater than or equal to ${operand}`
    }
  }
  return null
}

const numberGreaterThan = (value, operand) => {
  if (value > operand) {
    return {
      message: `${value} is greater than ${operand}`
    }
  }
  return null
}

const numberIsNot = (value, operand) => {
  if (value !== operand) {
    return {
      message: `${value} is not ${operand}`
    }
  }
  return null
}

const numberIs = (value, operand) => {
  if (value === operand) {
    return {
      message: `is ${operand}`
    }
  }
  return null
}

const numberLessThanEqualTo = (value, operand) => {
  if (value <= operand) {
    return {
      message: `${value} is less than or equal to ${operand}`
    }
  }
  return null
}

const numberLessThan = (value, operand) => {
  if (value < operand) {
    return {
      message: `${value} is less than ${operand}`
    }
  }
  return null
}
