import { SubmissionError } from 'redux-form'
import { takeEvery } from 'redux-saga'
import { call, put, race, take } from 'redux-saga/effects'

import { FORM_SUBMIT } from '../constants/ActionTypes'

export function * formSubmitSaga ({
  payload: {
    submitAction,
    successAction,
    failureAction,
    values,
    resolve,
    reject
  }
}) {
  yield put({ type: submitAction, payload: { ...values } })

  const { success, failure } = yield race({
    success: take(successAction),
    failure: take(failureAction)
  })

  if (success) {
    yield call(resolve, success.payload)
  } else {
    yield call(reject, new SubmissionError(failure.payload))
  }
}

export function * watchFormSubmitSaga () {
  yield * takeEvery(FORM_SUBMIT, formSubmitSaga)
}
