/* global REDUX_LOGGING */

import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import injectRouterMiddleware from 'modules/injectRouterMiddleware'
import rootReducer from 'reducers'
import sagas from 'sagas'

import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import dealProfileConditionalFieldsUpdater from 'Deals/middleware'
import personProfileConditionalFieldsUpdater from 'People/middleware'
import companyProfileConditionalFieldsUpdater from 'Companies/middleware'
import customerProfileConditionalFieldsUpdater from 'Customers/middleware'

export const history = createBrowserHistory()

function configureStore (initialState) {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [
    injectRouterMiddleware,
    routerMiddleware(history),
    dealProfileConditionalFieldsUpdater,
    personProfileConditionalFieldsUpdater,
    companyProfileConditionalFieldsUpdater,
    customerProfileConditionalFieldsUpdater,
    sagaMiddleware
  ]

  if (REDUX_LOGGING) {
    const { logger } = require('redux-logger')
    middlewares.push(logger)
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    persistCombineReducers(
      { key: 'ppd', whitelist: ['calendar', 'reporting', 'userProfilesLayout', 'notificationsInline'], storage },
      { router: connectRouter(history), ...rootReducer }
    ),
    initialState,

    composeEnhancers(
      applyMiddleware(
        ...middlewares
      )
    )
  )

  sagaMiddleware.run(sagas)

  return store
}

export default configureStore({})
