import {
  TEAM_CREATE,
  TEAM_DELETE,
  TEAM_UPDATE,

  TEAM_FETCH_SUCCESS
} from 'admin/Teams/constants'

const initialById = {}
const initialListedIds = []
const initialLoaded = false

const team = (state, { payload, type }) => {
  switch (type) {
    case TEAM_CREATE:
      return payload
    case TEAM_UPDATE:
      return {
        ...state,
        ...payload
      }
  }
}

const byId = (state = initialById, { payload, type }, { listedIds }) => {
  switch (type) {
    case TEAM_CREATE:
    case TEAM_UPDATE:
      return {
        ...state,
        [payload.id]: team(state[payload.id], { payload, type })
      }
    case TEAM_DELETE: {
      const teamDeleted = state[payload.id]
      const newState = { ...state }

      Object
        .values(state)
        .filter(_team => _team.parent_id === teamDeleted.id)
        .forEach(_team => {
          newState[_team.id].parent_id = teamDeleted.parent_id
        })

      return newState
    }
    case TEAM_FETCH_SUCCESS: {
      const fetchSuccess = {}
      payload.forEach(team => { fetchSuccess[team.id] = team })

      return fetchSuccess
    }
    default:
      return state
  }
}

const listedIds = (state = initialListedIds, { payload, type }, { byId }) => {
  switch (type) {
    case TEAM_CREATE:
      return [...state, payload.id]
    case TEAM_DELETE:
      return state.filter(id => id !== payload.id)
    case TEAM_FETCH_SUCCESS:
      return payload.map(team => team.id)
    default:
      return state
  }
}

const loaded = (state = initialLoaded, { type }) => {
  switch (type) {
    case TEAM_FETCH_SUCCESS:
      return true
    default:
      return state
  }
}

export default (state = {}, action) => {
  return {
    byId: byId(state.byId, action, state),
    listedIds: listedIds(state.listedIds, action, state),
    loaded: loaded(state.loaded, action)
  }
}
