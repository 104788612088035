import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { preloadApp } from 'ducks'

function * conditionalDropdownsExpireCacheSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      'api/v3/account/expire_conditional_dropdowns',
      {
        method: 'GET'
      }
    )
    yield put(preloadApp())
  } catch (expection) {
    console.error(expection.message)
  }
}

function * watchConditionalDropdownsExpireCacheSaga () {
  yield call(takeEvery, 'CONDITIONAL_DROPDOWNS_EXPIRE_CACHE', conditionalDropdownsExpireCacheSaga)
}

export default [watchConditionalDropdownsExpireCacheSaga()]
