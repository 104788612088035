import { INDEX } from 'permissions/actions'

export const GOAL = '@@permissions/goal/policy'

export default (user, action) => {
  switch (action) {
    case INDEX:
      return !user.read_only
    default:
      return false
  }
}
