/* _, window */

const sanitizeHtml = require('sanitize-html')

if (!window.Helpers) { window.Helpers = {} }

Helpers.escapeHtml = function (value) {
  return sanitizeHtml(value)
}

Helpers.escapeText = function (text) {
  return _.escape(_.unescape(text))
}

Handlebars.registerHelper('escapeHtml', text => Helpers.escapeHtml(text))
Handlebars.registerHelper('escapeText', text => Helpers.escapeText(text))
