export default [
  'addAttachment',
  'addBodyMergeTag',
  'addSubjectMergeTag',
  'applyFontFormatting',
  'archive',
  'campaignActivated',
  'campaignCreated',
  'campaignDeactivated',
  'campaignDetailsSaved',
  'campaignRulesSaved',
  'campaignStepsSaved',
  'cloneTemplate',
  'createTemplate',
  'clickAiSuggestion',
  'clickApproveAiText',
  'clickCompose',
  'clickRewriteAiText',
  'clickSyncProvider',
  'emailSettings',
  'filterAnalyticsByUser',
  'imageUploadedToEmail',
  'insertHyperlink',
  'insertImage',
  'insertTemplate',
  'markAsRead',
  'markAsUnread',
  'openEmail',
  'replyActions',
  'scheduleLater',
  'sendEmail',
  'sendTestEmail',
  'shareTemplate',
  'showSyncModal',
  'submitGenerateEmailRequest',
  'submitImproveTextRequest',
  'templateInsertImage',
  'toggleAiSuggestions',
  'updateTemplate'
]
