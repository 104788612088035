import { createSelector } from 'reselect'

import { QUOTA_TYPE_CUSTOM_FIELD_COMPANY, QUOTA_TYPE_CUSTOM_FIELD_DEAL, QUOTA_TYPE_CUSTOM_FIELD_PERSON, QUOTA_TYPE_DEAL, QUOTA_TYPE_EXPORTS, QUOTA_TYPE_MAPPING } from './constants'

export const quotaVerifyType = state => {
  switch (state.quotas.quotaVerify) {
    case QUOTA_TYPE_DEAL: {
      return state.quotas.quotas.deals
    }
    case QUOTA_TYPE_EXPORTS: {
      return state.quotas.quotas.monthly_exports_per_user
    }
    case QUOTA_TYPE_CUSTOM_FIELD_DEAL: {
      return state.quotas.quotas.custom_fields_deal
    }
    case QUOTA_TYPE_CUSTOM_FIELD_PERSON: {
      return state.quotas.quotas.custom_fields_person
    }
    case QUOTA_TYPE_CUSTOM_FIELD_COMPANY: {
      return state.quotas.quotas.custom_fields_company
    }
  }
}

export const quotaExceeded = createSelector(
  [quotaVerifyType],
  (type) => {
    return type && Boolean(!type.can_allocate)
  }
)

export const quotaReadOnlyUserAvailable = state => state.quotas.quotas.read_only_users?.available
export const quotaCanAllocateReadOnlyUser = state => Boolean(state.quotas.quotas.read_only_users?.can_allocate)
export const quotaCanAllocateDeal = state => {
  if (!state.quotas.quotasFetchedAt || !state.quotas.quotas.deals) { return true }
  return Boolean(state.quotas.quotas.deals?.can_allocate)
}

export const quotaVerifyTimestamp = state => state.quotas.quotaVerifyTimestamp
export const quotaContent = state => state.quotas.quotaContent

export const quotaDealCouldExceed = createSelector(
  (state, changeCount) => ({ changeCount, state }),
  ({ changeCount, state }) => {
    if (!state.quotas.quotasFetchedAt || !state.quotas.quotas.deals) { return false }

    if (state.quotas.quotas.deals?.limit > 0 && state.quotas.quotas.deals?.available < changeCount) {
      return true
    }

    return false
  }
)

export const quotaEmailTrackingWillExceed = createSelector(
  (state, recipientCount) => ({ recipientCount, state }),
  ({ recipientCount, state }) => {
    if (!state.quotas.quotasFetchedAt || !state.quotas.quotas.email_tracking) { return false }

    if (state.quotas.quotas.email_tracking?.limit !== -1 && state.quotas.quotas.email_tracking?.available < recipientCount) {
      return true
    }

    return false
  }
)

export const quotaExportsAvailable = state => state.quotas.quotas.monthly_exports_per_user && state.quotas.quotas.monthly_exports_per_user?.available

export const getQuotasInUse = state =>
  Object.entries(QUOTA_TYPE_MAPPING)
    .filter(quota => state.modern.currentAccount.quotas_in_use.includes(quota[1]))
    .map(quotaArray => quotaArray[0])
