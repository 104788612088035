import { COMPANY_FETCH_SUCCESS } from 'Companies/constants'
import { conditionalFieldsValidation } from 'Companies/actions'

const companyProfileConditionalFieldsUpdater = store => next => action => {
  if (action.type === COMPANY_FETCH_SUCCESS) {
    const result = next(action)
    store.dispatch(conditionalFieldsValidation())
    return result
  }

  return next(action)
}

export default companyProfileConditionalFieldsUpdater
