import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'
import ensureHttp from 'modules/link'

import { useBusinessCard } from 'components/BusinessCard/context'

const Website = ({ websiteAttrs }) => {
  const { cardMeta: { showOnlyFields }, resource, resourceType } = useBusinessCard()

  const websites = useMemo(() => {
    return websiteAttrs.filter(attr => showOnlyFields.includes(attr)).map(attr => resource[attr]).filter(attr => !!attr)
  }, [websiteAttrs, showOnlyFields, resource])

  if (!websites.length) return null

  return (
    <Section data-cypress={`${resourceType}-business-card-website`}>
      {websites.map(website =>
        <Row key={website}>
          <Left><span className="title">website</span></Left>
          <Right>
            <a href={ensureHttp(website)} target="_blank" rel="noopener noreferrer">{website}</a>
          </Right>
        </Row>
      )}
    </Section>
  )
}

Website.propTypes = {
  websiteAttrs: PropTypes.array.isRequired
}

export default Website
