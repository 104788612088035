const filterNewCustomFieldLabelDropdownEntries = label => {
  return {
    ...label,
    custom_field_label_dropdown_entries: label.custom_field_label_dropdown_entries.filter(entry => !entry.isNew)
  }
}

const scrubCustomFieldLabelDropdownEntriesId = label => {
  return {
    ...label,
    custom_field_label_dropdown_entries: label.custom_field_label_dropdown_entries.map(entry => {
      if (!entry.isNotPersisted) return entry

      return {
        ...entry,
        id: undefined,
        isNotPersisted: undefined
      }
    })
  }
}

const renameCustomFieldLabelDropdownEntries = label => {
  label.custom_field_label_dropdown_entries_attributes = [...label.custom_field_label_dropdown_entries]
  delete label.custom_field_label_dropdown_entries

  return label
}

export default (label) => {
  if (label.custom_field_label_dropdown_entries) {
    label = filterNewCustomFieldLabelDropdownEntries(label)
    label = scrubCustomFieldLabelDropdownEntriesId(label)
    label = renameCustomFieldLabelDropdownEntries(label)

    if (label.custom_field_label_dropdown_entries_attributes.length === 0) {
      delete label.custom_field_label_dropdown_entries_attributes
    }
  }

  return label
}
