import update from 'immutability-helper'

import { reducer as formReducer } from 'redux-form'

import constants from '../constants' // otherwise it'll import from constants
import sortByAlpha from 'modules/sortByAlpha'

import customFields from 'admin/custom_fields/reducers'

import { reducer as customers } from 'Customers/ducks'
import { reducer as companies } from 'Companies/ducks'
import { reducer as deals } from 'Deals/ducks'
import { reducer as people } from 'People/ducks'
import account from 'admin/account/reducers'
import modals from 'modals/ducks'
import { reducer as modern } from 'ducks'
import dealPipelines from 'admin/deal_pipelines/reducers/pipelines'
import dealStages from 'admin/deal_pipelines/reducers/stages'
import productLines from 'admin/product_lines/reducers/product_lines'
import { quickSearch } from 'GlobalSearch/ducks'
import subscription from './Subscription'

import teams from 'admin/Teams/reducer'
import ui from 'reducers/ui'
import notificationsInline from 'Notifications/NotificationsInline/ducks'
import users, { userProfilesLayout } from 'reducers/User'
import searches from 'admin/shared_lists/reducer'
import goalsProductLinesReport from 'Goals/reducers/ProductLines/report'
import goalsProductLines from 'Goals/reducers/ProductLines/index'
import { reducer as calendar, reducerEvents as calendarEvents } from 'Calendar/ducks'
import { reducer as myProfile } from 'MyProfile/ducks'
import { reducer as quotas } from 'Quotas/ducks'

const form = formReducer.plugin({
  custom_field_label: (state, action) => {
    switch (action.type) {
      case constants.customFields.MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_AS_NOT_NEW: {
        const entry = {
          ...state.values.custom_field_label_dropdown_entries[action.index]
        }
        delete entry.isNew

        return {
          ...state,
          values: update(state.values, {
            custom_field_label_dropdown_entries: {
              $splice: [
                [action.index, 1, entry]
              ]
            }
          })
        }
      }
      case constants.customFields.MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_FOR_DELETION: {
        const entries = state.values.custom_field_label_dropdown_entries.map(entry => {
          if (entry.id !== action.payload.id) return entry

          return { ...entry, _destroy: true }
        })

        return {
          ...state,
          values: {
            ...state.values,
            custom_field_label_dropdown_entries: entries
          }
        }
      }
      case constants.customFields.MOVE_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY: {
        const item = state.values.custom_field_label_dropdown_entries.find(item => item.id === action.id)
        const index = state.values.custom_field_label_dropdown_entries.indexOf(item)

        const values = update(state.values, {
          custom_field_label_dropdown_entries: {
            $splice: [
              [index, 1],
              [action.atIndex, 0, item]
            ]
          }
        })

        let position = 1
        values.custom_field_label_dropdown_entries = values.custom_field_label_dropdown_entries.map(entry => {
          if (entry._destroy) return entry

          return {
            ...entry,
            position: position++
          }
        })

        return {
          ...state,
          values
        }
      }
      case constants.customFields.SORT_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRIES: {
        let _entries = [...state.values.custom_field_label_dropdown_entries]
        const newEntry = _entries.find(entry => entry.isNew)
        const { direction } = action

        if (newEntry) _entries.pop()

        _entries.sort(sortByAlpha('name', direction))
        _entries = _entries.map((entry, index) => {
          return {
            ...entry,
            position: index + 1
          }
        })

        if (newEntry) _entries.push(newEntry)

        return {
          ...state,
          values: {
            ...state.values,
            custom_field_label_dropdown_entries: _entries
          }
        }
      }
      default:
        return state
    }
  }
})

const reducers = {
  account,
  customFields,
  customers,
  companies,
  deals,
  people,
  goalsProductLinesReport,
  goalsProductLines,
  form,
  modals,
  modern,
  dealPipelines,
  dealStages,
  productLines,
  subscription,
  teams,
  ui,
  users,
  userProfilesLayout,
  searches,
  quickSearch,
  calendar,
  calendarEvents,
  myProfile,
  notificationsInline,
  quotas
}

export default reducers
