import React from 'react'

import Addresses from 'components/BusinessCard/components/Addresses'
import CreatedUpdated from 'components/BusinessCard/components/CreatedUpdated'
import Deals from 'components/BusinessCard/components/Deals'
import Description from 'components/BusinessCard/components/Description'
import Emails from 'components/BusinessCard/components/Emails'
import Fax from 'components/BusinessCard/components/Fax'
import PhoneNumbers from 'components/BusinessCard/components/PhoneNumbers'
import Tags from 'components/BusinessCard/components/Tags'
import Websites from 'components/BusinessCard/components/Websites'
import SocialSection from 'components/BusinessCard/components/SocialSection'

const Company = () => {
  return (
    <>
      <CreatedUpdated />

      <PhoneNumbers phoneAttrs={['phone1', 'phone2', 'phone3', 'phone4']} />
      <Fax faxAttrs={['fax']} />
      <Emails emailAttrs={['email']} />
      <Websites websiteAttrs={['web']} />
      <Addresses addressTypes={['address']} />
      <Tags />
      <Deals />
      <Description />
      <SocialSection />
    </>
  )
}

export default Company
