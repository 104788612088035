const DEAL_TYPE = 'Deal'
const PERSON_TYPE = 'Person'
const COMPANY_TYPE = 'Company'

const DEFAULT_FIELDS = {
  [COMPANY_TYPE]: [
    'name',
    'owner',
    'phone1',
    'phone2',
    'phone3',
    'phone4',
    'fax',
    'email',
    'web',
    'address',
    'twitter',
    'facebook_url',
    'linked_in_url',
    'instant_message'
  ],
  [DEAL_TYPE]: [
    'name',
    'user'
  ],
  [PERSON_TYPE]: [
    'name',
    'owner',
    'position',
    'company_name',
    'phone',
    'home_phone',
    'mobile',
    'fax',
    'email',
    'email2',
    'home_email',
    'website',
    'work_address',
    'company_address',
    'home_address',
    'twitter',
    'facebook_url',
    'linked_in_url',
    'instant_message'
  ]
}

export {
  DEAL_TYPE,
  PERSON_TYPE,
  COMPANY_TYPE,
  DEFAULT_FIELDS
}
