/* global App */

import addEditRecord from 'add_edit_modals'

export default ({ personData, onSuccess, onClose }) => {
  const { id, full_name } = personData || {}

  const person = new App.Models.Person({
    id,
    full_name
  })
  const calendarTask = new App.Models.CalendarTask()
  calendarTask.associate(person)

  addEditRecord({ resourceType: 'calendar_task', values: calendarTask.attributes, formMeta: { onSuccess, onClose } })
}
