export const COMPANIES_COMPANY_FETCH_SUCCESS = 'COMPANIES_COMPANY_FETCH_SUCCESS'
export const COMPANY_PROFILE_FETCH = 'COMPANY_PROFILE_FETCH'
export const COMPANY_FETCH = 'COMPANY_FETCH'
export const COMPANY_RELOAD = 'COMPANY_RELOAD'
export const COMPANY_FETCH_SUCCESS = 'COMPANY_FETCH_SUCCESS'
export const COMPANY_FETCH_ERROR = 'COMPANY_FETCH_ERROR'
export const COMPANY_RELOAD_TODOS = 'COMPANY_RELOAD_TODOS'
export const COMPANY_FETCH_VISIBLE_USERS = 'COMPANY_FETCH_VISIBLE_USERS'

export const CONVERT_TO_CUSTOMER_MODAL = 'CONVERT_TO_CUSTOMER_MODAL'
export const COMPANIES_MERGE_MODAL = 'COMPANIES_MERGE_MODAL'

export const COMPANIES_UPDATE_ACTIVITY_FILTERS = 'COMPANIES_UPDATE_ACTIVITY_FILTERS'
export const COMPANY_ACTIVITY_ANY_TIME = 'COMPANY_ACTIVITY_ANY_TIME'
export const COMPANY_PERSON_FILTER_ALL = 'COMPANY_PERSON_FILTER_ALL'
export const COMPANY_COMPANY_FILTER_ALL = 'COMPANY_COMPANY_FILTER_ALL'

export const COMPANIES_LOAD_TODOS = 'COMPANIES_LOAD_TODOS'
export const COMPANIES_UPDATE_TODO = 'COMPANIES_UPDATE_TODO'
export const COMPANIES_DELETE_TODO = 'COMPANIES_DELETE_TODO'
export const COMPANIES_UPDATE_TODO_FILTERS = 'COMPANIES_UPDATE_TODO_FILTERS'

export const COMPANY_DEAL_NEW_SUCCESS = 'COMPANY_DEAL_NEW_SUCCESS'
export const COMPANY_DEAL_ACTIVE_FILTER = 'COMPANY_DEAL_ACTIVE_FILTER'

export const COMPANIES_LOAD_DEALS = 'COMPANIES_LOAD_DEALS'
export const COMPANIES_CREATE_DEAL = 'COMPANIES_CREATE_DEAL'
export const COMPANIES_UPDATE_DEAL = 'COMPANIES_UPDATE_DEAL'
export const COMPANIES_DELETE_DEAL = 'COMPANIES_DELETE_DEAL'
export const COMPANIES_SORT_DEALS = 'COMPANIES_SORT_DEALS'
export const COMPANIES_FILTER_DEALS = 'COMPANIES_FILTER_DEALS'

export const COMPANIES_LOAD_FILES = 'COMPANIES_LOAD_FILES'
export const COMPANIES_CREATE_FILE = 'COMPANIES_CREATE_FILE'
export const COMPANIES_DELETE_FILE = 'COMPANIES_DELETE_FILE'
export const COMPANIES_FILTER_FILES = 'COMPANIES_FILTER_FILES'
export const COMPANIES_UPDATE_FILE = 'COMPANIES_UPDATE_FILE'
export const COMPANIES_SORT_FILES = 'COMPANIES_SORT_FILES'

export const COMPANIES_LOAD_PEOPLE = 'COMPANIES_LOAD_PEOPLE'
export const COMPANIES_CREATE_PERSON = 'COMPANIES_CREATE_PERSON'
export const COMPANIES_UPDATE_PERSON = 'COMPANIES_UPDATE_PERSON'
export const COMPANIES_DELETE_PERSON = 'COMPANIES_DELETE_PERSON'
export const COMPANIES_SORT_PEOPLE = 'COMPANIES_SORT_PEOPLE'
export const COMPANIES_FILTER_PEOPLE = 'COMPANIES_FILTER_PEOPLE'
export const COMPANY_PERSON_NEW_SUCCESS = 'COMPANY_PERSON_NEW_SUCCESS'
export const COMPANIES_FETCH_PEOPLE = 'COMPANIES_FETCH_PEOPLE'

export const COMPANY_PERSON_LEAD_STATUS_FILTER = 'COMPANY_PERSON_LEAD_STATUS_FILTER'

export const COMPANIES_LOAD_VISIBLE_USERS = 'COMPANIES_LOAD_VISIBLE_USERS'

export const COMPANY_PROFILE_RESET = 'COMPANY_PROFILE_RESET'
export const COMPANY_UPDATE = 'COMPANY_UPDATE'
export const COMPANY_CONDITIONAL_FIELDS_VALIDATION = 'COMPANY_CONDITIONAL_FIELDS_VALIDATION'
export const COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE = 'COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE'

export const COMPANY_FETCH_CHILDREN_COMPANIES = 'COMPANY_FETCH_CHILDREN_COMPANIES'
export const COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES = 'COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES'
export const COMPANY_FILTER_CHILDREN_COMPANIES = 'COMPANY_FILTER_CHILDREN_COMPANIES'
export const COMPANY_SORT_CHILDREN_COMPANIES = 'COMPANY_SORT_CHILDREN_COMPANIES'
