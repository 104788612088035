export const DEAL_LOSS_REASON_CREATE = 'DEAL_LOSS_REASON_CREATE'
export const DEAL_LOSS_REASON_CREATE_SUBMIT = 'DEAL_LOSS_REASON_CREATE_SUBMIT'
export const DEAL_LOSS_REASON_CREATE_FAILURE = 'DEAL_LOSS_REASON_CREATE_FAILURE'

export const DEAL_LOSS_REASON_UPDATE = 'DEAL_LOSS_REASON_UPDATE'
export const DEAL_LOSS_REASON_UPDATE_SUBMIT = 'DEAL_LOSS_REASON_UPDATE_SUBMIT'
export const DEAL_LOSS_REASON_UPDATE_FAILURE = 'DEAL_LOSS_REASON_UPDATE_FAILURE'

export const DEAL_LOSS_REASON_DELETE = 'DEAL_LOSS_REASON_DELETE'
export const DEAL_LOSS_REASON_DELETE_SUBMIT = 'DEAL_LOSS_REASON_DELETE_SUBMIT'
export const DEAL_LOSS_REASON_DELETE_FAILURE = 'DEAL_LOSS_REASON_DELETE_FAILURE'

export const DEAL_LOSS_REASON_REQUIRED_CHANGE = 'DEAL_LOSS_REASON_REQUIRED'
export const DEAL_LOSS_REASON_REQUIRED_CHANGE_SUBMIT = 'DEAL_LOSS_REASON_REQUIRED_SUBMIT'
export const DEAL_LOSS_REASON_REQUIRED_CHANGE_FAILURE = 'DEAL_LOSS_REASON_REQUIRED_FAILURE'

export const DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE = 'DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE'
export const DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_SUBMIT = 'DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_SUBMIT'
export const DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_FAILURE = 'DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_FAILURE'

export const DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE = 'DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE'
export const DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT = 'DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT'
export const DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_FAILURE = 'DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_FAILURE'
