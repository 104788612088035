import jQuery from 'jquery'

export default (element, options = {}, user = undefined) => {
  const {
    className,
    cypress,
    disabled,
    elementName = 'div',
    elementClassName,
    excludeDisabledClassName,
    omitHTML,
    omitParent,
    omittedClassName,
    readonly,
    tooltip,
    tooltipPosition,
    linkedTooltip
  } = options
  const shouldOmitHTML = (omitHTML === 'true' || omitHTML === true)
  const shouldOmitParent = (omitParent === 'true' || omitParent === true)
  let _content

  if (shouldOmitHTML) {
    _content = element.text()
  } else if (shouldOmitParent) {
    _content = element.prop('innerHTML')
  } else {
    if (disabled) {
      element.prop('disabled', true)
      element.attr('disabled', true)
    }
    if (elementClassName) element.addClass(elementClassName)
    if (readonly) element.prop('readonly', true)
    _content = element.prop('outerHTML')
  }

  const div = jQuery(`<${elementName} />`)
  div.html(_content)
  div.attr('data-cypress', cypress || 'ah-ah-ah-you-didnt-say-the-magic-word')
  if (!excludeDisabledClassName) div.addClass('disabled')

  if (className) div.addClass(className)
  if (shouldOmitHTML && omittedClassName) div.addClass(omittedClassName)
  if (tooltip) {
    // tooltip that includes link
    if (linkedTooltip) {
      div.attr('data-toggle', 'tooltip-with-link')
      div.attr('data-html', 'true')
      div.attr('data-container', 'body')
    } else {
      div.attr('data-toggle', 'tooltip')
    }

    div.attr('data-placement', tooltipPosition || 'top')
    div.attr('title', tooltip)
  }

  return div.prop('outerHTML')
}
