import * as constants from '../constants'
import { LOADING, READY } from 'components/Loader'

const initialState = {
  stages: [],
  currentEditStageId: null,
  loadingStatus: LOADING
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.DEAL_STAGES_FETCH_SUCCESS: {
      const stages = action.payload.sort((a, b) => a.percent - b.percent)
      return {
        ...state,
        loadingStatus: READY,
        stages
      }
    }
    case constants.DEAL_STAGE_CREATE:
      return {
        ...state,
        loadingStatus: READY,
        stages: [...state.stages, action.payload]
      }
    case constants.DEAL_STAGE_CURRENTLY_EDIT:
      return {
        ...state,
        loadingStatus: READY,
        currentEditStageId: action.payload.id
      }
    case constants.DEAL_STAGE_CURRENT_EDIT_CLEAR:
      return {
        ...state,
        currentEditStageId: null
      }
    case constants.DEAL_STAGE_UPDATE:
      return {
        ...state,
        loadingStatus: READY,
        stages: state.stages.map(item => item.id !== action.payload.id ? item : action.payload)
      }
    case constants.DEAL_STAGE_DELETE:
      return {
        ...state,
        loadingStatus: READY
      }
    default:
      return state
  }
}
