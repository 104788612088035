import * as constants from 'Goals/constants/ProductLines'
import { LOADING, READY } from 'components/Loader'

import {
  decorateLines,
  decorateTeams,
  decorateUsers,
  decorateUserLines
} from 'Goals/lib/ProductLines/decorators'

const defaultFiltersState = {
  lines: [],
  team: null,
  user: null,
  user_line: null
}

const defaultReportState = {
  lines: [],
  teams: [],
  users: [],
  userLines: []
}

const initialState = {
  filtersLoadingStatus: LOADING,
  goals: [],
  goal: {},
  currentSection: null,
  filters: { ...defaultFiltersState },
  report: { ...defaultReportState },
  dealPipelineId: null,
  dealVisibilityType: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GOALS_PRODUCT_LINES_FILTERS_FETCH_SUCCESS: {
      const { goals, dealVisibilityType } = action.payload

      return {
        ...state,
        goals,
        dealVisibilityType,
        filtersLoadingStatus: READY
      }
    }
    case constants.GOALS_PRODUCT_LINES_REPORT_FETCH_SUCCESS: {
      const { data, goal } = action.payload
      const { lines, teams, users, user_lines: userLines } = data

      return {
        ...state,
        goal,
        reportLoadingStatus: READY,
        report: {
          lines: decorateLines({ lines }),
          teams: decorateTeams({ teams }),
          users: decorateUsers({ users }),
          userLines: decorateUserLines({ lines: userLines })
        }
      }
    }
    case constants.GOALS_PRODUCT_LINES_FILTERS_UPDATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    case constants.GOALS_PRODUCT_LINES_REPORT_FETCH: {
      const { dealPipelineId, goalId, initialSection } = action.payload

      return {
        ...state,
        filters: { ...defaultFiltersState },
        report: { ...defaultReportState },
        reportLoadingStatus: LOADING,
        currentSection: initialSection,
        goal: state.goals.find(g => g.id === goalId),
        dealPipelineId
      }
    }
    case constants.GOALS_PRODUCT_LINES_FILTERS_SECTION_CHANGE:
      return {
        ...state,
        currentSection: action.payload.currentSection
      }
    case constants.GOALS_PRODUCT_LINES_FILTERS_GOAL_RESET:
      return {
        ...state,
        goal: {}
      }
    default:
      return state
  }
}
