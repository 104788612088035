import * as constants from '../constants'

const initialState = {
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.PRODUCT_LINE_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }

    case constants.PRODUCT_LINE_UPDATE:
      return {
        ...state,
        error: false
      }
    case constants.PRODUCT_LINE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }

    case constants.PRODUCT_LINE_DELETE:
      return {
        ...state,
        error: false
      }
    case constants.PRODUCT_LINE_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    default:
      return state
  }
}
