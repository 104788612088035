import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import {
  dealWonReasonCreate,
  dealWonReasonCreateFailure,
  dealWonReasonUpdate,
  dealWonReasonUpdateFailure,
  dealWonReasonDelete,
  dealWonReasonDeleteFailure,
  dealWonReasonRequiredChange,
  dealWonReasonRequiredChangeFailure,
  dealWonReasonShowDialogChange,
  dealWonReasonShowDialogChangeFailure,
  dealWonReasonShowDealProfileChange,
  dealWonReasonShowDealProfileChangeFailure
} from '../actions'

import {
  DEAL_WON_REASON_CREATE_SUBMIT,
  DEAL_WON_REASON_UPDATE_SUBMIT,
  DEAL_WON_REASON_DELETE_SUBMIT,
  DEAL_WON_REASON_REQUIRED_CHANGE_SUBMIT,
  DEAL_WON_REASON_SHOW_DIALOG_CHANGE_SUBMIT,
  DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT
} from '../constants'

export function * dealWonReasonCreateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_won_reasons',
      {
        method: 'POST',
        body: JSON.stringify({
          deal_won_reason: {
            name: payload.name
          }
        })
      }
    )
    yield put(dealWonReasonCreate(response))
  } catch (exception) {
    yield put(dealWonReasonCreateFailure(exception.message))
  }
}

export function * dealWonReasonUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/deal_won_reasons/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          deal_won_reason: {
            name: payload.name,
            id: payload.id
          }
        })
      }
    )
    yield put(dealWonReasonUpdate(response))
  } catch (exception) {
    yield put(dealWonReasonUpdateFailure(exception.message))
  }
}

export function * dealWonReasonDeleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/deal_won_reasons/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(dealWonReasonDelete(payload))
  } catch (exception) {
    yield put(dealWonReasonDeleteFailure(exception.message))
  }
}

export function * dealWonReasonRequiredChangeSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher, 'admin/account',
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal_won_reason_required: payload.dealWonReasonRequired
        })
      }
    )
    yield put(dealWonReasonRequiredChange(response))
    yield call(window.Helpers.notify, 'Deal Won Reason', 'Your settings have been saved')
  } catch (exception) {
    yield put(dealWonReasonRequiredChangeFailure(exception.message))
  }
}

export function * dealWonReasonShowDialogChangeSaga ({ payload }) {
  try {
    const response = yield call(fetcher, 'admin/account', {
      method: 'PATCH',
      body: JSON.stringify({
        show_deal_won_reason_dialog: payload.ShowDealWonReasonDialog
      })
    })
    yield put(dealWonReasonShowDialogChange(response))
    yield call(
      window.Helpers.notify,
      'Deal Won Reason',
      'Your settings have been saved'
    )
  } catch (exception) {
    yield put(dealWonReasonShowDialogChangeFailure(exception.message))
  }
}

export function * dealWonReasonShowDealProfileChangeSaga ({ payload }) {
  try {
    const response = yield call(fetcher, 'admin/account', {
      method: 'PATCH',
      body: JSON.stringify({
        show_deal_won_reason_deal_profile: payload.ShowDealWonReasonDealProfile
      })
    })
    yield put(dealWonReasonShowDealProfileChange(response))
    yield call(
      window.Helpers.notify,
      'Deal Won Reason',
      'Your settings have been saved'
    )
  } catch (exception) {
    yield put(dealWonReasonShowDealProfileChangeFailure(exception.message))
  }
}

export function * watchDealWonReasonCreateSaga () {
  yield call(takeEvery, DEAL_WON_REASON_CREATE_SUBMIT, dealWonReasonCreateSaga)
}

export function * watchDealWonReasonUpdateSaga () {
  yield call(takeEvery, DEAL_WON_REASON_UPDATE_SUBMIT, dealWonReasonUpdateSaga)
}

export function * watchDealWonReasonDeleteSaga () {
  yield call(takeEvery, DEAL_WON_REASON_DELETE_SUBMIT, dealWonReasonDeleteSaga)
}

export function * watchDealWonReasonRequiredChangeSaga () {
  yield call(takeEvery, DEAL_WON_REASON_REQUIRED_CHANGE_SUBMIT, dealWonReasonRequiredChangeSaga)
}

export function * watchDealWonReasonShowDialogChangeSaga () {
  yield call(takeEvery, DEAL_WON_REASON_SHOW_DIALOG_CHANGE_SUBMIT, dealWonReasonShowDialogChangeSaga)
}

export function * watchDealWonReasonShowDealProfileChangeSaga () {
  yield call(takeEvery, DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT, dealWonReasonShowDealProfileChangeSaga)
}
