import React from 'react'
import store from 'store'

import { showModal } from 'modals/ducks'
import { MODAL_MESSAGE_COMPOSER } from 'Email/constants'

export default (email, formatted = undefined) => {
  if (!email) return null

  if (window.User.connectSynced && window.User.pipelineEmailClient) {
    return composeEmail(email, formatted)
  } else {
    return regularMailtoAndBccPipelineUser(email, formatted)
  }
}

const handleClick = (e, email) => {
  e.stopPropagation()

  store.dispatch(showModal({ type: MODAL_MESSAGE_COMPOSER, props: { emailData: { to: email } } }))
}

const composeEmail = (email, formatted) => (
  <a
    className="js-compose-email track"
    data-user-action="Connect - compose from profile"
    data-email={email}
    onClick={(e) => handleClick(e, email)}
  >
    {formatted || email}
  </a>
)

const regularMailtoAndBccPipelineUser = (email, formatted) => (
  <a
    href={`mailto:${email}?bcc=cc@app.pipelinecrm.com`}
    target="_blank"
    rel="noopener noreferrer"
    onClick={(e) => { e.stopPropagation() }}
  >
    {formatted || email}
  </a>
)
