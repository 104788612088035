import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  API_APPLICATION_UPDATE_SUBMIT,
  API_APPLICATION_CREATE_SUBMIT,
  API_APPLICATION_DELETE_SUBMIT
} from './constants'

import {
  apiApplicationCreate,
  apiApplicationCreateFailure,
  apiApplicationUpdate,
  apiApplicationUpdateFailure,
  apiApplicationDelete,
  apiApplicationDeleteFailure
} from './actions'

function apiApplicationFlags ({ payload }) {
  return {
    ...payload.flags,
    jwt_expiration_period: payload.jwt_expiration_period && payload.jwt_expiration_period * (24 * 60 * 60),
    allow_jwt_auth: payload.auth_strategy === 'jwt' || payload.auth_strategy === 'both',
    allow_api_key_auth: payload.auth_strategy === 'api_key' || payload.auth_strategy === 'both'
  }
}

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/api_applications',
      {
        method: 'POST',
        body: JSON.stringify({
          api_application: {
            app_name: payload.app_name,
            app_owner_name: payload.app_owner_name,
            app_owner_email: payload.app_owner_email,
            app_owner_phone: payload.app_owner_phone,
            public_app: payload.public_app,
            flags: apiApplicationFlags({ payload }),
            account_id: payload.account_id
          }
        })
      }
    )
    yield put(apiApplicationCreate(response))
  } catch (expection) {
    yield put(apiApplicationCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, API_APPLICATION_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/api_applications/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          api_application: {
            app_name: payload.app_name,
            app_owner_name: payload.app_owner_name,
            app_owner_email: payload.app_owner_email,
            app_owner_phone: payload.app_owner_phone,
            public_app: payload.public_app,
            flags: apiApplicationFlags({ payload })
          }
        })
      }
    )
    yield put(apiApplicationUpdate(response))
  } catch (exception) {
    yield put(apiApplicationUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, API_APPLICATION_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/api_applications/${payload.id}`,
      {
        method: 'DELETE'
      }
    )
    yield put(apiApplicationDelete(payload))
  } catch (exception) {
    yield put(apiApplicationDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, API_APPLICATION_DELETE_SUBMIT, deleteSaga)
}
