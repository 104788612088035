import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  ACCOUNT_EXPORT_UPDATE_SUBMIT,
  ACCOUNT_EXPORT_CREATE_SUBMIT,
  ACCOUNT_EXPORT_DELETE_SUBMIT
} from './constants'

import {
  accountExportCreate,
  accountExportCreateFailure,
  accountExportUpdate,
  accountExportUpdateFailure,
  accountExportDelete,
  accountExportDeleteFailure
} from './actions'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/account_exports',
      {
        method: 'POST',
        body: JSON.stringify({
          account_export: {
            export_type: payload.export_type,
            period: payload.period,
            user_id: payload.user_id,
            account_id: payload.account_id,
            callout_url: payload.callout_url,
            notification_emails: payload.emails ? payload.emails.join(',') : ''
          }
        })
      }
    )
    yield put(accountExportCreate(response))
  } catch (expection) {
    console.error(expection.message)
    yield put(accountExportCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, ACCOUNT_EXPORT_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/account_exports/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          account_export: {
            export_type: payload.export_type,
            period: payload.period,
            callout_url: payload.callout_url || '',
            notification_emails: payload.emails ? payload.emails.join(',') : ''
          }
        })
      }
    )
    yield put(accountExportUpdate(response))
  } catch (exception) {
    yield put(accountExportUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, ACCOUNT_EXPORT_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/account_exports/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(accountExportDelete(payload))
  } catch (exception) {
    console.error(exception.message)
    yield put(accountExportDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, ACCOUNT_EXPORT_DELETE_SUBMIT, deleteSaga)
}
