import {
  UPDATE,
  DELETE,
  BULK_ACTIONS,
  LIST_VIEW_CREATE,
  LIST_VIEW_DELETE,
  LIST_VIEW_DUPLICATE,
  LIST_VIEW_SHARE,
  LIST_VIEW_STAR,
  LIST_VIEW_UPDATE,
  LIST_VIEW_CREATE_FOCUSED
} from 'permissions/actions'

export const DOCUMENT = '@@permissions/document/policy'

export default {
  [UPDATE]: user => !user.read_only,
  [DELETE]: user => !user.read_only,
  [BULK_ACTIONS]: user => !user.read_only,
  [LIST_VIEW_CREATE]: user => !user.read_only,
  [LIST_VIEW_DELETE]: user => !user.read_only,
  [LIST_VIEW_DUPLICATE]: user => !user.read_only,
  [LIST_VIEW_SHARE]: user => !user.read_only,
  [LIST_VIEW_STAR]: user => !user.read_only,
  [LIST_VIEW_UPDATE]: user => !user.read_only,
  [LIST_VIEW_CREATE_FOCUSED]: user => !user.read_only && !user.external
}
