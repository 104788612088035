// This must be a function defined as function Name (args) {}
function RequestError (response, jsonError = null) {
  this.message = response.statusText || 'No message'
  this.name = 'RequestError'
  this.response = response
  this.stack = (new Error()).stack
  this.status = response.status
  this.json = jsonError
}
RequestError.prototype = Object.create(Error.prototype)
RequestError.prototype.constructor = RequestError

export default RequestError
