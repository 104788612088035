import {
  SOURCE_CREATE,
  SOURCE_CREATE_FAILURE,
  SOURCE_UPDATE,
  SOURCE_UPDATE_FAILURE,
  SOURCE_DELETE,
  SOURCE_DELETE_FAILURE
} from '../constants'

export const sourceCreate = payload => ({ payload, type: SOURCE_CREATE })
export const sourceCreateFailure = payload => ({ payload, type: SOURCE_CREATE_FAILURE })

export const sourceUpdate = payload => ({ payload, type: SOURCE_UPDATE })
export const sourceUpdateFailure = payload => ({ payload, type: SOURCE_UPDATE_FAILURE })

export const sourceDelete = payload => ({ payload, type: SOURCE_DELETE })
export const sourceDeleteFailure = payload => ({ payload, type: SOURCE_DELETE_FAILURE })
