export const HIDE_MODAL = '@@modals/HIDE_MODAL'
export const SHOW_MODAL = '@@modals/SHOW_MODAL'
export const TOGGLE_MODAL = '@@modals/TOGGLE_MODAL'

export const CONFIRMATION_MODAL = '@@modals/CONFIRMATION_MODAL'
export const LICENSE_INFO_MODAL = '@@modals/LICENSE_INFO_MODAL'
export const GLOBAL_SEARCH_MODAL = '@@modals/GLOBAL_SEARCH_MODAL'

const initialState = {}

export default function modals (state = initialState, action) {
  const hideModal = () => {
    const localState = {}

    window.trackableEvent.general.hideModal({ type: action.payload.type })

    for (const modal in state) {
      if (action.payload.type !== modal) localState[modal] = state[modal]
    }

    return localState
  }

  const showModal = () => {
    if (state[action.payload.type]) return state

    window.trackableEvent.general.showModal({ type: action.payload.type })

    return {
      ...state,
      [action.payload.type]: action.payload.props
    }
  }

  switch (action.type) {
    case HIDE_MODAL:
      return hideModal()
    case SHOW_MODAL:
      return showModal()
    case TOGGLE_MODAL:
      return state[action.payload.type] ? hideModal() : showModal()
    default:
      return state
  }
}

export const hideModal = payload => ({ payload, type: HIDE_MODAL })
export const showModal = payload => ({ payload, type: SHOW_MODAL })
export const toggleModal = payload => ({ payload, type: TOGGLE_MODAL })

export const confirmationModal = props => showModal({ props, type: CONFIRMATION_MODAL })
export const licenseInfoModal = props => showModal({ props, type: LICENSE_INFO_MODAL })
