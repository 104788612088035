import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BusinessCard, PERSON_TYPE } from './index'
import $ from 'jquery'
import styled from 'styled-components'

const Navigation = styled.div`
  &&& {
    margin: 9px 14px;
    text-align: left;
    margin-bottom: -5px;
  }
`
const Indicators = styled.ol`
  &&& {
    position: relative;
    top: 10px;
    left: 0;
    height: auto;
    min-height: 25px;
    max-width: 260px;
    margin: 0;
  }
`
const Right = styled.a`
  &&& {
    width: 12px;
    height: auto;
    right: 0 !important;
    top: 10px !important;
    position: relative;
    float: right !important;
    margin-right: 10px;
  }
`
const Left = styled.a`
  &&& {
    width: 12px;
    height: auto;
    right: 0 !important;
    top: 10px !important;
    position: relative;
    float: right !important;
  }
`

const PeopleCarousel = ({ personIds, onClose }) => {
  const [loadedPeople, setLoadedPeople] = useState([])
  useEffect(() => { loadPeople() }, [])
  useEffect(() => {
    setTimeout(() => {
      $('#deal-content').hide().remove()
      if (personIds.length < 2) return
      $('.carousel').carousel({ interval: false })
      $('.carousel .item:first').addClass('active')
    }, 10)
  }, [personIds])

  const loadPeople = () => {
    const loaded = []
    personIds.forEach((personId) => {
      loaded.push(<BusinessCard type={PERSON_TYPE} itemId={personId} onClose={onClose} fromCarousel />)
    })
    setLoadedPeople(loaded)
  }
  const renderPeople = () => (
    loadedPeople.map((person, index) => {
      return (
        <div className="item" key={index}>
          {person}
        </div>
      )
    })
  )

  if (personIds.length === 1) {
    return (
      <BusinessCard
        type={PERSON_TYPE}
        itemId={personIds[0]}
        onClose={onClose}
      />
    )
  } else {
    return (
      <div
        id="people_carousel"
        className="carousel slide"
      >
        <Navigation className="navigation">
          <Left
            className="carousel-control right js_carousel-control-right"
            data-slide="next"
            onClick={() => $('.carousel').carousel('next')}
          >
            <i className="fas fa-chevron-right" />
          </Left>
          <Right
            className="carousel-control left js_carousel-control-left"
            data-slide="prev"
            onClick={() => $('.carousel').carousel('prev')}
          >
            <i className="fas fa-chevron-left" />
          </Right>
          <Indicators className="carousel-indicators">
            {personIds.map((id, index) => {
              return (
                <li key={id} data-target="#people_carousel" data-id={id} data-slide-to={index} className={index === 0 ? 'active' : undefined} />
              )
            })}
          </Indicators>
        </Navigation>

        <div
          className="carousel-inner"
          style={{ overflow: 'hidden' }}
        >
          {renderPeople()}
        </div>
      </div>
    )
  }
}

PeopleCarousel.propTypes = {
  personIds: PropTypes.array.isRequired,
  onClose: PropTypes.func
}

export default PeopleCarousel
