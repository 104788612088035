import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import moment from 'moment'

import {
  H1,
  H4,
  P,
  colors
} from '@PipelineDeals/shared-react-components'

import { formatUserDate } from 'lib/helpers'

const Email = styled.span`
  color: ${colors.primary.blue}
`

const ThankYou = ({ emailAddress, demoDateAndTime, dateFormat }) => {
  const formatTime = moment(demoDateAndTime).format('h:mm A')

  return (
    <>
      <H1>Thank you</H1>
      <div style={{ padding: '30px 0' }}>
        <H4>You have booked your demo</H4>
        <H4>on {formatUserDate(demoDateAndTime, dateFormat)} at {formatTime}.</H4>
      </div>
      <P>We will send a meeting invitation with a zoom link to <Email>{emailAddress}</Email> shortly.</P>
      <br />
      <P>If you have any questions please email <a href="mailto:customercare@pipelinecrm.com">customercare@pipelinecrm.com</a> for more information.</P>
    </>
  )
}

ThankYou.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  demoDateAndTime: PropTypes.instanceOf(Date).isRequired
}

export default ThankYou
