import { SYNC, CREATE, UPDATE, INBOX_READ } from 'permissions/actions'

export const EMAIL = '@@permissions/email/policy'

export default (user, action) => {
  switch (action) {
    case SYNC:
      return !user.read_only
    case CREATE:
      return !user.read_only
    case UPDATE:
      return !user.read_only
    case INBOX_READ:
      return !user.read_only
    default:
      return false
  }
}
