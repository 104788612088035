import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  dealStagesFetchFailure,
  dealStagesFetchSuccess,
  dealStageCreate,
  dealStageCreateFailure,
  dealStageUpdate,
  dealStageUpdateFailure,
  dealStageDelete,
  dealStageDeleteFailure
} from '../actions'

import {
  DEAL_STAGES_FETCH_REQUEST,
  DEAL_STAGE_CREATE_SUBMIT,
  DEAL_STAGE_UPDATE_SUBMIT,
  DEAL_STAGE_DELETE,
  DEAL_STAGES_REORDER_SUBMIT,
  DEAL_PIPELINES_FETCH_SUCCESS,
  DEAL_PIPELINE_CREATE
} from '../constants'

export function * dealStagesFetchSaga () {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_stages'
    )
    yield put(dealStagesFetchSuccess(response.entries))
  } catch (exception) {
    yield put(dealStagesFetchFailure({ error: exception.message }))
  }
}

export function * dealStageCreateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_stages',
      {
        method: 'POST',
        body: JSON.stringify({
          deal_stage: {
            name: payload.name,
            percent: payload.percentage,
            deal_pipeline_id: payload.selected
          }
        })
      }
    )
    yield put(dealStageCreate(response))
  } catch (exception) {
    put(dealStageCreateFailure({ error: exception.message }))
  }
}

export function * dealStageUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/deal_stages/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal_stage: {
            id: payload.id,
            name: payload.name,
            percent: payload.percentage
          }
        })
      }
    )
    yield put(dealStageUpdate(response))
  } catch (exception) {
    yield put(dealStageUpdateFailure({ error: exception.message }))
  }
}

export function * dealStageDeleteSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/deal_stages/${payload.id}`,
      {
        method: 'DELETE'
      }
    )
    yield put(dealStageDelete(response))
    yield call(dealStagesFetchSaga)
  } catch (exception) {
    yield put(dealStageDeleteFailure({ error: exception.message }))
  }
}

export function * dealStagesReorderSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      '/api/v3/admin/deal_stages/sort',
      {
        method: 'PUT',
        body: JSON.stringify(
          ({ order: payload.orderedDealStages.map(el => el.id), deal_pipeline_id: payload.dealPipelineId })
        )
      }
    )
    yield call(dealStagesFetchSaga)
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchDealStagesFetchSaga () {
  yield call(takeEvery, DEAL_STAGES_FETCH_REQUEST, dealStagesFetchSaga)
}

export function * watchDealPipelinesFetchUpdateStagesSaga () {
  yield call(takeEvery, DEAL_PIPELINES_FETCH_SUCCESS, dealStagesFetchSaga)
}

export function * watchDealPipelinesCreateUpdateStagesSaga () {
  yield call(takeEvery, DEAL_PIPELINE_CREATE, dealStagesFetchSaga)
}

export function * watchDealStageCreateSaga () {
  yield call(takeEvery, DEAL_STAGE_CREATE_SUBMIT, dealStageCreateSaga)
}

export function * watchDealStageUpdateSaga () {
  yield call(takeEvery, DEAL_STAGE_UPDATE_SUBMIT, dealStageUpdateSaga)
}

export function * watchDealStageDeleteSaga () {
  yield call(takeEvery, DEAL_STAGE_DELETE, dealStageDeleteSaga)
}

export function * watchDealStagesReorderSaga () {
  yield call(takeEvery, DEAL_STAGES_REORDER_SUBMIT, dealStagesReorderSaga)
}
