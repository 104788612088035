export default [
  'addActivityFromListView',
  'addColumns',
  'applyFilter',
  'chooseColumnColor',
  'clickAddCustomFieldFromAddRemoveColumns',
  'clickAddNewButton',
  'clickAddRemoveColumns',
  'clickBulkAction',
  'clickDuplicateList',
  'clickImportButton',
  'clickManageSharing',
  'clickManageSharingFocusedList',
  'columnSizeChange',
  'compareDealsToPast',
  'completeBulkAction',
  'createFocusedList',
  'createNewListButton',
  'deleteSampleData',
  'editValueFromCell',
  'expandRows',
  'exportAsCsv',
  'exportAsPdf',
  'freezeColumn',
  'manageImports',
  'markTaskAsCompleteFromList',
  'moveColumn',
  'performSearch',
  'postponeTaskFromList',
  'removeColumn',
  'resetList',
  'rowSizeChange',
  'saveAsNewList',
  'saveChangesToList',
  'searchFieldsColumnManager',
  'selectAllColumns',
  'selectRowFormatting',
  'setAsDefault',
  'setRecordsPerPage',
  'shareList',
  'sortList',
  'starListView',
  'viewActivitiesFromListView',
  'viewContactCardFromListView',
  'viewListInFullscreenMode',
  'viewListOptions',
  'viewNextPage',
  'viewSavedList'
]
