/* global CustomEvent */

import styled from 'styled-components'
import { SecondaryButton } from '@PipelineDeals/shared-react-components'

export const handleSubmitSuccess = (background, title, msg, backgroundMsg) => {
  if (!background) {
    const ev = new CustomEvent('list_view:refresh')
    window.dispatchEvent(ev)

    window.Helpers.notify(title, msg)
  } else {
    window.Helpers.notify(title, backgroundMsg)
  }
}

export const handleSubmitFailure = (title, msg) => {
  window.Helpers.notify(title, msg)
}

export const BodyWrapper = styled.div`
  padding: 0 15px;
  flex: 1;
`

export const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const RowWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`

export const SecondaryButtonWrapper = styled(SecondaryButton)`
  margin-right: 15px;
`
