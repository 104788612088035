export const PERSON_PROFILE_FETCH = 'PERSON_PROFILE_FETCH'
export const PERSON_FETCH = 'PERSON_FETCH'
export const PERSON_FETCH_SUCCESS = 'PERSON_FETCH_SUCCESS'
export const PERSON_FETCH_ERROR = 'PERSON_FETCH_ERROR'
export const PERSON_FETCH_VISIBLE_USERS = 'PERSON_FETCH_VISIBLE_USERS'
export const PERSON_LOAD_VISIBLE_USERS = 'PERSON_LOAD_VISIBLE_USERS'

export const PEOPLE_UPDATE_ACTIVITY_FILTERS = 'PEOPLE_UPDATE_ACTIVITY_FILTERS'
export const PERSON_RELOAD_TODOS = 'PERSON_RELOAD_TODOS'
export const PERSON_LOAD_TODOS = 'PERSON_LOAD_TODOS'
export const PERSON_UPDATE_TODO = 'PERSON_UPDATE_TODO'
export const PERSON_DELETE_TODO = 'PERSON_DELETE_TODO'
export const PERSON_UPDATE_TODO_FILTERS = 'PERSON_UPDATE_TODO_FILTERS'
export const PERSON_DEAL_NEW_SUCCESS = 'PERSON_DEAL_NEW_SUCCESS'
export const PERSON_DEAL_ACTIVE_FILTER = 'PERSON_DEAL_ACTIVE_FILTER'
export const PEOPLE_UPDATE_DEAL = 'PEOPLE_UPDATE_DEAL'
export const PEOPLE_DELETE_DEAL = 'PEOPLE_DELETE_DEAL'
export const PEOPLE_CREATE_DEAL = 'PEOPLE_CREATE_DEAL'
export const PEOPLE_LOAD_DEALS = 'PEOPLE_LOAD_DEALS'
export const PEOPLE_SORT_DEALS = 'PEOPLE_SORT_DEALS'
export const PEOPLE_FILTER_DEALS = 'PEOPLE_FILTER_DEALS'
export const PEOPLE_FETCH_DEALS = 'PEOPLE_FETCH_DEALS'
export const PERSON_LOAD_FILES = 'PERSON_LOAD_FILES'
export const PERSON_CREATE_FILE = 'PERSON_CREATE_FILE'
export const PERSON_DELETE_FILE = 'PERSON_DELETE_FILE'
export const PERSON_FILTER_FILES = 'PERSON_FILTER_FILES'
export const PERSON_UPDATE_FILE = 'PERSON_UPDATE_FILE'
export const PERSON_SORT_FILES = 'PERSON_SORT_FILES'
export const PEOPLE_FETCH_MAILCHIMP = 'PEOPLE_FETCH_MAILCHIMP'
export const PEOPLE_FETCH_SUCCESS_MAILCHIMP = 'PEOPLE_FETCH_SUCCESS_MAILCHIMP'
export const PEOPLE_SORT_MAILCHIMP = 'PEOPLE_SORT_MAILCHIMP'
export const PEOPLE_FILTER_MAILCHIMP = 'PEOPLE_FILTER_MAILCHIMP'
export const PERSON_PROFILE_RESET = 'PERSON_PROFILE_RESET'
export const PERSON_FETCH_EMAIL_SEQUENCES = 'PERSON_FETCH_EMAIL_SEQUENCES'
export const PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES = 'PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES'

export const PERSON_LOAD_VISIBLE_ACTIVE_USERS_FOR_PERSON_OWNER = 'PERSON_LOAD_VISIBLE_ACTIVE_USERS_FOR_PERSON_OWNER'
export const PERSON_FILTER_EMAIL_SEQUENCES = 'PERSON_FILTER_EMAIL_SEQUENCES'
export const PERSON_SORT_EMAIL_SEQUENCES = 'PERSON_SORT_EMAIL_SEQUENCES'
export const PERSON_UPDATE = 'PERSON_UPDATE'
export const PERSON_RELOAD = 'PERSON_RELOAD'
export const PERSON_CONDITIONAL_FIELDS_VALIDATION = 'PERSON_CONDITIONAL_FIELDS_VALIDATION'
export const PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE = 'PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE'
