/* eslint-disable
    camelcase,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

const formattedCurrency = function (value, symbol, decimal_places, group_digits) {
  if (Helpers.unparsableCurrencyValue(value)) { return '' }

  const regex = new RegExp(`-?\\d+\\.?\\d{0,${decimal_places}}`, 'g')

  const negative_format = '-%s%n'

  const val = jQuery(`<span>${value}</span>`).formatCurrency({
    symbol,
    colorize: false,
    roundToDecimalPlace: decimal_places,
    groupDigits: group_digits,
    negativeFormat: negative_format
  }).text()
  if (val === 'null') { return null } else { return val }
}

Helpers.formatCurrency = (value, currencySymbol) => formattedCurrency(value, currencySymbol || ppd.currencySymbol || '$', ppd.currencyDecimalPlaces, true)

Helpers.formatCurrencyForForm = value => formattedCurrency(value, '', ppd.currencyDecimalPlaces, false)

Helpers.unparsableCurrencyValue = value => _(value).isUndefined() || (value === '') || (value === null)

Helpers.formatCurrencyCustomField = function (value, currencySymbol) {
  let precision
  if (!isNaN(parseFloat(value))) {
    value = Helpers.normalizeCurrencyValue(value)
    const decimals = value.split('.')[1]
    precision = decimals ? decimals.length : ppd.currencyDecimalPlaces
  } else {
    precision = ppd.currencyDecimalPlaces
  }
  return formattedCurrency(value, currencySymbol || ppd.currencySymbol, precision, true)
}

Helpers.normalizeCurrencyValue = value => parseFloat(value).toFixed(8).replace(/0{0,6}$/, '')

Helpers.formatCurrencyCustomFieldForForm = function (value) {
  if (Helpers.unparsableCurrencyValue(value)) { return '' }
  return Helpers.normalizeCurrencyValue(value)
}

Helpers.formatNumber = value => formattedCurrency(value, '', 0, true)

Handlebars.registerHelper('formatCurrency', function (value, currencySymbol) {
  if (!currencySymbol || (typeof currencySymbol !== 'string')) {
    currencySymbol = null
  }
  return new Handlebars.SafeString(Helpers.formatCurrency(value, currencySymbol))
})

Handlebars.registerHelper('formatCurrencyForForm', value => new Handlebars.SafeString(Helpers.formatCurrencyForForm(value)))

Handlebars.registerHelper('formatCurrencyCustomField', function (value, currencySymbol) {
  if (!currencySymbol || (typeof currencySymbol !== 'string')) {
    currencySymbol = null
  }
  return new Handlebars.SafeString(Helpers.formatCurrencyCustomField(value, currencySymbol))
})

Handlebars.registerHelper('formatCurrencyCustomFieldForForm', value => new Handlebars.SafeString(Helpers.formatCurrencyCustomFieldForForm(value)))

module.exports = Helpers
