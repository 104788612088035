import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  productLineCreate,
  productLineCreateFailure,
  productLineUpdate,
  productLineUpdateFailure,
  productLineDelete,
  productLineDeleteFailure
} from '../actions'

import {
  PRODUCT_LINE_CREATE_SUBMIT,
  PRODUCT_LINE_UPDATE_SUBMIT,
  PRODUCT_LINE_DELETE_SUBMIT
} from '../constants'

export function * productLineNewFormSubmitSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/product_lines',
      {
        method: 'POST',
        body: JSON.stringify({ product_line: payload })
      }
    )

    yield put(productLineCreate(response))
  } catch (exception) {
    yield put(productLineCreateFailure({ _error: exception.message }))
  }
}

export function * productLineUpdateFormSubmitSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/product_lines/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ product_line: payload })
      }
    )

    yield put(productLineUpdate(response))
  } catch (exception) {
    yield put(productLineUpdateFailure({ _error: exception.message }))
  }
}

export function * productLineDeleteSubmitSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/product_lines/${payload.id}`,
      {
        method: 'DELETE'
      }
    )

    yield put(productLineDelete(payload))
  } catch (exception) {
    yield put(productLineDeleteFailure({ error: exception.message }))
  }
}

export function * watchProductLineNewFormSubmitSaga () {
  yield call(takeEvery, PRODUCT_LINE_CREATE_SUBMIT, productLineNewFormSubmitSaga)
}

export function * watchProductLineUpdateFormSubmitSaga () {
  yield call(takeEvery, PRODUCT_LINE_UPDATE_SUBMIT, productLineUpdateFormSubmitSaga)
}

export function * watchProductLineDeleteSaga () {
  yield call(takeEvery, PRODUCT_LINE_DELETE_SUBMIT, productLineDeleteSubmitSaga)
}
