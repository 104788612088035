import {
  PEOPLE_CREATE_DEAL,
  PEOPLE_DELETE_DEAL,
  PEOPLE_FETCH_DEALS,
  PEOPLE_FETCH_MAILCHIMP,
  PEOPLE_FETCH_SUCCESS_MAILCHIMP,
  PEOPLE_FILTER_DEALS,
  PEOPLE_FILTER_MAILCHIMP,
  PEOPLE_LOAD_DEALS,
  PEOPLE_SORT_DEALS,
  PEOPLE_SORT_MAILCHIMP,
  PEOPLE_UPDATE_ACTIVITY_FILTERS,
  PEOPLE_UPDATE_DEAL,
  PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  PERSON_CONDITIONAL_FIELDS_VALIDATION,
  PERSON_CREATE_FILE,
  PERSON_DEAL_NEW_SUCCESS,
  PERSON_DELETE_FILE,
  PERSON_DELETE_TODO,
  PERSON_FETCH_EMAIL_SEQUENCES,
  PERSON_FETCH_ERROR,
  PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES,
  PERSON_FETCH_SUCCESS,
  PERSON_FETCH,
  PERSON_FILTER_EMAIL_SEQUENCES,
  PERSON_FILTER_FILES,
  PERSON_LOAD_FILES,
  PERSON_LOAD_TODOS,
  PERSON_LOAD_VISIBLE_ACTIVE_USERS_FOR_PERSON_OWNER,
  PERSON_LOAD_VISIBLE_USERS,
  PERSON_PROFILE_FETCH,
  PERSON_PROFILE_RESET,
  PERSON_RELOAD_TODOS,
  PERSON_SORT_EMAIL_SEQUENCES,
  PERSON_SORT_FILES,
  PERSON_UPDATE,
  PERSON_UPDATE_FILE,
  PERSON_UPDATE_TODO_FILTERS,
  PERSON_UPDATE_TODO
} from 'People/constants'

export const personProfileFetch = payload => ({ payload, type: PERSON_PROFILE_FETCH })
export const personFetch = payload => ({ payload, type: PERSON_FETCH })
export const personFetchSuccess = payload => ({ payload, type: PERSON_FETCH_SUCCESS })
export const personFetchError = payload => ({ payload, type: PERSON_FETCH_ERROR })

export const personProfileReset = payload => ({ payload, type: PERSON_PROFILE_RESET })

export const loadVisibleUsers = payload => ({ payload, type: PERSON_LOAD_VISIBLE_USERS })
export const loadVisibleActiveUsersForPersonOwner = payload => ({ payload, type: PERSON_LOAD_VISIBLE_ACTIVE_USERS_FOR_PERSON_OWNER })

export const personFetchMailchimp = payload => ({ payload, type: PEOPLE_FETCH_MAILCHIMP })
export const personFetchSuccessMailchimp = payload => ({ payload, type: PEOPLE_FETCH_SUCCESS_MAILCHIMP })

export const personFetchEmailSequences = payload => ({ payload, type: PERSON_FETCH_EMAIL_SEQUENCES })
export const personFetchSuccessEmailSequences = payload => ({ payload, type: PERSON_FETCH_SUCCESS_EMAIL_SEQUENCES })

export const updateActivityFilters = payload => ({ payload, type: PEOPLE_UPDATE_ACTIVITY_FILTERS })

export const reloadTodos = payload => ({ payload, type: PERSON_RELOAD_TODOS })

export const personDealNewSuccess = payload => ({ payload, type: PERSON_DEAL_NEW_SUCCESS })

export const conditionalFieldsValidation = payload => ({ payload, type: PERSON_CONDITIONAL_FIELDS_VALIDATION })
export const updateConditionalFieldsValidation = payload => ({ payload, type: PERSON_CONDITIONAL_FIELDS_VALIDATION_UPDATE })

// TODO
export const loadTodos = payload => ({ payload, type: PERSON_LOAD_TODOS })
export const updateTodo = payload => ({ payload, type: PERSON_UPDATE_TODO })
export const deleteTodo = payload => ({ payload, type: PERSON_DELETE_TODO })
export const updateTodoFilters = payload => ({ payload, type: PERSON_UPDATE_TODO_FILTERS })

// FILES
export const loadFiles = payload => ({ payload, type: PERSON_LOAD_FILES })
export const createFile = payload => ({ payload, type: PERSON_CREATE_FILE })
export const deleteFile = payload => ({ payload, type: PERSON_DELETE_FILE })
export const filterFiles = payload => ({ payload, type: PERSON_FILTER_FILES })
export const updateFile = payload => ({ payload, type: PERSON_UPDATE_FILE })
export const sortFiles = payload => ({ payload, type: PERSON_SORT_FILES })

// DEALS
export const loadDeals = payload => ({ payload, type: PEOPLE_LOAD_DEALS })
export const fetchDeals = payload => ({ payload, type: PEOPLE_FETCH_DEALS })
export const createDeal = payload => ({ payload, type: PEOPLE_CREATE_DEAL })
export const updateDeal = payload => ({ payload, type: PEOPLE_UPDATE_DEAL })
export const deleteDeal = payload => ({ payload, type: PEOPLE_DELETE_DEAL })
export const sortDeals = payload => ({ payload, type: PEOPLE_SORT_DEALS })
export const filterDeals = payload => ({ payload, type: PEOPLE_FILTER_DEALS })

// PERSON
export const personUpdate = (payload, { successCallback, failureCallback, finallyCallback } = {}) => (
  { payload, type: PERSON_UPDATE, successCallback, failureCallback, finallyCallback }
)

// MAILCHIMP
export const sortMailchimpCampaignMembers = payload => ({ payload, type: PEOPLE_SORT_MAILCHIMP })
export const filterMailchimpCampaignMembers = payload => ({ payload, type: PEOPLE_FILTER_MAILCHIMP })

// EMAIL SEQUENCE
export const sortEmailSequences = payload => ({ payload, type: PERSON_SORT_EMAIL_SEQUENCES })
export const filterEmailSequences = payload => ({ payload, type: PERSON_FILTER_EMAIL_SEQUENCES })
