
export const formatNumeric = (value) => {
  const numericValue = parseFloat(value)

  if (isNaN(numericValue)) {
    return ''
  }

  return numericValue.toLocaleString()
}
export const formatWithCommas = (value) => value !== null ? value.toLocaleString() : ''
