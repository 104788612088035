export default {
  banner: [
    'clickBuyNow',
    'clickCaret'
  ],
  dropdown: [
    'clickBuyNow',
    'clickPlanDetails',
    'selectPlan'
  ]
}
