import { createSelector } from 'reselect'
import {
  getCustomFieldGroupsSelector,
  getCustomFieldTypeSelector
} from './Selectors'

export const getCustomFieldGroups = createSelector(
  getCustomFieldGroupsSelector,
  getCustomFieldTypeSelector,
  (groups, type) => groups.filter(group => group.type.toLowerCase() === `${type}customfieldgroup`)
)
