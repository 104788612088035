/**
 * The SyncMessageUtil is responsible for namespacing miscellaneous
 * functionality that deals with the SyncMessage @type.
 */
export const SyncMessageUtil = {
  /**
   * createPersonFromSyncMessageContact is a method that returns a
   * BackboneModelPerson given a SyncMessageContactFrom.
   *
   * @param {SyncMessageContactFrom} contact
   * @returns {BackboneModelPerson}
   */
  createPersonFromSyncMessageContact: (contact) => {
    /**
     * @type {BackboneModelAttributesPerson}
     */
    const attributes = {
      id: contact.id,
      lead_source_id: contact.lead_source_id,
      company_name: contact.company_name,
      company_id: contact.company_id
    }

    return new App.Models.Person(attributes)
  }
}
