/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.personAvatarUrl = function (person) {
  // check if it's a backbone object
  if (person.name === 'person') {
    return Helpers.generateAvatarUrl(person.id, person.get('updated_at'))
  }

  if (person.updated_at) {
    return Helpers.generateAvatarUrl(person.id, person.updated_at)
  }

  return ''
}

Helpers.generateAvatarUrl = (id, time) => `${ppd.pipelineAvatarsUrl}/people/${id}?` + (new Date(time).getTime() / 1000)

Handlebars.registerHelper('personAvatarUrl', personId => new Handlebars.SafeString(Helpers.personAvatarUrl(personId)))
