import React from 'react'
import PropTypes from 'prop-types'

import ReactHighlightWords from 'react-highlight-words'

const Highlighter = ({ term, children }) => {
  if (!term || term === '') return children

  const searchWords = term.split(' ')
  return (
    <ReactHighlightWords
      highlightClassName="SearchResultHighlight"
      searchWords={searchWords}
      autoEscape
      textToHighlight={children}
    />
  )
}

Highlighter.propTypes = {
  children: PropTypes.string.isRequired,
  term: PropTypes.string
}

export { Highlighter as default }
