// These components relate specifically to dropdown menus

import styled from 'styled-components'
import { colors, P } from '@PipelineDeals/shared-react-components'

export const ModernDropdownListHeaderLink = styled(P)`
  color: ${colors.text.secondary};
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: ${colors.text.primary};
  }

  .collapse-icon {
    margin-right: 3px;
    font-size: 80%;
    width: 10px;
  }
`

export const ModernDropdownListItem = styled.li`
  cursor: pointer;
  color: ${colors.text.secondary};
  padding: 0;

  &:hover {
    background-color: ${colors.structure.secondary} !important;
  }

  a {
    white-space: nowrap !important;
  }

  a, p {
    color: ${colors.text.secondary} !important;
    display: block;
    padding: 5px 15px !important;
  }

  a:hover, p:hover {
    background-color: ${colors.structure.secondary} !important;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${colors.text.primary};
    text-decoration: none;
  }

  i {
    color: ${colors.text.secondary}
  }
`

export const ModernDropdownListSubItem = styled(ModernDropdownListItem)`
a, p {
  padding-left: 30px !important;
}
`

export const ModernDropdownListSeparator = styled.li`
  border-bottom: 1px solid ${colors.structure.quaternary};
  margin: 7px 15px;
  height: 1px;
`

export const ModernDropdownListHeader = styled(ModernDropdownListItem)`
  border-bottom: 1px solid ${colors.structure.quaternary};
  margin: 0px 15px 5px;
  padding: 0 !important;

  a, p {
    padding: 5px 0px !important;
  }

  &:hover {
    background-color: transparent !important;
    cursor: default;
  }

  a:hover, p:hover {
    background-color: transparent !important;
  }
`

export const DropdownListItem = styled.li`
  cursor: pointer;
  position: relative;

  &> :first-child {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .none {
    color: #949494;
    font-style: italic;
  }
    
  &.disabled {
    cursor: default;  
  }

  a.selected {
    font-weight: bold;
  }
`

export const DropdownListHeader = styled(DropdownListItem)`
  color: #808080;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;

  &:hover {
    background-color: transparent !important;
    cursor: default;
  }
`
export const DropdownListHeaderLink = styled.button`
  color: #808080;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  border: none;
  background-color: transparent;

  &:hover {
    color: #333;
  }

  .collapse-icon {
    margin-right: 3px;
    font-size: 80%;
  }
`

export const SearchForm = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  position: relative;

  .input-icon {
    position: absolute;
    top: 20px;
    left: 18px;
    color: #949494;
    font-size: 90%;
  }

  input[type='text'] {
    padding-left: 24px !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  // must accomodate clear (X) button if present

  input.hasremoveoption {
    padding-right: 22px;
  }
`

export const EmptyStateMessage = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  color: #949494;
  white-space: pre-wrap;
`

export const OptGroup = styled.div`
  .opt-group {
    padding-bottom: 12px;
    padding-left: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #808080;
    font-size: 13px;
  }

  .dropdown-list-item {
    padding-left: 20px;
  }
`
