import {
  EXTERNAL_USER_SEARCHES_FETCH_SUCCESS
} from './constants'

export default (state = {}, action) => {
  switch (action.type) {
    case EXTERNAL_USER_SEARCHES_FETCH_SUCCESS:
      return {
        ...state,
        forExternalUsers: action.payload
      }
    default:
      return state
  }
}
