import {
  COMPANIES_CREATE_DEAL,
  COMPANIES_CREATE_FILE,
  COMPANIES_CREATE_PERSON,
  COMPANIES_DELETE_DEAL,
  COMPANIES_DELETE_FILE,
  COMPANIES_DELETE_PERSON,
  COMPANIES_DELETE_TODO,
  COMPANIES_FETCH_PEOPLE,
  COMPANIES_FILTER_DEALS,
  COMPANIES_FILTER_FILES,
  COMPANIES_FILTER_PEOPLE,
  COMPANIES_LOAD_DEALS,
  COMPANIES_LOAD_FILES,
  COMPANIES_LOAD_PEOPLE,
  COMPANIES_LOAD_TODOS,
  COMPANIES_LOAD_VISIBLE_USERS,
  COMPANIES_SORT_DEALS,
  COMPANIES_SORT_FILES,
  COMPANIES_SORT_PEOPLE,
  COMPANIES_UPDATE_ACTIVITY_FILTERS,
  COMPANIES_UPDATE_DEAL,
  COMPANIES_UPDATE_FILE,
  COMPANIES_UPDATE_PERSON,
  COMPANIES_UPDATE_TODO_FILTERS,
  COMPANIES_UPDATE_TODO,
  COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  COMPANY_CONDITIONAL_FIELDS_VALIDATION,
  COMPANY_DEAL_NEW_SUCCESS,
  COMPANY_FETCH_ERROR,
  COMPANY_FETCH_SUCCESS,
  COMPANY_PERSON_NEW_SUCCESS,
  COMPANY_PROFILE_FETCH,
  COMPANY_PROFILE_RESET,
  COMPANY_RELOAD_TODOS,
  COMPANY_UPDATE,
  COMPANY_FETCH_CHILDREN_COMPANIES,
  COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES,
  COMPANY_FILTER_CHILDREN_COMPANIES,
  COMPANY_SORT_CHILDREN_COMPANIES
} from 'Companies/constants'

// COMPANY
export const companyProfileFetch = payload => ({ payload, type: COMPANY_PROFILE_FETCH })
export const companyFetchSuccess = payload => ({ payload, type: COMPANY_FETCH_SUCCESS })
export const companyFetchError = payload => ({ payload, type: COMPANY_FETCH_ERROR })
export const companyUpdate = (payload, { successCallback, failureCallback, finallyCallback } = {}) => (
  { payload, type: COMPANY_UPDATE, successCallback, failureCallback, finallyCallback })

export const companyProfileReset = () => ({ type: COMPANY_PROFILE_RESET })
export const conditionalFieldsValidation = payload => ({ payload, type: COMPANY_CONDITIONAL_FIELDS_VALIDATION })
export const updateConditionalFieldsValidation = payload => ({ payload, type: COMPANY_CONDITIONAL_FIELDS_VALIDATION_UPDATE })

// PEOPLE
export const loadPeople = payload => ({ payload, type: COMPANIES_LOAD_PEOPLE })
export const createPerson = payload => ({ payload, type: COMPANIES_CREATE_PERSON })
export const updatePerson = payload => ({ payload, type: COMPANIES_UPDATE_PERSON })
export const deletePerson = payload => ({ payload, type: COMPANIES_DELETE_PERSON })
export const sortPeople = payload => ({ payload, type: COMPANIES_SORT_PEOPLE })
export const filterPeople = payload => ({ payload, type: COMPANIES_FILTER_PEOPLE })
export const companyPersonNewSuccess = payload => ({ payload, type: COMPANY_PERSON_NEW_SUCCESS })
export const companiesFetchPeople = payload => ({ payload, type: COMPANIES_FETCH_PEOPLE })

// DEALS
export const loadDeals = payload => ({ payload, type: COMPANIES_LOAD_DEALS })
export const createDeal = payload => ({ payload, type: COMPANIES_CREATE_DEAL })
export const updateDeal = payload => ({ payload, type: COMPANIES_UPDATE_DEAL })
export const deleteDeal = payload => ({ payload, type: COMPANIES_DELETE_DEAL })
export const sortDeals = payload => ({ payload, type: COMPANIES_SORT_DEALS })
export const filterDeals = payload => ({ payload, type: COMPANIES_FILTER_DEALS })
export const companyDealNewSuccess = payload => ({ payload, type: COMPANY_DEAL_NEW_SUCCESS })

// AGENDA
export const loadTodos = payload => ({ payload, type: COMPANIES_LOAD_TODOS })
export const updateTodo = payload => ({ payload, type: COMPANIES_UPDATE_TODO })
export const deleteTodo = payload => ({ payload, type: COMPANIES_DELETE_TODO })
export const updateTodoFilters = payload => ({ payload, type: COMPANIES_UPDATE_TODO_FILTERS })
export const reloadTodos = payload => ({ payload, type: COMPANY_RELOAD_TODOS })

// FILES
export const loadFiles = payload => ({ payload, type: COMPANIES_LOAD_FILES })
export const createFile = payload => ({ payload, type: COMPANIES_CREATE_FILE })
export const deleteFile = payload => ({ payload, type: COMPANIES_DELETE_FILE })
export const filterFiles = payload => ({ payload, type: COMPANIES_FILTER_FILES })
export const updateFile = payload => ({ payload, type: COMPANIES_UPDATE_FILE })
export const sortFiles = payload => ({ payload, type: COMPANIES_SORT_FILES })

// ACTIVITIES
export const updateActivityFilters = payload => ({ payload, type: COMPANIES_UPDATE_ACTIVITY_FILTERS })

// VISIBLE USERS
export const loadVisibleUsers = payload => ({ payload, type: COMPANIES_LOAD_VISIBLE_USERS })

// CHILDREN COMPANIES
export const companyFetchChildrenCompanies = payload => ({ payload, type: COMPANY_FETCH_CHILDREN_COMPANIES })
export const companyFetchSuccessChildrenCompanies = payload => ({ payload, type: COMPANY_FETCH_SUCCESS_CHILDREN_COMPANIES })
export const sortChildrenCompanies = payload => ({ payload, type: COMPANY_SORT_CHILDREN_COMPANIES })
export const filterChildrenCompanies = payload => ({ payload, type: COMPANY_FILTER_CHILDREN_COMPANIES })
