import { QUOTA_VERIFY, QUOTAS_FETCH_SUCCESS } from './constants'

const initialState = {
  quotaContent: null,
  quotas: {},
  quotasFetchedAt: null,
  quotaVerify: null,
  quotaVerifyTimestamp: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTAS_FETCH_SUCCESS: {
      return { ...state, quotas: { ...state.quotas, ...action.payload }, quotasFetchedAt: Date.now() }
    }
    case QUOTA_VERIFY: {
      return { ...state, quotaVerify: action.payload.quotaType, quotaContent: action.payload.content, quotaVerifyTimestamp: Date.now() }
    }
    default:
      return state
  }
}
