import { useContext } from 'react'
import camelize from 'camelize'

import FormContext from 'add_edit_modals/formContext'

export const resourceTypes = ['company', 'deal', 'person', 'calendar_task', 'calendar_event']
export const customizableTypes = ['company', 'deal', 'person']

export const isDefaultOpen = (id) => {
  const { resourceType } = useContext(FormContext)

  if (!window.User.hasOwnProperty(resourceType + 'JsGroupState')) return false
  return window.User[resourceType + 'JsGroupState'][id] === 'opened'
}

export const capitalize = (word) => word[0].toUpperCase() + word.substr(1)
export const classify = (word) => capitalize(camelize(word))

const addCurrentOwner = (possibleOwners, currentOwnerId) => {
  const currentOwner = window.Account.allUsers.models.find(u => u.attributes.id === currentOwnerId)
  if (currentOwner !== undefined && !possibleOwners.find(o => o.attributes.id === currentOwner.attributes.id)) possibleOwners = possibleOwners.concat(currentOwner)
  return possibleOwners
}

const mapOwners = (possibleOwners) => {
  return possibleOwners.map(u => ({ label: u.attributes.name, value: u.attributes.id }))
}

export const possibleOwnersFor = (resourceType, fieldValue) => {
  let possibleOwners = {
    person: window.Account.visibleActiveUsersForPersonOwner.models,
    deal: window.Account.visibleActiveUsersForDealOwner.models,
    company: window.Account.visibleActiveUsersForCompanyOwner.models
  }[resourceType]
  if (!possibleOwners) possibleOwners = window.Account.visibleActiveUsersWithMe.models
  return mapOwners(addCurrentOwner(possibleOwners, fieldValue))
}

export const possibleTaskEventOwners = (entity, fieldValue) => {
  const possibleOwners = window.Account.visibleUsersForEntity(entity)
  return mapOwners(addCurrentOwner(possibleOwners, fieldValue))
}

export const possibleCurrencies = (selectedCurrencyId) => {
  let possibleCurrencies = [...window.Account.selectedCurrencies]
  possibleCurrencies = possibleCurrencies.sort((a, b) => a.position - b.position).map(c => ({ label: `${c.code} ${c.symbol} - ${c.name}`, value: c.id }))

  if (selectedCurrencyId && !possibleCurrencies.map(c => c.value).includes(selectedCurrencyId)) {
    possibleCurrencies.unshift({ label: selectedCurrencyId, value: selectedCurrencyId })
  }

  return possibleCurrencies
}

export const toBool = (val) => [true, 'true', 1, '1'].includes(val)

export const resourceToUrlMap = {
  person: '/people',
  deal: '/deals',
  company: '/companies',
  customer: '/companies',
  calendar_event: '/calendar_events',
  calendar_task: '/calendar_tasks'
}

export const humanizeResourceType = (resourceType) => {
  switch (resourceType) {
    case 'calendar_task':
      return 'Calendar Task'
    case 'calendar_event':
      return 'Calendar Event'
    default:
      return capitalize(resourceType)
  }
}

export const valuesToReject = (resourceType) => {
  switch (resourceType) {
    case 'deal':
      return [
        'is_allowed_to_delete',
        'can_be_reassigned_by_current_user',
        'sharees',
        'stage_name',
        'company_path',
        'next_entry',
        'primary_contact',
        'possible_notify_user_ids',
        'documents',
        'notes',
        'people',
        'commission',
        'expected_close_date_dummy',
        'latest_activity',
        'profile_layout',
        'source',
        'deal_pipeline',
        'days_active',
        'time_to_close',
        'deal_stage',
        'deal_status',
        'import',
        'user',
        'company',
        'owner_name',
        'status_color',
        'status_name',
        'updated_at',
        'days_in_stage',
        'date_of_first_activity',
        'hours_to_first_activity',
        'days_since_last_activity',
        'latest_deal_stage_change',
        'customer_days_in_health',
        'health_score_changed_at',
        'currency',
        'revenue_type',
        'owner_deleted_at',
        'can_be_merged_by_current_user',
        'deal_weighted_forecast',
        'deal_time_to_dollar',
        'deal_days_active',
        'deal_time_to_close',
        'product_lines',
        'created_by_user_id',
        'updated_by_user_id',
        'deal_time_to_dollar_change',
        'number_of_days_in_stages',
        'activities_created_count',
        'days_without_activity',
        'tags'
      ]
    case 'person':
      return [
        'is_allowed_to_delete',
        'can_be_reassigned_by_current_user',
        'full_name',
        'company_path',
        'company_address',
        'home_address',
        'latest_activity',
        'lead_status',
        'profile_layout',
        'source',
        'work_address',
        'possible_notify_user_ids',
        'notes',
        'deals',
        'documents',
        'tags',
        'work_address_google_maps_url',
        'cached_tag_ids',
        'owner_name',
        'updated_at',
        'has_deals',
        'date_of_first_activity',
        'hours_to_first_activity',
        'days_since_last_activity',
        'userCurrencySymbol',
        'owner_deleted_at',
        'created_by_user_id'
      ]
    case 'company':
      return [
        'can_be_deleted_by_current_user',
        'can_be_merged_by_current_user',
        'address',
        'pdf_company_path',
        'possible_notify_user_ids',
        'notes',
        'deals',
        'people',
        'documents',
        'total_pipeline',
        'won_value',
        'worked_on_by',
        'owner_name',
        'loggedUserIsExecutive',
        'updated_at',
        'people_for_notes_association',
        'has_deals',
        'has_people',
        'customer',
        'userCurrencySymbol',
        'owner_deleted_at',
        'tags',
        'hierarchy_details'
      ]
    case 'calendar_event':
      return [
        'start_time',
        'end_time',
        'primary_contact',
        'contact',
        '_date_select_for_start_time_date',
        '_date_select_for_end_time_date',
        '_date_select_for_recurrence_end',
        '_date_select_for_due_date',
        'primary_contact_id',
        'company_name',
        'company',
        'possible_notify_user_ids',
        'shared_user_ids',
        'deal_id',
        'person_id',
        'contact_id',
        'lead_id',
        'deal_name',
        'person_name',
        'contact_name',
        'lead_name',
        'deal',
        'lead',
        'updated_at',
        'created_by_user_id',
        'repeat_this_entry'
      ]
    case 'calendar_task':
      return [
        'start_time',
        'end_time',
        'primary_contact',
        '_date_select_for_start_time_date',
        '_date_select_for_end_time_date',
        '_date_select_for_recurrence_end',
        '_date_select_for_due_date',
        'primary_contact_id',
        'company_name',
        'company',
        'created_at',
        'contact',
        'updated_at',
        'possible_notify_user_ids',
        'shared_user_ids',
        'deal_id',
        'person_id',
        'contact_id',
        'lead_id',
        'deal_name',
        'person_name',
        'contact_name',
        'lead_name',
        'deal',
        'lead',
        'repeat_this_entry'
      ]
    default:
      return []
  }
}

export const fieldIdMapped = (fieldId) => {
  if (fieldId === 'is_lead') return 'type'
  if (fieldId === 'deal_revenue_type') return 'revenue_type_id'
  if (fieldId === 'deal_primary_contact_id') return 'primary_contact_id'
  if (fieldId === 'company_id') return 'company_name'
  return fieldId
}

export const inverseFieldIdMapped = (fieldId) => {
  if (fieldId === 'type') return 'is_lead'
  if (fieldId === 'revenue_type_id') return 'deal_revenue_type'
  if (fieldId === 'primary_contact_id') return 'deal_primary_contact_id'
  if (fieldId === 'company_name') return 'company_id'
  return fieldId
}

export const addMandatoryFields = (resourceType, changedAttrs, currentValues) => {
  if (resourceType === 'calendar_event' || resourceType === 'calendar_task') {
    if (changedAttrs.recurrence_end || changedAttrs.recurrence_frequency) {
      changedAttrs.recurrence_frequency ||= currentValues.recurrence_frequency
      changedAttrs.recurrence_end ||= currentValues.recurrence_end
    }
  }

  return changedAttrs
}
