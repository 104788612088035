import React from 'react'

import { PrimaryBadge } from '@PipelineDeals/shared-react-components'
import { useBusinessCard } from 'components/BusinessCard/context'

const IdBadge = () => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  if (!showOnlyFields.includes('id')) return null

  return (
    <span>&nbsp;&nbsp;<PrimaryBadge content={resource.id} /></span>
  )
}

export default IdBadge
