import checkNumber from './rules/number'
import checkText from './rules/text'
import checkBoolean from './rules/boolean'
import checkDate from './rules/date'
import checkDropdown from './rules/dropdown'
import checkMultiselect from './rules/multiselect'

export const checkRule = (rule, object) => {
  if (rule.triggers.length === 0) {
    return checkTriggerlessRule(rule)
  }

  const changesAll = []
  const changesAny = []

  rule.triggers.forEach(trigger => {
    const triggerResult = checkTrigger(
      trigger,
      attributeValue(object, trigger.model_attribute)
    )

    if (triggerResult) {
      const triggeredParams = {
        rule,
        trigger,
        ...triggerResult
      }

      if (trigger.joined_with_all) {
        changesAll.push(triggeredParams)
      } else {
        changesAny.push(triggeredParams)
      }
    }
  })

  const triggeredAll = changesAll.length === triggersJoinedWithAllCount(rule)
  const triggeredAny = changesAny.length > 0

  if (triggersJoinedWithAnyCount(rule) === 0) {
    return triggeredAll ? changesAll : []
  } else {
    return triggeredAll && triggeredAny ? [...changesAll, ...changesAny] : []
  }
}

export const checkTriggerlessRule = (rule) => {
  return [{ message: 'Triggerless rule' }]
}

export const checkEventAction = (rule, isNew) => {
  if (isNew && ['create', 'create_update'].includes(rule.event_action)) {
    return true
  }

  if (!isNew && ['update', 'create_update'].includes(rule.event_action)) {
    return true
  }

  return false
}

const checkTrigger = (trigger, value) => {
  switch (trigger.data_type) {
    case 'number':
      return checkNumber(trigger, value)
    case 'text':
      return checkText(trigger, value)
    case 'boolean':
      return checkBoolean(trigger, value)
    case 'date':
      return checkDate(trigger, value)
    case 'dropdown':
      return checkDropdown(trigger, value)
    case 'multiselect':
      return checkMultiselect(trigger, value)
    default:
      return null
  }
}

const attributeValue = (object, attribute) => {
  if (attribute && attribute.includes('custom_label_')) {
    return object.custom_fields ? object.custom_fields[attribute] : null
  } else {
    const attributeAdjusted = attribute === 'value' ? 'deal_value' : attribute
    return object[attributeAdjusted]
  }
}

const triggersJoinedWithAllCount = (rule) => rule.triggers.filter(trigger => trigger.joined_with_all).length
const triggersJoinedWithAnyCount = (rule) => rule.triggers.filter(trigger => !trigger.joined_with_all).length
