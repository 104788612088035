import {
  REPORT_SHARE,
  REPORT_STAR,
  REPORT_CREATE,
  REPORT_UPDATE,
  REPORT_DUPLICATE,
  REPORT_DELETE
} from 'permissions/actions'

export const REPORT = '@@permissions/policy/report'

export default {
  [REPORT_SHARE]: (user) => !user.read_only,
  [REPORT_STAR]: (user) => !user.read_only,
  [REPORT_CREATE]: (user) => !user.read_only,
  [REPORT_UPDATE]: (user) => !user.read_only,
  [REPORT_DUPLICATE]: (user) => !user.read_only,
  [REPORT_DELETE]: (user) => !user.read_only
}
