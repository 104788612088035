/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

const truncate = require('trunc-html')

Helpers.truncate = function (str, max) {
  if (max == null) { max = 25 }
  if (!str) { return '' }
  if (str.length <= max) { return str }
  return `${str.substring(0, max)}...`
}

Handlebars.registerHelper('truncate20', text => Helpers.truncate(text, 20))

Helpers.truncateHTML = function (str, max = 25, output = 'text') {
  if (!str) { return '' }
  if (str.length <= max) { return str }
  return truncate(str, max)[output]
}

module.exports = Helpers
