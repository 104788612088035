import moment from 'moment'

export const formatUserDate = (date, dateFormat, parse = null) => {
  let format = 'MM/DD/YY'

  if (dateFormat !== 'us') {
    format = 'DD/MM/YY'
  }

  return moment(date, parse).format(format)
}

/*
 * This functionality has been extracted from the datejs library
 *
 * Returns an offset in a string format, e.g.: "+0200" or "-0200"
 */
export const getUTCOffset = (date) => {
  const n = date.getTimezoneOffset() * -10 / 6
  let r

  if (n < 0) {
    r = (n - 10000).toString()
    return r.charAt(0) + r.substr(2)
  } else {
    r = (n + 10000).toString()
    return '+' + r.substr(1)
  }
}

export const formatHoursToFirstContact = (hours) => {
  let noun
  if ((hours == null)) {
    return ' '
  } else if (hours <= 0.0) {
    return 'less than one minute'
  } else if (hours < 1.0) {
    const minutes = Math.round(hours * 60)
    noun = minutes === 1 ? 'minute' : 'minutes'
    return `${minutes} ${noun}`
  } else if (hours >= 1 && hours < 24) {
    hours = Math.floor(hours)
    noun = hours === 1 ? 'hour' : 'hours'
    return `${hours} ${noun}`
  } else {
    const days = Math.floor(hours / 24)
    noun = days === 1 ? 'day' : 'days'
    return `${days} ${noun}`
  }
}
