import { push } from 'connected-react-router'

export default (e, dispatch, url) => {
  if (e) {
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
      return
    }

    e.preventDefault()
  }

  dispatch(push(url))
}
