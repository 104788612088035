export const TAG_CREATE = 'TAG_CREATE'
export const TAG_CREATE_SUBMIT = 'TAG_CREATE_SUBMIT'
export const TAG_CREATE_FAILURE = 'TAG_CREATE_FAILURE'

export const TAG_UPDATE = 'TAG_UPDATE'
export const TAG_UPDATE_SUBMIT = 'TAG_UPDATE_SUBMIT'
export const TAG_UPDATE_FAILURE = 'TAG_UPDATE_FAILURE'

export const TAG_DELETE = 'TAG_DELETE'
export const TAG_DELETE_SUBMIT = 'TAG_DELETE_SUBMIT'
export const TAG_DELETE_FAILURE = 'TAG_DELETE_FAILURE'
