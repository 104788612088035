import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  calendarEntryPriorityCreate,
  calendarEntryPriorityCreateFailure,
  calendarEntryPriorityUpdate,
  calendarEntryPriorityUpdateFailure,
  calendarEntryPriorityDelete,
  calendarEntryPriorityDeleteFailure
} from '../actions'

import {
  CALENDAR_ENTRY_PRIORITY_CREATE_SUBMIT,
  CALENDAR_ENTRY_PRIORITY_UPDATE_SUBMIT,
  CALENDAR_ENTRY_PRIORITY_DELETE_SUBMIT
} from '../constants'

export function * calendarEntryPriorityCreateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/calendar_entry_priorities',
      {
        method: 'POST',
        body: JSON.stringify({
          calendar_entry_priority: {
            name: payload.name,
            hex_color: payload.hexColor
          }
        })
      }
    )
    yield put(calendarEntryPriorityCreate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(calendarEntryPriorityCreateFailure(exception.message))
  }
}

export function * watchCalendarEntryPriorityCreateSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_PRIORITY_CREATE_SUBMIT, calendarEntryPriorityCreateSaga)
}

export function * calendarEntryPriorityUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/calendar_entry_priorities/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          calendar_entry_priority: {
            name: payload.name,
            hex_color: payload.hexColor
          }
        })
      }
    )
    yield put(calendarEntryPriorityUpdate(response))
  } catch (exception) {
    yield put(calendarEntryPriorityUpdateFailure(exception.message))
  }
}

export function * watchCalendarEntryPriorityUpdateSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_PRIORITY_UPDATE_SUBMIT, calendarEntryPriorityUpdateSaga)
}

export function * calendarEntryPriorityDeleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/calendar_entry_priorities/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(calendarEntryPriorityDelete(payload))
  } catch (exception) {
    yield put(calendarEntryPriorityDeleteFailure(exception.message))
  }
}

export function * watchCalendarEntryPriorityDeleteSaga () {
  yield call(takeEvery, CALENDAR_ENTRY_PRIORITY_DELETE_SUBMIT, calendarEntryPriorityDeleteSaga)
}
