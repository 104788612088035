import { createSelector } from 'reselect'

export const getCalendarDateRangeState = state => state.calendar.range
export const getCalendarUsers = state => state.calendar.userIds
export const getCalendarUserColors = store => store.calendar.userColors
export const getCalendarEventTypes = state => state.calendar.filter
export const getCalendarEventTypeColors = store => store.calendar.eventTypeColors
export const getCalendarSelectedFilterId = store => store.calendar.selectedFilterId
export const getCalendarFilters = store => store.calendar.filters

export const getCalendarDateRange = createSelector(
  [getCalendarDateRangeState],
  range => {
    return range
  }
)
