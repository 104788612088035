import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import PhoneNumber from 'components/BusinessCard/components/PhoneNumber'
import { Section } from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const numberLabel = (number) => {
  return {
    phone: 'w',
    phone1: 'p',
    home_phone: 'h',
    mobile: 'm',
    work_phone: 'w',
    mobile_number: 'm'
  }[number.type]
}

const PhoneNumbers = ({ phoneAttrs }) => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  const phoneNumbers = useMemo(() => {
    return phoneAttrs.filter(phoneNumberType => showOnlyFields.includes(phoneNumberType)).map(phoneNumberType => {
      if (resource[phoneNumberType]) {
        return { number: resource[phoneNumberType], type: phoneNumberType }
      }
    }).filter(phoneNumber => phoneNumber !== undefined)
  }, [phoneAttrs, showOnlyFields, resource])

  if (!phoneNumbers.length) return null

  return (
    <Section data-cypress="person-business-card-phone-numbers">
      {phoneNumbers.map(phoneNumber =>
        <PhoneNumber
          key={phoneNumber.number}
          label={numberLabel(phoneNumber)}
          number={phoneNumber.number}
          type={phoneNumber.type}
        />
      )}
    </Section>
  )
}

PhoneNumbers.propTypes = {
  phoneAttrs: PropTypes.array.isRequired
}

export default PhoneNumbers
