import { takeEvery } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import { UPDATE_CURRENT_USER } from 'ducks'
import { getCurrentUserSelector } from 'selectors'

import { UPDATE_SEARCH_SETTINGS } from './ducks'
import { getSearchSettings } from './selectors'

function * updateSearchSettingsSaga ({ payload }) {
  const settings = yield select(getSearchSettings)
  const currentUser = yield select(getCurrentUserSelector)

  try {
    yield call(
      fetcher,
      'api/v3/user_settings/global_search_settings',
      {
        method: 'PUT',
        body: JSON.stringify({ value: { ...settings, ...payload } })
      }
    )
    yield put({ type: UPDATE_CURRENT_USER, payload: { ...currentUser, global_search_settings: { ...settings, ...payload } } })
  } catch (expection) {
    console.error(expection.message)
  }
}

function * watchUpdateSearchSettingsSaga () {
  yield call(takeEvery, UPDATE_SEARCH_SETTINGS, updateSearchSettingsSaga)
}

export default [watchUpdateSearchSettingsSaga()]
