import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { DateInput, Modal, PrimaryButton } from '@PipelineDeals/shared-react-components'
import {
  CenteredWrapper,
  SecondaryButtonWrapper
} from 'list_views/components/bulk_actions/index'
import moment from 'moment/moment'

const PostponeToDateModal = React.memo(
  ({ title, onSubmit, onClose }) => {
    const [date, setDate] = useState()

    const parsedDate = useMemo(() => (
      date ? moment(date).format('YYYY-MM-DD') : ''
    ), [date])

    const handleChange = (selection) => {
      setDate(selection)
    }

    const handleSubmit = (e) => {
      onSubmit(parsedDate, e)
    }

    const contentStyles = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '450px'
    }

    return (
      <Modal
        isOpen
        title={title}
        onClose={onClose}
        footerTrailingContent={
          <>
            <SecondaryButtonWrapper content="Cancel" onClick={onClose} />
            <PrimaryButton content="Postpone" onClick={handleSubmit} />
          </>
        }
        contentStyles={contentStyles}
      >
        <CenteredWrapper>
          <DateInput
            label="Date"
            onChange={handleChange}
            placeholder="Choose date"
            value={date}
            clearable
          />
        </CenteredWrapper>
      </Modal>
    )
  }
)

PostponeToDateModal.propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default PostponeToDateModal
