export const TEAM_OBJECTS = [
  {
    pluralised: 'companies',
    type: 'company'
  },
  {
    pluralised: 'deals',
    type: 'deal'
  },
  {
    pluralised: 'people',
    type: 'person'
  }
]
export const TEAM_PERMISSIONS = ['owner', 'member', 'lead', 'account']

export const TEAM_CREATE = '@@constants/TEAM_CREATE'
export const TEAM_DELETE = '@@constants/TEAM_DELETE'
export const TEAM_UPDATE = '@@constants/TEAM_UPDATE'

export const TEAM_DELETE_FORM_FAILURE = '@@constants/TEAM_DELETE_FORM_FAILURE'
export const TEAM_DELETE_FORM_SUBMIT = '@@constants/TEAM_DELETE_FORM_SUBMIT'

export const TEAM_EDIT_FORM_FAILURE = '@@constants/TEAM_EDIT_FORM_FAILURE'
export const TEAM_EDIT_FORM_SUBMIT = '@@constants/TEAM_EDIT_FORM_SUBMIT'

export const TEAM_FETCH_FAILURE = '@@constants/TEAM_FETCH_FAILURE'
export const TEAM_FETCH_REQUEST = '@@constants/TEAM_FETCH_REQUEST'
export const TEAM_FETCH_SUCCESS = '@@constants/TEAM_FETCH_SUCCESS'

export const TEAM_NEW_FORM_FAILURE = '@@constants/TEAM_NEW_FORM_FAILURE'
export const TEAM_NEW_FORM_SUBMIT = '@@constants/TEAM_NEW_FORM_SUBMIT'

export const TEAM_USERS_FORM_FAILURE = '@@constants/TEAM_USERS_FORM_FAILURE'
export const TEAM_USERS_FORM_SUBMIT = '@@constants/TEAM_USERS_FORM_SUBMIT'

export const TEAM_PERMISSION_LABELS = {
  company: '2. What <strong>companies</strong> can members<br /><span class="cell-recordtype-indent">see & edit</span>?',
  deal: '1. What <strong>deals</strong> can members<br /><span class="cell-recordtype-indent">see & edit</span>?',
  person: '3. What <strong>people</strong> can members<br /><span class="cell-recordtype-indent">see & edit</span>?'
}

export const TEAM_USERS_FILTER = '@@constants/TEAM_USERS_FILTER'
export const TEAM_USERS_FILTER_ACTIVE = '@@constants/TEAM_USERS_FILTER_ACTIVE'
export const TEAM_USERS_FILTER_ALL = '@@constants/TEAM_USERS_FILTER_ALL'
