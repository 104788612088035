import store from 'store'
import { checkRule } from './rules'

export const conditionalFieldsSummary = (rules, object, lastRule = false) => {
  const storeState = store.getState()
  const summary = {}

  rules.forEach(rule => {
    const ruleResults = checkRule(rule, object)
    if (ruleResults.length > 0) {
      if (lastRule) {
        Object.keys(summary).forEach(key => delete summary[key])
      }

      rule.actions.forEach(action => {
        action.fields.forEach(field => {
          if (field.name.startsWith('custom_label_')) {
            const cflId = parseInt(field.name.replace('custom_label_', ''))
            const accountCfls = storeState.modern.currentAccount[`${rule.event_model}_custom_field_labels`]
            if (!accountCfls.find(cfl => cfl.id === cflId)) return
          }

          if (field.value !== '') {
            const values = field.value.includes('[') ? JSON.parse(field.value) : [field.value]
            values.forEach(value => {
              summary[field.name] ||= {}
              summary[field.name][value] ||= []

              ruleResults.forEach(ruleResult => {
                summary[field.name][value].push({ ...ruleResult, rule })
              })
            })
          }
        })
      })
    }
  })

  return summary
}

export default conditionalFieldsSummary
