/* global CustomEvent */

import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { dealFetch } from 'Deals/actions'

import {
  setDealWonReason,
  setDealWonReasonFailure
} from '../actions'

import {
  SET_DEAL_WON_REASON_SUBMIT
} from '../constants'

export function * setDealWonReasonSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `deals/${payload.dealId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal: {
            deal_won_reason_id: parseInt(payload.dealWonReasonId),
            deal_won_reason_notes: payload.dealWonReasonNotes
          }
        })
      }
    )
    yield put(setDealWonReason(response))
    yield put(dealFetch({ dealId: payload.dealId }))

    // Custom event to trigger update on React LVs
    const ev = new CustomEvent('list_view:refresh')
    window.dispatchEvent(ev)
  } catch (exception) {
    yield put(setDealWonReasonFailure({ error: exception.message }))
  }
}

export function * watchSetDealWonReasonSaga () {
  yield call(takeEvery, SET_DEAL_WON_REASON_SUBMIT, setDealWonReasonSaga)
}
