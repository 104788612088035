import * as permissions from 'permissions'

export const actions = {
  activity_create: permissions.ACTIVITY_CREATE,
  activity_delete: permissions.ACTIVITY_DELETE,
  activity_update: permissions.ACTIVITY_UPDATE,
  agenda_create: permissions.AGENDA_CREATE,
  avatar_upload: permissions.AVATAR_UPLOAD,
  bulk_actions: permissions.BULK_ACTIONS,
  calendar_subscribe: permissions.CALENDAR_SUBSCRIBE,
  comment_create: permissions.COMMENT_CREATE,
  comment_delete: permissions.COMMENT_DELETE,
  comment_update: permissions.COMMENT_UPDATE,
  file_create: permissions.FILE_CREATE,
  file_delete: permissions.FILE_DELETE,
  file_tag: permissions.FILE_TAG,
  list_view_create: permissions.LIST_VIEW_CREATE,
  list_view_delete: permissions.LIST_VIEW_DELETE,
  list_view_duplicate: permissions.LIST_VIEW_DUPLICATE,
  list_view_share: permissions.LIST_VIEW_SHARE,
  list_view_star: permissions.LIST_VIEW_STAR,
  list_view_update: permissions.LIST_VIEW_UPDATE,
  list_view_add_remove_columns: permissions.LIST_VIEW_ADD_REMOVE_COLUMNS,
  person_associate: permissions.PERSON_ASSOCIATE,
  create: permissions.CREATE,
  delete: permissions.DELETE,
  manage: permissions.MANAGE,
  share: permissions.SHARE,
  update: permissions.UPDATE,
  update_owner: permissions.UPDATE_OWNER,
  list_view_create_focused: permissions.LIST_VIEW_CREATE_FOCUSED,
  campaign_subscribe: permissions.CAMPAIGN_SUBSCRIBE
}

export const policies = {
  CalendarEvent: permissions.CALENDAR_ENTRY,
  CalendarTask: permissions.CALENDAR_ENTRY,
  activity: permissions.ACTIVITY,
  activities: permissions.ACTIVITY,
  calendar: permissions.CALENDAR,
  calendar_entry: permissions.CALENDAR_ENTRY,
  campaign: permissions.CAMPAIGN,
  campaigns: permissions.CAMPAIGN,
  company: permissions.COMPANY,
  companies: permissions.COMPANY,
  customField: permissions.CUSTOM_FIELD,
  deal: permissions.DEAL,
  deals: permissions.DEAL,
  document: permissions.DOCUMENT,
  documents: permissions.DOCUMENT,
  message_template: permissions.MESSAGE_TEMPLATE,
  message_templates: permissions.MESSAGE_TEMPLATE,
  milestone: permissions.MILESTONE,
  milestones: permissions.MILESTONE,
  note: permissions.ACTIVITY,
  notes: permissions.ACTIVITY,
  person: permissions.PERSON,
  people: permissions.PERSON,
  recipient: permissions.RECIPIENT,
  recipients: permissions.RECIPIENT,
  scoreboard_report: permissions.SCOREBOARD_REPORT,
  scoreboard_reports: permissions.SCOREBOARD_REPORT,
  todos: permissions.CALENDAR_ENTRY
}

export const hasAction = name => actions[name] !== undefined
export const hasPolicy = name => policies[name] !== undefined

export default (user, policy, action, options = {}) => {
  return !hasPolicy(policy) || !hasAction(action) || permissions.permissible(user, policies[policy], actions[action], options)
}
