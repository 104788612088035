import { UPDATE, CREATE } from 'permissions/actions'
import { userLevels } from 'permissions/userLevels'

export const CUSTOM_FIELD = '@@permissions/policy/customField'

export default {
  [CREATE]: (user, options) => {
    const { locked_add: lockedAdd, field_type: fieldType } = options.field || {}

    const lockedOptions = !lockedAdd || user.level === userLevels.EXECUTIVE || user.is_account_admin
    const calculated = fieldType === 'calculated'
    return !user.read_only && !user.external && lockedOptions && !calculated
  },
  [UPDATE]: (user, options) => {
    const { locked, field_type: fieldType } = options.field || {}

    const lockedOptions = !locked || user.level === userLevels.EXECUTIVE || user.is_account_admin
    const calculated = fieldType === 'calculated'
    return !user.read_only && !user.external && lockedOptions && !calculated
  }
}
