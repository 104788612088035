/* global App */

import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import addEditRecord from 'add_edit_modals'

import { hideModal } from 'modals/ducks'
import { EMAIL_MODAL } from 'Email/constants'

const AddPersonWidget = ({ person, onPersonAdded }) => {
  const dispatch = useDispatch()

  const addPersonModal = (personObj) => {
    let firstName, lastName

    if (personObj.name) {
      const names = personObj.name.split(' ')
      firstName = names[0]
      lastName = names.splice(1).join(' ')
    } else {
      firstName = ''
      lastName = ''
    }

    const person = new App.Models.Person({
      first_name: firstName,
      last_name: lastName,
      email: personObj.email
    })

    addEditRecord({ resourceType: 'person', values: person.attributes, formMeta: { onSuccess: onPersonAdded } })
  }

  const getCallbackFor = (person) => {
    return (e) => {
      dispatch(hideModal({ type: EMAIL_MODAL }))
      addPersonModal(person)
      e.stopPropagation()
    }
  }

  return (
    <a className="label label-addcontact" onClick={getCallbackFor(person)}>
      {person.email}
      <i className="fas fa-plus" />
      <i className="fas fa-user" />
    </a>
  )
}

AddPersonWidget.displayName = 'Email.Connect.EmailReply.AddPersonWidget'

AddPersonWidget.propTypes = {
  person: PropTypes.object,
  onPersonAdded: PropTypes.func
}

export default AddPersonWidget
