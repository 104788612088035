import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { TertiaryButton } from '@PipelineDeals/shared-react-components'
import { useOnOutsideClick } from 'hooks/useOnOutsideClick'
import styled from 'styled-components'

const DropdownContainer = styled.ul`
  left: auto;
  right: 0;
`

const DealPipelinesSelector = ({ label, pipelines, onDropdownItemClick }) => {
  const [isOpen, setIsOpen] = useState(false)

  const node = useRef()
  useOnOutsideClick({ node, showComponent: isOpen, onOutsideClick: () => setIsOpen(false) })

  const handleClick = (e) => {
    onDropdownItemClick(e)
    setIsOpen(false)
  }

  return (
    <div ref={node} className={classnames('dropdown dropdown-menu-pld', isOpen && 'open')} style={{ display: 'inline-block' }}>
      <TertiaryButton content={label} trailingContent={<i className="fas fa-caret-down" />} onClick={() => setIsOpen(!isOpen)} />

      <DropdownContainer className="dropdown-menu" role="menu" aria-labelledby="dropdownMenu">
        {pipelines.map((dp) => (
          <li key={`pipeline-selection-${dp.id}`}>
            <a tabIndex="-1" onClick={handleClick} data-pipeline-label={dp.name} data-pipeline-id={dp.id}>
              {dp.name}
              {dp.default === true &&
              ' (default)'}
            </a>
          </li>
        ))}
      </DropdownContainer>
    </div>
  )
}

DealPipelinesSelector.propTypes = {
  label: PropTypes.string.isRequired,
  pipelines: PropTypes.array.isRequired,
  onDropdownItemClick: PropTypes.func.isRequired
}

export default DealPipelinesSelector
