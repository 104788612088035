export const isReadOnly = (user) => {
  return (user.read_only === true && !isInactive(user))
}

export const isInactive = (user) => {
  return (user.deleted_at !== null && user.deleted_at !== undefined)
}

export const isActive = (user) => {
  return (!isReadOnly(user) && !isInactive(user))
}

export const filterActiveUsers = (users) => {
  return users.filter(user => isActive(user))
}
