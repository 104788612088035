export const EVENT_TYPE_CREATE = 'EVENT_TYPE_CREATE'
export const EVENT_TYPE_CREATE_SUBMIT = 'EVENT_TYPE_CREATE_SUBMIT'
export const EVENT_TYPE_CREATE_FAILURE = 'EVENT_TYPE_CREATE_FAILURE'

export const EVENT_TYPE_UPDATE = 'EVENT_TYPE_UPDATE'
export const EVENT_TYPE_UPDATE_SUBMIT = 'EVENT_TYPE_UPDATE_SUBMIT'
export const EVENT_TYPE_UPDATE_FAILURE = 'EVENT_TYPE_UPDATE_FAILURE'

export const EVENT_TYPE_DELETE = 'EVENT_TYPE_DELETE'
export const EVENT_TYPE_DELETE_SUBMIT = 'EVENT_TYPE_DELETE_SUBMIT'
export const EVENT_TYPE_DELETE_FAILURE = 'EVENT_TYPE_DELETE_FAILURE'
