import React, { Component } from 'react'
import PropTypes from 'prop-types'

import onClickOutside from 'react-onclickoutside'

import orderBy from 'modules/orderBy'

import styled from 'styled-components'
import { DropdownListItem, SearchForm, EmptyStateMessage } from 'Profiles/DropdownComponents'

const ClearButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  color: lightgray;
`

const DropdownHeader = styled.h2`
  line-height: 1.2em;
  text-align: center;
  margin: 10px 5px 5px 5px;
  border: 0;
  font-size: 16px;
`

const DropdownHeaderSpacer = styled.hr`
  margin: 0;
`

const SubHeader = styled.h4`
  font-size: 12px;
  font-weight: bold;
  margin: 5px 10px;
`

const AddNewDealButton = styled.button`
  margin: 5px 10px;
`

class DealSelector extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showDropdown: false,
      filterText: ''
    }

    this.makeSelection = this.makeSelection.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.handleAddNewDeal = this.handleAddNewDeal.bind(this)
  }

  componentDidMount () {
    const deals = this.props.deals.filter(deal => ![0, 100].includes(deal.deal_stage_percent))
    const { selectFirstDealByDefault } = this.props
    if (selectFirstDealByDefault && deals.length === 1) this.makeSelection(deals[0].id)
  }

  handleClickOutside (event) {
    const { showDropdown } = this.state
    if (showDropdown) {
      this.setState({ showDropdown: false })
    }
  }

  buttonContent () {
    const { selectedDeal, deals } = this.props

    switch (selectedDeal) {
      case null:
        return 'Choose a deal'
      default: {
        const deal = deals.find(deal => deal.id === selectedDeal)
        return deal && deal.name
      }
    }
  }

  makeSelection (value) {
    this.props.handleDealClick(value)
    this.setState({ showDropdown: false })
  }

  renderDealOpts (deals) {
    const { selectedDeal } = this.props

    return deals && orderBy(deals).map(deal => {
      return (
        <DropdownListItem className="dropdown-list-item" key={'deal-option-' + deal.id} onClick={() => this.makeSelection(deal.id)}>
          <a tabIndex="-1" className={(deal.id === parseInt(selectedDeal)) ? 'selected' : ''}>
            {deal.name}
          </a>
        </DropdownListItem>
      )
    })
  }

  filterDeals (deals) {
    const { filterText } = this.state

    if (!filterText) return deals

    return deals.filter(deal => { return deal.name.toLowerCase().includes(filterText) })
  }

  renderSearch () {
    const { deals, searchLength } = this.props
    const { filterText } = this.state

    if (deals.length && deals.length >= (searchLength || 10)) {
      return (
        <SearchForm role="search">
          <i className="fas fa-search input-icon" />
          <input
            className="form-control input-block-level hasremoveoption"
            type="text"
            placeholder="Search deals"
            value={filterText}
            onChange={(event) => this.setState({ filterText: event.target.value.toLowerCase() })}
          />
          <ClearButton onClick={() => this.setState({ filterText: '' })}><i className="fas fa-times" /></ClearButton>
        </SearchForm>
      )
    }
  }

  emptyFilteringState () {
    return (
      <EmptyStateMessage className="text-center">No deals match this search criteria.</EmptyStateMessage>
    )
  }

  emptyState () {
    return (
      <div className="dropdown">
        <button
          type="button"
          className="btn btn-pld-default btn-block btn-dropdown dropdown-toggle"
          aria-haspopup="true" aria-expanded="false"
          disabled
        >
          No deals available
          <span className="caret" />
        </button>
      </div>
    )
  }

  toggleDropdown () {
    const { showDropdown } = this.state
    this.setState({ showDropdown: !showDropdown })
  }

  renderDropdownToggle () {
    const { dropdownToggle } = this.props

    if (dropdownToggle) {
      return dropdownToggle(this.toggleDropdown)
    } else {
      return (
        <button type="button" className="btn btn-dropdown btn-pld-default btn-block dropdown-toggle" aria-haspopup="true" aria-expanded="false" onClick={this.toggleDropdown}>
          {this.buttonContent()}
          <span className="caret" />
        </button>
      )
    }
  }

  renderDropdownHeader () {
    const { dropdownHeader } = this.props
    return dropdownHeader && (
      <div>
        <DropdownHeader>{dropdownHeader}</DropdownHeader>
        <DropdownHeaderSpacer />
      </div>
    )
  }

  renderNoneOption () {
    const { filterText } = this.state
    const { noneOption } = this.props

    return noneOption && !filterText &&
      <DropdownListItem className="dropdown-list-item">
        <a tabIndex="-1" onClick={() => this.makeSelection(null)}>None</a>
      </DropdownListItem>
  }

  handleAddNewDeal (e) {
    const { onAddNewDeal } = this.props
    this.toggleDropdown()
    onAddNewDeal(e)
  }

  renderAddNewDeal () {
    const { displayAddNewDeal } = this.props
    return displayAddNewDeal &&
      <div>
        <SubHeader>OR</SubHeader>
        <AddNewDealButton className="btn-link" onClick={this.handleAddNewDeal}>Add new deal</AddNewDealButton>
      </div>
  }

  render () {
    const { deals, displayDealsHeader, pullRight } = this.props
    const { showDropdown } = this.state
    const filteredDeals = this.filterDeals(deals)

    if (!deals.length) return this.emptyState()

    return (
      <div className={`dropdown ${showDropdown && 'open'}`}>
        {this.renderDropdownToggle()}
        <ul className={`dropdown-menu dropdown-generic dropdown-overflow-sm ${pullRight && 'pull-right'}`} style={{ width: '100%' }}>
          {this.renderDropdownHeader()}
          {displayDealsHeader && <SubHeader>DEALS</SubHeader>}
          {this.renderSearch()}
          {this.renderNoneOption()}
          {!!filteredDeals.length && this.renderDealOpts(filteredDeals)}
          {!filteredDeals.length && this.emptyFilteringState()}
          {this.renderAddNewDeal()}
        </ul>
      </div>
    )
  }
}

DealSelector.propTypes = {
  deals: PropTypes.array.isRequired,
  dropdownToggle: PropTypes.func,
  handleDealClick: PropTypes.func.isRequired,
  dropdownHeader: PropTypes.node,
  displayDealsHeader: PropTypes.bool,
  displayAddNewDeal: PropTypes.bool,
  noneOption: PropTypes.bool,
  onAddNewDeal: PropTypes.func,
  pullRight: PropTypes.bool,
  selectedDeal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  searchLength: PropTypes.number,
  selectFirstDealByDefault: PropTypes.bool
}

DealSelector.defaultProps = {
  displayDealsHeader: false,
  displayAddNewDeal: false,
  noneOption: true,
  pullRight: false,
  selectFirstDealByDefault: true
}

export default onClickOutside(DealSelector)
