import * as constants from '../constants/stages'

export const dealStagesFetchSuccess = payload => ({ payload, type: constants.DEAL_STAGES_FETCH_SUCCESS })
export const dealStagesFetchFailure = payload => ({ payload, type: constants.DEAL_STAGES_FETCH_FAILURE })

export const dealStageCreate = payload => ({ payload, type: constants.DEAL_STAGE_CREATE })
export const dealStageCreateFailure = payload => ({ payload, type: constants.DEAL_STAGE_CREATE_FAILURE })

export const dealStageCurrentlyEdit = payload => ({ payload, type: constants.DEAL_STAGE_CURRENTLY_EDIT })
export const dealStageCurrentEditClear = payload => ({ payload, type: constants.DEAL_STAGE_CURRENT_EDIT_CLEAR })

export const dealStageUpdate = payload => ({ payload, type: constants.DEAL_STAGE_UPDATE })
export const dealStageUpdateFailure = payload => ({ payload, type: constants.DEAL_STAGE_UPDATE_FAILURE })

export const dealStageDelete = payload => ({ payload, type: constants.DEAL_STAGE_DELETE })
export const dealStageDeleteFailure = payload => ({ payload, type: constants.DEAL_STAGE_DELETE_FAILURE })

export const dealStagesReorderFailure = payload => ({ payload, type: constants.DEAL_STAGES_REORDER_FAILURE })
export const dealStagesReorderSubmit = payload => ({ payload, type: constants.DEAL_STAGES_REORDER_SUBMIT })
