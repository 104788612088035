/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.humanize = string => string.replace(/_/g, ' ').replace(/\w+/g, match => match.charAt(0).toUpperCase() + match.slice(1))

Handlebars.registerHelper('humanize', string => Helpers.humanize(string))

Helpers.possessiveName = function (name) {
  const names = name.trim().split(' ')
  let last = names.slice(-1)[0]
  if (last.slice(-1) === 's') {
    last = `${last}'`
  } else {
    last = `${last}'s`
  }
  names.splice(-1, 9e9, ...[].concat(last))
  return names.join(' ')
}

module.exports = Helpers
