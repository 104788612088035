import classNames from 'classnames'

import Component from 'permissions/Handlebars/Component'

export const Button = options => {
  return Container({
    ...options,
    hash: {
      ...options.hash,
      className: classNames('disabled', options.hash.className),
      disabled: true,
      tooltip: options.hash.tooltip || 'You are unable to perform this action'
    }
  })
}

export const Container = options => {
  const { action, object, objectType, policy, user, ...rest } = options.hash

  return Component({
    action,
    html: options.fn(this),
    object,
    objectType,
    options: rest,
    policy,
    user: user || options.data.root.user || window.User
  })
}

export const Input = options => {
  return Container({
    ...options,
    hash: {
      ...options.hash,
      readonly: true,
      tooltip: 'You are unable to perform this action'
    }
  })
}

export const Link = options => {
  return Container({
    ...options,
    hash: {
      ...options.hash,
      disabled: true,
      elementClassName: classNames('disabled unauthorized', options.hash.elementClassName),
      tooltip: 'You are unable to perform this action'
    }
  })
}

export const Text = options => {
  return Container({
    ...options,
    hash: {
      ...options.hash,
      omitHTML: true
    }
  })
}

export default Component
