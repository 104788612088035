/* global Account */

import { createSelector } from 'reselect'
import { getCurrentAccountSelector, getCurrentUserSelector } from './index'
import { filterActiveUsers } from 'modules/userFiltering'
import orderBy from 'modules/orderBy'

const rewindUserV3ToOrig = (user) => ({ ...user, name: user.full_name, active: user.deleted_at === null })

export const getFutureChangesSelector = state => state.modern.currentAccount.future_changes

export const getFutureDowngrades = createSelector(
  getFutureChangesSelector,
  futureChanges => futureChanges.filter(futureChange => futureChange.change_type === 'downgrade')
)

export const getFutureLicenseDowngrades = createSelector(
  getFutureDowngrades,
  futureDowngrades => futureDowngrades.filter(futureDowngrade => futureDowngrade.change_options &&
    futureDowngrade.change_options.license_count)
)

export const getDealPipelines = state => state.modern.currentAccount.deal_pipelines
export const getDefaultDealPipeline = createSelector(
  getDealPipelines,
  pipelines => pipelines.find(pipeline => pipeline.default)
)
export const getDealRevenueTypes = state => state.modern.currentAccount.revenue_types
export const getDealLossReasons = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_loss_reasons
)

export const getDealLossReasonRequired = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_loss_reason_required
)

export const getDealStatuses = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_statuses
)

export const getShowDealLossReasonDialog = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.show_deal_loss_reason_dialog
)

export const getShowDealLossReasonDealProfile = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.show_deal_loss_reason_deal_profile
)

export const getDealWonReasons = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_won_reasons
)
export const getDealWonReasonRequired = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_won_reason_required
)
export const getShowDealWonReasonDialog = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.show_deal_won_reason_dialog
)

export const getShowDealWonReasonDealProfile = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.show_deal_won_reason_deal_profile
)

export const getPersonStatuses = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.lead_statuses
)

export const getPersonTags = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.predefined_contacts_tags
)

export const getCompanyTags = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.company_tags
)

export const getDealTags = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_tags
)

export const getLeadSources = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.lead_sources
)

export const getEmailSequenceRecipientStatuses = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.email_sequence_recipient_statuses
)

export const getActivityCategories = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.note_categories
)

export const getActivityCategoriesWithSystemCategories = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.note_categories_with_system_categories
)

export const getNotEditableNoteSystemCategories = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.note_categories_with_system_categories.filter(category => !category.editable)
)

export const getAccountExports = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.account_exports
)

export const getEventCategories = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.event_categories
)

export const getTodoTemplates = state => state.modern.currentAccount.todo_templates
export const getProductLines = state => state.modern.currentAccount.product_lines
export const getAccountPlan = state => state.modern.currentAccount.plan

export const shouldShowDealLossReasonDialog = state => !!state.modern.currentAccount.show_deal_loss_reason_dialog
export const shouldShowDealWonReasonDialog = state => !!state.modern.currentAccount.show_deal_won_reason_dialog
export const shouldShowDealLossReasonDealProfile = state => !!state.modern.currentAccount.show_deal_loss_reason_deal_profile
export const shouldShowDealWonReasonDealProfile = state => !!state.modern.currentAccount.show_deal_won_reason_deal_profile

export const isQuickbooksEnabled = state => state.modern.currentAccount.quickbooks_enabled

export const getDocumentTags = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.document_tags
)

export const getUsers = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.users
)

export const getInactiveUsers = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.inactive_users
)

export const getTeams = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.teams
)

export const getVisibleActiveUsersForCompanyOwners = createSelector(
  getUsers,
  users => {
    const visibleActiveUsersForCompanyOwnerIds = Account.visibleActiveUsersForCompanyOwner
      ? Account.visibleActiveUsersForCompanyOwner.models.map(m => m.id)
      : []
    const activeUsers = filterActiveUsers(users)
    return activeUsers.filter(user => visibleActiveUsersForCompanyOwnerIds.includes(user.id))
  }
)

// duplicates formatting of window.Account.visibleUsersWithTeams
export const getVisibleUsersWithTeams = createSelector(
  getUsers,
  getInactiveUsers,
  getTeams,
  getCurrentUserSelector,
  (users, usersInactive, teams, currentUser) => ([
    ...users.filter(user => currentUser && currentUser.id === user.id).map(user => rewindUserV3ToOrig(user)),
    ...orderBy(teams.map(team => ({ ...team, id: `${team.id}_team`, active: true })), 'name'),
    ...orderBy(users.filter(user => currentUser?.id !== user.id).map(user => rewindUserV3ToOrig(user)), 'full_name'),
    ...orderBy(usersInactive.map(user => rewindUserV3ToOrig(user)), 'full_name')
  ])
)

export const getVisibleUsers = createSelector(
  getUsers,
  getInactiveUsers,
  (users, usersInactive) => ([
    ...orderBy(users.map(user => rewindUserV3ToOrig(user)), 'full_name'),
    ...orderBy(usersInactive.map(user => rewindUserV3ToOrig(user)), 'full_name')
  ])
)

export const getUsersLevelNumber = (state, props) => props.level
export const getUsersLevel = createSelector(
  getCurrentAccountSelector,
  getUsersLevelNumber,
  (currentAccount, level) => currentAccount.users.filter(user => user.level === level)
)

export const getExternalUsers = state => getUsersLevel(state, { level: 5 })

export const getReadOnlyUsers = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.users.filter(user => user.read_only)
)

export const getNonReadOnlyUsers = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.users.filter(user => !user.read_only)
)

export const getAccountDealStages = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_stages
)

export const getPipelineId = (state, props) => props.dealPipelineId

export const getCurrentDealStages = createSelector(
  getAccountDealStages,
  getPipelineId,
  (stages, dealPipelineId) => {
    return !dealPipelineId ? [] : stages.filter(stage => stage.deal_pipeline_id === dealPipelineId)
  }
)

export const getCalendarEntryPriorities = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_priorities
)

export const getForms = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.forms
)

const getFields = (field) => {
  if (!field.hasOwnProperty('children')) return field

  return field.children.map(i => getFields(i)).flat()
}

export const getCompanyForm = createSelector(
  getForms,
  forms => forms.company
)

export const getCompanyFormFields = createSelector(
  getCompanyForm,
  form => form.map(g => getFields(g)).flat()
)

export const getDealForm = createSelector(
  getForms,
  forms => forms.deal
)

export const getDealFormFields = createSelector(
  getDealForm,
  form => form.map(g => getFields(g)).flat()
)

export const getPersonForm = createSelector(
  getForms,
  forms => forms.person
)

export const getPersonFormFields = createSelector(
  getPersonForm,
  form => form.map(g => getFields(g)).flat()
)

export const getFormFieldId = (state, props) => props.formFieldId
export const getCompanyFormField = createSelector(
  getCompanyForm,
  getFormFieldId,
  (form, formFieldId) => {
    return form.map(s => s.children).flat().find(field => field.id === formFieldId)
  }
)

export const getCalendarEntryRecurringEndDateMonths = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_recurring_end_date_months
)

export const getCalendarEntryPostponePeriods = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_postpone_periods.map(period => parseInt(period))
)

export const getCalendarEntryPostponeWeekends = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_postpone_weekends
)

export const getCalendarEntryTaskDueDateRequired = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_task_due_date_required
)

export const getCalendarEntryUseCalendarToPostpone = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.calendar_entry_use_calendar_to_postpone
)

export const getTodoTemplatesIgnoreWeekends = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.todo_templates_ignore_weekends
)

export const getDealCustomFieldLabels = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.deal_custom_field_labels
)

export const getPersonCustomFieldLabels = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.person_custom_field_labels
)

export const getCompanyCustomFieldLabels = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.company_custom_field_labels
)

export const getCustomFieldGroups = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.custom_field_groups
)

export const getDealCustomFieldGroups = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.custom_field_groups.filter(item => item.type === 'DealCustomFieldGroup')
)

export const getPersonCustomFieldGroups = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.custom_field_groups.filter(item => item.type === 'PersonCustomFieldGroup')
)

export const getCompanyCustomFieldGroups = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.custom_field_groups.filter(item => item.type === 'CompanyCustomFieldGroup')
)

export const getCurrencies = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.currencies
)

export const getUpgradeToGrowUrl = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.upgrade_to_grow
)

export const getUpgradeToDevelopUrl = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.upgrade_to_develop
)

export const getConditionalFields = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.conditional_fields
)

export const getConditionalFieldsDefaults = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.conditional_fields_defaults
)

export const getMergeTags = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.merge_tags
)

export const getMfaEnforced = createSelector(
  getCurrentAccountSelector,
  currentAccount => currentAccount.mfa_enforced
)
