import { createSelector } from 'reselect'
import { getTodoTemplates } from 'selectors/Account'
import { getCompanyChildrenCompanies } from './children_companies'

export const getCompanySelector = state => state.companies.company
export const getCompanyIdSelector = state => state.companies.companyId
export const getCompanyFetchStatus = state => state.companies.fetching
export const getCompanyReloadingSelector = state => state.companies.reloading

export const getCompanyTodoTemplates = createSelector(
  [getTodoTemplates],
  (templates) => {
    return templates.filter(template => template.template_type === 'companies' || template.todo_template_types.find((types) => types.template_type === 'companies'))
  }
)

export const getChildrenCompaniesAndSelf = createSelector(
  [getCompanySelector, getCompanyChildrenCompanies],
  (company, childrenCompanies) => {
    return [company, ...childrenCompanies]
  }
)
