/* global trackableEvent, PipelineDeals, jQuery, App, FroalaEditor */

import React from 'react'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'
import createReactClass from 'create-react-class'

import MergeTagsContent from 'Email/components/merge_tags/MergeTagsContent'

const WysiwygEditor = createReactClass({
  displayName: 'Email.WysiwygEditor',

  propTypes: {
    html: PropTypes.string,
    mergeTags: PropTypes.bool,
    editorClass: PropTypes.string,
    editorId: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyUp: PropTypes.func,
    onMount: PropTypes.func,
    iframe: PropTypes.bool,
    replacePTagsWithDiv: PropTypes.bool,
    editingTemplate: PropTypes.bool,
    forDeal: PropTypes.bool,
    heightMin: PropTypes.number,
  },

  getDefaultProps: function () {
    return {
      html: ' ',
      mergeTags: false,
      editorClass: 'froala-editor',
      editorId: 'froala_editor',
      onChange: null,
      onClick: null,
      onKeyUp: null,
      onMount: null,
      iframe: true,
      replacePTagsWithDiv: true,
      forDeal: false,
      editorNode: null,
      heightMin: 400
    }
  },

  getInitialState: function () {
    this.editorNode = null

    return {
      html: this.props.html
    }
  },

  componentDidMount: function () {
    this.initializeEditor()
    if (this.props.onMount) {
      this.props.onMount(this)
    }
  },

  componentWillUnmount: function () {
    try {
      this.editorNode.destroy()
    } catch (error) {

    }
  },

  componentDidUpdate: function (_prevProps, prevState) {
    if (this.state.html !== prevState.html && this.editorNode.html) {
      this.editorNode.html.set(this.replacePWithDiv(this.state.html), true)
      this.syncAndClean()
    }
  },

  render: function () {
    return React.createElement(
      'div',
      { id: this.props.editorId, className: this.props.editorClass, ref: 'editor' }
    )
  },

  initializeEditor: function () {
    this.mergeTagsSupport()

    const MAX_LENGTH = 65000

    var buttons = this.buttons(this.props.mergeTags)

    this.editorNode = new FroalaEditor(this.refs.editor, {
      heightMin: this.props.heightMin,
      attribution: false,
      toolbarSticky: false,
      toolbarButtons: buttons,
      toolbarButtonsMD: buttons,
      toolbarButtonsSM: buttons,

      charCounterCount: Boolean(this.props.editingTemplate),
      charCounterMax: MAX_LENGTH,

      fontFamily: this.fontFamilies(),
      fontSize: this.fontSizes(),

      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      linkInsertButtons: ['linkBack'],

      imagePaste: false,
      imageInsertButtons: ['imageUpload', 'imageByURL'],
      imageDefaultAlign: 'left',
      imageUploadRemoteUrls: false,
      imageMaxSize: 1024 * 1024 * 5,

      // The default Froala option for htmlRemoveTags includes 'style'
      // By allowing style tags, users can include media queries in their emails to support responsive design
      htmlRemoveTags: ['script'],

      imageUploadToS3: {
        bucket: PipelineDeals.aws.s3_bucket_name,
        region: 's3',
        keyStart: `connect_images/${window.Account.id}/${window.User.id}/`,
        params: {
          acl: 'public-read',
          AWSAccessKeyId: PipelineDeals.aws.access_key_id
        }
      },

      pastePlain: true,

      placeholderText: null,
      useClasses: false,
      enter: FroalaEditor.ENTER_DIV,

      fullPage: this.props.iframe,
      theme: 'pipelinedeals',

      key: 'kRB4zB3B2B1B1C1C1F1rXYb1VPUGRHYZNRJd1JVOOb1HAc1zG2B1A2B1D6C1C1C4F1D4==',
      quickInsertEnabled: false,
      events: {
        contentChanged: (e) => {
          this.handleChange(e, this.editorNode)
          this.editorNode.size.syncIframe()
        },
        'image.beforeUpload': (images) => {
          jQuery.ajax({
            url: '/connect/image_upload_auth',
            type: 'POST',
            async: false,
            success: (data) => {
              this.editorNode.opts.imageUploadToS3.params.policy = data.policy
              this.editorNode.opts.imageUploadToS3.params.signature = data.signature
            }
          })
        },
        'image.uploadedToS3': () => trackableEvent.email.imageUploadedToEmail(),

        // Froala by default only sets the CSS styling for images
        // Unfortunately email clients don't respect CSS styling
        // This should add the width and height attributes directly to the images
        'image.inserted': ($img, response) => {
          if (this.props.editingTemplate) {
            window.trackableEvent.email.templateInsertImage({})
          } else {
            window.trackableEvent.email.insertImage({})
          }

          $img.attr({ width: parseInt($img.css('width'), 10) })
          if (parseInt($img.css('height'), 10) > 0) {
            $img.attr({ height: parseInt($img.css('height'), 10) })
          }
        },
        'image.resizeEnd': ($img, response) => {
          $img.attr({ width: parseInt($img.css('width'), 10) })
          if (parseInt($img.css('height'), 10) > 0) {
            $img.attr({ height: parseInt($img.css('height'), 10) })
          }
        },
        'charCounter.update': () => {
          if (this.editorNode?.charCounter?.count() >= MAX_LENGTH) {
            jQuery('.fr-counter').addClass('error')
          } else {
            jQuery('.fr-counter').removeClass('error')
          }
        },

        'image.error': (error, response) => {
          const errorMessageContainer = this.editorNode.popups.get('image.insert').find('.fr-image-progress-bar-layer h3')
          let errorMessage

          switch (error.code) {
            case 1:
              errorMessage = 'Image cannot be loaded from the passed link'
              break
            case 2:
              errorMessage = 'No link in upload response'
              break
            case 3:
              errorMessage = 'Error during file upload'
              break
            case 4:
              errorMessage = 'Parsing response failed'
              break
            case 5:
              errorMessage = 'File is too large'
              break
            case 6:
              errorMessage = 'Image file type is invalid'
              break
            case 7:
              errorMessage = 'Files can be uploaded only to same domain in IE 8 and IE 9'
              break
            default:
              errorMessage = 'Something went wrong'
              break
          }

          errorMessageContainer.text(errorMessage)
        },

        'commands.after': function (cmd) {
          switch (cmd) {
            case 'linkInsert':
              window.trackableEvent.email.insertHyperlink({})
              break
            case 'mergeTags':
              window.trackableEvent.email.addBodyMergeTag({})
              break
            case 'bold':
            case 'italic':
            case 'underline':
            case 'paragraphFormat':
            case 'applytextColor':
            case 'fontFamily':
            case 'fontSize':
              window.trackableEvent.email.applyFontFormatting({})
              break
          }
        },

        // For some reason we must manually call syncIframe to adjust iframe sizing
        keydown: (e) => (e.keyCode === 13) && this.editorNode.size.syncIframe(),
        keyup: (e) => this.handleKeyUp(e, this.editorNode),
        click: (e) => this.handleClick(e, this.editorNode)
      }
    }, () => {
      const inputHtml = this.replacePWithDiv(this.state.html)
      if (inputHtml.match(/^<!DOCTYPE html>/i)) {
        this.editorNode.html.set(inputHtml)
      } else {
        // Need to use html.insert rather than html.set due to `fullPage: true` Froala setting
        this.editorNode.html.insert(inputHtml, true)
      }

      this.editorNode.size.syncIframe()
    })

    this.syncAndClean()
  },

  mergeTagsSupport: function () {
    var _this = this

    FroalaEditor.DefineIcon('mergeTagIcon', { NAME: 'tags', SVG_KEY: 'tags' })
    FroalaEditor.RegisterCommand('mergeTags', {
      title: 'Merge Tags',
      type: 'dropdown',
      icon: 'mergeTagIcon',
      undo: true,
      focus: true,
      refreshAfterCallback: false,
      html: function () {
        return _this.renderTagOptions()
      },
      callback: function (cmd, val) {
        this.html.insert(val)
      }
    })
  },

  handleChange: function (e, editor) {
    if (this.props.onChange) {
      this.props.onChange(e, editor)
    }
  },

  handleClick: function (e, editor) {
    if (this.props.onClick) {
      this.props.onClick(e, editor)
    }
  },

  handleKeyUp: function (e, editor) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e, editor)
    }
  },

  buttons: function (withMergeTags) {
    return [
      ['bold', 'italic', 'underline'],
      ['paragraphFormat', 'textColor', 'fontFamily', 'fontSize'],
      ['insertLink', 'insertImage', withMergeTags ? 'mergeTags' : null],
      ['align', 'formatOL', 'formatUL', 'outdent', 'indent'],
      ['undo', 'redo'],
      ['html']
    ]
  },

  fontFamilies: function () {
    return {
      'Arial,sans-serif': 'Arial',
      "'Comic Sans MS'": 'Comic Sans',
      Impact: 'Impact',
      Tahoma: 'Tahoma',
      "'Trebuchet MS'": 'Trebuchet MS',
      Georgia: 'Georgia',
      "'Times New Roman',Times,serif": 'Times New Roman'
    }
  },

  fontSizes: function () {
    return [
      '8', '9', '10', '11',
      '12', '14', '16',
      '18', '24', '30', '36',
      '48', '60', '72', '96'
    ]
  },

  getText: function () {
    return this.editorNode.$el.text().trim()
  },

  setHtml: function (html) {
    const updatedHtml = this.replacePWithDiv(html)
    this.setState({ html: updatedHtml })

    return updatedHtml
  },
  insertHtml: function (html) {
    this.editorNode.html.insert(this.replacePWithDiv(html), true)
    this.setState({ html: this.editorNode.html.get() })

    return this.editorNode.html.get()
  },
  getHtml: function () {
    // Need to trigger form submit, so that changes from code view are persisted
    this.editorNode.events.trigger('form.submit')
    var body = this.editorNode.html.get()

    // Since we disable `useClasses` in Froala, Froala converts the user applied `class` to `fr-original-class`
    // We need to convert it back to `class` so that the email client can render the styles
    return body.replaceAll('fr-original-class', 'class')
  },
  setFocus: function () {
    this.editorNode.events?.focus()
  },
  replacePWithDiv: function (html) {
    if (!this.props.replacePTagsWithDiv) {
      return html
    }
    return html.replace(/(<p)/igm, '<div').replace(/<\/p>/igm, '</div>').trim()
  },

  // We could potentially have nested <div> tags that break Froala editing.
  unNestDivTags: function (html) {
    var openingDivMatch = /<div(.*)>\s*<div(.*)>/igm
    var openingDivReplace = '<div$1$2>'

    var closingDivMatch = /<\/div>(\s*)<\/div>/igm
    var closingDivReplace = '</div>'

    return html.replace(
      openingDivMatch,
      openingDivReplace
    ).replace(
      closingDivMatch,
      closingDivReplace
    )
  },

  syncAndClean: function () {
    setTimeout(function () {
      this.editorNode.html.cleanEmptyTags()
      if (this.props.iframe) {
        this.editorNode.size.syncIframe()
      }
      this.setFocus()
    }.bind(this), 0)
  },

  getSelectedText: function () {
    return this.editorNode.selection.text()
  },

  renderTagOptions: function () {
    const { forDeal } = this.props

    return ReactDOMServer.renderToStaticMarkup(<MergeTagsContent forDeal={forDeal} forWysiwyg />)
  }
})

App.Components.Email.WysiwygEditor = WysiwygEditor
export default WysiwygEditor
