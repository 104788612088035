import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Children, cloneElement } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import permissible from 'permissions'

const Disabled = styled.div`
  .btn-pld-default {
    margin-left: 0;
  }
`

const Content = ({
  children,
  className,
  cypress,
  disabled,
  // elementName = 'div',
  elementClassName,
  excludeDisabledClassName,
  omitHTML,
  omitParent,
  omittedClassName,
  readonly,
  tooltip
}) => {
  let _content

  if (omitHTML) {
    // _content = element.text()
  } else if (omitParent) {
    _content = <span className={omittedClassName}>{children.props.children}</span>
  } else {
    _content = React.cloneElement(children, {
      className: classNames(children.props.className, elementClassName),
      disabled: disabled,
      readOnly: readonly
    })
  }

  return (
    <Disabled
      className={classNames({ disabled: !excludeDisabledClassName }, className)}
      data-cypress={cypress}
      data-for={cypress}
      data-tip={tooltip}
    >
      {_content}
      {tooltip && <ReactTooltip class="clickableTooltip" delayHide={200} id={cypress}>{tooltip}</ReactTooltip>}
    </Disabled>
  )
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cypress: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  elementClassName: PropTypes.string,
  excludeDisabledClassName: PropTypes.bool,
  omitHTML: PropTypes.bool,
  omitParent: PropTypes.bool,
  omittedClassName: PropTypes.string,
  readonly: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

Content.defaultProps = {
  cypress: 'ah-ah-ah-you-didnt-say-the-magic-word',
  tooltip: 'You are unable to perform this action'
}

const Permissible = ({ action, children, cypress, object, objectType, policy, user, ...rest }, { currentUser }) => {
  if (!user) user = currentUser
  const child = Children.only(children)

  if (permissible(user, policy, action, { object, objectType })) {
    return cloneElement(child, { 'data-cypress': cypress })
  } else {
    return (<Content cypress={cypress} {...rest}>{child}</Content>)
  }
}

Permissible.contextTypes = {
  currentUser: PropTypes.object
}

Permissible.propTypes = {
  action: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  cypress: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  omitParent: PropTypes.bool,
  object: PropTypes.object,
  objectType: PropTypes.string,
  policy: PropTypes.string.isRequired,
  user: PropTypes.object
}

Permissible.defaultProps = {
  cypress: 'ah-ah-ah-you-didnt-say-the-magic-word'
}

export const PermissibleButton = props => <Permissible disabled {...props} />
export const PermissibleCheckbox = props => <Permissible disabled {...props} />
export const PermissibleInput = props => <Permissible readonly {...props} />
export const PermissibleLink = ({ elementClassName, ...rest }) => <Permissible disabled="disabled" elementClassName={classNames('disabled unauthorzied', elementClassName)} {...rest} />
PermissibleLink.propTypes = {
  elementClassName: PropTypes.string
}

export default Permissible
