import React, { Component } from 'react'
import PropTypes from 'prop-types'

import QRCode from 'qrcode.react'

import { Modal, SecondaryButton, PrimaryButton, TextInput, P } from '@PipelineDeals/shared-react-components'

import styled from 'styled-components'
const BodyWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`
const CodeWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`
const InputWrapper = styled.div`
  width: 250px;
  margin-top: 15px;
`

class EnableMfaModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      inputValue: ''
    }

    this.enableMfa = this.enableMfa.bind(this)
  }

  enableMfa () {
    const { props: { onSubmit }, state: { inputValue } } = this
    onSubmit(inputValue)
  }

  render () {
    const { onClose, provisioningUri, errorMessage, hideCancelButton } = this.props

    return (
      <Modal
        isOpen
        title="Enable Multi-Factor Authentication"
        onClose={onClose}
        contentStyles={{
          width: '525px',
          top: 'auto',
          right: 'auto',
          bottom: 'auto',
          left: 'auto'
        }}
        footerTrailingContent={
          <>
            {!hideCancelButton && (
              <span style={{ marginRight: '15px' }}>
                <SecondaryButton content="Cancel" onClick={onClose} />
              </span>
            )}
            <PrimaryButton content="Enable" onClick={this.enableMfa} />
          </>
        }
      >
        <BodyWrapper>
          <P>1. Scan the image below with the multi-factor authentication app on your phone</P>

          <CodeWrapper>
            <QRCode
              value={provisioningUri}
              level="M"
              renderAs="svg"
              size={256}
            />
          </CodeWrapper>

          <P>2. After scanning the QR code, use the code generated in your authenticator app and enter it here.</P>

          <InputWrapper>
            <TextInput
              required
              value={this.state.inputValue}
              onChange={e => this.setState({ inputValue: e.target.value })}
              placeholder="XXXXXX"
              pattern="\d{6}"
              errorMsg={errorMessage}
              label="Authenticator Code"
            />
          </InputWrapper>
        </BodyWrapper>
      </Modal>
    )
  }
}

EnableMfaModal.propTypes = {
  errorMessage: PropTypes.string,
  provisioningUri: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  hideCancelButton: PropTypes.bool
}

export default EnableMfaModal
