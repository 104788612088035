export const FETCH_STATES = {
  fetching: {
    fetching: true,
    fetched: false,
    fetchError: null
  },
  fetchSuccess: {
    fetching: false,
    fetched: true,
    fetchError: null
  },
  fetchError: {
    fetching: false,
    fetched: false,
    fetchError: ''
  }
}
