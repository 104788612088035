import * as constants from './constants'

export const fetchProfileSettings = payload => ({
  payload,
  type: constants.MY_PROFILE_SETTINGS_FETCH
})

export const fetchProfileSettingsSuccess = payload => ({
  payload,
  type: constants.MY_PROFILE_SETTINGS_FETCH_SUCCESS
})
