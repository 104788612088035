import * as constants from '../constants'

const initialState = {
  account: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.ACCOUNT_UPDATE:
      return {
        ...state,
        account: state.modern.currentAccount
      }
    default:
      return state
  }
}
