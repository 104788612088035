export default {
  activityCategories: [
    'create',
    'delete',
    'edit',
    'reorder'
  ],

  addEditForms: [
    'makeHidden',
    'makeRequired',
    'createFieldOrdering'
  ],

  advancedUserViz: [
    'removeCustomFieldPermission'
  ],

  colorsAndBranding: [
    'saveBranding',
    'uploadLogo'
  ],

  conditionalFields: [
    'clickCloneRule',
    'clickCreateNewRule',
    'clickDeleteRule',
    'clickSaveRule',
    'clickToggleRule'
  ],

  currencies: [
    'add'
  ],

  customFields: [
    'create',
    'createCustomGroup',
    'move',
    'openManageModal'
  ],

  dateAndTime: [
    'editDateFormat',
    'editTimeFormat'
  ],

  dealLossReasons: [
    'create',
    'require',
    'showDialog'
  ],

  dealPipelines: [
    'create',
    'delete',
    'edit',
    'openManageModal'
  ],

  dealStages: [
    'create',
    'move'
  ],

  dealStatuses: [
    'create',
    'delete',
    'edit'
  ],

  dealTags: [
    'create',
    'delete',
    'edit'
  ],

  email: [
    'editAllowSendTrackedEmails',
    'editAutomaticAttachmentCreation',
    'editAutomaticNoteCreation'
  ],

  eventTypes: [
    'create',
    'delete',
    'edit',
    'reorder'
  ],

  fileTags: [
    'create',
    'delete',
    'edit'
  ],

  general: [
    'clickMenuLink'
  ],

  globalNavigation: [
    'edit',
    'hide',
    'show'
  ],

  instantDocs: [
    'clickAddNewTemplate',
    'clickPreviewButton',
    'clickSaveNewTemplate',
    'clickSaveTemplate',
    'documentUploadError',
    'documentUploadSuccess',
    'toggleEnabledForCheckbox'
  ],

  leadSources: [
    'create',
    'delete',
    'edit'
  ],

  performanceLanes: [
    'create'
  ],

  personStatuses: [
    'create',
    'delete',
    'edit'
  ],

  personTags: [
    'create',
    'delete',
    'edit'
  ],

  postponePeriods: [
    'create',
    'delete',
    'skipWeekends',
    'useCalendarToPostpone'
  ],

  priorities: [
    'create',
    'delete',
    'edit',
    'reorder'
  ],

  recurringTasks: [
    'duration'
  ],

  revenueTypes: [
    'create',
    'delete',
    'edit'
  ],

  sharedFiles: [
    'create'
  ],

  teamManagement: [
    'create',
    'editVisibility'
  ],

  users: [
    'loginAuditReport',
    'reassignData',
    'requestMFA'
  ],

  todoTemplates: [
    'create',
    'delete',
    'edit',
    'skipWeekends'
  ],

  companyTags: [
    'create',
    'delete',
    'edit'
  ]
}
