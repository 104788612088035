import { takeEvery, takeLatest } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import PipelineDeals from 'PipelineDeals'

import { PRELOAD_APP } from 'ducks'

import * as actions from '../actions'
import * as constants from '../constants'

import sortByPosition from 'modules/sortByPosition'

const pd = new PipelineDeals({}, '/api/v3')

export function * fetchCustomFieldsSaga () {
  try {
    const customFieldGroups = yield call([pd, pd.customFieldGroups])
    const groups = yield call([customFieldGroups, customFieldGroups.search])

    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    const labels = yield call([customFieldLabels, customFieldLabels.search])

    const { pagination: { pages, per_page } } = labels
    let { entries: concatenatedLabels, pagination: { page, total } } = labels

    while (page <= pages) {
      if (page > 1) {
        const response = yield call([customFieldLabels, customFieldLabels.search], { page, per_page })
        concatenatedLabels = concatenatedLabels.concat(response.entries)
      }

      page++
    }

    concatenatedLabels.forEach(label => {
      if (label.custom_field_label_dropdown_entries) {
        label.custom_field_label_dropdown_entries.sort(sortByPosition)
      }
    })

    yield put(actions.receiveCustomFields({ groups: groups.entries, labels: concatenatedLabels, total }))
  } catch (e) {
    console.error(e)
  }
}

export function * watchFetchCustomFieldsSaga () {
  yield call(takeEvery, constants.FETCH_CUSTOM_FIELDS, fetchCustomFieldsSaga)
}

export function * watchSuccessCustomFieldSaga () {
  yield call(
    takeLatest,
    [
      constants.DELETE_CUSTOM_FIELD_LABEL_SUCCESS,
      constants.DELETE_CUSTOM_FIELD_GROUP_SUCCESS,
      constants.EDIT_CUSTOM_FIELD_GROUP_FORM_SUCCESS,
      constants.EDIT_CUSTOM_FIELD_LABEL_FORM_SUCCESS,
      constants.NEW_CUSTOM_FIELD_GROUP_FORM_SUCCESS,
      constants.NEW_CUSTOM_FIELD_LABEL_FORM_SUCCESS,
      constants.REPOSITION_CUSTOM_FIELD_LABEL_SUCCESS,
      constants.UPDATE_CUSTOM_FIELD_LABEL_SUCCESS
    ],
    successCustomFieldSaga
  )
}

export function * successCustomFieldSaga () {
  try {
    yield call(() => { window.Account.resync(false, false) })
    yield put({ type: PRELOAD_APP })
  } catch (e) {
    console.error(e)
  }
}

export * from './Group'
export * from './Label'
