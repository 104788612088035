import React, { useMemo } from 'react'

import {
  SectionTitle,
  Row,
  Left,
  RecordIcon,
  Right,
  Owner,
  InterpunctJoin
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'
import Name from 'components/BusinessCard/components/Name'

const Deal = () => {
  const {
    cardMeta: {
      showOnlyFields
    },
    resource: deal
  } = useBusinessCard()

  const showOwner = useMemo(() => showOnlyFields.includes('user') || showOnlyFields.includes('owner') || showOnlyFields.includes('team_id'), [showOnlyFields])
  const ownerItems = useMemo(() => [showOnlyFields.includes('user') || showOnlyFields.includes('owner') ? `${deal.user.first_name} ${deal.user.last_name}` : null, showOnlyFields.includes('team_id') ? deal.team?.name : null].filter(Boolean), [deal, showOnlyFields])

  return (
    <>
      <SectionTitle>
        <Row>
          <Left>
            <RecordIcon className="fas fa-briefcase" />
          </Left>

          <Right data-cypress="business-card-deal-name" fullWidth>
            <Name />

            {showOwner && ownerItems.length > 0 && (
              <Owner className="owner">
                <InterpunctJoin items={ownerItems} />
              </Owner>
            )}
          </Right>
        </Row>
      </SectionTitle>
    </>
  )
}

export default Deal
