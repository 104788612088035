export const TODO_TEMPLATE_CREATE = 'TODO_TEMPLATE_CREATE'
export const TODO_TEMPLATE_CREATE_SUBMIT = 'TODO_TEMPLATE_CREATE_SUBMIT'
export const TODO_TEMPLATE_CREATE_FAILURE = 'TODO_TEMPLATE_CREATE_FAILURE'

export const TODO_TEMPLATE_UPDATE = 'TODO_TEMPLATE_UPDATE'
export const TODO_TEMPLATE_UPDATE_SUBMIT = 'TODO_TEMPLATE_UPDATE_SUBMIT'
export const TODO_TEMPLATE_UPDATE_FAILURE = 'TODO_TEMPLATE_UPDATE_FAILURE'

export const TODO_TEMPLATE_DELETE = 'TODO_TEMPLATE_DELETE'
export const TODO_TEMPLATE_DELETE_SUBMIT = 'TODO_TEMPLATE_DELETE_SUBMIT'
export const TODO_TEMPLATE_DELETE_FAILURE = 'TODO_TEMPLATE_DELETE_FAILURE'

export const TODO_TEMPLATE_REMOVE_TASK = 'TODO_TEMPLATE_REMOVE_TASK'

export const TODO_TEMPLATE_TYPES = [
  {
    label: 'Company',
    value: 'companies'
  },
  {
    label: 'Customer',
    value: 'customers'
  },
  {
    label: 'Deal',
    value: 'deals'
  },
  {
    label: 'Person',
    value: 'people'
  }
]

export const TODO_TEMPLATE_IGNORE_WEEKENDS = 'TODO_TEMPLATE_IGNORE_WEEKENDS'
