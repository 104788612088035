const injectRouterMiddleware = store => next => action => {
  if (action.injectRouter) {
    const { injectRouter, ...rest } = action // eslint-disable-line no-unused-vars

    action = {
      ...rest,
      router: store.getState().router
    }
  }

  return next(action)
}

export default injectRouterMiddleware
