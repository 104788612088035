import {
  ACTIVITY_CATEGORY_CREATE,
  ACTIVITY_CATEGORY_CREATE_FAILURE,
  ACTIVITY_CATEGORY_UPDATE,
  ACTIVITY_CATEGORY_UPDATE_FAILURE,
  ACTIVITY_CATEGORY_DELETE,
  ACTIVITY_CATEGORY_DELETE_FAILURE
} from '../constants'

export const activityCategoryCreate = payload => ({ payload, type: ACTIVITY_CATEGORY_CREATE })
export const activityCategoryCreateFailure = payload => ({ payload, type: ACTIVITY_CATEGORY_CREATE_FAILURE })
export const activityCategoryUpdate = payload => ({ payload, type: ACTIVITY_CATEGORY_UPDATE })
export const activityCategoryUpdateFailure = payload => ({ payload, type: ACTIVITY_CATEGORY_UPDATE_FAILURE })
export const activityCategoryDelete = payload => ({ payload, type: ACTIVITY_CATEGORY_DELETE })
export const activityCategoryDeleteFailure = payload => ({ payload, type: ACTIVITY_CATEGORY_DELETE_FAILURE })
