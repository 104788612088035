import * as constants from 'Goals/constants/ProductLines'

const initialState = {
  goal: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.PRODUCT_LINE_GOAL_FETCH_SUCCESS:
      return {
        ...state,
        goal: action.payload
      }
    default:
      return state
  }
}
