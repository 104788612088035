/* global User, PpdStorage, _, jQuery */

const pipelineMixpanel = {
  isReady: false,
  readyFunctions: [],
  syncSuperproperties: function () {
    if (!User.isControlledBySu) {
      if (pipelineMixpanel._shouldUpdate()) {
        jQuery.getJSON('/api/internal/mixpanel/user_properties.json', pipelineMixpanel._updateSuperproperties)
      } else {
        pipelineMixpanel.ready()
      }
    }
  },
  whenReady: function (fn) {
    this.readyFunctions.push(fn)
  },
  ready: function () {
    this._setMixpanelUser(PpdStorage.get('mixpanel_superproperties'))
    this.isReady = true
    _.each(this.readyFunctions, function (fn) { fn() })
  },
  _updateSuperproperties: function (data) {
    PpdStorage.set('mixpanel_superproperties', data)
    pipelineMixpanel._touchLastUpdated()
    pipelineMixpanel.ready()
  },
  _setMixpanelUser: function (data) {
    window.mixpanel.people.set(data)
  },
  _shouldUpdate: function () {
    if (_.isUndefined(this._lastUpdated())) { return true }
    if (this._isOverAnHour(Date.now() - this._lastUpdated())) { return true }
    return false
  },
  _isOverAnHour: function (dateDiff) {
    return (Math.floor(dateDiff / 1000 / 60 / 60) > 1)
  },
  _lastUpdated: function () {
    return PpdStorage.get('mixpanel_superproperties_updated')
  },
  _touchLastUpdated: function () {
    PpdStorage.set('mixpanel_superproperties_updated', Date.now())
  }
}

window.pipelineMixpanel = pipelineMixpanel
module.exports = pipelineMixpanel
