import { createSelector } from 'reselect'
import { getTodoTemplates } from 'selectors/Account'

export const getPersonSelector = state => state.people.person
export const getPersonIdSelector = state => state.people.personId
export const getPersonDealsSelector = state => state.people.deals
export const getPersonCompanyIdSelector = state => state.people.person.company_id
export const getPersonCompanyNameSelector = state => state.people.person.company_name
export const getPersonReloadingSelector = state => state.people.reloading

export const getPersonTodoTemplates = createSelector(
  [getTodoTemplates],
  (templates) => {
    return templates.filter(template => template.template_type === 'people' || template.todo_template_types.find((types) => types.template_type === 'people'))
  }
)

export const getPersonFetchStatus = state => state.people.fetching
