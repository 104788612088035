import { createSelector } from 'reselect'
import _ from 'underscore'

import arrayIntersection from 'modules/arrayIntersection'
import orderBy from 'modules/orderBy'
import dateFilter from 'modules/dateFilter'

import { FILTER_NONE, FILTER_HAS_VALUE } from 'Profiles/constants/filters'

export const getCompanyChildrenCompanies = state => state.companies.childrenCompanies
export const getChildrenCompaniesSort = state => state.companies.childrenCompaniesSort
export const getChildrenCompaniesSortField = state => state.companies.childrenCompaniesSort.field
export const getChildrenCompaniesSortDir = state => state.companies.childrenCompaniesSort.direction

export const getChildrenCompaniesFilters = state => state.companies.childrenCompaniesFilters
export const getChildrenCompaniesNameFilter = state => state.companies.childrenCompaniesFilters.name
export const getChildrenCompaniesUpdatedAtFilter = state => state.companies.childrenCompaniesFilters.updatedAt
export const getChildrenCompaniesOwnerFilter = state => state.companies.childrenCompaniesFilters.owner
export const getChildrenCompaniesValueFilter = state => state.companies.childrenCompaniesFilters.value

export const getChildrenCompaniesByName = createSelector(
  [getCompanyChildrenCompanies, getChildrenCompaniesNameFilter],
  (companies, nameFilter) => {
    if (!nameFilter.length) return companies
    return companies.filter(company => nameFilter.includes(company.id) || (nameFilter.includes(FILTER_NONE) && !company.id))
  }
)

export const getChildrenCompaniesByUpdatedAt = createSelector(
  [getCompanyChildrenCompanies, getChildrenCompaniesUpdatedAtFilter],
  (companies, updatedAtFilter) => dateFilter({ dateAttribute: 'updated_at', filter: updatedAtFilter, objects: companies })
)

export const getChildrenCompaniesByOwner = createSelector(
  [getCompanyChildrenCompanies, getChildrenCompaniesOwnerFilter],
  (companies, ownerFilter) => {
    if (!ownerFilter.length) return companies
    return companies.filter(company => ownerFilter.includes(company.owner_id) || (ownerFilter.includes(FILTER_NONE) && !company.owner_id))
  }
)

export const getChildrenCompaniesByValue = createSelector(
  [getCompanyChildrenCompanies, getChildrenCompaniesValueFilter],
  (companies, valueFilter) => {
    if (!Object.keys(valueFilter).length) return companies

    const withoutValue = (company) => (_.isNull(company.deals_value) || _.isUndefined(company.deals_value))
    const withValue = company => !withoutValue(company)

    if (valueFilter === FILTER_NONE) return companies.filter(withoutValue)
    if (valueFilter === FILTER_HAS_VALUE) return companies.filter(withValue)

    const fromValue = parseFloat(valueFilter.from)
    const toValue = parseFloat(valueFilter.to)
    return companies.filter(company => {
      const dealValue = parseFloat(company.deals_value)
      return (!fromValue || dealValue >= fromValue) && (!toValue || dealValue <= toValue)
    })
  }
)

export const getFilteredChildrenCompanies = createSelector(
  [getChildrenCompaniesByName, getChildrenCompaniesByUpdatedAt, getChildrenCompaniesByOwner, getChildrenCompaniesByValue, getChildrenCompaniesSortField, getChildrenCompaniesSortDir],
  (nameChildrenCompanies, updatedAtChildrenCompanies, ownerChildrenCompanies, valueCompanies, sortField, sortDir) => {
    return orderBy(
      arrayIntersection(nameChildrenCompanies, updatedAtChildrenCompanies, ownerChildrenCompanies, valueCompanies)
      , sortField, sortDir
    )
  }
)
