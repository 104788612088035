import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  tagCreate,
  tagCreateFailure,
  tagUpdate,
  tagUpdateFailure,
  tagDelete,
  tagDeleteFailure
} from '../actions'

import {
  TAG_CREATE_SUBMIT,
  TAG_UPDATE_SUBMIT,
  TAG_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/${payload.type}_tags`,
      {
        method: 'POST',
        body: JSON.stringify({
          [`${payload.type}_tag`]: {
            name: payload.name
          }
        })
      }
    )
    yield put(tagCreate({ ...response, type: payload.type }))
  } catch (expection) {
    console.error(expection.message)
    yield put(tagCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, TAG_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/${payload.type}_tags/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          [`${payload.type}_tag`]: {
            name: payload.name
          }
        })
      }
    )
    yield put(tagUpdate({ ...response, type: payload.type }))
  } catch (exception) {
    yield put(tagUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, TAG_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/${payload.type}_tags/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(tagDelete(payload))
  } catch (exception) {
    yield put(tagDeleteFailure)
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, TAG_DELETE_SUBMIT, deleteSaga)
}
