/* eslint-disable
    camelcase,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class UserNamePresenter {
  static nameForListing (id, message) {
    if (message == null) { message = '' }
    if (!id) { return message }
    const user = Account.usersIncludingDeleted.get(id)
    if (!user) { return 'Unknown user' }
    if (user.get('deleted_at')) {
      return this.nameWithMarkup(user.fullName())
    } else {
      return user.fullName()
    }
  }

  static nameForProfile (user) {
    if (user.deleted_at) {
      return this.nameWithMarkup(user.full_name)
    } else {
      return user.full_name
    }
  }

  static nameWithMarkup (full_name) {
    return `\
      <span class="inactive_user"> ${full_name}
        <span data-toggle="tooltip" title="inactive">&#8856;</span>
      </span>\
    `
  }
}

module.exports = UserNamePresenter
