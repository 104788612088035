import * as constants from 'MyProfile/constants'

export const initialState = {
  timezoneOptionsForSelect: [],
  accountCurrencies: [],
  userSync: {},
  emailPrefs: {},
  notifPrefs: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.MY_PROFILE_SETTINGS_FETCH_SUCCESS:
      return ({
        ...state,
        ...action.payload
      })
    default:
      return state
  }
}
