import {
  PERSON_STATUS_CREATE,
  PERSON_STATUS_CREATE_FAILURE,
  PERSON_STATUS_UPDATE,
  PERSON_STATUS_UPDATE_FAILURE,
  PERSON_STATUS_DELETE,
  PERSON_STATUS_DELETE_FAILURE
} from '../constants'

export const personStatusCreate = payload => ({ payload, type: PERSON_STATUS_CREATE })
export const personStatusCreateFailure = payload => ({ payload, type: PERSON_STATUS_CREATE_FAILURE })

export const personStatusUpdate = payload => ({ payload, type: PERSON_STATUS_UPDATE })
export const personStatusUpdateFailure = payload => ({ payload, type: PERSON_STATUS_UPDATE_FAILURE })

export const personStatusDelete = payload => ({ payload, type: PERSON_STATUS_DELETE })
export const personStatusDeleteFailure = payload => ({ payload, type: PERSON_STATUS_DELETE_FAILURE })
