export default {
  surveyModal: {
    closeButton: [
      'clicked'
    ],
    nextButton: [
      'clicked'
    ],
    scheduleDemoButton: [
      'clicked'
    ]
  }
}
