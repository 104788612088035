import React, { } from 'react'

import {
  SectionTitle,
  Row,
  Left,
  Right,
  RecordIcon,
  Owner
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

import Avatar from 'components/BusinessCard/components/Avatar'
import Name from 'components/BusinessCard/components/Name'

const Person = () => {
  const {
    cardMeta: {
      showOnlyFields
    },
    callbacks: {
      avatarUpdateCallback
    },
    resource: person
  } = useBusinessCard()

  return (
    <>
      <SectionTitle>
        <Row>
          {avatarUpdateCallback ? (
            <Avatar record={person} recordType="person" callback={avatarUpdateCallback}>
              {!/missing/.test(person.image_thumb_url)
                ? <img src={person.image_thumb_url} alt={person.full_name} />
                : <RecordIcon className="fas fa-user" />}
            </Avatar>
          ) : (
            <Left>
              {!/missing/.test(person.image_thumb_url)
                ? <img src={person.image_thumb_url} alt={person.full_name} />
                : <RecordIcon className="fas fa-user" />}
            </Left>
          )}

          <Right data-cypress="business-card-person-name" fullWidth>
            <Name />

            {showOnlyFields.includes('owner') && (
              <Owner className="owner">
                {person.owner}
              </Owner>
            )}
          </Right>
        </Row>
      </SectionTitle>
    </>
  )
}

export default Person
