import React, { useMemo } from 'react'

import { useBusinessCard } from 'components/BusinessCard/context'

import CustomField from 'components/BusinessCard/components/CustomField'

const CustomFields = () => {
  const { cardMeta: { showOnlyFields }, resourceId } = useBusinessCard()
  const customFields = useMemo(() => showOnlyFields.filter(field => field.includes('custom_label_')), [showOnlyFields])

  if (customFields.length === 0) return null

  return (
    <>
      {customFields.map(field =>
        <CustomField key={`${resourceId}-${field}`} field={field} />
      )}
    </>
  )
}

export default CustomFields
