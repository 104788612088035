import React, { useMemo } from 'react'
import ReactTooltip from 'react-tooltip'

import store from 'store'
import { profilePush } from 'actions/index'
import addEditRecord from 'add_edit_modals'

import { PermissibleButton } from 'components/Permissible'
import { UPDATE, COMPANY } from 'permissions'

import { Section, Row, Left, Right } from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const CompanyName = () => {
  const { cardMeta: { showOnlyFields }, callbacks: { onClose }, resource } = useBusinessCard()

  if (!showOnlyFields.includes('company_name')) return null

  const companyId = useMemo(() => resource.company_id || resource.company?.id, [JSON.stringify(resource)])
  const companyName = useMemo(() => resource.company_name || resource.company?.name, [JSON.stringify(resource)])

  if (!companyId || !companyName) return null

  const openCompanyEditModal = e => {
    e.stopPropagation()
    if (onClose) onClose(false)
    addEditRecord({ resourceType: 'company', resourceId: companyId })
  }

  const handleCompanyClick = event => {
    store.dispatch(profilePush({ event, type: 'Company', id: companyId }))
    if (onClose) onClose(false)
  }

  return (
    <Section data-cypress="business-card-company-name">
      <Row>
        <Left>
          {/* eslint-disable-next-line camelcase */}
          <i className={`fas ${resource.company?.is_customer ? 'fa-users' : 'fa-building'}`} />
        </Left>

        <Right>
          <a href={`/companies/${companyId}`} onClick={handleCompanyClick}>{companyName}{resource.company_is_sample && ' (sample)'}</a>
          <PermissibleButton
            action={UPDATE}
            cypress="bc-edit-company"
            policy={COMPANY}
            user={window.User}
          >
            <button
              className="show-on-hover ui-corner-all btn btn-link normal"
              style={{ padding: 0, paddingLeft: 6 }}
              data-cypress="bc-edit-company"
              onClick={openCompanyEditModal}
            >
              <span
                data-class="tooltip-react tooltip-md"
                data-for="edit-company-tooltip"
                data-html="true"
                data-tip="Edit"
              >
                <i className="fas fa-pencil-alt" style={{ fontSize: '12px' }} />
              </span>
              <ReactTooltip id="edit-company-tooltip" className="react-tooltip" />
            </button>
          </PermissibleButton>
        </Right>
      </Row>
    </Section>
  )
}

export default CompanyName
