import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import { notificationsInlineRemoveTimeout } from 'Notifications/NotificationsInline/actions'
import {
  NOTIFICATIONS_INLINE_ADD,
  NOTIFICATIONS_INLINE_REMOVE_INTERVAL
} from 'Notifications/NotificationsInline/constants.js'

const delay = time => new Promise(resolve => setTimeout(resolve, time))

function * notificationsInlineAddSaga ({ payload }) {
  try {
    yield delay(NOTIFICATIONS_INLINE_REMOVE_INTERVAL)
    yield put(notificationsInlineRemoveTimeout(payload.uuid))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchNotificationsInlineAddSaga () {
  yield call(takeEvery, NOTIFICATIONS_INLINE_ADD, notificationsInlineAddSaga)
}

export const notificationsInlineSagas = [watchNotificationsInlineAddSaga()]
