import { takeEvery } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import * as actions from '../actions'
import * as constants from '../constants'
import { getCustomFieldLabel } from '../selectors'

import cleanCustomFieldLabel from './cleanCustomFieldLabel'
import sortByPosition from 'modules/sortByPosition'

import PipelineDeals from 'PipelineDeals'
const pd = new PipelineDeals({}, '/api/v3')

export function * newCustomFieldLabelSaga ({ payload }) {
  try {
    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    const response = yield call([customFieldLabels, customFieldLabels.create], cleanCustomFieldLabel(payload))

    if (response.custom_field_label_dropdown_entries) {
      response.custom_field_label_dropdown_entries.sort(sortByPosition)
    }

    yield put(actions.newCustomFieldLabelFormSuccess({ label: response }))
  } catch (exception) {
    console.error(exception)

    const json = yield call([exception.response, exception.response.json])
    yield put(actions.newCustomFieldLabelFormFailure({ _error: json }))
  }
}

export function * watchNewCustomFieldLabelSaga () {
  yield call(takeEvery, constants.NEW_CUSTOM_FIELD_LABEL_FORM_SUBMIT, newCustomFieldLabelSaga)
}

export function * editCustomFieldLabelSaga ({ payload }) {
  try {
    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    const response = yield call([customFieldLabels, customFieldLabels.update], cleanCustomFieldLabel(payload))

    if (response.custom_field_label_dropdown_entries) {
      response.custom_field_label_dropdown_entries.sort(sortByPosition)
    }

    const current = yield select(getCustomFieldLabel, { id: payload.id })

    yield put(actions.editCustomFieldLabelFormSuccess({ label: response, previous: current }))
  } catch (exception) {
    console.error(exception)

    const json = yield call([exception.response, exception.response.json])
    yield put(actions.editCustomFieldLabelFormFailure({ _error: json }))
  }
}

export function * watchEditCustomFieldLabelSaga () {
  yield call(takeEvery, constants.EDIT_CUSTOM_FIELD_LABEL_FORM_SUBMIT, editCustomFieldLabelSaga)
}

export function * deleteCustomFieldLabelSaga ({ id }) {
  try {
    const label = yield select(getCustomFieldLabel, { id })

    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    yield call([customFieldLabels, customFieldLabels.delete], id)

    yield put(actions.deleteCustomFieldLabelSuccess(label))
  } catch (exception) {
    console.error(exception)

    yield put(actions.deleteCustomFieldLabelFailure({ _error: exception.message }))
  }
}

export function * watchDeleteCustomFieldLabelSaga () {
  yield call(takeEvery, constants.DELETE_CUSTOM_FIELD_LABEL, deleteCustomFieldLabelSaga)
}

export function * watchMoveCustomFieldLabelCustomFieldGroupSaga () {
  yield call(
    takeEvery,
    constants.MOVE_CUSTOM_FIELD_LABEL_CUSTOM_FIELD_GROUP,
    updateCustomFieldLabelSaga
  )
}

export function * repositionCustomFieldLabelSaga ({ payload: { label, originalPosition } }) {
  try {
    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    const response = yield call([customFieldLabels, customFieldLabels.update], label)

    yield put(actions.repositionCustomFieldLabelSuccess({ label: response, originalPosition }))
  } catch (exception) {
    console.error(exception)

    yield put(actions.repositionCustomFieldLabelFailure({ _error: exception.message }))
  }
}

export function * watchRepositionCustomFieldLabelSaga () {
  yield call(takeEvery, constants.REPOSITION_CUSTOM_FIELD_LABEL, repositionCustomFieldLabelSaga)
}

export function * updateCustomFieldLabelSaga ({ payload }) {
  try {
    const customFieldLabels = yield call([pd, pd.customFieldLabels])
    const response = yield call([customFieldLabels, customFieldLabels.update], cleanCustomFieldLabel(payload))

    if (response.custom_field_label_dropdown_entries) {
      response.custom_field_label_dropdown_entries.sort(sortByPosition)
    }

    const current = yield select(getCustomFieldLabel, { id: payload.id })

    yield put(actions.updateCustomFieldLabelSuccess({ label: response, previous: current }))
  } catch (exception) {
    console.error(exception)

    yield put(actions.updateCustomFieldLabelFailure({ _error: exception.message }))
  }
}

export function * watchUpdateCustomFieldLabelSaga () {
  yield call(takeEvery, constants.UPDATE_CUSTOM_FIELD_LABEL, updateCustomFieldLabelSaga)
}
