import {
  DEAL_STATUS_CREATE,
  DEAL_STATUS_CREATE_FAILURE,
  DEAL_STATUS_UPDATE,
  DEAL_STATUS_UPDATE_FAILURE,
  DEAL_STATUS_DELETE,
  DEAL_STATUS_DELETE_FAILURE
} from '../constants'

export const dealStatusCreate = payload => ({ payload, type: DEAL_STATUS_CREATE })
export const dealStatusCreateFailure = payload => ({ payload, type: DEAL_STATUS_CREATE_FAILURE })

export const dealStatusUpdate = payload => ({ payload, type: DEAL_STATUS_UPDATE })
export const dealStatusUpdateFailure = payload => ({ payload, type: DEAL_STATUS_UPDATE_FAILURE })

export const dealStatusDelete = payload => ({ payload, type: DEAL_STATUS_DELETE })
export const dealStatusDeleteFailure = payload => ({ payload, type: DEAL_STATUS_DELETE_FAILURE })
