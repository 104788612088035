import {
  EVENT_TYPE_CREATE,
  EVENT_TYPE_CREATE_FAILURE,
  EVENT_TYPE_UPDATE,
  EVENT_TYPE_UPDATE_FAILURE,
  EVENT_TYPE_DELETE,
  EVENT_TYPE_DELETE_FAILURE
} from '../constants'

export const eventTypeCreate = payload => ({ payload, type: EVENT_TYPE_CREATE })
export const eventTypeCreateFailure = payload => ({ payload, type: EVENT_TYPE_CREATE_FAILURE })
export const eventTypeUpdate = payload => ({ payload, type: EVENT_TYPE_UPDATE })
export const eventTypeUpdateFailure = payload => ({ payload, type: EVENT_TYPE_UPDATE_FAILURE })
export const eventTypeDelete = payload => ({ payload, type: EVENT_TYPE_DELETE })
export const eventTypeDeleteFailure = payload => ({ payload, type: EVENT_TYPE_DELETE_FAILURE })
