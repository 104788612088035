import * as constants from '../constants'

export const productLineCreate = payload => ({ payload, type: constants.PRODUCT_LINE_CREATE })
export const productLineCreateFailure = payload => ({ payload, type: constants.PRODUCT_LINE_CREATE_FAILURE })

export const productLineUpdate = payload => ({ payload, type: constants.PRODUCT_LINE_UPDATE })
export const productLineUpdateSubmit = payload => ({ payload, type: constants.PRODUCT_LINE_UPDATE_SUBMIT })
export const productLineUpdateFailure = payload => ({ payload, type: constants.PRODUCT_LINE_UPDATE_FAILURE })

export const productLineDelete = payload => ({ payload, type: constants.PRODUCT_LINE_DELETE })
export const productLineDeleteSubmit = payload => ({ payload, type: constants.PRODUCT_LINE_DELETE_SUBMIT })
export const productLineDeleteFailure = payload => ({ payload, type: constants.PRODUCT_LINE_DELETE_FAILURE })
