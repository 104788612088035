import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@PipelineDeals/shared-react-components'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: ${props => props.centered ? 'center' : 'inherit'}
`
const Dot = styled.div`
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 3ex;
  background-color: #${props => props.color}
`

const LeadStatusFormated = ({ name, color, hideName, centered }) => {
  return (
    <Wrapper centered={centered}>
      {hideName &&
        <Tooltip content={name}>
          <Dot color={color} />
        </Tooltip>}

      {!hideName && <Dot color={color} />}
      {!hideName && <span className="name"> {name}</span>}
    </Wrapper>
  )
}

LeadStatusFormated.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hideName: PropTypes.bool,
  centered: PropTypes.bool
}

export default LeadStatusFormated
