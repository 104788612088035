import fetcher from 'modules/fetcher'

const fetchItem = (url) => {
  return fetcher(url, {
    method: 'GET'
  })
}

const selectItem = (type, itemId) => {
  let url = ''
  switch (type) {
    case 'Person':
      url = `/api/v3/people/${itemId}`
      break
    case 'Company':
      url = `/api/v3/companies/${itemId}`
      break
    case 'Deal':
      url = `api/v3/deals/${itemId}`
      break
  }
  return fetchItem(url)
}

export { selectItem }
