export const TRENDS_OVER_TIME = 'trendsOverTime'
export const COMPARE_DEAL_STAGES = 'compareDealStages'
export const COMPARE_DEAL_STATUSES = 'compareDealStatuses'
export const COMPARE_OWNERS = 'compareOwners'
export const COMPARE_DEAL_FIELDS = 'compareDealFields'

export const DEALS_BY_STAGE = 'dealsByStage'
export const ACTIVE_DEALS_BY_STATUS = 'activeDealsByStatus'
export const DEALS_WON = 'dealsWon'
export const FORECASTED_TO_CLOSE = 'forecastedToClose'
export const LOST_DEALS_BY_LOSS_REASON = 'lostDealsByLossReason'
export const WIN_RATIO = 'winRatio'
export const WON_DEALS_BY_SOURCE = 'wonDealsBySource'

export const subsectionsForSection = {
  [DEALS_BY_STAGE]: [COMPARE_DEAL_STAGES, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [ACTIVE_DEALS_BY_STATUS]: [COMPARE_DEAL_STATUSES, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [DEALS_WON]: [TRENDS_OVER_TIME, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [FORECASTED_TO_CLOSE]: [TRENDS_OVER_TIME, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [LOST_DEALS_BY_LOSS_REASON]: [TRENDS_OVER_TIME, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [WIN_RATIO]: [TRENDS_OVER_TIME, COMPARE_OWNERS, COMPARE_DEAL_FIELDS],
  [WON_DEALS_BY_SOURCE]: [TRENDS_OVER_TIME, COMPARE_OWNERS, COMPARE_DEAL_FIELDS]
}

export const exportOptions = [
  { name: 'CSV', value: 'csv' },
  { nane: 'PDF', value: 'pdf' },
  { name: 'Image', value: 'image' }
]

export const subsectionToUrlParamsMapping = {
  [TRENDS_OVER_TIME]: 'trends-over-time',
  [COMPARE_DEAL_STAGES]: 'compare-deal-stages',
  [COMPARE_DEAL_STATUSES]: 'compare-deal-statuses',
  [COMPARE_OWNERS]: 'compare-owners',
  [COMPARE_DEAL_FIELDS]: 'compare-deal-fields'
}

export const sectionToUrlParamsMapping = {
  dealsByStage: 'deals-by-stage',
  activeDealsByStatus: 'active-deals-by-status',
  dealsWon: 'deals-won',
  forecastedToClose: 'forecasted-to-close',
  lostDealsByLossReason: 'lost-deals',
  winRatio: 'win-ratio',
  wonDealsBySource: 'won-deals'
}

export const sectionToQuestionMapping = {
  dealsWon: 'How many deals have been won?',
  forecastedToClose: "What's forecasted to close?",
  lostDealsByLossReason: 'Why are we losing deals?',
  winRatio: "What's the win ratio?",
  wonDealsBySource: 'Where are won deals coming from?',
  dealsByStage: 'Deals by Stage',
  activeDealsByStatus: 'Active Deals by Status'
}

export const urlParamsToSubsectionMapping = {
  'trends-over-time': TRENDS_OVER_TIME,
  'compare-deal-stages': COMPARE_DEAL_STAGES,
  'compare-deal-statuses': COMPARE_DEAL_STATUSES,
  'compare-owners': COMPARE_OWNERS,
  'compare-deal-fields': COMPARE_DEAL_FIELDS
}

export const sectionToText = {
  [DEALS_BY_STAGE]: 'Deals by stage',
  [COMPARE_DEAL_STATUSES]: 'Deals by stage',
  [DEALS_WON]: 'Deals won',
  [FORECASTED_TO_CLOSE]: 'Forecasted to closed',
  [LOST_DEALS_BY_LOSS_REASON]: 'Lost deals by loss reason',
  [WIN_RATIO]: 'Win ratio',
  [WON_DEALS_BY_SOURCE]: 'Won deals by source',
  [ACTIVE_DEALS_BY_STATUS]: 'Active deals by status'
}

export const subsectionToText = {
  [TRENDS_OVER_TIME]: 'Trends Over Time',
  [COMPARE_DEAL_STAGES]: 'Compare Deal Stages',
  [COMPARE_DEAL_STATUSES]: 'Compare Deal Statuses',
  [COMPARE_OWNERS]: 'Compare Owners',
  [COMPARE_DEAL_FIELDS]: 'Compare Deal Types'
}

export const urlParamsToSectionMapping = {
  'deals-by-stage': DEALS_BY_STAGE,
  'active-deals-by-status': ACTIVE_DEALS_BY_STATUS,
  'deals-won': DEALS_WON,
  'forecasted-to-close': FORECASTED_TO_CLOSE,
  'lost-deals': LOST_DEALS_BY_LOSS_REASON,
  'win-ratio': WIN_RATIO,
  'won-deals': WON_DEALS_BY_SOURCE
}

export const timeFrameOptions = [
  { name: 'Any time', value: 'all' },
  { name: 'Today', value: 'today' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'This week', value: 'this_week' },
  { name: 'Last week', value: 'last_week' },
  { name: 'Last 30 days', value: 'last_30' },
  { name: 'This month', value: 'this_month' },
  { name: 'Last month', value: 'last_month' },
  { name: 'This quarter', value: 'this_quarter' },
  { name: 'Last quarter', value: 'last_quarter' },
  { name: 'This year', value: 'this_year' },
  { name: 'Last year', value: 'last_year' },
  { name: 'Custom date range', value: 'custom' }
]
