export const GOALS_PRODUCT_LINES_FILTERS_FETCH = 'GOALS_PRODUCT_LINES_FILTERS_FETCH'
export const GOALS_PRODUCT_LINES_FILTERS_FETCH_SUCCESS = 'GOALS_PRODUCT_LINES_FILTERS_FETCH_SUCCESS'
export const GOALS_PRODUCT_LINES_FILTERS_FETCH_FAILURE = 'GOALS_PRODUCT_LINES_FILTERS_FETCH_FAILURE'

export const GOALS_PRODUCT_LINES_FILTERS_UPDATE = 'GOALS_PRODUCT_LINES_FILTERS_UPDATE'
export const GOALS_PRODUCT_LINES_FILTERS_SECTION_CHANGE = 'GOALS_PRODUCT_LINES_FILTERS_SECTION_CHANGE'

export const GOALS_PRODUCT_LINES_REPORT_FETCH = 'GOALS_PRODUCT_LINES_REPORT_FETCH'
export const GOALS_PRODUCT_LINES_REPORT_FETCH_SUCCESS = 'GOALS_PRODUCT_LINES_REPORT_FETCH_SUCCESS'
export const GOALS_PRODUCT_LINES_REPORT_FETCH_FAILURE = 'GOALS_PRODUCT_LINES_REPORT_FETCH_FAILURE'
export const GOALS_PRODUCT_LINES_FILTERS_GOAL_RESET = 'GOALS_PRODUCT_LINES_FILTERS_GOAL_RESET'

export const PULSE_SECTION = 'PULSE_SECTION'
export const TEAMS_SECTION = 'TEAMS_SECTION'
export const TEAM_MEMBERS_SECTION = 'TEAM_MEMBERS_SECTION'
export const LANES_SECTION = 'LANES_SECTION'
