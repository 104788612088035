import color from 'tinycolor2'
import { colors } from '@PipelineDeals/shared-react-components'

import { CALENDAR_COLORS } from './constants'

export const updatedEventFromDropped = ({ event, start, end, allDay }) => {
  if (event.attributes.type === 'CalendarEvent') {
    return {
      updateAttributes: {
        start_time_date: start.toDateString(),
        start_time_time: start.toTimeString(),
        end_time_date: end.toDateString(),
        end_time_time: end.toTimeString()
      },
      eventReplace: {
        ...event.attributes,
        system_start_time_date: start,
        system_end_time_date: end
      }
    }
  } else if (event.attributes.type === 'CalendarTask') {
    return {
      updateAttributes: {
        due_date: start.toDateString()
      },
      eventReplace: {
        ...event.attributes,
        system_start_time_date: start,
        system_end_time_date: end
      }
    }
  }
}

export const colorStylesForUser = ({ userColors, userId, lighten }) => {
  let userColor =
    (userColors[userId] || CALENDAR_COLORS[userId % CALENDAR_COLORS.length])

  if (lighten) {
    userColor = color(userColor).lighten(7).toString()
  }

  const textUserColor = color(userColor).isLight()
    ? colors.text.primary
    : colors.primary.white
  return {
    backgroundColor: userColor,
    color: textUserColor
  }
}

export const colorStylesForEventType = ({ eventTypeColors, category, eventTypeId }) => {
  const colorId = eventTypeId ? `${category}_${eventTypeId}` : category
  const userColor = eventTypeColors[colorId] || eventTypeColors[category]

  if (!userColor) {
    return {}
  }

  const textUserColor = color(userColor).isLight()
    ? colors.text.primary
    : colors.primary.white
  return {
    backgroundColor: userColor,
    color: textUserColor
  }
}

export const getDefaultFilter = (userId) => ({
  id: null,
  user_ids: [userId],
  user_colors: {},
  event_type_ids: [],
  event_type_colors: {}
})
