import { validEmail } from 'modules/validEmail'

export const formValidation = (fields) => {
  const touchedFields = {}
  let errorMessages = {}

  const fieldNames = Object.keys(fields)
  fieldNames.map(k => {
    if (fields[k] === null || fields[k] === '') {
      touchedFields[k] = false
    } else {
      touchedFields[k] = true
    }
  })

  const touch = (field) => { touchedFields[field] = true }

  const validate = (fields = {}) => {
    const {
      userName,
      emailAddress,
      phoneNumber,
      demoDate,
      demoTime
    } = fields

    errorMessages = {}

    if (touchedFields.userName && !userName) {
      errorMessages.userName = "Can't be blank"
    }

    if (touchedFields.emailAddress) {
      if (!emailAddress) {
        errorMessages.emailAddress = "Can't be blank"
      } else if (!validEmail(emailAddress)) {
        errorMessages.emailAddress = 'Must be a valid email'
      }
    }

    if (touchedFields.phoneNumber && !phoneNumber) {
      errorMessages.phoneNumber = "Can't be blank"
    }

    if (touchedFields.demoDate && !demoDate) {
      errorMessages.demoDate = "Can't be blank"
    }

    if (touchedFields.demoTime && !demoTime?.value) {
      errorMessages.demoTime = "Can't be blank"
    }

    return errorMessages
  }

  const touchAll = () => {
    fieldNames.map(k => { touchedFields[k] = true })
  }

  const isFormValid = () => {
    return fieldNames.map(name => touchedFields[name]).every(d => d) && !Object.keys(errorMessages).length
  }

  return (
    {
      touchAll: () => touchAll(),
      touch: (field) => touch(field),
      validate: (fields) => validate(fields),
      errorMessages: () => errorMessages,
      isFormValid
    }
  )
}

export default formValidation
