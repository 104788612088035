/* global Helpers */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import store from 'store'

import { profilePush } from 'actions/index'
import sanitizeAndLinkifyHtml from 'modules/sanitizeAndLinkifyHtml'
import { formatNumeric } from 'lib/helpers'

import { PERSON_TYPE, COMPANY_TYPE, DEAL_TYPE } from 'components/BusinessCard/constants'

import { useBusinessCard } from 'components/BusinessCard/context'
import { getCurrentAccountSelector } from 'selectors/index'
import { getDealCustomFieldLabels, getPersonCustomFieldLabels, getCompanyCustomFieldLabels } from 'selectors/Account'
import { Section, Row, Left, Right } from './StyledComponents'

const TRUNCATE_LENGTH = 240

const textFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  const value = (customFields[id] || '').substring(0, TRUNCATE_LENGTH)
  const htmlString = sanitizeAndLinkifyHtml(value)
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />
}

const dateFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  const value = customFields[id]
  if (value) {
    return Helpers.formatDate(value)
  } else {
    return ''
  }
}

const numericFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  const value = customFields[id]
  if (value) {
    return formatNumeric(value)
  } else {
    return ''
  }
}

const currencyFormatter = ({
  currentAccount,
  currentUser,
  customFieldLabel,
  custom_fields: customFields,
  currency_id: currencyId
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  const value = customFields[id]

  if (value) {
    const currency = currentAccount.selected_currencies.find(c => c.id === currencyId) || currentUser.currency
    return Helpers.formatCurrency(
      value,
      currency ? currency.symbol : undefined
    )
  } else {
    return ''
  }
}

const selectFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  let value = customFields[id]

  if ([null, undefined].includes(value)) return null
  value = Array.of(value).flat().filter(Boolean)

  const entry = customFieldLabel.custom_field_label_dropdown_entries.filter(entry =>
    value.includes(entry.id)
  )
  if (!entry) return null

  return entry.map(e => e.name).join(', ')
}

const associationFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  let value = customFields[id]

  if ([null, undefined].includes(value)) return null
  value = Array.of(value).flat().filter(Boolean)

  if (window.User.external) {
    return value.map(v => v.split(':')[0]).join(', ')
  }

  return value.map(v => {
    const displayValue = v.split(':')[0]
    const id = v.split(':')[1]
    return <><a key={id} onClick={e => { e.stopPropagation(); store.dispatch(profilePush({ type: 'Company', id, event: e })) }} href={`/companies/${id}`}>{displayValue}</a></>
  }).map((link, index) => {
    return index < value.length - 1 ? [link, ', '] : [link]
  }).reduce((a, b) => [...a, ...b], [])
}

const booleanFormatter = ({
  customFieldLabel,
  custom_fields: customFields
}) => {
  const id = `custom_label_${customFieldLabel.id}`
  const value = customFields[id]
  switch (value) {
    case true:
      return 'True'
    case false:
      return 'False'
    default:
      return ''
  }
}

const formatForTable = {
  text: textFormatter,
  date: dateFormatter,
  numeric: numericFormatter,
  currency: currencyFormatter,
  forecast: currencyFormatter,
  dropdown: selectFormatter,
  multi_select: selectFormatter,
  single_association: associationFormatter,
  multi_association: associationFormatter,
  boolean: booleanFormatter
}

const CustomField = ({ field }) => {
  const { resource, currentUser, resourceType } = useBusinessCard()
  const currentAccount = useSelector(getCurrentAccountSelector)

  const customFields = {
    [DEAL_TYPE]: useSelector(getDealCustomFieldLabels),
    [PERSON_TYPE]: useSelector(getPersonCustomFieldLabels),
    [COMPANY_TYPE]: useSelector(getCompanyCustomFieldLabels)
  }

  const customFieldsType = customFields[resourceType]

  if (!customFieldsType) return null

  const customFieldLabel = customFieldsType.find(customFieldLabel => `custom_label_${customFieldLabel.id}` === field)

  if (!customFieldLabel) return null

  const value = resource.custom_fields[field]

  if (!value && value !== false) return null

  const customFieldLabelValue = formatForTable[customFieldLabel.output_type]({
    customFieldLabel,
    custom_fields: resource.custom_fields,
    currency_id: resource.currency_id,
    currentAccount,
    currentUser
  })

  if (!customFieldLabelValue || customFieldLabelValue === '') return null

  return (
    <Section>
      <Row>
        <Left style={{ overflow: 'hidden' }}>{customFieldLabel.name}</Left>
        <Right>{customFieldLabelValue}</Right>
      </Row>
    </Section>
  )
}

CustomField.propTypes = {
  field: PropTypes.string
}

export default CustomField
