import {
  TEAM_CREATE,
  TEAM_DELETE,
  TEAM_UPDATE,

  TEAM_DELETE_FORM_FAILURE,
  TEAM_EDIT_FORM_FAILURE,

  TEAM_FETCH_FAILURE,
  TEAM_FETCH_REQUEST,
  TEAM_FETCH_SUCCESS,

  TEAM_NEW_FORM_FAILURE,

  TEAM_USERS_FORM_FAILURE,

  TEAM_USERS_FILTER
} from 'admin/Teams/constants'

export const teamCreate = payload => ({ payload, type: TEAM_CREATE })
export const teamDelete = payload => ({ payload, type: TEAM_DELETE })
export const teamUpdate = payload => ({ payload, type: TEAM_UPDATE })

export const teamDeleteFormFailure = payload => ({ payload, type: TEAM_DELETE_FORM_FAILURE })
export const teamEditFormFailure = payload => ({ payload, type: TEAM_EDIT_FORM_FAILURE })

export const teamFetchFailure = payload => ({ payload, type: TEAM_FETCH_FAILURE })
export const teamFetchRequest = payload => ({ payload, type: TEAM_FETCH_REQUEST })
export const teamFetchSuccess = payload => ({ payload, type: TEAM_FETCH_SUCCESS })

export const teamNewFormFailure = payload => ({ payload, type: TEAM_NEW_FORM_FAILURE })

export const teamUsersFormFailure = payload => ({ payload, type: TEAM_USERS_FORM_FAILURE })

export const teamUsersFilter = payload => ({ payload, type: TEAM_USERS_FILTER })
