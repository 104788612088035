import PermissionsError from 'permissions/PermissionsError'

export const permissible = (policies, user, policy, action, options = {}) => {
  if (window.readOnly) return false

  const policyMatch = policies[policy]
  if (policyMatch === undefined) throw new PermissionsError(`Policy (${policy}) not found`)

  if (typeof policyMatch === 'function') return policyMatch(user, action)

  const actionMatch = policyMatch[action]
  if (actionMatch === undefined) throw new PermissionsError(`Policy (${policy}) action (${action}) not found`)

  return actionMatch(user, options)
}

const makePermissible = policies => (user, policy, action, options = {}) => {
  return permissible(policies, user, policy, action, options)
}

export default makePermissible
