import React from 'react'
import { useDispatch } from 'react-redux'

import { profilePush } from 'actions/index'
import { useBusinessCard } from 'components/BusinessCard/context'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

const Deals = () => {
  const dispatch = useDispatch()
  const { cardMeta: { showOnlyFields }, callbacks: { onClose }, resource } = useBusinessCard()

  if (!showOnlyFields.includes('deals') || !resource.deals?.length) return null

  const handleDealClick = (event, dealId) => {
    dispatch(profilePush({ event, type: 'Deal', id: dealId }))
    if (onClose) onClose(false)
  }

  return (
    <Section>
      {resource.deals.map(deal => (
        <Row key={deal.id}>
          <Left>
            <i className="fas fa-badge-dollar" aria-label="Deal" title="Deal" />
          </Left>
          <Right>
            <a href={`/deals/${deal.id}`} onClick={e => handleDealClick(e, deal.id)}>{deal.name}</a>
          </Right>
        </Row>
      ))}
    </Section>
  )
}

export default Deals
