import * as constants from '../constants'

export const dealPipelinesFetchRequest = payload => ({ payload, type: constants.DEAL_PIPELINES_FETCH_REQUEST })
export const dealPipelinesFetchSuccess = payload => ({ payload, type: constants.DEAL_PIPELINES_FETCH_SUCCESS })
export const dealPipelinesFetchFailure = payload => ({ payload, type: constants.DEAL_PIPELINES_FETCH_FAILURE })

export const dealPipelinesQuotaSuccess = payload => ({ payload, type: constants.DEAL_PIPELINE_QUOTA_SUCCESS })
export const dealPipelinesQuotaFailure = payload => ({ payload, type: constants.DEAL_PIPELINE_QUOTA_FAILURE })

export const dealPipelinesCanAllocate = payload => ({ payload, type: constants.DEAL_PIPELINE_CAN_ALLOCATE })
export const dealPipelinesCanAllocateFailure = payload => ({ payload, type: constants.DEAL_PIPELINE_CAN_ALLOCATE_FAILURE })

export const dealPipelineCreate = payload => ({ payload, type: constants.DEAL_PIPELINE_CREATE })
export const dealPipelineCreateFailure = payload => ({ payload, type: constants.DEAL_PIPELINE_CREATE_FAILURE })

export const dealPipelineUpdate = payload => ({ payload, type: constants.DEAL_PIPELINE_UPDATE })
export const dealPipelineUpdateFailure = payload => ({ payload, type: constants.DEAL_PIPELINE_UPDATE_FAILURE })

export const dealPipelineDelete = payload => ({ payload, type: constants.DEAL_PIPELINE_DELETE })
export const dealPipelineDeleteFailure = payload => ({ payload, type: constants.DEAL_PIPELINE_DELETE_FAILURE })

export const changeDefaultPipeline = (payload) => ({ payload, type: constants.CHANGE_DEFAULT_PIPELINE })

export const dealPipelineSelect = payload => ({ payload, type: constants.DEAL_PIPELINE_SELECT })
