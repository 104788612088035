export default (path) => {
  const fullPath = require(`images/${path}`)
  return assetPath(fullPath)
}

export const fontPath = (path) => {
  const fullPath = require(`fonts/${path}`)
  return assetPath(fullPath)
}

const assetPath = (fullPath) => {
  return fullPath
}
