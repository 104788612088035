import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'

import PipelineDeals from 'PipelineDeals'

import * as accountAdminSagas from 'admin/account/sagas'
import * as customFieldSagas from 'admin/custom_fields/sagas'
import * as pipelineSagas from 'admin/deal_pipelines/sagas'
import * as searchesSagas from 'admin/shared_lists/sagas'
import * as teamSagas from 'admin/Teams/sagas'
import * as userSagas from 'sagas/User'
import { watchFormSubmitSaga } from './formSubmit'
import { watchSubscriptionRequestSaga } from './Subscription'
import * as customerProfileSagas from 'Customers/sagas'
import * as personProfileSagas from 'People/sagas'
import * as dealProfileSagas from 'Deals/sagas'
import * as companyProfileSagas from 'Companies/sagas'
import * as goalsProductLinesReport from 'Goals/sagas/ProductLines/report'
import * as productLineGoals from 'Goals/sagas/ProductLines/persist'
import * as productLineSagas from 'admin/product_lines/sagas'
import * as dealLossReasonsSagas from 'admin/deal_loss_reasons/sagas'
import * as adminDealWonReasonsSagas from 'admin/deal_won_reasons/sagas'
import * as dealWonReasonsSagas from 'reasons/sagas/dealWonReasons'
import * as adminDealStatusesSagas from 'admin/deal_statuses/sagas'
import * as adminPersonStatusesSagas from 'admin/person_statuses/sagas'
import * as adminPersonTagsSagas from 'admin/tags/sagas'
import * as adminSourcesSagas from 'admin/sources/sagas'
import * as adminActivityCategoriesSagas from 'admin/activity_categories/sagas'
import * as accountExportSagas from 'admin/data_exports/sagas'
import * as appRegistrationSagas from 'admin/app_registration/sagas'
import * as adminEventTypesSagas from 'admin/event_types/sagas'
import * as adminTodoTemplatesSagas from 'admin/todo_templates/sagas'
import * as adminDocumentTagsSagas from 'admin/document_tags/sagas'
import * as otherEmailsSagas from 'MyProfile/components/email/UserOtherEmails/sagas'
import * as calendarSagas from 'Calendar/sagas'
import * as myProfileSagas from 'MyProfile/sagas'
import * as adminCalendarEntryPrioritiesSagas from 'admin/calendar_entry_priorities/sagas'
import * as adminCalendarEntryPostponePeriodsSagas from 'admin/calendar_entry_postpone_periods/sagas'
import { notificationsInlineSagas } from 'Notifications/NotificationsInline/sagas'
import conditionalFieldsSagas from 'admin/conditionalFields/sagas'
import conditionalDropdownsSagas from 'admin/conditionalDropdowns/sagas'
import globalSearchSagas from 'GlobalSearch/sagas'

import {
  PRELOAD_APP,
  loadApp
} from 'ducks'

import { PROFILE_PUSH } from '../constants'
import { quotasSagas } from 'Quotas/sagas'

const userPd = new PipelineDeals({}, '/api/v3')
const accountPd = new PipelineDeals({ exclude_attrs: 'total_company_count' }, '/api/v3')

export function * preloadAppSaga () {
  try {
    const [users, account] = yield [
      call([userPd, userPd.users]),
      call([accountPd, accountPd.account])
    ]

    const [currentUser, currentAccount] = yield [
      call([users, users.get], window.User.id),
      call([account, account.get])
    ]

    yield put(loadApp({ currentUser, currentAccount }))
  } catch (e) {
    console.error(e)
  }
}

export function * watchPreloadAppSaga () {
  yield call(takeEvery, PRELOAD_APP, preloadAppSaga)
}

const urlFor = ({ type, id }) => {
  switch (type) {
    case 'Deal': return `/deals/${id}`
    case 'Person': return `/people/${id}`
    case 'Company': return `/companies/${id}`
    case 'Customer': return `/customers/${id}`
    case 'Account': return `/backend/accounts/${id}`
    case 'User': return `/backend/users/${id}`
    case 'Document Template': return `/admin/modern/instant_docs/${id}`
    case 'Affiliate': return `/backend/affiliates/${id}`
  }
}

function * profilePush ({ payload }) {
  try {
    if (payload.event) {
      if (payload.event.metaKey || payload.event.altKey || payload.event.ctrlKey || payload.event.shiftKey) { return }

      payload.event.preventDefault()
      const nextUrl = payload.url ? payload.url : urlFor(payload)
      const method = payload.replace ? replace : push
      yield put(method(nextUrl))
    }
  } catch (e) {
    console.error(e)
  }
}

function * watchProfilePush () {
  yield call(takeEvery, PROFILE_PUSH, profilePush)
}

export default function * rootSaga () {
  yield [
    watchProfilePush(),
    accountAdminSagas.watchAccountUpdateSaga(),
    customFieldSagas.watchFetchCustomFieldsSaga(),

    customFieldSagas.watchNewCustomFieldGroupSaga(),
    customFieldSagas.watchEditCustomFieldGroupSaga(),
    customFieldSagas.watchDeleteCustomFieldGroupSaga(),

    customFieldSagas.watchNewCustomFieldLabelSaga(),
    customFieldSagas.watchEditCustomFieldLabelSaga(),
    customFieldSagas.watchDeleteCustomFieldLabelSaga(),
    customFieldSagas.watchMoveCustomFieldLabelCustomFieldGroupSaga(),
    customFieldSagas.watchRepositionCustomFieldLabelSaga(),
    customFieldSagas.watchUpdateCustomFieldLabelSaga(),
    customFieldSagas.watchSuccessCustomFieldSaga(),

    ...customerProfileSagas.customerProfileSagas,

    ...personProfileSagas.personProfileSagas,
    ...dealProfileSagas.dealProfileSagas,
    ...companyProfileSagas.companyProfileSagas,

    goalsProductLinesReport.watchGoalsProductLinesFiltersFetchSaga(),
    goalsProductLinesReport.watchGoalsProductLinesReportFetchSaga(),

    productLineGoals.watchProductLineGoalsPersistSaga(),

    dealLossReasonsSagas.watchDealLossReasonCreateSaga(),
    dealLossReasonsSagas.watchDealLossReasonUpdateSaga(),
    dealLossReasonsSagas.watchDealLossReasonDeleteSaga(),
    dealLossReasonsSagas.watchDealLossReasonRequiredChangeSaga(),
    dealLossReasonsSagas.watchDealLossReasonShowDialogChangeSaga(),
    dealLossReasonsSagas.watchDealLossReasonShowDealProfileChangeSaga(),

    adminDealWonReasonsSagas.watchDealWonReasonCreateSaga(),
    adminDealWonReasonsSagas.watchDealWonReasonUpdateSaga(),
    adminDealWonReasonsSagas.watchDealWonReasonDeleteSaga(),
    adminDealWonReasonsSagas.watchDealWonReasonRequiredChangeSaga(),
    adminDealWonReasonsSagas.watchDealWonReasonShowDialogChangeSaga(),
    adminDealWonReasonsSagas.watchDealWonReasonShowDealProfileChangeSaga(),

    dealWonReasonsSagas.watchSetDealWonReasonSaga(),

    adminDealStatusesSagas.watchDealStatusCreateSaga(),
    adminDealStatusesSagas.watchDealStatusUpdateSaga(),
    adminDealStatusesSagas.watchDealStatusDeleteSaga(),

    adminPersonStatusesSagas.watchPersonStatusCreateSaga(),
    adminPersonStatusesSagas.watchPersonStatusUpdateSaga(),
    adminPersonStatusesSagas.watchPersonStatusDeleteSaga(),

    adminPersonTagsSagas.watchCreateSaga(),
    adminPersonTagsSagas.watchUpdateSaga(),
    adminPersonTagsSagas.watchDeleteSaga(),

    adminSourcesSagas.watchCreateSaga(),
    adminSourcesSagas.watchUpdateSaga(),
    adminSourcesSagas.watchDeleteSaga(),

    adminActivityCategoriesSagas.watchCreateSaga(),
    adminActivityCategoriesSagas.watchUpdateSaga(),
    adminActivityCategoriesSagas.watchDeleteSaga(),

    accountExportSagas.watchCreateSaga(),
    accountExportSagas.watchUpdateSaga(),
    accountExportSagas.watchDeleteSaga(),
    adminEventTypesSagas.watchCreateSaga(),
    adminEventTypesSagas.watchUpdateSaga(),
    adminEventTypesSagas.watchDeleteSaga(),

    adminTodoTemplatesSagas.watchCreateSaga(),
    adminTodoTemplatesSagas.watchUpdateSaga(),
    adminTodoTemplatesSagas.watchDeleteSaga(),
    adminTodoTemplatesSagas.watchTodoTemplateIgnoreWeekendsSaga(),

    appRegistrationSagas.watchCreateSaga(),
    appRegistrationSagas.watchUpdateSaga(),
    appRegistrationSagas.watchDeleteSaga(),

    adminDocumentTagsSagas.watchCreateSaga(),
    adminDocumentTagsSagas.watchUpdateSaga(),
    adminDocumentTagsSagas.watchDeleteSaga(),

    pipelineSagas.watchPipelineNewFormSubmitSaga(),
    pipelineSagas.watchPipelineUpdateFormSubmitSaga(),
    pipelineSagas.watchPipelinesFetchSaga(),
    pipelineSagas.watchPipelinesQuotaFetchSaga(),
    pipelineSagas.watchPipelinesCanAllocateSaga(),
    pipelineSagas.watchChangeDefaultPipelineSaga(),
    pipelineSagas.watchPipelineDeleteSaga(),

    pipelineSagas.watchDealPipelinesFetchUpdateStagesSaga(),
    pipelineSagas.watchDealPipelinesCreateUpdateStagesSaga(),

    pipelineSagas.watchDealStagesFetchSaga(),
    pipelineSagas.watchDealStageCreateSaga(),
    pipelineSagas.watchDealStageUpdateSaga(),
    pipelineSagas.watchDealStageDeleteSaga(),
    pipelineSagas.watchDealStagesReorderSaga(),

    productLineSagas.watchProductLineNewFormSubmitSaga(),
    productLineSagas.watchProductLineUpdateFormSubmitSaga(),
    productLineSagas.watchProductLineDeleteSaga(),

    searchesSagas.watchSearchesFetchSaga(),

    teamSagas.watchTeamFetchSaga(),
    teamSagas.watchTeamDeleteFormSubmitSaga(),
    teamSagas.watchTeamEditFormSubmitSaga(),
    teamSagas.watchTeamNewFormSubmitSaga(),
    teamSagas.watchTeamUsersFormSubmitSaga(),

    userSagas.watchUserFetchSaga(),
    userSagas.watchUserEditFormSubmitSaga(),
    userSagas.watchUserMarkInactiveFormSubmitSaga(),
    userSagas.watchUserReassignDataFormSubmitSaga(),
    userSagas.watchUserDisableMfaSaga(),
    userSagas.watchUserRequestMfaSaga(),
    userSagas.watchUserBulkActionSaga(),
    userSagas.watchUserReactivateFormSubmitSaga(),
    userSagas.watchUserProfilesLayoutSaga(),
    userSagas.watchUserProfilesLayoutPositionUpdateSaga(),
    userSagas.watchUserProfilesLayoutHiddenUpdateSaga(),

    otherEmailsSagas.watchCreateSaga(),
    otherEmailsSagas.watchUpdateSaga(),
    otherEmailsSagas.watchDeleteSaga(),

    watchSubscriptionRequestSaga(),
    watchFormSubmitSaga(),
    watchPreloadAppSaga(),

    calendarSagas.watchReloadCalendarSaga(),
    calendarSagas.watchMarkCompleteCalendarSaga(),
    calendarSagas.watchDeleteCalendarEventSaga(),
    calendarSagas.watchSetCalendarUsersSettingsSaga(),
    calendarSagas.watchFetchCalendarUserDataSaga(),
    calendarSagas.watchFetchCalendarUsersSettingsSaga(),
    calendarSagas.watchUpdateCalendarEventSaga(),
    calendarSagas.watchFetchCalendarFilterSaga(),
    calendarSagas.watchSelectCalendarFilterSaga(),
    calendarSagas.watchSelectDefaultCalendarFilterSaga(),
    calendarSagas.watchCreateCalendarFilterSaga(),
    calendarSagas.watchUpdateCalendarFilterSaga(),
    calendarSagas.watchDeleteCalendarFilterSaga(),

    myProfileSagas.watchFetchProfileSettingsSaga(),

    adminCalendarEntryPrioritiesSagas.watchCalendarEntryPriorityCreateSaga(),
    adminCalendarEntryPrioritiesSagas.watchCalendarEntryPriorityUpdateSaga(),
    adminCalendarEntryPrioritiesSagas.watchCalendarEntryPriorityDeleteSaga(),

    adminCalendarEntryPostponePeriodsSagas.watchCalendarEntryPostponeCreateSaga(),
    adminCalendarEntryPostponePeriodsSagas.watchCalendarEntryPostponeDeleteSaga(),
    adminCalendarEntryPostponePeriodsSagas.watchCalendarEntryPostponeWeekendsSaga(),
    adminCalendarEntryPostponePeriodsSagas.watchCalendarEntryUseCalendarToPostponeSaga(),

    ...notificationsInlineSagas,
    ...quotasSagas,
    ...conditionalFieldsSagas,
    ...conditionalDropdownsSagas,
    ...globalSearchSagas
  ]
}
