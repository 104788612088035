/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Helpers.hasWorkAddress = context => context.work_address_1 || context.work_address_2 || context.work_city || context.work_state || context.work_postal_code || context.work_country

Helpers.hasHomeAddress = context => context.home_address_1 || context.home_address_2 || context.home_city || context.home_state || context.home_postal_code || context.home_country

Helpers.keyContactIcon = function (person, tooltip) {
  let content
  if (tooltip == null) { tooltip = false }
  if (window.Features.customer_management && person.is_key_contact) {
    const relationshipName = person.relationship || 'relationship undefined'

    if (tooltip) {
      content = `\
<i
  class="fas fa-star key-contact"
  data-toggle="tooltip"
  title="${relationshipName} @ ${person.company_name}"
/>\
`
    } else {
      content = "<i class='fas fa-star key-contact' />"
    }
  }

  return new Handlebars.SafeString(content || '')
}

Handlebars.registerHelper('hasWorkAddress', function () { return Helpers.hasWorkAddress(this) })
Handlebars.registerHelper('hasHomeAddress', function () { return Helpers.hasHomeAddress(this) })

Handlebars.registerHelper('shouldDisplayContactSection', function () {
  return this.phone || this.mobile || this.home_phone || this.fax
})

Handlebars.registerHelper('shouldDisplayEmailSection', function () {
  return this.email || this.email2 || this.home_email
})

Handlebars.registerHelper('hasMoreDetails', function () {
  return Helpers.hasWorkAddress(this) || Helpers.hasHomeAddress(this)
})

Handlebars.registerHelper('keyContactIcon', function (person, tooltip) {
  if (tooltip == null) { tooltip = false }
  return Helpers.keyContactIcon(person, tooltip)
})
