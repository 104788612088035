import { takeEvery, takeLatest } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import {
  goalsProductLinesFiltersFetchSuccess,
  goalsProductLinesFiltersFetchFailure,
  goalsProductLinesReportFetchSuccess,
  goalsProductLinesReportFetchFailure
} from 'Goals/actions/ProductLines'

import {
  GOALS_PRODUCT_LINES_FILTERS_FETCH,
  GOALS_PRODUCT_LINES_REPORT_FETCH
} from 'Goals/constants/ProductLines'

export function * goalsProductLinesFiltersFetchSaga (action) {
  try {
    const { filter } = action.payload

    const { entries: goals, deal_visibility_type: dealVisibilityType } = yield call(
      fetcher,
      '/api/internal/goals/product_lines.json', {
        query: { filter },
        method: 'GET'
      }
    )

    yield put(goalsProductLinesFiltersFetchSuccess({ dealVisibilityType, goals }))
  } catch (exception) {
    console.error(exception)

    yield put(goalsProductLinesFiltersFetchFailure({ error: exception.message }))
  }
}

export function * goalsProductLinesReportFetchSaga (action) {
  const { goalId, dealPipelineId } = action.payload

  const [goalResponse, reportResponse] = yield [
    call(
      fetcher,
      `/api/internal/goals/product_lines/${goalId}.json`, {
        method: 'GET'
      }
    ),
    call(
      fetcher,
      '/api/internal/goals/product_lines_report.json', {
        body: JSON.stringify({ id: goalId, deal_pipeline_id: dealPipelineId }),
        method: 'POST'
      }
    )
  ]

  try {
    yield put(goalsProductLinesReportFetchSuccess({ goal: goalResponse.goal, data: reportResponse.table }))
  } catch (exception) {
    console.error(exception)

    yield put(goalsProductLinesReportFetchFailure({ error: exception.message }))
  }
}

export function * watchGoalsProductLinesReportFetchSaga () {
  yield call(takeLatest, GOALS_PRODUCT_LINES_REPORT_FETCH, goalsProductLinesReportFetchSaga)
}

export function * watchGoalsProductLinesFiltersFetchSaga () {
  yield call(takeEvery, GOALS_PRODUCT_LINES_FILTERS_FETCH, goalsProductLinesFiltersFetchSaga)
}
