export const checkMultiselect = (trigger, value) => {
  const valueArray = (
    value === null
      ? []
      : value === undefined
        ? []
        : value === ''
          ? []
          : Array.isArray(value)
            ? value.map(v => parseInt(v))
            : value.split(',').map(v => parseInt(v))
  )

  const operandArray = (
    Array.isArray(trigger.operand)
      ? trigger.operand
      : trigger.operand.split(',')
  ).map(v => parseInt(v))

  switch (trigger.name) {
    case 'Include':
      return multiselectInclude(valueArray, operandArray)
    case 'Exclude':
      return multiselectExclude(valueArray, operandArray)
    default:
      return null
  }
}
export default checkMultiselect

const multiselectInclude = (value, operand) => {
  if (value.filter(v => operand.includes(v)).length === operand.length) {
    return {
      message: 'includes selected values'
    }
  }
  return null
}

const multiselectExclude = (value, operand) => {
  if (value.filter(v => operand.includes(v)).length === 0) {
    return {
      message: 'does not include selected value'
    }
  }
  return null
}
