import {
  CREATE,
  UPDATE
} from 'permissions/actions'
import { userLevels } from 'permissions/userLevels'

export const PRODUCT_LINE_GOAL = '@@permissions/policy/productLineGoal'
export default {
  [CREATE]: (user, options) => !user.read_only && user.level === userLevels.EXECUTIVE,
  [UPDATE]: (user, options) => !user.read_only && user.level === userLevels.EXECUTIVE
}
