/* global fetch */
import fetcher from 'modules/fetcher'
import trackableEvent from 'modules/eventTracking/trackableEvent'

export const trackPersonChange = (id, component) => {
  trackableEvent.profilePageUpdate.person({
    id,
    component
  })
}

export const updatePersistedFilters = (filter, personId) => {
  fetch(`/api/internal/people/${personId}/activities/global_filters`, {
    body: JSON.stringify({ value: filter }),
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'PUT'
  })
}

export const normalizeDeals = (deals) => {
  return deals.map(deal => {
    if (deal.value) deal.value = parseFloat(deal.value)

    return deal
  })
}

export const fetchDeals = (personId, archivedDeals = false) => {
  const params = {}
  if (archivedDeals) params.archived_deals = 'true'

  return fetcher(`/api/internal/people/${personId}/deals`, {
    method: 'GET',
    query: params
  }).then(deals => normalizeDeals(deals))
}
