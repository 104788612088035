import {
  API_APPLICATION_CREATE,
  API_APPLICATION_CREATE_FAILURE,
  API_APPLICATION_UPDATE,
  API_APPLICATION_UPDATE_FAILURE,
  API_APPLICATION_DELETE,
  API_APPLICATION_DELETE_FAILURE
} from './constants'

export const apiApplicationUpdate = payload => ({ payload, type: API_APPLICATION_UPDATE })
export const apiApplicationUpdateFailure = payload => ({ payload, type: API_APPLICATION_UPDATE_FAILURE })

export const apiApplicationCreate = payload => ({ payload, type: API_APPLICATION_CREATE })
export const apiApplicationCreateFailure = payload => ({ payload, type: API_APPLICATION_CREATE_FAILURE })

export const apiApplicationDelete = payload => ({ payload, type: API_APPLICATION_DELETE })
export const apiApplicationDeleteFailure = payload => ({ payload, type: API_APPLICATION_DELETE_FAILURE })
