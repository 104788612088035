export const DEAL_STATUS_CREATE = 'DEAL_STATUS_CREATE'
export const DEAL_STATUS_CREATE_SUBMIT = 'DEAL_STATUS_CREATE_SUBMIT'
export const DEAL_STATUS_CREATE_FAILURE = 'DEAL_STATUS_CREATE_FAILURE'

export const DEAL_STATUS_UPDATE = 'DEAL_STATUS_UPDATE'
export const DEAL_STATUS_UPDATE_SUBMIT = 'DEAL_STATUS_UPDATE_SUBMIT'
export const DEAL_STATUS_UPDATE_FAILURE = 'DEAL_STATUS_UPDATE_FAILURE'

export const DEAL_STATUS_DELETE = 'DEAL_STATUS_DELETE'
export const DEAL_STATUS_DELETE_SUBMIT = 'DEAL_STATUS_DELETE_SUBMIT'
export const DEAL_STATUS_DELETE_FAILURE = 'DEAL_STATUS_DELETE_FAILURE'
