import { takeEvery, takeLatest } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { getCompanySelector } from './selectors'

import store from 'store'
import { anyFieldsToUpdate, updateResourceThroughModal } from 'ConditionalFields/helpers/updaters'
import conditionalFieldsValidator from 'ConditionalFields/validator'

import {
  companyFetchError,
  companyFetchSuccess,
  loadPeople,
  loadTodos,
  loadVisibleUsers,
  updateConditionalFieldsValidation,
  companyFetchSuccessChildrenCompanies
} from 'Companies/actions'

import {
  COMPANIES_FETCH_PEOPLE,
  COMPANY_CONDITIONAL_FIELDS_VALIDATION,
  COMPANY_FETCH_VISIBLE_USERS,
  COMPANY_FETCH,
  COMPANY_PROFILE_FETCH,
  COMPANY_RELOAD_TODOS,
  COMPANY_RELOAD,
  COMPANY_UPDATE,
  COMPANY_FETCH_CHILDREN_COMPANIES
} from './constants'

function * reloadCompanySaga ({ payload }) {
  const company = yield select(getCompanySelector)
  setTimeout(() => store.dispatch(companyFetchSuccess(company)), 0)
}

function * fetchCompanySaga ({ payload }) {
  try {
    if (payload.window && payload.window.id === payload.companyId) {
      yield put(companyFetchSuccess(payload.window))
    } else {
      const response = yield call(
        fetcher,
        `/companies/${payload.companyId}`, {
          method: 'GET'
        }
      )
      yield put(companyFetchSuccess(response))
    }
  } catch (exception) {
    console.error(exception.message)
    const errorBody = yield exception.response.json()
    yield put(companyFetchError(errorBody.error))
  }
}

function * reloadCompanyPeopleSaga ({ payload }) {
  const company = yield select(getCompanySelector)
  if (!company) return
  try {
    const response = yield call(
      fetcher,
      `/api/internal/companies/${company.id}/people`, {
        method: 'GET'
      }
    )
    yield put(loadPeople(response.people))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * reloadTodosSaga () {
  const company = yield select(getCompanySelector)
  try {
    const responseTodos = yield call(
      fetcher,
      `/api/internal/companies/${company.id}/agenda`, {
        method: 'GET'
      }
    )
    yield put(loadTodos(responseTodos))
  } catch (exception) {
    throw new Error(exception.message)
  }
}

function * loadDealVisibleUsersSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `/api/internal/companies/${payload.companyId}/visible_users`, {
        method: 'GET'
      }
    )
    yield put(loadVisibleUsers(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * companyUpdateSaga ({ payload, successCallback, failureCallback, finallyCallback }) {
  const company = yield select(getCompanySelector)
  const companyId = payload.id || company.id

  const resource = companyId === company.id ? company : {}
  const conditionalFieldsParams = { resourceType: 'company', resource, values: payload }

  if (anyFieldsToUpdate(conditionalFieldsParams)) {
    updateResourceThroughModal({
      ...conditionalFieldsParams,
      resourceFetchSuccess: companyFetchSuccess,
      closeCallback: () => {
        // Force misc components to reload with the correct data
        store.dispatch({ type: COMPANY_RELOAD })
      },
      successCallback,
      finallyCallback
    })

    return
  }

  try {
    const response = yield call(
      fetcher,
      `/companies/${companyId}`, {
        body: JSON.stringify({ company: { ...payload } }),
        method: 'PATCH'
      }
    )

    yield put(companyFetchSuccess(response))
    if (successCallback) {
      yield call(successCallback, response)
    }
  } catch (exception) {
    console.error(exception.message)

    if (failureCallback) {
      yield call(failureCallback, exception)
    }
  } finally {
    if (finallyCallback) {
      yield call(finallyCallback)
    }
  }
}

function * updateConditionalFieldsValidationSaga () {
  const company = yield select(getCompanySelector)

  try {
    const conditionalFieldsValidations = conditionalFieldsValidator('company', company)
    yield put(updateConditionalFieldsValidation(conditionalFieldsValidations))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * fetchChildrenCompaniesSaga () {
  const company = yield select(getCompanySelector)

  try {
    const response = yield call(
      fetcher,
      `/api/v3/companies/${company.id}/children_companies`, {
        method: 'GET'
      }
    )
    yield put(companyFetchSuccessChildrenCompanies(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

function * watchReloadCompanySaga () {
  yield call(takeEvery, COMPANY_RELOAD, reloadCompanySaga)
}

function * watchCompanyUpdateSaga () {
  yield call(takeEvery, COMPANY_UPDATE, companyUpdateSaga)
}

function * watchFetchCompanySaga () {
  yield call(takeLatest, [COMPANY_PROFILE_FETCH, COMPANY_FETCH], fetchCompanySaga)
}

function * watchReloadCompanyPeopleSaga () {
  yield call(takeEvery, COMPANIES_FETCH_PEOPLE, reloadCompanyPeopleSaga)
}

function * watchReloadCompanyTodosSaga () {
  yield call(takeEvery, COMPANY_RELOAD_TODOS, reloadTodosSaga)
}

function * watchLoadDealVisibleUsersSaga () {
  yield call(takeLatest, [COMPANY_PROFILE_FETCH, COMPANY_FETCH_VISIBLE_USERS], loadDealVisibleUsersSaga)
}

function * watchUpdateConditionalFieldsValidationSaga () {
  yield call(takeEvery, COMPANY_CONDITIONAL_FIELDS_VALIDATION, updateConditionalFieldsValidationSaga)
}

function * watchCompanyFetchChildrenCompaniesSaga () {
  yield call(takeEvery, COMPANY_FETCH_CHILDREN_COMPANIES, fetchChildrenCompaniesSaga)
}

export const companyProfileSagas = [
  watchReloadCompanySaga(),
  watchFetchCompanySaga(),
  watchReloadCompanyPeopleSaga(),
  watchReloadCompanyTodosSaga(),
  watchLoadDealVisibleUsersSaga(),
  watchCompanyUpdateSaga(),
  watchUpdateConditionalFieldsValidationSaga(),
  watchCompanyFetchChildrenCompaniesSaga()
]
