import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  sourceCreate,
  sourceCreateFailure,
  sourceUpdate,
  sourceUpdateFailure,
  sourceDelete,
  sourceDeleteFailure
} from '../actions'

import {
  SOURCE_CREATE_SUBMIT,
  SOURCE_UPDATE_SUBMIT,
  SOURCE_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/lead_sources',
      {
        method: 'POST',
        body: JSON.stringify({
          lead_source: {
            name: payload.name
          }
        })
      }
    )
    yield put(sourceCreate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(sourceCreateFailure(exception.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, SOURCE_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/lead_sources/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          lead_source: {
            name: payload.name
          }
        })
      }
    )
    yield put(sourceUpdate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(sourceUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, SOURCE_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/lead_sources/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(sourceDelete(payload))
  } catch (exception) {
    console.error(exception.message)
    yield put(sourceDeleteFailure(exception.message))
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, SOURCE_DELETE_SUBMIT, deleteSaga)
}
