import moment from 'moment'

import * as constants from 'Calendar/constants'
import { REHYDRATE } from 'redux-persist'

const userId = window.ppd && window.ppd.User ? window.ppd.User.id : 0
export const initialState = {
  filters: [],
  selectedFilterId: null,
  filter: [],
  userIds: [userId],
  userColors: {},
  eventTypeColors: {},
  range: {
    start: moment()
      .startOf('month')
      .startOf('week'),
    end: moment()
      .endOf('month')
      .endOf('week')
  },
  view: 'month',
  date: new Date(),
  lineClamp: 1,
  startOfWeek: 0
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...initialState,
        date: action?.payload?.calendar?.date || initialState.date,
        view: action?.payload?.calendar?.view || initialState.view,
        range: action?.payload?.calendar?.range || initialState.range,
        userIds: [window?.ppd?.User?.id || 0]
      }
    case constants.CALENDAR_UPDATE_LOADING:
      return { ...state, loading: Boolean(action.payload) }
    case constants.CALENDAR_SET_DATE_RANGE:
      return { ...state, range: action.payload }
    case constants.CALENDAR_FETCH_USERS_SETTINGS_SUCCESS:
      return {
        ...state,
        lineClamp: action.payload.line_clamp === undefined ? state.lineClamp : action.payload.line_clamp,
        startOfWeek: action.payload.start_of_week === undefined ? state.startOfWeek : action.payload.start_of_week
      }
    case constants.CALENDAR_SELECT_FILTER_SUCCESS:
      return {
        ...state,
        selectedFilterId: action.payload.id || null,
        userIds: action.payload.user_ids || state.userIds,
        userColors: action.payload.user_colors || state.userColors,
        filter: action.payload.event_type_ids || state.filter,
        eventTypeColors: action.payload.event_type_colors || state.eventTypeColors
      }
    case constants.CALENDAR_SET_VIEW:
      return { ...state, view: action.payload }
    case constants.CALENDAR_SET_DATE:
      return { ...state, date: action.payload }
    case constants.CALENDAR_UPDATE_EVENT_TYPES:
      return { ...state, filter: action.payload }
    case constants.CALENDAR_UPDATE_EVENT_TYPE_COLORS:
      return { ...state, eventTypeColors: action.payload }
    case constants.CALENDAR_UPDATE_USERS:
      return { ...state, userIds: action.payload }
    case constants.CALENDAR_UPDATE_USER_COLORS:
      return { ...state, userColors: action.payload }
    case constants.CALENDAR_FETCH_FILTERS_SUCCESS:
      return { ...state, filters: action.payload }
    case constants.CALENDAR_CREATE_FILTER_SUCCESS:
      return { ...state, filters: [...state.filters, action.payload] }
    case constants.CALENDAR_UPDATE_FILTER_SUCCESS:
      return { ...state, filters: state.filters.map(filter => filter.id === action.payload.id ? action.payload : filter) }
    case constants.CALENDAR_DELETE_FILTER_SUCCESS:
      return { ...state, filters: state.filters.filter(filter => filter.id !== action.payload.id) }
    default:
      return state
  }
}

export const reducerEvents = (state = [], action) => {
  switch (action.type) {
    case constants.CALENDAR_FETCH_SUCCESS:
      return action.payload
    case constants.CALENDAR_UPDATE_EVENT_STATE:
      return state.map(event =>
        event.id === action.payload.id ? action.payload : event
      )
    default:
      return state
  }
}
