import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  documentTagCreate,
  documentTagCreateFailure,
  documentTagUpdate,
  documentTagUpdateFailure,
  documentTagDelete,
  documentTagDeleteFailure
} from '../actions'

import {
  DOCUMENT_TAG_CREATE_SUBMIT,
  DOCUMENT_TAG_UPDATE_SUBMIT,
  DOCUMENT_TAG_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/document_tags',
      {
        method: 'POST',
        body: JSON.stringify({
          document_tag: {
            name: payload.name
          }
        })
      }
    )
    yield put(documentTagCreate(response))
  } catch (expection) {
    console.error(expection.message)
    yield put(documentTagCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, DOCUMENT_TAG_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/document_tags/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          document_tag: {
            name: payload.name
          }
        })
      }
    )
    yield put(documentTagUpdate(response))
  } catch (exception) {
    yield put(documentTagUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, DOCUMENT_TAG_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/document_tags/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(documentTagDelete(payload))
  } catch (exception) {
    yield put(documentTagDeleteFailure)
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, DOCUMENT_TAG_DELETE_SUBMIT, deleteSaga)
}
