import {
  USER_CREATE,
  USER_UPDATE,
  USER_FETCH_SUCCESS,
  USER_PROFILES_LAYOUT_FETCH_SUCCESS,
  USER_PROFILES_LAYOUT_POSITIONS_UPDATE,
  USER_PROFILES_LAYOUT_HIDDEN_UPDATE
} from 'constants/User'

import { TEAM_DELETE } from 'admin/Teams/constants'

const initialById = {}
const initialListedIds = []
const initialLoaded = false

const user = (state, { payload, type }) => {
  switch (type) {
    case USER_CREATE:
      return payload
    case USER_UPDATE:
    case TEAM_DELETE:
      return {
        ...state,
        ...payload
      }
  }
}

const byId = (state = initialById, { payload, type }, { listedIds }) => {
  switch (type) {
    case USER_CREATE:
    case USER_UPDATE:
      return {
        ...state,
        [payload.id]: user(state[payload.id], { payload, type })
      }
    case USER_FETCH_SUCCESS: {
      const fetchSuccess = {}
      payload.forEach(user => {
        fetchSuccess[user.id] = user
      })

      return fetchSuccess
    }
    case TEAM_DELETE: {
      const { users } = payload
      const newState = state

      users.forEach(payload => {
        newState[payload.id] = user(state[payload.id], { payload, type })
      })

      return newState
    }
    default:
      return state
  }
}

const listedIds = (state = initialListedIds, { payload, type }, { byId }) => {
  switch (type) {
    case USER_CREATE:
      return [...state, payload.id]
    case USER_FETCH_SUCCESS:
      return payload.map(user => user.id)
    default:
      return state
  }
}

const loaded = (state = initialLoaded, { type }) => {
  switch (type) {
    case USER_FETCH_SUCCESS:
      return true
    default:
      return state
  }
}

const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item]

const INITIAL_PROFILE_TYPE_STATE = {
  collapsed_tiles: {},
  positions: { left: [], right: [] },
  hidden_tiles: []
}

const INITIAL_PROFILE_LAYOUT_STATE = {
  companies: INITIAL_PROFILE_TYPE_STATE,
  customers: INITIAL_PROFILE_TYPE_STATE,
  deals: INITIAL_PROFILE_TYPE_STATE,
  dashboard: INITIAL_PROFILE_TYPE_STATE,
  people: INITIAL_PROFILE_TYPE_STATE
}

const profilesLayout = (
  state, { type, payload }
) => {
  switch (type) {
    case USER_PROFILES_LAYOUT_FETCH_SUCCESS:
      return { ...state, ...payload }
    case USER_PROFILES_LAYOUT_POSITIONS_UPDATE:
      return {
        ...state,
        [payload.profileType]: {
          ...state[payload.profileType],
          positions: {
            ...state[payload.profileType].positions,
            ...payload.columns
          }
        }
      }
    case USER_PROFILES_LAYOUT_HIDDEN_UPDATE:
      return {
        ...state,
        [payload.profileType]: {
          ...state[payload.profileType],
          hidden_tiles: addOrRemove(
            state[payload.profileType].hidden_tiles,
            payload.tile
          )
        }
      }
    default:
      return state
  }
}

export default (state = {}, action) => {
  return {
    byId: byId(state.byId, action, state),
    listedIds: listedIds(state.listedIds, action, state),
    loaded: loaded(state.loaded, action)
  }
}

export const userProfilesLayout = (state = INITIAL_PROFILE_LAYOUT_STATE, action) => {
  return profilesLayout(state, action)
}
