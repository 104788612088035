// This must be a function defined as function Name (args) {}
function PermissionsError (message) {
  this.message = message
  this.name = 'PermissionsError'
  this.stack = (new Error()).stack
}
PermissionsError.prototype = Object.create(Error.prototype)
PermissionsError.prototype.constructor = PermissionsError

export default PermissionsError
