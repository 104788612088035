export const DEAL_STAGES_FETCH_REQUEST = 'DEAL_STAGES_FETCH_REQUEST'
export const DEAL_STAGES_FETCH_SUCCESS = 'DEAL_STAGES_FETCH_SUCCESS'
export const DEAL_STAGES_FETCH_FAILURE = 'DEAL_STAGES_FETCH_FAILURE'

export const DEAL_STAGE_CREATE = 'DEAL_STAGE_CREATE'
export const DEAL_STAGE_CREATE_SUBMIT = 'DEAL_STAGE_CREATE_SUBMIT'
export const DEAL_STAGE_CREATE_FAILURE = 'DEAL_STAGE_CREATE_FAILURE'

export const DEAL_STAGE_CURRENTLY_EDIT = 'DEAL_STAGE_CURRENTLY_EDIT'
export const DEAL_STAGE_CURRENT_EDIT_CLEAR = 'DEAL_STAGE_CURRENT_EDIT_CLEAR'

export const DEAL_STAGE_UPDATE = 'DEAL_STAGE_UPDATE'
export const DEAL_STAGE_UPDATE_SUBMIT = 'DEAL_STAGE_UPDATE_SUBMIT'
export const DEAL_STAGE_UPDATE_FAILURE = 'DEAL_STAGE_UPDATE_FAILURE'

export const DEAL_STAGE_DELETE = 'DEAL_STAGE_DELETE'
export const DEAL_STAGE_DELETE_SUBMIT = 'DEAL_STAGE_DELETE_SUBMIT'
export const DEAL_STAGE_DELETE_FAILURE = 'DEAL_STAGE_DELETE_FAILURE'

export const DEAL_STAGES_REORDER_SUBMIT = 'DEL_STAGES_REORDER_SUBMIT'
export const DEAL_STAGES_REORDER_FAILURE = 'DEL_STAGES_REORDER_FAILURE'
