import * as constants from './constants'

export const setCalendarLoading = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_LOADING
})

export const fetchCalendar = payload => ({
  payload,
  type: constants.CALENDAR_FETCH
})

export const fetchCalendarSuccess = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_SUCCESS
})

export const updateCalendarEventTypes = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_EVENT_TYPES
})

export const updateCalendarEventTypeColors = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_EVENT_TYPE_COLORS
})

export const updateCalendarUsers = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_USERS
})

export const updateCalendarUserColors = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_USER_COLORS
})

export const setCalendarDateRange = payload => ({
  payload,
  type: constants.CALENDAR_SET_DATE_RANGE
})

export const markCompleteCalendar = payload => ({
  payload,
  type: constants.CALENDAR_MARK_COMPLETE
})

export const deleteCalendarEvent = payload => ({
  payload,
  type: constants.CALENDAR_DELETE_EVENT
})

export const setCalendarUsersSettings = payload => ({
  payload,
  type: constants.CALENDAR_SET_USERS_SETTINGS
})

export const fetchCalendarUserData = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_USER_DATA
})

export const fetchCalendarUsersSettings = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_USERS_SETTINGS
})

export const fetchCalendarUsersSettingsSuccess = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_USERS_SETTINGS_SUCCESS
})

export const updateCalendarEvent = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_EVENT
})

export const selectCalendarFilter = payload => ({
  payload,
  type: constants.CALENDAR_SELECT_FILTER
})

export const selectDefaultCalendarFilter = payload => ({
  payload,
  type: constants.CALENDAR_SELECT_DEFAULT_FILTER
})

export const selectCalendarFilterSuccess = payload => ({
  payload,
  type: constants.CALENDAR_SELECT_FILTER_SUCCESS
})

export const createCalendarFilter = payload => ({
  payload,
  type: constants.CALENDAR_CREATE_FILTER
})

export const createCalendarFilterSuccess = payload => ({
  payload,
  type: constants.CALENDAR_CREATE_FILTER_SUCCESS
})

export const updateCalendarFilter = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_FILTER
})

export const updateCalendarFilterSuccess = payload => ({
  payload,
  type: constants.CALENDAR_UPDATE_FILTER_SUCCESS
})

export const deleteCalendarFilter = payload => ({
  payload,
  type: constants.CALENDAR_DELETE_FILTER
})

export const deleteCalendarFilterSuccess = payload => ({
  payload,
  type: constants.CALENDAR_DELETE_FILTER_SUCCESS
})

export const fetchCalendarFilters = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_FILTERS
})

export const fetchCalendarFiltersSuccess = payload => ({
  payload,
  type: constants.CALENDAR_FETCH_FILTERS_SUCCESS
})
