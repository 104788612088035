import * as constants from '../constants'

export const dealWonReasonCreate = payload => ({ payload, type: constants.DEAL_WON_REASON_CREATE })
export const dealWonReasonCreateFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_CREATE_FAILURE })

export const dealWonReasonUpdate = payload => ({ payload, type: constants.DEAL_WON_REASON_UPDATE })
export const dealWonReasonUpdateFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_UPDATE_FAILURE })

export const dealWonReasonDelete = payload => ({ payload, type: constants.DEAL_WON_REASON_DELETE })
export const dealWonReasonDeleteFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_DELETE_FAILURE })

export const dealWonReasonRequiredChange = payload => ({ payload, type: constants.DEAL_WON_REASON_REQUIRED_CHANGE })
export const dealWonReasonRequiredChangeSubmit = payload => ({ payload, type: constants.DEAL_WON_REASON_REQUIRED_CHANGE_SUBMIT })
export const dealWonReasonRequiredChangeFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_REQUIRED_CHANGE_FAILURE })

export const dealWonReasonShowDialogChange = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DIALOG_CHANGE })
export const dealWonReasonShowDialogChangeSubmit = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DIALOG_CHANGE_SUBMIT })
export const dealWonReasonShowDialogChangeFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DIALOG_CHANGE_FAILURE })

export const dealWonReasonShowDealProfileChange = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE })
export const dealWonReasonShowDealProfileChangeSubmit = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT })
export const dealWonReasonShowDealProfileChangeFailure = payload => ({ payload, type: constants.DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE_FAILURE })
