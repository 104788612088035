import * as constants from '../constants'

export const dealLossReasonCreate = payload => ({ payload, type: constants.DEAL_LOSS_REASON_CREATE })
export const dealLossReasonCreateFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_CREATE_FAILURE })

export const dealLossReasonUpdate = payload => ({ payload, type: constants.DEAL_LOSS_REASON_UPDATE })
export const dealLossReasonUpdateFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_UPDATE_FAILURE })

export const dealLossReasonDelete = payload => ({ payload, type: constants.DEAL_LOSS_REASON_DELETE })
export const dealLossReasonDeleteFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_DELETE_FAILURE })

export const dealLossReasonRequiredChange = payload => ({ payload, type: constants.DEAL_LOSS_REASON_REQUIRED_CHANGE })
export const dealLossReasonRequiredChangeSubmit = payload => ({ payload, type: constants.DEAL_LOSS_REASON_REQUIRED_CHANGE_SUBMIT })
export const dealLossReasonRequiredChangeFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_REQUIRED_CHANGE_FAILURE })

export const dealLossReasonShowDialogChange = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE })
export const dealLossReasonShowDialogChangeSubmit = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_SUBMIT })
export const dealLossReasonShowDialogChangeFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE_FAILURE })

export const dealLossReasonShowDealProfileChange = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE })
export const dealLossReasonShowDealProfileChangeSubmit = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_SUBMIT })
export const dealLossReasonShowDealProfileChangeFailure = payload => ({ payload, type: constants.DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE_FAILURE })
