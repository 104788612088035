export default [
  'activityAdded',
  'addEventFromAgenda',
  'addFile',
  'addHealthStatusNote',
  'addMilestone',
  'addMilestoneCollaborator',
  'addPeople',
  'addPersonToCampaign',
  'addNewDeal',
  'addTaskFromAgenda',
  'addTemplateFromAgenda',
  'archive',
  'cancelAddNote',
  'changeHealthStatus',
  'clickAddDealToCompany',
  'clickAddPersonToCompany',
  'clickAssociateToDeal',
  'clickEdit',
  'clone',
  'collapseDetailsTile',
  'collapseTile',
  'completeTaskEventFromAgenda',
  'delete',
  'deleteMilestone',
  'deleteTaskEventFromAgenda',
  'dragAndDropTile',
  'editLayout',
  'editMilestone',
  'editName',
  'editPipeline',
  'filterActivitiesByCategory',
  'filterActivitiesByCompanies',
  'filterActivitiesByPeople',
  'filterActivitiesFeedByTime',
  'hideTile',
  'merge',
  'notifyOthers',
  'notifyOthersInActivityBody',
  'pdf',
  'pinMilestoneToDetails',
  'postponeTaskEventFromAgenda',
  'removePersonFromCompany',
  'removePersonFromDeal',
  'searchActivities',
  'sendMeACopy',
  'sendMeACopyInActivityBody',
  'share',
  'showTile',
  'updateMilestoneProgress',
  'uploadFile',
  'userMentioned'
]
