import * as constants from '../constants'
import { LOADING, READY } from 'components/Loader'

const initialState = {
  pipelines: [],
  dealPipelinesQuota: { dealPipelinesAllowed: 0 },
  selectedPipeline: null,
  loadingStatus: LOADING,
  error: null
}

const updateDashboardCurrentPipeline = (state, deletedPipelineId) => {
  window.App.Stores.UserSetting.get('current_deal_pipeline_id').done((setting) => {
    if (parseInt(setting.value) === deletedPipelineId) {
      const pipeline = state.pipelines.find(el => el.default === true)
      window.App.Stores.UserSetting.update('current_deal_pipeline_id', { value: pipeline.id })
      window.App.Stores.UserSetting.update('current_deal_pipeline_selection', { value: pipeline.name })
    }
  })
}

const defaultPipeline = (pipelines) => {
  return pipelines.find(el => el.default).id
}

const selectedPipeline = (pipelines) => {
  return parseInt(window.sessionStorage.getItem('selected_deal_pipeline_id')) || defaultPipeline(pipelines)
}

const setSelectedPipeline = (pipelineId) => {
  window.sessionStorage.setItem('selected_deal_pipeline_id', pipelineId)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.DEAL_PIPELINE_CREATE:
      setSelectedPipeline(action.payload.id)
      return {
        ...state,
        loadingStatus: READY,
        pipelines: [...state.pipelines, action.payload]
      }
    case constants.DEAL_PIPELINE_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      }
    case constants.DEAL_PIPELINES_FETCH_REQUEST:
      return {
        ...state,
        loadingStatus: LOADING
      }
    case constants.DEAL_PIPELINES_FETCH_SUCCESS:
      return {
        ...state,
        loadingStatus: READY,
        pipelines: action.payload,
        selectedPipeline: selectedPipeline(action.payload)
      }
    case constants.DEAL_PIPELINES_FETCH_FAILURE:
      return {
        ...state,
        loadingStatus: READY,
        error: action.payload,
        pipelines: []
      }
    case constants.DEAL_PIPELINE_QUOTA_SUCCESS:
      return {
        ...state,
        loadingStatus: READY,
        dealPipelinesQuota: action.payload
      }
    case constants.DEAL_PIPELINE_QUOTA_FAILURE:
      return {
        ...state,
        loadingStatus: READY,
        error: action.payload
      }
    case constants.DEAL_PIPELINE_CAN_ALLOCATE:
      return {
        ...state,
        canAllocate: action.payload.deal_pipelines.can_allocate
      }
    case constants.DEAL_PIPELINE_CAN_ALLOCATE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case constants.DEAL_PIPELINE_UPDATE:
      return {
        ...state,
        loadingStatus: READY,
        error: false,
        pipelines: state.pipelines.map(item => item.id !== action.payload.id ? item : action.payload)
      }
    case constants.DEAL_PIPELINE_UPDATE_FAILURE:
      return {
        ...state,
        error: true
      }
    case constants.DEAL_PIPELINE_DELETE:
      updateDashboardCurrentPipeline(state, action.payload.pipelineId)
      setSelectedPipeline(defaultPipeline(state.pipelines))
      return {
        ...state,
        loadingStatus: READY,
        error: false,
        selectedPipeline: defaultPipeline(state.pipelines),
        pipelines: state.pipelines.filter(item => item.id !== action.payload.pipelineId)
      }
    case constants.DEAL_PIPELINE_DELETE_FAILURE:
      return {
        ...state,
        error: true
      }
    case constants.CHANGE_DEFAULT_PIPELINE: {
      const pipelines = state.pipelines.map((el) => {
        el.id === action.payload.id ? el.default = action.payload.default : el.default = false
        return el
      })
      return {
        ...state,
        pipelines
      }
    }
    case constants.DEAL_PIPELINE_SELECT:
      setSelectedPipeline(action.payload.id)
      return {
        ...state,
        selectedPipeline: action.payload.id
      }
    default:
      return state
  }
}
