import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Loadable from 'react-loader-spinner'
require('style-loader!css-loader!react-loader-spinner/dist/loader/css/react-spinner-loader.css') // eslint-disable-line import/no-webpack-loader-syntax

export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const READY = 'READY'

const Overlay = styled.div` 
  position: relative;
  opacity: ${props => props.overlayActive ? 0.4 : 1};
`

const Wrapper = styled.div`
  z-index: 1002;
  top: 20%;
  left: 48%;
  position: absolute;
`

export const Loader = ({ active, color, height, width, ...rest }) => {
  return (
    <Overlay overlayActive={active}>
      <Wrapper>
        <Loadable
          visible={!!active}
          type="TailSpin"
          color={color || 'grey'}
          height={height || 60}
          width={width || 60}
        />
      </Wrapper>
      {rest.children}
    </Overlay>
  )
}

Loader.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
}

export default Loader
