import createReactClass from 'create-react-class'
import * as Password from 'modules/validPassword'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const RuleParagraph = styled.p`
  display: inline-block;
  margin-left: 2px !important;
`
const RulesContainer = styled.div`
  margin-top: -10px;
  div {
    height: 25px;
    p {
      font-size: 13px;
    }
    i {
      font-size: 12px;
      color: #666;
    }
  }
  .complete i {
    color: green;
  }
  .complete p {
    color: green;
  }
`

App.Components.User.Password.Rules = createReactClass({
  displayName: "UserPasswordRules",

  rules: function() {
    const res = []
    const { passwordRequirements } = this.props

    if (passwordRequirements.lower_case && (passwordRequirements.lower_case | 0) > 0) {
      res.push({
        className: "lower-case",
        label: `${passwordRequirements.lower_case} lowercase letter${passwordRequirements.lower_case > 1 ? 's' : ''}`,
        test: function(val) {
          return Password.containsLowerCase(val, passwordRequirements.lower_case)
        }
      })
    }

    if (passwordRequirements.upper_case && (passwordRequirements.upper_case | 0) > 0) {
      res.push({
        className: "upper-case",
        label: `${passwordRequirements.upper_case} uppercase letter${passwordRequirements.upper_case > 1 ? 's' : ''}`,
        test: function(val) {
          return Password.containsUpperCase(val, passwordRequirements.upper_case)
        }
      })
    }

    if (passwordRequirements.number && (passwordRequirements.number | 0) > 0) {
      res.push({
        className: "number",
        label: `${passwordRequirements.number} number${passwordRequirements.number > 1 ? 's' : ''}`,
        test: function(val) {
          return Password.containsDigit(val, passwordRequirements.number)
        }
      })
    }

    if (passwordRequirements.special_character && (passwordRequirements.special_character | 0) > 0) {
      res.push({
        className: "special-character",
        label: `${passwordRequirements.special_character} special character${passwordRequirements.special_character > 1 ? 's' : ''}`,
        test: function(val) {
          return Password.containsSpecialCharacter(val, passwordRequirements.special_character)
        }
      })
    }

    res.push({
      className: "minimum-length",
      label: `${passwordRequirements.length[0]} character${passwordRequirements.length[0] > 1 ? 's' : ''} minimum`,
      test: function (val) {
        return Password.longEnough(val, passwordRequirements.length)
      }
    })

    return res
  },

  render: function() {
    return (
      <div className="row-pld">
        <div className="col-xs-12">
          <RulesContainer className="password-rules row-pld" data-cypress="passwordRules">
            {this.rules().map((rule, index) => {
              const test = rule.test(this.props.password)
              const icon = test ? 'fas fa-check' : 'fas fa-times'
              const ruleClass = test ? 'complete' : 'incomplete'

              return (
                <div className="col-sm-6" key={index}>
                  <span key={rule.className} className={rule.className + ' ' + ruleClass}>
                    <i className={icon} />
                    <RuleParagraph>{rule.label}</RuleParagraph>
                  </span>
                </div>
              )
            })}
          </RulesContainer>
        </div>
      </div>
    )
  }
});

App.Components.User.Password.Rules.propTypes = {
  password: PropTypes.string.isRequired,
  passwordRequirements: PropTypes.object.isRequired
};

export default App.Components.User.Password.Rules
