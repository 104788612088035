// Note Category Groups
export const EMAIL_NOTE_CATEGORIES = ['Email', 'Trackable Email Action']
export const SYSTEM_NOTE_CATEGORIES = ['Customer', 'Deal', 'Company', 'Person', 'CalendarTask', 'CalendarEvent']
export const NON_STANDARD_NOTE_CATEGORIES = [...EMAIL_NOTE_CATEGORIES, ...SYSTEM_NOTE_CATEGORIES]

export const ALL_ACTIVITIES_TAB = 'ALL'
export const STANDARD_ACTIVITIES_TAB = 'ACTIVITIES'
export const EMAIL_ACTIVITIES_TAB = 'EMAIL'

export const SMS_ACTIVITIES_TAB = 'SMS'
export const SYSTEM_ACTIVITIES_TAB = 'SYSTEM_UPDATES'

export const GENERIC_FILTER_ANY = 'Any'
export const ACTIVITY_ANY_TIME = 'ANY_TIME'
export const ACTIVITY_TODAY = 'TODAY'
export const ACTIVITY_YESTERDAY = 'YESTERDAY'
export const ACTIVITY_PAST_7_DAYS = 'PAST_7_DAYS'
export const ACTIVITY_OLDER = 'OLDER'

export const PER_PAGE = 20
