/* eslint-disable
    no-new,
    no-undef,
*/

// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

require('highcharts')
require('highcharts.nodata')

Helpers.renderHighchart = function (container, data) {
  data.chart.renderTo = container

  if (data.customTooltip) {
    data.tooltip.formatter = function () {
      return this.point.options.tooltip
    }
  }

  if (data.thousandsFormatter) {
    data.plotOptions[data.chart.type].dataLabels.formatter = function () {
      if (this.y > 1000) {
        return Highcharts.numberFormat(this.y / 1000, 1) + 'K'
      } else {
        return Highcharts.numberFormat(this.y, 0)
      }
    }
  }

  new Highcharts.Chart(data)
  return data
}
