import fetcher from 'modules/fetcher'
import moment from 'moment'
import { toBool } from '../helpers'

export const hasNoValue = (fieldValue) => fieldValue === undefined || fieldValue === null || fieldValue === '' || (Array.isArray(fieldValue) && !fieldValue.length)

export const presenceValidator = ({ formField, fieldValue, fieldMeta, getFieldValue }) => {
  const { validatePresenceOn, disabled } = fieldMeta
  const value = validatePresenceOn ? getFieldValue(validatePresenceOn) : fieldValue

  const valid = disabled || !hasNoValue(value)

  return {
    valid,
    errorMsg: valid ? null : 'Required',
    meta: {}
  }
}

export const emailValidator = ({ fieldValue }) => {
  const valid = hasNoValue(fieldValue) ||
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(String(fieldValue))

  return {
    valid,
    errorMsg: valid ? null : 'Not a valid email address',
    meta: {}
  }
}

export const currencyValidator = ({ fieldValue }) => {
  const valid = hasNoValue(fieldValue) ||
    /^-?\.?(?:\d+|\d{1,3}(?:[\s.,]\d{3})+)(?:[.,]\d+)?$/.test(String(fieldValue))

  return {
    valid,
    errorMsg: valid ? null : 'Not a valid number',
    meta: {}
  }
}

export const companyNameUniquenessValidator = async ({ fieldValue, resourceId }) => {
  if (hasNoValue(fieldValue)) {
    return {
      valid: true,
      errorMsg: null,
      meta: {}
    }
  }

  const resp = await fetcher('/api/v3/companies.json', {
    query: { view: 'api_v3', name: fieldValue, conditions: { company_name_eq: fieldValue } }
  }).then((resp) => {
    const valid = !resp.entries.length || (resp.entries.length === 1 && resp.entries[0].id === resourceId)

    return {
      valid,
      errorMsg: valid ? null : 'Has to be unique',
      meta: { duplicates: resp.entries }
    }
  })

  return resp
}

export const eventStartTimeValidator = ({ getFieldValue }) => {
  const startTimeDate = getFieldValue('start_time_date')
  const startTimeTime = getFieldValue('start_time_time')

  const endTimeDate = getFieldValue('end_time_date')
  const endTimeTime = getFieldValue('end_time_time')

  const allDay = toBool(getFieldValue('all_day'))
  const format = allDay ? 'YYYY/MM/DD' : 'YYYY/MM/DD h:mmA'

  const valid = (!startTimeDate || !endTimeDate) || (moment(`${endTimeDate} ${endTimeTime}`, format) >= moment(`${startTimeDate} ${startTimeTime}`, format))

  return {
    valid,
    errorMsg: valid ? null : 'Should be after start time',
    meta: {}
  }
}
