import {
  TAG_CREATE,
  TAG_CREATE_FAILURE,
  TAG_UPDATE,
  TAG_UPDATE_FAILURE,
  TAG_DELETE,
  TAG_DELETE_FAILURE
} from '../constants'

export const tagCreate = payload => ({ payload, type: TAG_CREATE })
export const tagCreateFailure = payload => ({ payload, type: TAG_CREATE_FAILURE })
export const tagUpdate = payload => ({ payload, type: TAG_UPDATE })
export const tagUpdateFailure = payload => ({ payload, type: TAG_UPDATE_FAILURE })
export const tagDelete = payload => ({ payload, type: TAG_DELETE })
export const tagDeleteFailure = payload => ({ payload, type: TAG_DELETE_FAILURE })
