import {
  REPORTING_VIEW_CREATE,
  REPORTING_VIEW_DELETE,
  REPORTING_VIEW_DUPLICATE,
  REPORTING_VIEW_SHARE,
  REPORTING_VIEW_STAR,
  REPORTING_VIEW_UPDATE
} from 'permissions/actions'

export const REPORTING_VIEW = '@@permissions/policy/reportingView'

export default {
  [REPORTING_VIEW_CREATE]: (user) => !user.read_only,
  [REPORTING_VIEW_DELETE]: (user) => !user.read_only,
  [REPORTING_VIEW_DUPLICATE]: (user) => !user.read_only,
  [REPORTING_VIEW_SHARE]: (user) => !user.read_only,
  [REPORTING_VIEW_STAR]: (user) => !user.read_only,
  [REPORTING_VIEW_UPDATE]: (user) => !user.read_only
}
