import { takeEvery, takeLatest } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  dealPipelinesFetchFailure,
  dealPipelinesFetchSuccess,
  dealPipelineCreate,
  dealPipelineCreateFailure,
  dealPipelineUpdate,
  dealPipelineUpdateFailure,
  dealPipelineDelete,
  dealPipelineDeleteFailure,
  dealPipelinesQuotaSuccess,
  dealPipelinesQuotaFailure,
  dealPipelinesCanAllocate,
  dealPipelinesCanAllocateFailure

} from '../actions'

import {
  DEAL_PIPELINES_FETCH_REQUEST,
  DEAL_PIPELINE_UPDATE_SUBMIT,
  DEAL_PIPELINE_CREATE,
  DEAL_PIPELINE_CREATE_SUBMIT,
  DEAL_PIPELINE_DELETE_SUBMIT,
  DEAL_PIPELINE_DELETE,
  CHANGE_DEFAULT_PIPELINE
} from '../constants'

export function * pipelinesFetchSaga () {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_pipelines'
    )
    yield put(dealPipelinesFetchSuccess(response.entries))
  } catch (exception) {
    yield put(dealPipelinesFetchFailure({ error: exception.message }))
  }
}

export function * pipelineNewFormSubmitsaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/deal_pipelines',
      {
        method: 'POST',
        body: JSON.stringify({
          deal_pipeline: {
            name: payload.name,
            pipeline_type: parseInt(payload.pipelineType),
            default: payload.defaultPipeline,
            description: payload.description
          }
        })
      }
    )
    yield put(dealPipelineCreate(response))
  } catch (exception) {
    yield put(dealPipelineCreateFailure({ error: exception.message }))
  }
}

export function * pipelineUpdateFormSubmitSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/deal_pipelines/${payload.pipelineId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal_pipeline: {
            name: payload.name,
            pipeline_type: parseInt(payload.pipelineType),
            default: payload.defaultPipeline,
            description: payload.description
          }
        })
      }
    )
    yield put(dealPipelineUpdate(response))
    yield call(pipelinesFetchSaga)
  } catch (exception) {
    yield put(dealPipelineUpdateFailure({ error: exception.message }))
  }
}

export function * pipelineDeleteSaga ({ payload }) {
  const body = {}
  if (payload.new_pipeline) { body.new_pipeline_id = payload.new_pipeline.value }
  if (payload.new_stage) { body.new_stage_id = payload.new_stage.value }
  try {
    yield call(
      fetcher,
      `api/v3/admin/deal_pipelines/${payload.pipelineId}`,
      {
        method: 'DELETE',
        body: JSON.stringify(body)
      }
    )
    yield put(dealPipelineDelete(payload))
  } catch (exception) {
    yield put(dealPipelineDeleteFailure({ error: exception.message }))
  }
}

export function * changeDefaultPipelineSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/deal_pipelines/${payload.id}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          deal_pipeline: {
            default: payload.default
          }
        })
      }
    )
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * dealPipelinesQuotaFetchSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/internal/deal_pipelines/quota'
    )
    yield put(dealPipelinesQuotaSuccess(response))
  } catch (exception) {
    yield put(dealPipelinesQuotaFailure({ error: exception.message }))
  }
}

export function * dealPipelineCanAllocateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      '/api/internal/quotas'
    )
    yield put(dealPipelinesCanAllocate(response))
    yield call(pipelinesFetchSaga)
  } catch (exception) {
    console.error(exception.message)
    yield put(dealPipelinesCanAllocateFailure({ payload: exception.message }))
  }
}

export function * watchPipelinesFetchSaga () {
  yield call(takeEvery, DEAL_PIPELINES_FETCH_REQUEST, pipelinesFetchSaga)
}

export function * watchPipelinesQuotaFetchSaga () {
  yield call(takeEvery, DEAL_PIPELINES_FETCH_REQUEST, dealPipelinesQuotaFetchSaga)
}

export function * watchPipelinesCanAllocateSaga () {
  yield call(takeLatest, [
    DEAL_PIPELINES_FETCH_REQUEST,
    DEAL_PIPELINE_CREATE,
    DEAL_PIPELINE_DELETE
  ], dealPipelineCanAllocateSaga)
}

export function * watchPipelineNewFormSubmitSaga () {
  yield call(takeEvery, DEAL_PIPELINE_CREATE_SUBMIT, pipelineNewFormSubmitsaga)
}

export function * watchPipelineUpdateFormSubmitSaga () {
  yield call(takeEvery, DEAL_PIPELINE_UPDATE_SUBMIT, pipelineUpdateFormSubmitSaga)
}

export function * watchPipelineDeleteSaga () {
  yield call(takeEvery, DEAL_PIPELINE_DELETE_SUBMIT, pipelineDeleteSaga)
}

export function * watchChangeDefaultPipelineSaga () {
  yield call(takeEvery, CHANGE_DEFAULT_PIPELINE, changeDefaultPipelineSaga)
}
