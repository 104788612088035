import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { colors } from '@PipelineDeals/shared-react-components'

import styled from 'styled-components'

const Row = styled.div`
  padding: 3px 20px;
  cursor: pointer;
  color: ${colors.text.primary};
`

const MergeTag = ({ label, value, forWysiwyg, onTagClick }) => {
  const handleTagClick = () => {
    onTagClick(value)
  }

  const tagProps = useMemo(() => (
    forWysiwyg ? {
      className: 'fr-command',
      'data-cmd': 'mergeTags',
      'data-param1': value
    } : {
      onClick: handleTagClick
    }
  ), [forWysiwyg, handleTagClick])

  return (
    <Row {...tagProps}>
      {label}
    </Row>
  )
}

MergeTag.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  forWysiwyg: PropTypes.bool,
  onTagClick: PropTypes.func
}

export default MergeTag
