import {
  DUPLICATE_CHECK_UPDATE,
  DUPLICATE_CHECK_DELETE
} from 'permissions/actions'

export const DUPLICATE_CHECK = '@@permissions/policy/duplicateCheck'

export default {
  [DUPLICATE_CHECK_UPDATE]: (user, { object }) => user.id === object.user_id,
  [DUPLICATE_CHECK_DELETE]: (user, { object }) => user.id === object.user_id
}
