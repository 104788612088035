export const OTHER_EMAIL_CREATE = 'OTHER_EMAIL_CREATE'
export const OTHER_EMAIL_CREATE_SUBMIT = 'OTHER_EMAIL_CREATE_SUBMIT'
export const OTHER_EMAIL_CREATE_FAILURE = 'OTHER_EMAIL_CREATE_FAILURE'

export const OTHER_EMAIL_UPDATE = 'OTHER_EMAIL_UPDATE'
export const OTHER_EMAIL_UPDATE_SUBMIT = 'OTHER_EMAIL_UPDATE_SUBMIT'
export const OTHER_EMAIL_UPDATE_FAILURE = 'OTHER_EMAIL_UPDATE_FAILURE'

export const OTHER_EMAIL_DELETE = 'OTHER_EMAIL_DELETE'
export const OTHER_EMAIL_DELETE_SUBMIT = 'OTHER_EMAIL_DELETE_SUBMIT'
export const OTHER_EMAIL_DELETE_FAILURE = 'OTHER_EMAIL_DELETE_FAILURE'
