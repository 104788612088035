import {
  GOALS_PRODUCT_LINES_FILTERS_FETCH,
  GOALS_PRODUCT_LINES_FILTERS_FETCH_SUCCESS,
  GOALS_PRODUCT_LINES_FILTERS_FETCH_FAILURE,
  GOALS_PRODUCT_LINES_FILTERS_UPDATE,
  GOALS_PRODUCT_LINES_REPORT_FETCH,
  GOALS_PRODUCT_LINES_REPORT_FETCH_SUCCESS,
  GOALS_PRODUCT_LINES_REPORT_FETCH_FAILURE,
  GOALS_PRODUCT_LINES_FILTERS_SECTION_CHANGE,
  GOALS_PRODUCT_LINES_FILTERS_GOAL_RESET
} from 'Goals/constants/ProductLines'

export const goalsProductLinesFiltersFetch = payload => ({ payload, type: GOALS_PRODUCT_LINES_FILTERS_FETCH })
export const goalsProductLinesFiltersFetchSuccess = payload => ({ payload, type: GOALS_PRODUCT_LINES_FILTERS_FETCH_SUCCESS })
export const goalsProductLinesFiltersFetchFailure = payload => ({ payload, type: GOALS_PRODUCT_LINES_FILTERS_FETCH_FAILURE })
export const goalsProductLinesFiltersUpdate = payload => ({ payload, type: GOALS_PRODUCT_LINES_FILTERS_UPDATE })

export const goalsProductLinesReportFetch = payload => ({ payload, type: GOALS_PRODUCT_LINES_REPORT_FETCH })
export const goalsProductLinesReportFetchSuccess = payload => ({ payload, type: GOALS_PRODUCT_LINES_REPORT_FETCH_SUCCESS })
export const goalsProductLinesReportFetchFailure = payload => ({ payload, type: GOALS_PRODUCT_LINES_REPORT_FETCH_FAILURE })

export const goalsProductLinesFiltersSectionChange = payload => ({ payload, type: GOALS_PRODUCT_LINES_FILTERS_SECTION_CHANGE })

export const goalsProductLinesFiltersGoalReset = () => ({ type: GOALS_PRODUCT_LINES_FILTERS_GOAL_RESET })
