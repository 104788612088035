import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import assetPath from 'modules/assetPath'

import {
  colors,
  H3,
  Modal,
  P,
  PrimaryButton,
  TertiaryButton
} from '@PipelineDeals/shared-react-components'

const PWrapper = styled(P)`
  margin-top: 20px;
  margin-bottom: 20px;
`
const SVGWrapper = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
`
const H3Wrapper = styled(H3)`
  font-weight: 700;
  color: ${colors.primary.blue};
`

const ButtonUpgrade = styled(PrimaryButton)`
  display: block;
  width: 100%;
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 19px;
  line-height: 167.1%;
`
const AlertBadgeMashup = styled.span`
  background: rgba(255, 101, 132, 0.2);
  border-radius: 3px;
  font-size: 12px;
  padding: 4px 4px 1px 4px;
  color: #ff6584;
`

const UpgradeEmailModal = ({ onCloseModal, ...props }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen ?? true)

  const upgradeToGrowUrl = 'https://billing.pipelinecrm.com/upgrade/grow'

  useEffect(() => {
    window.trackableEvent.upgradeCTA.showModal({ content: 'QUOTA_CONTENT_EMAIL_TRACKING' })
  }, [isOpen])

  const closeModal = () => {
    window.trackableEvent.upgradeCTA.closeModal({ content: 'QUOTA_CONTENT_EMAIL_TRACKING' })
    setIsOpen(false)
    onCloseModal()
  }

  return (
    <Modal
      title="Email Functionality"
      isOpen={isOpen}
      onClose={closeModal}
      contentStyles={{
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
        width: '600px'
      }}
    >
      <div style={{ textAlign: 'center', margin: '9px 25px' }}>
        <AlertBadgeMashup>Upgrade Now</AlertBadgeMashup>

        <div data-cypress="quota-deal-exceeded">
          <H3Wrapper>
            Get the most out of your email in Pipeline with the Grow Plan
          </H3Wrapper>
          <SVGWrapper
            src={assetPath('quotas/email.svg')}
            alt="Get the most out of your email in Pipeline with the Grow Plan"
          />
          <PWrapper>
            When you upgrade to Grow, you can use templates, create campaigns,
            access analytics, and more! Upgrade now to see what powerful tools
            Pipeline can add to your email.
          </PWrapper>
          <ButtonUpgrade
            data-cypress="upgrade"
            onClick={() => {
              window.trackableEvent.upgradeCTA.clickUpgrade({ content: 'QUOTA_CONTENT_EMAIL_TRACKING' })
              window.location = upgradeToGrowUrl
            }}
            content="Upgrade to Grow Plan"
          />

          <TertiaryButton
            onClick={() => {
              window.trackableEvent.upgradeCTA.clickLearnMore({ content: 'QUOTA_CONTENT_EMAIL_TRACKING' })
              window.location =
                'https://help.pipelinecrm.com/article/611-account-plans'
            }}
            content="Learn More"
          />
        </div>
      </div>
    </Modal>
  )
}

UpgradeEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired
}

export default UpgradeEmailModal
