import {
  COMMENT_CREATE,
  COMMENT_DELETE,
  COMMENT_UPDATE,
  FILE_CREATE,
  FILE_DELETE,
  FILE_TAG,
  LIST_VIEW_CREATE,
  LIST_VIEW_DELETE,
  LIST_VIEW_DUPLICATE,
  LIST_VIEW_SHARE,
  LIST_VIEW_STAR,
  LIST_VIEW_UPDATE,
  LIST_VIEW_ADD_REMOVE_COLUMNS,
  UPDATE,
  BULK_ACTIONS,
  LIST_VIEW_CREATE_FOCUSED
} from 'permissions/actions'
import ownerId from 'permissions/ownerId'

export const ACTIVITY = '@@permissions/policy/activity'

export default {
  [BULK_ACTIONS]: user => !user.read_only,
  [COMMENT_CREATE]: (user, options) => true,
  [COMMENT_DELETE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [COMMENT_UPDATE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [FILE_CREATE]: (user, options) => !user.read_only,
  [FILE_DELETE]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [FILE_TAG]: (user, { object, objectType }) => !user.read_only || ownerId(object, objectType) === user.id,
  [LIST_VIEW_CREATE]: (user, options) => !user.read_only,
  [LIST_VIEW_DELETE]: (user, options) => !user.read_only,
  [LIST_VIEW_DUPLICATE]: (user, options) => !user.read_only,
  [LIST_VIEW_SHARE]: (user, options) => !user.read_only,
  [LIST_VIEW_STAR]: (user, options) => !user.read_only,
  [LIST_VIEW_UPDATE]: (user, options) => !user.read_only,
  [LIST_VIEW_ADD_REMOVE_COLUMNS]: (user, options) => !user.external,
  [UPDATE]: (user, options) => !user.read_only,
  [LIST_VIEW_CREATE_FOCUSED]: user => !user.read_only && !user.external
}
