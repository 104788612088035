import {
  CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_FAILURE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_FAILURE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS,
  CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE
} from '../constants'

export const calendarEntryPostponeCreate = payload => ({ payload, type: CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE })
export const calendarEntryPostponeCreateFailure = payload => ({ payload, type: CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE_FAILURE })

export const calendarEntryPostponeDelete = payload => ({ payload, type: CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE })
export const calendarEntryPostponeDeleteFailure = payload => ({ payload, type: CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE_FAILURE })

export const calendarEntryPostponeWeekends = payload => ({ payload, type: CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS })

export const calendarEntryUseCalendarToPostpone = payload => ({ payload, type: CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE })
