import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  otherEmailCreate,
  otherEmailCreateFailure,
  otherEmailUpdate,
  otherEmailUpdateFailure,
  otherEmailDelete,
  otherEmailDeleteFailure
} from '../actions'

import {
  OTHER_EMAIL_CREATE_SUBMIT,
  OTHER_EMAIL_UPDATE_SUBMIT,
  OTHER_EMAIL_DELETE_SUBMIT
} from '../constants'

export function * createSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/profile',
      {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            other_emails_attributes: [
              { email: payload.name }
            ]
          }
        })
      }
    )
    yield put(otherEmailCreate(response))
  } catch (expection) {
    console.error(expection.message)
    yield put(otherEmailCreateFailure(expection.message))
  }
}

export function * watchCreateSaga () {
  yield call(takeEvery, OTHER_EMAIL_CREATE_SUBMIT, createSaga)
}

export function * updateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/profile',
      {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            other_emails_attributes: [
              { id: payload.id, email: payload.name }
            ]
          }
        })
      }
    )
    yield put(otherEmailUpdate(response))
  } catch (exception) {
    yield put(otherEmailUpdateFailure(exception.message))
  }
}

export function * watchUpdateSaga () {
  yield call(takeEvery, OTHER_EMAIL_UPDATE_SUBMIT, updateSaga)
}

export function * deleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      'api/v3/profile',
      {
        method: 'PUT',
        body: JSON.stringify({
          user: {
            other_emails_attributes: [
              { id: payload.id, _destroy: true }
            ]
          }
        })
      }
    )
    yield put(otherEmailDelete(payload))
  } catch (exception) {
    yield put(otherEmailDeleteFailure)
  }
}

export function * watchDeleteSaga () {
  yield call(takeEvery, OTHER_EMAIL_DELETE_SUBMIT, deleteSaga)
}
