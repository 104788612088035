import React, { useMemo } from 'react'
import {
  Section,
  Row,
  Left,
  Right,
  InterpunctJoin
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const DealStage = () => {
  const { cardMeta: { showOnlyFields }, resource: deal } = useBusinessCard()

  if (!showOnlyFields.includes('stage')) return null
  if (!deal.deal_stage) return null

  return (
    <Section>
      <Row>
        <Left>stage</Left>
        <Right>{deal.deal_stage.name}</Right>
      </Row>
    </Section>
  )
}

const DealPipeline = () => {
  const { cardMeta: { showOnlyFields }, resource: deal } = useBusinessCard()

  if (!showOnlyFields.includes('deal_pipeline_id')) return null
  if (!deal.deal_pipeline_id || !deal.deal_pipeline) return null

  return (
    <Section>
      <Row>
        <Left>pipeline</Left>
        <Right>{deal.deal_pipeline.name}</Right>
      </Row>
    </Section>
  )
}

const DealStagePipeline = () => {
  const { cardMeta: { showOnlyFields }, resource: deal } = useBusinessCard()

  if (!showOnlyFields.includes('stage') && !showOnlyFields.includes('deal_pipeline_id')) return null

  const pipelineAndStage = useMemo(() => (deal.deal_pipeline || deal.deal_pipeline_id) && deal.deal_stage, [deal])

  return (
    <>
      {showOnlyFields.includes('stage') && showOnlyFields.includes('deal_pipeline_id') && pipelineAndStage ? (
        <Section>
          <Row>
            <Left>stage</Left>
            <Right><InterpunctJoin items={[deal.deal_pipeline.name, deal.deal_stage.name]} /></Right>
          </Row>
        </Section>)
        : (<><DealStage /><DealPipeline /></>)}
    </>
  )
}

export default DealStagePipeline
