import moment from 'moment'

export const momentJsFormat = () => window.ppd.dateFormat === 'mm/dd/yyyy' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
export const momentJsTimeFormat = () => window.ppd.timeFormat === 'H:MM' ? 'HH:mm' : 'hh:mm A'
export const momentJsFormatWithTime = () => `${momentJsFormat()} ${momentJsTimeFormat()}`
export const dateFormatStringWordy = () => window.ppd.dateFormat === 'mm/dd/yyyy' ? 'MMM D' : 'D MMM'
export const getFormattedTime = (date) => date.format(momentJsTimeFormat())
export const getFormattedDateWordy = (date) => {
  let display = ''

  if (date.isSame(Date.now(), 'day')) {
    display = 'Today'
  } else {
    display = `${date.format(dateFormatStringWordy())}`
    if (date.year() !== (new Date()).getFullYear()) {
      display = `${display}, ${date.format('YYYY')}`
    }
  }

  return display
}
export const getFormattedDateTimeWordy = (date) => `${getFormattedDateWordy(date)}, ${getFormattedTime(date)}`
export const getFormattedDateFromString = (dateString, format) => {
  if (!dateString) return ''

  const date = moment(dateString.replace(/\s[+-]\d{4}/, ''))
  format = format || momentJsFormat()
  return date.format(format)
}
export const getFormattedTimeFromString = (dateString) => getFormattedDateFromString(dateString, momentJsTimeFormat())
export const getFormatDateTimeFromString = (dateString) => `${getFormattedDateFromString(dateString)} ${getFormattedTimeFromString(dateString)}`
