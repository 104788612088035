import {
  USER_CREATE,
  USER_UPDATE,

  USER_EDIT_FORM_FAILURE,

  USER_FETCH_FAILURE,
  USER_FETCH_REQUEST,
  USER_FETCH_SUCCESS,

  USER_MARK_INACTIVE_FORM_FAILURE,
  USER_REASSIGN_DATA_FORM_FAILURE,
  USER_REACTIVATE_FORM_FAILURE,
  USER_PROFILES_LAYOUT_FETCH,
  USER_PROFILES_LAYOUT_POSITIONS_UPDATE,
  USER_PROFILES_LAYOUT_HIDDEN_UPDATE
} from 'constants/User'

export const userCreate = payload => ({ payload, type: USER_CREATE })
export const userUpdate = payload => ({ payload, type: USER_UPDATE })

export const userEditFormFailure = payload => ({ payload, type: USER_EDIT_FORM_FAILURE })

export const userFetchFailure = payload => ({ payload, type: USER_FETCH_FAILURE })
export const userFetchRequest = payload => ({ payload, type: USER_FETCH_REQUEST })
export const userFetchSuccess = payload => ({ payload, type: USER_FETCH_SUCCESS })

export const userMarkInactiveFormFailure = payload => ({ payload, type: USER_MARK_INACTIVE_FORM_FAILURE })
export const userReassignDataFormFailure = payload => ({ payload, type: USER_REASSIGN_DATA_FORM_FAILURE })
export const userReactivateFormFailure = payload => ({ payload, type: USER_REACTIVATE_FORM_FAILURE })

export const userProfilesLayout = payload => ({ payload, type: USER_PROFILES_LAYOUT_FETCH })
export const userProfilesLayoutPositionsUpdate = payload => ({ payload, type: USER_PROFILES_LAYOUT_POSITIONS_UPDATE })
export const userProfilesLayoutHiddenUpdate = payload => ({ payload, type: USER_PROFILES_LAYOUT_HIDDEN_UPDATE })
