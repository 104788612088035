import getUrlParams from 'modules/url/getUrlParams'

export const getCustomFieldTypeFromUrl = (state) =>
  getUrlParams(state.router.location.pathname, { path: '/admin/modern/custom_fields/:type' })?.type

export const getCustomFieldGroupIdSelector = (state, { groupId }) => groupId
export const getCustomFieldGroupsSelector = (state) => state.customFields.groups
export const getCustomFieldLabelsSelector = (state) => state.customFields.labels
export const getCustomFieldLabelIdSelector = (state, { id }) => id
export const getCustomFieldTypeSelector = (state, { type }) => {
  if (type) return type.toLowerCase().replace(/customfield(group|label)/, '')
}
