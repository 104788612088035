import deepMerge from 'deepmerge'
import { arrayOverwriteSource } from 'modules/arrayOverwriteSource'

import conditionalFieldsValidator from 'ConditionalFields/validator'
import addEditRecord from 'add_edit_modals'
import store from 'store'

import { hasNoValue } from 'add_edit_modals/FormField/validators'
import { fieldIdMapped } from 'add_edit_modals/helpers'

const IGNORE_RULES_ATTRIBUTES = {
  deal: ['is_archived']
}

export const anyFieldsToUpdate = ({ resourceType, resource, values }) => {
  if (!Object.keys(resource).length) return false

  const skipConditionalFieldsCheck = IGNORE_RULES_ATTRIBUTES[resourceType] && IGNORE_RULES_ATTRIBUTES[resourceType].some(attribute => Object.keys(values).includes(attribute))
  if (skipConditionalFieldsCheck) return false

  const newRecord = deepMerge(resource, values, { arrayMerge: arrayOverwriteSource })

  const conditionalFieldsValidations = conditionalFieldsValidator(resourceType, newRecord)

  const isFieldDisabled = ([fieldName, value]) => {
    const SPECIAL_CASES = {
      company: {
        owner_id: !window.User.canChangeCompanyOwner
      }
    }
    return !(value.disabled?.length || (SPECIAL_CASES[resourceType] && SPECIAL_CASES[resourceType][fieldName]))
  }
  const conditionsNotDisabled = Object.fromEntries(Object.entries(conditionalFieldsValidations).filter(isFieldDisabled))

  const missingRequiredFields = Object.keys(conditionsNotDisabled).filter(fieldName => {
    const attributeToCheck = values.hasOwnProperty(fieldName) ? fieldName : fieldIdMapped(fieldName)
    return fieldName.startsWith('custom_label_') ? hasNoValue(newRecord.custom_fields[fieldName]) : hasNoValue(newRecord[attributeToCheck])
  })

  return missingRequiredFields.length !== 0
}

export const updateResourceThroughModal = ({ resourceType, resourceFetchSuccess, resource, values, successCallback, closeCallback, finallyCallback }) => {
  const onSuccess = (res) => {
    if (resourceFetchSuccess) store.dispatch(resourceFetchSuccess(res))
    if (successCallback) successCallback(res)
    if (finallyCallback) finallyCallback()
  }

  const onClose = () => {
    if (closeCallback) closeCallback()
    if (finallyCallback) finallyCallback()
  }

  addEditRecord({ resourceType, resourceId: resource?.id, values, formMeta: { onSuccess, onClose, conditionalValidation: true } })
  return true
}

export default updateResourceThroughModal
