import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { colors } from '@PipelineDeals/shared-react-components'
import { supportedMergeTags } from 'modules/composerMergeTags'

import MergeTag from 'Email/components/merge_tags/MergeTag'

import styled from 'styled-components'

const Container = styled.div`
  padding: 10px 0;
  text-align: left;
  max-height: ${props => props.forWysiwyg ? 'auto' : '500px'};
  overflow: auto;
`

const Header = styled.div`
  margin: 10px 0 0px;
  padding: 0 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  color: ${colors.text.primary};
`

const SubHeader = styled.div`
  margin: 16px 0 4px 0;
  padding: 0 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  color: ${colors.text.tertiary};
`

const Divider = styled.hr`
  color: ${colors.structure.tertiary};
  margin: 15px 20px;
`

const MergeTagsContent = ({ query = '', forWysiwyg, forSubject, forDeal, onTagClick }) => {
  const IGNORED_HEADERS = ['global']
  const HEADER_TRANSLATIONS = { customFields: 'Custom Fields', systemFields: 'System Fields' }

  const [mergeTags] = useState(supportedMergeTags({ forSubject, forDeal }))
  const [filteredMergeTags, setFilteredMergeTags] = useState(mergeTags)

  useEffect(() => {
    let filteredData = {}

    if (query) {
      Object.keys(mergeTags).forEach(tagType => {
        filteredData[tagType] = {}

        Object.keys(mergeTags[tagType]).forEach(subHeader => {
          filteredData[tagType][subHeader] = mergeTags[tagType][subHeader].filter(tag => tag.label.toLowerCase().includes(query.toLowerCase()))
        })
      })
    } else {
      filteredData = mergeTags
    }

    setFilteredMergeTags(filteredData)
  }, [query, mergeTags])

  const containsMergeTags = (data) => {
    if (Array.isArray(data)) {
      return data.length > 0
    } else if (typeof data === 'object') {
      return Object.values(data).some(value => containsMergeTags(value))
    } else {
      return false
    }
  }

  const headerLabel = (header) => (
    HEADER_TRANSLATIONS.hasOwnProperty(header) ? HEADER_TRANSLATIONS[header] : header
  )

  const renderMergeTags = useCallback(() => {
    const nodes = []

    Object.keys(filteredMergeTags).forEach((tagType, index) => {
      if (!containsMergeTags(filteredMergeTags[tagType])) return

      if (!IGNORED_HEADERS.includes(tagType)) {
        nodes.push(<Header key={`${tagType}`}>{headerLabel(tagType)}</Header>)
      }

      Object.keys(filteredMergeTags[tagType]).forEach(subHeader => {
        if (containsMergeTags(filteredMergeTags[tagType][subHeader]) && !IGNORED_HEADERS.includes(subHeader)) {
          nodes.push(<SubHeader key={`${tagType}-${subHeader}`}>{headerLabel(subHeader)}</SubHeader>)
        }

        filteredMergeTags[tagType][subHeader].forEach(tag => {
          nodes.push(<MergeTag key={tag.value} label={tag.label} value={tag.value} forWysiwyg={forWysiwyg} onTagClick={onTagClick} />)
        })
      })

      if (index < Object.keys(mergeTags).length - 1) {
        nodes.push(<Divider key={`${tagType}-divider`} />)
      }
    })

    return nodes
  }, [filteredMergeTags])

  return (
    <Container forWysiwyg={forWysiwyg}>
      {renderMergeTags()}
    </Container>
  )
}

MergeTagsContent.propTypes = {
  query: PropTypes.string,
  forWysiwyg: PropTypes.bool,
  forSubject: PropTypes.bool,
  forDeal: PropTypes.bool,
  onTagClick: PropTypes.func
}

export default MergeTagsContent
