import {
  CUSTOM_DASHBOARD_CREATE,
  CUSTOM_DASHBOARD_DELETE,
  CUSTOM_DASHBOARD_DUPLICATE,
  CUSTOM_DASHBOARD_SHARE,
  CUSTOM_DASHBOARD_STAR,
  CUSTOM_DASHBOARD_UPDATE
} from 'permissions/actions'

export const CUSTOM_DASHBOARD = '@@permissions/policy/customDashboard'

export default {
  [CUSTOM_DASHBOARD_CREATE]: (user) => !user.read_only,
  [CUSTOM_DASHBOARD_DELETE]: (user) => !user.read_only,
  [CUSTOM_DASHBOARD_DUPLICATE]: (user) => !user.read_only,
  [CUSTOM_DASHBOARD_SHARE]: (user) => !user.read_only,
  [CUSTOM_DASHBOARD_STAR]: (user) => !user.read_only,
  [CUSTOM_DASHBOARD_UPDATE]: (user) => !user.read_only
}
