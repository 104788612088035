/* eslint-disable
    no-undef,
    no-unused-vars,
    no-useless-escape,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.SERVER_DATE_FORMAT = 'yyyy/mm/dd'

Helpers.formatDate = function (dateString, format) {
  if (!dateString) { return '' }
  try {
    const date = Date.parse(dateString.replace(/\s[\+\-]\d{4}/, ''))
    if (!date) { return dateString }
    if (date instanceof Date) {
      return date.format(format || ppd.dateFormat)
    } else {
      return dateString
    }
  } catch (e) {
    return ''
  }
}

Helpers.formatYear = function (dateString) {
  return this.formatDate(dateString, 'yyyy')
}

Helpers.formatDateSmall = function (dateString) {
  return this.formatDate(dateString, 'mmm dS')
}

Helpers.formatPrettyDate = function (dateString) {
  return this.formatDate(dateString, 'dddd, mmmm dS yyyy')
}

Helpers.formatTime = function (dateString) {
  return this.formatDate(dateString, ppd.timeFormat)
}

Helpers.formatTimeAgo = dateTimeString => moment(dateTimeString).fromNow()

Helpers.formatDateForDatepicker = dateString => dateString.replace(/yyyy/, 'yy')

Helpers.daysSinceNow = function (dateString, sinceDateString) {
  try {
    const now = sinceDateString ? Date.parse(sinceDateString) : new Date()
    const created = Date.parse(dateString)
    const oneDayInMs = 24 * 60 * 60 * 1000
    const ageInDaysFloat = Math.abs((now.getTime() - created.getTime()) / (oneDayInMs))
    const ageInDays = Math.floor(ageInDaysFloat)
    return ageInDays
  } catch (e) {
    return 0
  }
}

Helpers.daysSince = Helpers.daysSinceNow

Handlebars.registerHelper('formatDate', dateString => Helpers.formatDate(dateString))

Handlebars.registerHelper('formatTime', dateString => Helpers.formatTime(dateString))

Helpers.formatDateTime = function (dateString, joiner) {
  if (joiner == null) { joiner = '' }
  return `${Helpers.formatDate(dateString)} ${joiner} ${Helpers.formatTime(dateString)}`
}

Handlebars.registerHelper('formatDateTime', dateString => Helpers.formatDateTime(dateString))

Handlebars.registerHelper('formatTimeAgo', dateTimeString => Helpers.formatTimeAgo(dateTimeString))

// Creating Date Pickers
//
// tldr: Server needs yyyy/mm/dd format, user needs other formats
//
// We need to create a field that the user sees and a field that the
// server will receive. To acomplish this we take the real field, clone
// it and use the clone as the user visible field.
Helpers.createDatepicker = function ($real, options) {
  if (options == null) { options = {} }
  const $picker = $real.clone() // Clone the real field
  $picker.attr({
    autocomplete: 'off', // No autocomplete please
    id: `_date_select_id_for_${$real.attr('id')}`, // Avoid id clashes
    name: `_date_select_for_${$real.attr('name')}`, // Avoid name clashes
    value: Helpers.formatDate($real.val())
  }) // Set the display value to user format

  const opts = {
    opens: 'left',
    singleDatePicker: true,
    autoUpdateInput: false,
    format: ((ppd.dateFormat === 'mm/dd/yyyy') && 'MM/DD/YYYY') || 'DD/MM/YYYY',
    showDropdowns: true,
    minDate: moment().subtract('10', 'years'),
    maxDate: moment().add('10', 'years')
  }

  if (options.startDate) { opts.startDate = moment(options.startDate) }

  const callback = (start, end) => $real.val(start.format('YYYY/MM/DD'))

  $picker.daterangepicker(_.extend(opts, options), callback)

  // Append our fake field after the real field
  $real.after($picker)
  $real.addClass('hidden').hide()
  $picker.addClass('date_select')
  $picker.prop('readonly', true)

  if (options.addClearButton) {
    $picker.data('daterangepicker').container.append('<div><button class="clear btn btn-default" type="button" data-cypress="clear-date">clear</button></div>')

    $picker.data('daterangepicker').container.find('button.clear').on('click', function (e) {
      e.preventDefault()
      $picker.data('daterangepicker').clickCancel()
      $picker.data('daterangepicker').element.val('')
      return $picker.data('daterangepicker').element.siblings('input.date_select.hidden').attr('value', '')
    })
  }

  return $picker
}

// NOT CURRENTLY USED
Helpers.updateEndDate = function ($start) {
  const val = $start.val()
  const li = $start.parents('li').next()
  const startPicker = $start.parents('form').find('input.start_date.hasDatepicker')
  const startHolder = $start.parents('form').find('input.start_date.hidden')
  const endPicker = li.find('input.end_date.hasDatepicker')
  const endHolder = li.find('input.end_date.hidden')

  // If we try to parse dates, we inevitably screw up conversions.
  // Easiest just to set the end vals to be the same as the start vals
  if (endPicker && endHolder) {
    endPicker.val(startPicker.val())
    return endHolder.val(startHolder.val())
  }
}

Helpers.isPastDue = function (date) {
  return moment(date) < moment().startOf('day')
}
