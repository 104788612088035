import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'

import fetcher from 'modules/fetcher'

import * as constants from './constants'
import { fetchProfileSettingsSuccess } from './actions'

export function * fetchProfileSettingsSaga ({ payload }) {
  try {
    const response = yield call(fetcher, '/api/internal/users/profile', {
      method: 'GET'
    })
    yield put(fetchProfileSettingsSuccess(response))
  } catch (exception) {
    console.error(exception.message)
  }
}

export function * watchFetchProfileSettingsSaga () {
  yield call(
    takeEvery,
    constants.MY_PROFILE_SETTINGS_FETCH,
    fetchProfileSettingsSaga
  )
}
