import * as constants from './constants'

export const fetchCustomFields = () => ({ type: constants.FETCH_CUSTOM_FIELDS })
export const receiveCustomFields = payload => ({ payload, type: constants.RECEIVE_CUSTOM_FIELDS })

export const deleteCustomFieldGroup = payload => ({ payload, type: constants.DELETE_CUSTOM_FIELD_GROUP })
export const deleteCustomFieldGroupFailure = payload => ({ payload, type: constants.DELETE_CUSTOM_FIELD_GROUP_FAILURE })
export const deleteCustomFieldGroupSuccess = payload => ({ payload, type: constants.DELETE_CUSTOM_FIELD_GROUP_SUCCESS })

export const deleteCustomFieldLabel = id => ({ id, type: constants.DELETE_CUSTOM_FIELD_LABEL })
export const deleteCustomFieldLabelFailure = payload => ({ payload, type: constants.DELETE_CUSTOM_FIELD_LABEL_FAILURE })
export const deleteCustomFieldLabelSuccess = payload => ({ payload, type: constants.DELETE_CUSTOM_FIELD_LABEL_SUCCESS })

export const editCustomFieldGroupFormFailure = payload => ({ payload, type: constants.EDIT_CUSTOM_FIELD_GROUP_FORM_FAILURE })
export const editCustomFieldGroupFormSuccess = payload => ({ payload, type: constants.EDIT_CUSTOM_FIELD_GROUP_FORM_SUCCESS })

export const editCustomFieldLabelFormFailure = payload => ({ payload, type: constants.EDIT_CUSTOM_FIELD_LABEL_FORM_FAILURE })
export const editCustomFieldLabelFormSuccess = payload => ({ payload, type: constants.EDIT_CUSTOM_FIELD_LABEL_FORM_SUCCESS })

export const markCustomFieldLabelDropdownEntryAsNotNew = index => ({ index, type: constants.MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_AS_NOT_NEW })
export const markCustomFieldLabelDropdownEntryForDeletion = payload => ({ payload, type: constants.MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_FOR_DELETION })
export const moveCustomFieldLabelDropdownEntry = (id, atIndex) => ({ atIndex, id, type: constants.MOVE_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY })
export const sortCustomFieldLabelDropdownEntries = (direction) => ({ direction, type: constants.SORT_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRIES })

export const moveCustomFieldLabelCustomFieldGroup = payload => ({ payload, type: constants.MOVE_CUSTOM_FIELD_LABEL_CUSTOM_FIELD_GROUP })

export const newCustomFieldGroupFormFailure = payload => ({ payload, type: constants.NEW_CUSTOM_FIELD_GROUP_FORM_FAILURE })
export const newCustomFieldGroupFormSuccess = payload => ({ payload, type: constants.NEW_CUSTOM_FIELD_GROUP_FORM_SUCCESS })

export const newCustomFieldLabelFormFailure = payload => ({ payload, type: constants.NEW_CUSTOM_FIELD_LABEL_FORM_FAILURE })
export const newCustomFieldLabelFormSuccess = payload => ({ payload, type: constants.NEW_CUSTOM_FIELD_LABEL_FORM_SUCCESS })

export const repositionCustomFieldLabel = payload => ({ payload, type: constants.REPOSITION_CUSTOM_FIELD_LABEL })
export const repositionCustomFieldLabelFailure = payload => ({ payload, type: constants.REPOSITION_CUSTOM_FIELD_LABEL_FAILURE })
export const repositionCustomFieldLabelSuccess = payload => ({ payload, type: constants.REPOSITION_CUSTOM_FIELD_LABEL_SUCCESS })

export const updateCustomFieldLabel = payload => ({ payload, type: constants.UPDATE_CUSTOM_FIELD_LABEL })
export const updateCustomFieldLabelSuccess = payload => ({ payload, type: constants.UPDATE_CUSTOM_FIELD_LABEL_SUCCESS })
export const updateCustomFieldLabelFailure = payload => ({ payload, type: constants.UPDATE_CUSTOM_FIELD_LABEL_FAILURE })
