import React from 'react'

import {
  SectionTitle,
  Row,
  Left,
  Right,
  RecordIcon,
  Owner
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'
import Avatar from 'components/BusinessCard/components/Avatar'
import Name from 'components/BusinessCard/components/Name'

const Company = () => {
  const { cardMeta: { showOnlyFields }, callbacks: { avatarUpdateCallback }, resource: company } = useBusinessCard()

  return (
    <>
      <SectionTitle>
        <Row>
          {avatarUpdateCallback ? (
            <Avatar record={company} recordType="company" callback={avatarUpdateCallback}>
              {!/missing/.test(company.image_thumb_url)
                ? <img src={company.image_thumb_url} alt={company.name} />
                : <RecordIcon className={`fas ${company.customer ? 'fa-users' : 'fa-building'}`} />}
            </Avatar>
          ) : (
            <Left>
              {!/missing/.test(company.image_thumb_url)
                ? <img src={company.image_thumb_url} alt={company.name} />
                : <RecordIcon className={`fas ${company.customer ? 'fa-users' : 'fa-building'}`} />}
            </Left>
          )}

          <Right data-cypress="business-card-company-name" fullWidth>
            <Name />

            {showOnlyFields.includes('owner') && (
              <Owner className="owner">
                {company.owner_name}
              </Owner>
            )}
          </Right>
        </Row>
      </SectionTitle>
    </>
  )
}

export default Company
