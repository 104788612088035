/**
 * SyncMessageUtil is responsible for namespacing miscellaneous functionality
 * that deals with the BackboneModelDeal @type.
 */
export const BackboneModelDealUtil = {
  /**
   * prepopulateDealModal given an instance of BackboneModelPerson, will create
   * a BackboneModelDeal with prepopulated fields.
   *
   * @param {BackboneModelPerson} person
   * @returns {BackboneModelDeal}
   */
  prepopulateDealFromPerson: (person) => {
    /**
     * @type {BackboneModelAttributesDeal}
     */
    var dealAttributes = {
      name: person.get('company_name'),
      add_contact_ids: person.get('id'),
      company_id: person.get('company_id'),
      company_name: person.get('company_name'),
      source_id: person.get('lead_source_id')
    }

    return new App.Models.Deal(dealAttributes)
  }
}
