export const CALENDAR_ENTRY_PRIORITY_CREATE = 'CALENDAR_ENTRY_PRIORITY_CREATE'
export const CALENDAR_ENTRY_PRIORITY_CREATE_SUBMIT = 'CALENDAR_ENTRY_PRIORITY_CREATE_SUBMIT'
export const CALENDAR_ENTRY_PRIORITY_CREATE_FAILURE = 'CALENDAR_ENTRY_PRIORITY_CREATE_FAILURE'

export const CALENDAR_ENTRY_PRIORITY_UPDATE = 'CALENDAR_ENTRY_PRIORITY_UPDATE'
export const CALENDAR_ENTRY_PRIORITY_UPDATE_SUBMIT = 'CALENDAR_ENTRY_PRIORITY_UPDATE_SUBMIT'
export const CALENDAR_ENTRY_PRIORITY_UPDATE_FAILURE = 'CALENDAR_ENTRY_PRIORITY_UPDATE_FAILURE'

export const CALENDAR_ENTRY_PRIORITY_DELETE = 'CALENDAR_ENTRY_PRIORITY_DELETE'
export const CALENDAR_ENTRY_PRIORITY_DELETE_SUBMIT = 'CALENDAR_ENTRY_PRIORITY_DELETE_SUBMIT'
export const CALENDAR_ENTRY_PRIORITY_DELETE_FAILURE = 'CALENDAR_ENTRY_PRIORITY_DELETE_FAILURE'
