import {
  OTHER_EMAIL_CREATE,
  OTHER_EMAIL_CREATE_FAILURE,
  OTHER_EMAIL_UPDATE,
  OTHER_EMAIL_UPDATE_FAILURE,
  OTHER_EMAIL_DELETE,
  OTHER_EMAIL_DELETE_FAILURE
} from '../constants'

export const otherEmailCreate = payload => ({ payload, type: OTHER_EMAIL_CREATE })
export const otherEmailCreateFailure = payload => ({ payload, type: OTHER_EMAIL_CREATE_FAILURE })
export const otherEmailUpdate = payload => ({ payload, type: OTHER_EMAIL_UPDATE })
export const otherEmailUpdateFailure = payload => ({ payload, type: OTHER_EMAIL_UPDATE_FAILURE })
export const otherEmailDelete = payload => ({ payload, type: OTHER_EMAIL_DELETE })
export const otherEmailDeleteFailure = payload => ({ payload, type: OTHER_EMAIL_DELETE_FAILURE })
