import {
  GLOBAL_SEARCH,
  NAVIGATION_HOME,
  NAVIGATION_COMPANIES,
  NAVIGATION_PEOPLE,
  NAVIGATION_AGENDA,
  NAVIGATION_CALENDAR,
  NAVIGATION_EMAIL,
  NAVIGATION_REPORTS,
  NAVIGATION_GOALS,
  NAVIGATION_FILES,
  NAVIGATION_RECYCLE_BIN,
  NAVIGATION_PROFILE,
  CREATE
} from 'permissions/actions'
import { userLevels } from 'permissions/userLevels'

export const NAVIGATION = '@@permissions/policy/navigation'

export default {
  [GLOBAL_SEARCH]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_HOME]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_COMPANIES]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_PEOPLE]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_AGENDA]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_CALENDAR]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_EMAIL]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_REPORTS]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_GOALS]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_FILES]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_RECYCLE_BIN]: (user, options) => user.level !== userLevels.EXTERNAL,
  [NAVIGATION_PROFILE]: (user, options) => user.level !== userLevels.EXTERNAL,
  [CREATE]: (user, options) => !user.read_only && user.level !== userLevels.EXTERNAL
}
