import { colors } from '@PipelineDeals/shared-react-components'

export const CALENDAR_UPDATE_LOADING = 'CALENDAR_UPDATE_LOADING'
export const CALENDAR_FETCH = 'CALENDAR_FETCH'
export const CALENDAR_FETCH_SUCCESS = 'CALENDAR_FETCH_SUCCESS'
export const CALENDAR_UPDATE_USERS = 'CALENDAR_UPDATE_USERS'
export const CALENDAR_UPDATE_USER_COLORS = 'CALENDAR_UPDATE_USER_COLORS'
export const CALENDAR_UPDATE_EVENT_TYPES = 'CALENDAR_UPDATE_EVENT_TYPES'
export const CALENDAR_UPDATE_EVENT_TYPE_COLORS = 'CALENDAR_UPDATE_EVENT_TYPE_COLORS'
export const CALENDAR_SET_DATE_RANGE = 'CALENDAR_SET_DATE_RANGE'
export const CALENDAR_MARK_COMPLETE = 'CALENDAR_MARK_COMPLETE'
export const CALENDAR_DELETE_EVENT = 'CALENDAR_DELETE_EVENT'
export const CALENDAR_SET_USERS_SETTINGS = 'CALENDAR_SET_USERS_SETTINGS'
export const CALENDAR_FETCH_USERS_SETTINGS = 'CALENDAR_FETCH_USERS_SETTINGS'
export const CALENDAR_FETCH_USERS_SETTINGS_SUCCESS = 'CALENDAR_FETCH_USERS_SETTINGS_SUCCESS'
export const CALENDAR_FETCH_USER_DATA = 'CALENDAR_FETCH_USER_DATA'
export const CALENDAR_SET_VIEW = 'CALENDAR_SET_VIEW'
export const CALENDAR_SET_DATE = 'CALENDAR_SET_DATE'
export const CALENDAR_UPDATE_EVENT = 'CALENDAR_UPDATE_EVENT'
export const CALENDAR_UPDATE_EVENT_STATE = 'CALENDAR_UPDATE_EVENT_STATE'
export const CALENDAR_FETCH_FILTERS = 'CALENDAR_FETCH_FILTERS'
export const CALENDAR_FETCH_FILTERS_SUCCESS = 'CALENDAR_FETCH_FILTERS_SUCCESS'
export const CALENDAR_CREATE_FILTER = 'CALENDAR_CREATE_FILTER'
export const CALENDAR_CREATE_FILTER_SUCCESS = 'CALENDAR_CREATE_FILTER_SUCCESS'
export const CALENDAR_UPDATE_FILTER = 'CALENDAR_UPDATE_FILTER'
export const CALENDAR_UPDATE_FILTER_SUCCESS = 'CALENDAR_UPDATE_FILTER_SUCCESS'
export const CALENDAR_DELETE_FILTER = 'CALENDAR_DELETE_FILTER'
export const CALENDAR_DELETE_FILTER_SUCCESS = 'CALENDAR_DELETE_FILTER_SUCCESS'
export const CALENDAR_SELECT_FILTER = 'CALENDAR_SELECT_FILTER'
export const CALENDAR_SELECT_FILTER_SUCCESS = 'CALENDAR_SELECT_FILTER_SUCCESS'
export const CALENDAR_SELECT_DEFAULT_FILTER = 'CALENDAR_SELECT_DEFAULT_FILTER'

export const CALENDAR_COLORS = [
  colors.primary.blue,
  colors.reporting.green,
  colors.reporting.pink,
  colors.reporting.purple,
  colors.reporting.blue,
  colors.reporting.yellow,
  '#BC4600',
  '#BC002A',
  '#BCB600',
  '#ef6c00',
  colors.primary.dark
]

export const CALENDAR_EVENT_TYPE_ICONS = {
  CalendarTask: 'far fa-calendar-check',
  CalendarTaskOverdue: 'fas fa-exclamation-circle',
  CalendarEvent: 'far fa-calendar-alt'
}

export const CALENDAR_REPEAT_OPTIONS = [
  "Doesn't Repeat",
  'Daily',
  'Every Weekday',
  'Weekly',
  'Weekly on Monday, Wednesday, Friday',
  'Weekly on Tuesday, Thursday',
  'Every two weeks',
  'Monthly',
  'Every 3 months',
  'Every 6 months',
  'Yearly'
]
