/* eslint-disable no-undef, camelcase, new-cap, no-unused-vars */
if (!window.App) {
  window.App = {}
}
window.App.Models = {}
window.App.Collections = {}

window.App.Views = {}
window.App.Views.Notes = {}
window.App.Views.AssociatedDeals = {}
window.App.Views.CalendarTasks = {}
window.App.Views.CalendarEvents = {}
window.App.Views.Charts = {}
window.App.Views.CustomFields = {}
window.App.Views.CustomFieldGroups = {}
window.App.Views.FormFields = {}
window.App.Views.Documents = {}
window.App.Views.Deals = {}
window.App.Views.DealStages = {}
window.App.Views.DealLossReasons = {}
window.App.Views.DocumentTags = {}
window.App.Views.EventCategories = {}
window.App.Views.People = {}
window.App.Views.Companies = {}
window.App.Views.Pagination = {}
window.App.Views.SortedTable = {}
window.App.Views.Admin = {}
window.App.Views.Imports = {}
window.App.Views.TodoTemplates = {}
