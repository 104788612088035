import moment from 'moment'

const checkDate = (trigger, value) => {
  const valueDate = value ? moment(value) : null

  switch (trigger.name) {
    case 'LongerAgoThan':
      return value ? longerAgoThan(trigger, valueDate) : null
    case 'WithinTheLast':
      return value ? withinTheLast(trigger, valueDate) : null
    case 'SameDay':
      return value ? sameDay(trigger, valueDate) : null
    case 'WithinTheNext':
      return value ? withinTheNext(trigger, valueDate) : null
    case 'FurtherAheadThan':
      return value ? furtherAheadThan(trigger, valueDate) : null
    case 'Present':
      return present(trigger, valueDate)
    case 'NotPresent':
      return notPresent(trigger, valueDate)
    default:
      return null
  }
}

export default checkDate

const longerAgoThan = (trigger, value) => {
  const nowDate = moment()

  if (value < nowDate.clone().subtract(trigger.operand, 'days')) {
    return {
      message: `${value.format('YYYY-MM-DD')} is longer ago than ${trigger.operand} days`
    }
  }
  return null
}

const withinTheLast = (trigger, value) => {
  const nowDate = moment()
  const pastdate = nowDate.clone().subtract(trigger.operand, 'days')

  if (value < nowDate && value >= pastdate) {
    return {
      message: `${value} is within the last ${trigger.operand} days`
    }
  }
  return null
}

const sameDay = (trigger, value) => {
  const nowDate = moment()
  if (value && value.isSame(nowDate, 'day')) {
    return {
      message: `${value} is the same day`
    }
  }
  return null
}

const withinTheNext = (trigger, value) => {
  const nowDate = moment()
  const futureDate = nowDate.clone().add(trigger.operand, 'days')

  if (value > nowDate && value <= futureDate) {
    return {
      message: `${value} is within the next ${trigger.operand} days`
    }
  }
  return null
}

const furtherAheadThan = (trigger, value) => {
  const futureDate = moment().add(trigger.operand, 'days')

  if (value > futureDate) {
    return {
      message: `${value} is further ahead than ${trigger.operand} days`
    }
  }
  return null
}

const present = (trigger, value) => {
  if (value !== null) {
    return {
      message: 'date is present'
    }
  }
  return null
}

const notPresent = (trigger, value) => {
  if (value === null) {
    return {
      message: 'date is not present'
    }
  }
  return null
}
