import { takeEvery } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import * as actions from '../actions'
import * as constants from '../constants'
import { getNextCustomFieldLabelPosition } from '../selectors'

import PipelineDeals from 'PipelineDeals'
const pd = new PipelineDeals({}, '/api/v3')

export function * newCustomFieldGroupSaga ({ payload }) {
  try {
    const customFieldGroups = yield call([pd, pd.customFieldGroups])
    const response = yield call([customFieldGroups, customFieldGroups.create], payload)

    yield put(actions.newCustomFieldGroupFormSuccess(response))
  } catch (exception) {
    console.error(exception)

    yield put(actions.newCustomFieldGroupFormFailure({ _error: exception.message }))
  }
}

export function * watchNewCustomFieldGroupSaga () {
  yield call(takeEvery, constants.NEW_CUSTOM_FIELD_GROUP_FORM_SUBMIT, newCustomFieldGroupSaga)
}

export function * editCustomFieldGroupSaga ({ payload }) {
  try {
    const customFieldGroups = yield call([pd, pd.customFieldGroups])
    const response = yield call([customFieldGroups, customFieldGroups.update], payload)

    yield put(actions.editCustomFieldGroupFormSuccess(response))
  } catch (exception) {
    console.error(exception)

    yield put(actions.editCustomFieldGroupFormFailure({ _error: exception.message }))
  }
}

export function * watchEditCustomFieldGroupSaga () {
  yield call(takeEvery, constants.EDIT_CUSTOM_FIELD_GROUP_FORM_SUBMIT, editCustomFieldGroupSaga)
}

export function * deleteCustomFieldGroupSaga ({ payload }) {
  try {
    const customFieldGroups = yield call([pd, pd.customFieldGroups])
    yield call([customFieldGroups, customFieldGroups.delete], payload.id)

    const nextPosition = yield select(getNextCustomFieldLabelPosition, { type: payload.type })

    yield put(actions.deleteCustomFieldGroupSuccess({ group: payload, nextPosition: nextPosition }))
  } catch (exception) {
    console.error(exception)

    yield put(actions.deleteCustomFieldGroupFailure({ _error: exception.message }))
  }
}

export function * watchDeleteCustomFieldGroupSaga () {
  yield call(takeEvery, constants.DELETE_CUSTOM_FIELD_GROUP, deleteCustomFieldGroupSaga)
}
