import { DEAL_FETCH_SUCCESS } from 'Deals/constants'
import { conditionalFieldsValidation } from 'Deals/actions'

const dealProfileConditionalFieldsUpdater = store => next => action => {
  if (action.type === DEAL_FETCH_SUCCESS) {
    const result = next(action)
    store.dispatch(conditionalFieldsValidation())
    return result
  }

  return next(action)
}

export default dealProfileConditionalFieldsUpdater
