export const MODAL_MESSAGE_COMPOSER = '@@modals/MODAL_MESSAGE_COMPOSER'

export const SIGNATURE_PROMPT_MODAL = '@@modals/SIGNATURE_PROMPT_MODAL'

export const SIGNATURE_EDITOR_MODAL = '@@modals/SIGNATURE_EDITOR_MODAL'

export const TEMPLATE_MODAL = '@@modals/TEMPLATE_MODAL'

export const EMAIL_MODAL = '@@modals/EMAIL_MODAL'

const defaultPromptsSetup = 'Use a modern tone. Be concise.'

export const PROMPTS = {
  sayHello: `Write an email to say hello. ${defaultPromptsSetup}`,
  bookCall: `Write an email to book a call. ${defaultPromptsSetup}`,
  sayThankYou: `Write an email to say thank you. ${defaultPromptsSetup}`,
  closeDeal: `Write an email to close a deal. ${defaultPromptsSetup}`,
  followUp: `Write an email to follow up. ${defaultPromptsSetup}`,
  rewriteText: 'Rewrite the given text.',
  moreEngaging: 'Rewrite the given text to be more engaging.',
  rephrase: 'Rephrase the given text.',
  extend: 'Extend the given text.',
  shorten: 'Shorten the given text'
}

export const DEFAULT_PROMPTS = [
  {
    key: 'sayHello',
    icon: 'fas fa-hand-paper',
    content: 'Say Hello'
  },
  {
    key: 'bookCall',
    icon: 'fas fa-phone-alt',
    content: 'Book a call'
  },
  {
    key: 'sayThankYou',
    icon: 'fas fa-hand-holding-heart',
    content: 'Say Thank You'
  },
  {
    key: 'closeDeal',
    icon: 'fas fa-handshake',
    content: 'Close a deal'
  },
  {
    key: 'followUp',
    icon: 'fas fa-mail-bulk',
    content: 'Follow up'
  }
]

export const REWRITE_PROMPTS = [
  {
    key: 'moreEngaging',
    icon: 'fas fa-star',
    content: 'More Engaging'
  },
  {
    key: 'rephrase',
    icon: 'fas fa-pen',
    content: 'Rephrase'
  },
  {
    key: 'extend',
    icon: 'fas fa-keyboard',
    content: 'Extend'
  },
  {
    key: 'shorten',
    icon: 'fas fa-compress-alt',
    content: 'Shorten'
  }
]

export const AI_EMAIL_PLACEHOLDER_REGEX = /\[.*?\]/
