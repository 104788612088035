/* global, getDay */

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import moment from 'moment'
import fetcher from 'modules/fetcher'
import { getUTCOffset } from 'lib/helpers/date'

import formValidation from './formValidation'
import FormFields from './FormFields'
import ThankYou from './ThankYou'
import AlreadyRequested from './AlreadyRequested'

const Form = () => {
  const { id: userId, accountState, dateFormat, fullName, email, workPhone } = window.User || { dateFormat: 'us', fullName: '', email: '', workPhone: '' }
  const { holidays, officeHours } = window.instaDemo || { holidays: [], officeHours: { open: '09:00', close: '19:30' } }

  const [userName, setUserName] = useState(fullName)
  const [emailAddress, setEmailAddress] = useState(email)
  const [phoneNumber, setPhoneNumber] = useState(workPhone)
  const [demoDate, setDemoDate] = useState(null)
  const [demoTime, setDemoTime] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [error, setError] = useState(null)
  const [, rerenderForm] = useState()

  const dataLayerEventQualifier = () => {
    if (userId) return (accountState === 'paid' ? '_paid' : '_trial')
    return ''
  }

  useEffect(() => {
    window.dataLayer.push({ event: `demo_form_view${dataLayerEventQualifier()}` })
  }, [])

  const validateForm = useMemo(() => formValidation({ userName, emailAddress, phoneNumber, demoDate, demoTime }), [])
  validateForm.validate({ userName, emailAddress, phoneNumber, demoDate, demoTime })

  const combineDateAndTime = () => {
    const formattedDate = moment(demoDate).format('YYYY-MM-DD')
    const formattedTime = demoTime.value
    return moment(`${formattedDate} ${formattedTime}`).toDate()
  }

  const submitDemoRequest = useCallback(() => {
    if (!validateForm.isFormValid()) {
      rerenderForm(Date.now())
      return
    }

    const url = '/meetings'
    fetcher(url, {
      method: 'POST',
      body: JSON.stringify({
        meeting: {
          meeting_type: 'demo',
          start_time: combineDateAndTime()
        },
        person: {
          full_name: userName,
          email: emailAddress,
          phone: phoneNumber,
          time_zone_offset: getUTCOffset(demoDate)
        }
      })
    }).then((res) => {
      res.ok ? window.dataLayer.push({ event: `demo_form_success${dataLayerEventQualifier()}` }) : setError(res)

      setFormSubmitted(true)
    })
  }, [userName, emailAddress, phoneNumber, demoDate, demoTime])

  const displayDemoFormat = useMemo(() => {
    return dateFormat === 'us' ? 'MM/dd/yy' : 'dd/MM/yy'
  }, [dateFormat])

  const renderFormFields = () =>
    <FormFields
      submitDemoRequest={submitDemoRequest}
      displayDemoFormat={displayDemoFormat}
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      userName={userName}
      setUserName={setUserName}
      demoDate={demoDate}
      setDemoDate={setDemoDate}
      demoTime={demoTime}
      setDemoTime={setDemoTime}
      validateForm={validateForm}
      holidays={holidays}
      officeHours={officeHours}
    />

  const renderThankYou = () =>
    <ThankYou emailAddress={emailAddress} demoDateAndTime={combineDateAndTime()} dateFormat={dateFormat} />

  const renderError = () =>
    <AlreadyRequested startTime={new Date(error.meeting.start_time)} dateFormat={dateFormat} />

  return (
    <>
      {error ? renderError() : formSubmitted ? renderThankYou() : renderFormFields()}
    </>
  )
}

export default Form
