import {
  ACCOUNT_EXPORT_CREATE,
  ACCOUNT_EXPORT_CREATE_FAILURE,
  ACCOUNT_EXPORT_UPDATE,
  ACCOUNT_EXPORT_UPDATE_FAILURE,
  ACCOUNT_EXPORT_DELETE,
  ACCOUNT_EXPORT_DELETE_FAILURE
} from './constants'

export const accountExportUpdate = payload => ({ payload, type: ACCOUNT_EXPORT_UPDATE })
export const accountExportUpdateFailure = payload => ({ payload, type: ACCOUNT_EXPORT_UPDATE_FAILURE })

export const accountExportCreate = payload => ({ payload, type: ACCOUNT_EXPORT_CREATE })
export const accountExportCreateFailure = payload => ({ payload, type: ACCOUNT_EXPORT_CREATE_FAILURE })

export const accountExportDelete = payload => ({ payload, type: ACCOUNT_EXPORT_DELETE })
export const accountExportDeleteFailure = payload => ({ payload, type: ACCOUNT_EXPORT_DELETE_FAILURE })
