import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'

import {
  personStatusCreate,
  personStatusCreateFailure,
  personStatusUpdate,
  personStatusUpdateFailure,
  personStatusDelete,
  personStatusDeleteFailure
} from '../actions'

import {
  PERSON_STATUS_CREATE_SUBMIT,
  PERSON_STATUS_UPDATE_SUBMIT,
  PERSON_STATUS_DELETE_SUBMIT
} from '../constants'

export function * personStatusCreateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'api/v3/admin/lead_statuses',
      {
        method: 'POST',
        body: JSON.stringify({
          lead_status: {
            name: payload.name,
            hex_color: payload.hexColor
          }
        })
      }
    )
    yield put(personStatusCreate(response))
  } catch (exception) {
    console.error(exception.message)
    yield put(personStatusCreateFailure(exception.message))
  }
}

export function * watchPersonStatusCreateSaga () {
  yield call(takeEvery, PERSON_STATUS_CREATE_SUBMIT, personStatusCreateSaga)
}

export function * personStatusUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      `api/v3/admin/lead_statuses/${payload.id}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          lead_status: {
            name: payload.name,
            hex_color: payload.hexColor
          }
        })
      }
    )
    yield put(personStatusUpdate(response))
  } catch (exception) {
    yield put(personStatusUpdateFailure(exception.message))
  }
}

export function * watchPersonStatusUpdateSaga () {
  yield call(takeEvery, PERSON_STATUS_UPDATE_SUBMIT, personStatusUpdateSaga)
}

export function * personStatusDeleteSaga ({ payload }) {
  try {
    yield call(
      fetcher,
      `api/v3/admin/lead_statuses/${payload.id}`,
      {
        method: 'DELETE',
        body: JSON.stringify({})
      }
    )
    yield put(personStatusDelete(payload))
  } catch (exception) {
    yield put(personStatusDeleteFailure(exception.message))
  }
}

export function * watchPersonStatusDeleteSaga () {
  yield call(takeEvery, PERSON_STATUS_DELETE_SUBMIT, personStatusDeleteSaga)
}
