import React from 'react'

import sanitizeAndLinkifyHtml from 'modules/sanitizeAndLinkifyHtml'

import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const Description = () => {
  const { cardMeta: { showOnlyFields }, resource } = useBusinessCard()

  if (!showOnlyFields.includes('description') || !resource.description) return null

  return (
    <Section>
      <Row>
        <Left>
          summary
        </Left>
        <Right>
          <div className="section" dangerouslySetInnerHTML={{ __html: sanitizeAndLinkifyHtml(resource.description) }} />
        </Right>
      </Row>
    </Section>
  )
}

export default Description
