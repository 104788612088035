import moment from 'moment'
import update from 'immutability-helper'

import {
  CUSTOMER_COMPANY_FETCH_SUCCESS,
  CUSTOMER_COMPANY_RELOAD,
  CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE,
  CUSTOMER_DEAL_NEW_SUCCESS,
  CUSTOMER_FETCH_ERROR,
  CUSTOMER_FETCH_SUCCESS,
  CUSTOMER_FETCH,
  CUSTOMER_MILESTONES_FILTER_ALL,
  CUSTOMER_PERSON_FILTER_ALL,
  CUSTOMER_COMPANY_FILTER_ALL,
  CUSTOMER_PERSON_NEW_SUCCESS,
  CUSTOMER_PROFILE_FETCH,
  CUSTOMER_PROFILE_RESET,
  CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES,
  CUSTOMER_FILTER_CHILDREN_COMPANIES,
  CUSTOMER_SORT_CHILDREN_COMPANIES
} from 'Customers/constants'
import { ACTIVITY_ANY_TIME, GENERIC_FILTER_ANY } from 'Profiles/constants/activities'

import { FETCH_STATES } from 'Profiles/constants/fetch'

const userId = window.ppd && window.ppd.User ? window.ppd.User.id : 0

export const initialState = {
  customerId: null,
  customer: {},
  fetching: FETCH_STATES.fetching,
  company: {},
  agenda: [],
  agendaFilters: {
    ownerFilter: userId,
    dateFilter: 'ANY_TIME',
    milestoneFilter: CUSTOMER_MILESTONES_FILTER_ALL
  },
  visibleUsers: [],
  milestones: [],
  people: [],
  peopleSort: { field: 'updated_at', direction: 'desc' },
  peopleFilters: {
    personFullName: '',
    personCompany: [],
    personEmail: '',
    personPhone: '',
    personRelationship: '',
    personOwner: [],
    personUpdatedAt: {},
    personIsKeyContact: ''
  },
  deals: [],
  dealSort: { field: 'updated_at', direction: 'desc' },
  dealFilters: {
    dealName: '',
    dealCompany: [],
    dealOwner: [],
    dealPrimaryContact: [],
    dealRevenueType: [],
    dealStage: [],
    dealStatus: [],
    dealUpdatedAt: {},
    dealValue: {}
  },
  activityFilters: {
    milestoneFilter: CUSTOMER_MILESTONES_FILTER_ALL,
    dateFilter: ACTIVITY_ANY_TIME,
    personFilter: CUSTOMER_PERSON_FILTER_ALL,
    companyFilter: CUSTOMER_COMPANY_FILTER_ALL,
    activityTabFilters: {
      [GENERIC_FILTER_ANY]: true
    },
    emailTabFilters: {
      [GENERIC_FILTER_ANY]: true,
      Email: false,
      'Trackable Email Action': false
    },
    searchQuery: '',
    currentTab: 'ALL'
  },
  files: [],
  fileSort: { field: 'created_at', direction: 'desc' },
  fileFilters: {
    fileTitle: '',
    fileOwner: [],
    fileUpdatedAt: {},
    fileSize: {},
    fileTags: []
  },
  reloading: false,
  conditionalFieldsValidation: {},
  childrenCompanies: [],
  childrenCompaniesSort: { field: 'name', direction: 'asc' },
  childrenCompaniesFilters: { name: [], owner: [], updatedAt: {}, value: {} }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_COMPANY_RELOAD:
      return { ...state, reloading: true }
    case CUSTOMER_PROFILE_RESET:
      return { ...initialState }
    case CUSTOMER_PROFILE_FETCH:
      return { ...initialState, customerId: action.payload.customerId }
    case CUSTOMER_FETCH:
      return { ...state, customerId: action.payload.customerId, fetching: FETCH_STATES.fetching }
    case CUSTOMER_FETCH_SUCCESS:
      // DO NOT USE window.readOnly. This is for Historical/Restorable permissions only. See: webpack/permissions/permissible.js
      window.readOnly = Boolean(action.payload.company.valid_at)

      return {
        ...update(state, { customer: { $set: action.payload }, company: { $set: action.payload.company } }),
        fetching: FETCH_STATES.fetchSuccess,
        reloading: false
      }
    case CUSTOMER_FETCH_ERROR:
      return { ...initialState, fetching: { ...FETCH_STATES.fetchError, fetchError: action.payload } }
    case CUSTOMER_COMPANY_FETCH_SUCCESS:
      return {
        ...update(state, { company: { $set: action.payload } }),
        reloading: false
      }
    case CUSTOMER_DEAL_NEW_SUCCESS:
      return update(state, {
        deals: { $push: [action.payload] }
      })
    case CUSTOMER_PERSON_NEW_SUCCESS:
      return update(state, {
        people: { $push: [action.payload] }
      })
    case 'CUSTOMERS_LOAD_TODOS':
      return update(state, {
        agenda: {
          $set: action.payload.map(todo => (
            { ...todo, moment_due_date: todo.unformatted_due_date && moment(todo.unformatted_due_date, ['MM-DD-YY', 'YYYY/MM/DD HH:mm:ss Z']) }
          ))
        }
      })
    case 'CUSTOMERS_UPDATE_TODO':
      return { ...state, agenda: state.agenda.map(todo => todo.id === action.payload.id ? action.payload : todo) }
    case 'CUSTOMERS_DELETE_TODO':
      return { ...state, agenda: state.agenda.filter(todo => todo.id !== action.payload.id) }
    case 'CUSTOMERS_UPDATE_TODO_FILTERS':
      return { ...state, agendaFilters: Object.assign({}, state.agendaFilters, action.payload) }
    case 'CUSTOMERS_LOAD_VISIBLE_USERS':
      return { ...state, visibleUsers: action.payload }
    case 'CUSTOMERS_LOAD_MILESTONES':
      return update(state, {
        milestones: {
          $set: action.payload.map(milestone => (
            { ...milestone, moment_date: milestone.date ? moment(milestone.date, 'YYYY/MM/DD') : null }
          ))
        }
      })
    case 'CUSTOMERS_CREATE_MILESTONE':
      return { ...state, milestones: state.milestones.concat([action.payload]) }
    case 'CUSTOMERS_UPDATE_MILESTONE':
      return { ...state, milestones: state.milestones.map((milestone) => milestone.id === action.payload.id ? action.payload : milestone) }
    case 'CUSTOMERS_DELETE_MILESTONE':
      return { ...state, milestones: state.milestones.filter(milestone => milestone.id !== action.payload.id) }
    case 'CUSTOMERS_LOAD_PEOPLE':
      return { ...state, people: action.payload }
    case 'CUSTOMERS_UPDATE_PERSON':
      return { ...state, people: state.people.map((person) => person.id === action.payload.id ? action.payload : person) }
    case 'CUSTOMERS_SORT_PEOPLE':
      return { ...state, peopleSort: action.payload }
    case CUSTOMER_FILTER_CHILDREN_COMPANIES:
      return { ...state, childrenCompaniesFilters: action.payload }
    case CUSTOMER_SORT_CHILDREN_COMPANIES:
      return { ...state, childrenCompaniesSort: action.payload }
    case 'CUSTOMERS_FILTER_PEOPLE':
      return { ...state, peopleFilters: action.payload }
    case 'CUSTOMERS_LOAD_FILES':
      return { ...state, files: action.payload }
    case 'CUSTOMERS_UPDATE_FILE':
      return { ...state, files: state.files.map((file) => file.id === action.payload.id ? action.payload : file) }
    case 'CUSTOMERS_CREATE_FILE':
      return { ...state, files: state.files.concat([action.payload]) }
    case 'CUSTOMERS_DELETE_FILE':
      return { ...state, files: state.files.filter(file => file.id !== action.payload.id) }
    case 'CUSTOMERS_SORT_FILES':
      return { ...state, fileSort: action.payload }
    case 'CUSTOMERS_FILTER_FILES':
      return { ...state, fileFilters: action.payload }
    case 'CUSTOMERS_LOAD_DEALS':
      return { ...state, deals: action.payload }
    case 'CUSTOMERS_SORT_DEALS':
      return { ...state, dealSort: action.payload }
    case 'CUSTOMERS_FILTER_DEALS':
      return { ...state, dealFilters: action.payload }
    case 'CUSTOMERS_UPDATE_ACTIVITY_FILTERS':
      return { ...state, activityFilters: { ...state.activityFilters, ...action.payload } }
    case CUSTOMER_CONDITIONAL_FIELDS_VALIDATION_UPDATE:
      return { ...state, conditionalFieldsValidation: action.payload }
    case CUSTOMER_FETCH_SUCCESS_CHILDREN_COMPANIES:
      return update(state, {
        childrenCompanies: {
          $set: action.payload
        }
      })
    default:
      return state
  }
}
