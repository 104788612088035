import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import styled from 'styled-components'

import { timeSlots } from './timeSlots'

import {
  PrimaryButton,
  TextInput,
  SelectInput,
  DateInput,
  H1,
  H4
} from '@PipelineDeals/shared-react-components'

const Form = styled.div`
  text-align: left;
  margin: 20px 0 40px;
`

const Row = styled.div`
  margin: 30px;
  display: flex;
`

const Field = styled.div`
  flex: 1 1 auto;
`

const Separator = styled.div`
  margin: 5px;
`

const TzInfo = styled.div`
  font-size: 12px;
  font-style: italic;
  margin-top: -20px;
`

export const filterTime = (time, holidays, officeHours) => {
  const options = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  const formatter = new Intl.DateTimeFormat('en-US', options)

  let officeOpen = officeHours[`open-${moment(time).format('YYYY-MM-DD')}`] || officeHours.open
  const officeClose = officeHours[`close-${moment(time).format('YYYY-MM-DD')}`] || officeHours.close
  const nyTime = moment(formatter.format(time), 'M/D/YYYY, H:mm:ss A')
  const currentHour = nyTime.format('HH:mm')

  if (isWeekend(nyTime)) return false
  if (isHoliday(nyTime, holidays)) return false
  // if the time is today...
  if (moment().format('YYYY MM DD') === moment(time).format('YYYY MM DD')) {
    // Shift the time by 15 minutes to avoid short notice bookings
    const nextAvailableSlot = moment().add(15, 'minutes')
    if (time <= nextAvailableSlot) return false
    // if timezone is to the east of NY (using -04 instead of worring about DST), then give extra buffer time for officeOpen
    // If open time is already overridden for today, then just use the overridden value.
    if (moment(time).utcOffset() > -240 && officeOpen === officeHours.open) officeOpen = '11:00'
  }
  if (currentHour >= officeOpen && currentHour < officeClose) return true

  return false
}

const isWeekend = (time) => {
  return time.isoWeekday() === 6 || time.isoWeekday() === 7
}

const isHoliday = (time, holidays) => {
  return holidays.find(holiday => time.month() + 1 === holiday.month && time.date() === holiday.day)
}

const FormFields = ({
  submitDemoRequest,
  displayDemoFormat,
  emailAddress,
  setEmailAddress,
  phoneNumber,
  setPhoneNumber,
  userName,
  setUserName,
  demoDate,
  setDemoDate,
  demoTime,
  setDemoTime,
  validateForm,
  holidays,
  officeHours
}) => {
  const filterDate = (time) => {
    if (isWeekend(moment(time))) return false
    if (isHoliday(moment(time), holidays)) return false

    return moment().startOf('day').toDate().getTime() <= time.getTime()
  }

  const submitForm = () => {
    validateForm.touchAll()
    validateForm.validate({ userName, emailAddress, phoneNumber, demoDate, demoTime })

    submitDemoRequest()
  }

  const selectTimeOptions = useMemo(() => {
    if (!demoDate) return []

    return timeSlots.map(
      to => {
        const demoDateFormatted = moment(demoDate).format('YYYY-MM-DD')
        const dateToParse = `${demoDateFormatted} ${to}`
        return moment(dateToParse).toDate()
      }
    ).filter(
      date => {
        return filterTime(date, holidays, officeHours)
      }
    ).map(
      date => {
        const labelFormat = moment(date).format('h:mm A')
        const valueFormat = moment(date).format('HH:mm')

        return { value: valueFormat, label: labelFormat }
      }
    )
  }, [demoDate])

  return (
    <>
      <H1>Book a demo</H1>
      <div style={{ paddingTop: '30px' }}>
        <H4>Find out more about Pipeline CRM and book a demo with us.</H4>
      </div>
      <Form>
        <Row>
          <Field>
            <TextInput
              errorMsg={validateForm.errorMessages().userName}
              data-cypress="pl-text-input"
              label="Your name"
              onChange={e => {
                validateForm.touch('userName')
                setUserName(e.target.value)
              }}
              placeholder="e.g.: John Smith"
              value={userName}
            />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextInput
              errorMsg={validateForm.errorMessages().emailAddress}
              data-cypress="pl-text-input"
              label="Your email address"
              onChange={e => {
                validateForm.touch('emailAddress')
                setEmailAddress(e.target.value)
              }}
              placeholder="john.smith@example.com"
              value={emailAddress}
            />
          </Field>
        </Row>
        <Row>
          <Field>
            <TextInput
              errorMsg={validateForm.errorMessages().phoneNumber}
              data-cypress="pl-text-input"
              label="Your phone number"
              onChange={e => {
                validateForm.touch('phoneNumber')
                setPhoneNumber(e.target.value)
              }}
              placeholder="Add your phone number here"
              value={phoneNumber}
            />
          </Field>
        </Row>
        <Row>
          <Field style={{ width: '50%' }}>
            <DateInput
              errorMsg={validateForm.errorMessages().demoDate}
              dateFormat={`${displayDemoFormat}`}
              label="Date"
              onChange={date => {
                validateForm.touch('demoDate')
                setDemoDate(date)
                setDemoTime(null)
              }}
              onChangeRaw={e => e.preventDefault()}
              placeholder="Pick a date"
              minDate={new Date()}
              maxDate={moment().add(1, 'month').subtract(1, 'day').toDate()}
              showMonthDropdown={false}
              showYearDropdown={false}
              filterDate={filterDate}
              value={demoDate}
            />
          </Field>
          <Separator />
          <Field style={{ width: '50%' }}>
            <SelectInput
              menuPlacement="top"
              errorMsg={validateForm.errorMessages().demoTime}
              label="Time"
              placeholder="Select a time"
              noOptionsMessage={() => 'Pick a date first'}
              onChange={option => {
                validateForm.touch('demoTime')
                setDemoTime(option)
              }}
              searchable={false}
              clearable={false}
              value={demoTime}
              menuPortalTarget={document.body}
              options={selectTimeOptions}
            />
          </Field>
        </Row>
        <Row>
          <TzInfo>Select date and time in your local time zone ({Intl.DateTimeFormat().resolvedOptions().timeZone})</TzInfo>
        </Row>
      </Form>
      <PrimaryButton
        content="Book a demo"
        onClick={submitForm}
      />
    </>
  )
}

FormFields.propTypes = {
  submitDemoRequest: PropTypes.func.isRequired,
  displayDemoFormat: PropTypes.string.isRequired,
  emailAddress: PropTypes.string,
  setEmailAddress: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  userName: PropTypes.string,
  setUserName: PropTypes.func.isRequired,
  demoDate: PropTypes.instanceOf(Date),
  setDemoDate: PropTypes.func.isRequired,
  demoTime: PropTypes.object,
  setDemoTime: PropTypes.func.isRequired,
  validateForm: PropTypes.object.isRequired,
  holidays: PropTypes.array.isRequired,
  officeHours: PropTypes.object.isRequired
}

export default FormFields
