import moment from 'moment'
import { decimalNormalizer } from './numberFormatting.mjs'

export const momentUserDateFormat = (user) => user.date_format === 'us' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
export const userDateFormat = (user) => user.date_format === 'us' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'

export const formatCurrency = (amount, currency) => {
  const { symbol } = currency

  const formattedNumber = thousandSeparator(decimalNormalizer(amount))

  return `${symbol}${formattedNumber}`
}

export const formatPercent = (value) => {
  const percent = Number(value)

  return `${percent.toFixed(0)}%`
}

export const formatDate = (value, currentUser) => {
  if (value === undefined || value === null || value === '') return ''

  const parsedDate = moment.parseZone(value, 'YYYY/MM/DD HH:mm:ss ZZ')
  return parsedDate.format(momentUserDateFormat(currentUser))
}

export const getValueFormatter = (valueFormat, currentUser) => {
  switch (valueFormat) {
    case 'currency':
      return (value, currency) => formatCurrency(value, currency || currentUser.currency)
    case 'percent':
      return formatPercent
    case 'date':
      return (value) => formatDate(value, currentUser)
    case 'currencyColumn':
      return (currency) => `${currency.code} - ${currency.symbol}`
    default:
      return (value) => value
  }
}

export const getVolumeFormatter = () => {
  return (value) => value.toLocaleString()
}

export function thousandSeparator (input) {
  const number = String(input).split('.')
  let num = number[0] || number

  num = num.split('').reverse().join('')

  let numpoint = ''

  for (let i = 0; i < num.length; i++) {
    numpoint += num.substr(i, 1)

    if (((i + 1) % 3 === 0) && i !== num.length - 1) numpoint += ','
  }

  num = numpoint.split('').reverse().join('')

  if (number[1] !== undefined) num = num + '.' + number[1]

  return num
}

export function removeMantissa (number) {
  const parts = String(number).split('.')

  return (parts.length > 0) ? parts[0] : String(number)
}

export function tableCellDetailsFormatter (section) {
  const formatters = {
    dealsWon: extendedTableCellDetailsFormatter,
    forecastedToClose: extendedTableCellDetailsFormatter,
    winRatio: winRatioTableCellDetailsFormatter,
    enhancedReporting: enhancedReportingCellDetailsFormatter
  }

  const formatter = formatters[section]

  return formatter || defaultTableCellDetailsFormatter
}

function defaultTableCellDetailsFormatter (deal) {
  return [
    { name: 'deal_name', label: 'Deal name', value: deal.name },
    { name: 'amount', label: 'Amount', value: (deal.value || 0) },
    { name: 'currency', label: 'Currency', value: deal.currency },
    { name: 'source', label: 'Source', value: (deal.source ? deal.source.name : 'Has no value') },
    { name: 'closed_time', label: 'Closed at', value: deal.closed_time },
    { name: 'owner', label: 'Owner', value: `${deal.user.first_name} ${deal.user.last_name}` },
    { name: 'id', label: 'View', value: deal.id }
  ]
}

const enhancedReportingCellDetailsFormatter = {
  deals: (deal) => [
    { name: 'deal_name', label: 'Deal name', value: deal.name },
    { name: 'amount', label: 'Amount', value: (deal.value || 0) },
    { name: 'currency', label: 'Currency', value: deal.currency },
    { name: 'pipeline', label: 'Pipeline', value: (deal.deal_pipeline ? deal.deal_pipeline.name : 'Has no value') },
    { name: 'expected_close_date', value: deal.expected_close_date, label: 'Expected close date at' },
    { name: 'source', label: 'Source', value: (deal.source ? deal.source.name : 'Has no value') },
    { name: 'closed_time', label: 'Closed at', value: deal.closed_time },
    { name: 'owner', label: 'Owner', value: `${deal.user.first_name} ${deal.user.last_name}` },
    { name: 'id', label: 'View', value: deal.id }
  ],
  companies: (company) => [
    { name: 'company_name', label: 'Company name', value: company.name },
    { name: 'owner', label: 'Owner', value: (company.owner ? `${company.owner.first_name} ${company.owner.last_name}` : 'Has no value') },
    { name: 'id', label: 'View', value: company.id }
  ],
  people: (person) => [
    { name: 'person_full_name', label: 'Person name', value: person.full_name },
    { name: 'owner', label: 'Owner', value: `${person.user.first_name} ${person.user.last_name}` },
    { name: 'id', label: 'View', value: person.id },
    { name: 'lead_status', label: 'Status', value: person.lead_status?.name || 'Has no value' },
    { name: 'lead_source', label: 'Source', value: person.lead_source?.name || 'Has no value' }
  ]
}

function extendedTableCellDetailsFormatter (deal) {
  const extendedDetails = defaultTableCellDetailsFormatter(deal).slice()

  extendedDetails.splice(3, 0, {
    name: 'expected_close_date',
    value: deal.expected_close_date,
    label: 'Expected close date at'
  })

  extendedDetails.splice(3, 0, { name: 'deal_pipeline', label: 'Pipeline', value: deal.deal_pipeline.name })

  return extendedDetails
}

function winRatioTableCellDetailsFormatter (deal) {
  const details = defaultTableCellDetailsFormatter(deal)

  details.splice(5, 0, {
    name: 'deal_stage_name',
    value: deal.deal_stage.name,
    label: 'Deal stage'
  })

  return details
}
