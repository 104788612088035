import { getColor } from 'reporting-node/colors'

export const decorateLines = ({ lines }) => {
  return lines.map((line, index) => {
    return {
      ...line,
      color: getColor(index)
    }
  })
}

export const decorateTeams = ({ teams }) => {
  return teams.map(team => {
    return {
      ...team,
      lines: team.lines.map((line, index) => {
        return {
          ...line,
          color: getColor(index)
        }
      })
    }
  })
}

export const decorateUsers = ({ users }) => {
  return users.map(user => {
    return {
      ...user,
      lines: user.lines.map((line, index) => {
        return {
          ...line,
          color: getColor(index)
        }
      })
    }
  })
}

export const decorateUserLines = ({ lines }) => {
  return lines.map(line => {
    return {
      ...line,
      users: line.users.map((user, index) => {
        return {
          ...user,
          color: getColor(index)
        }
      })
    }
  })
}
