/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Handlebars.registerHelper('disabledIf', function (condition) {
  if (condition) { return 'disabled' } else { return '' }
})

Handlebars.registerHelper('isSelected', function (test) {
  let selected
  if (_.isArray(test)) {
    selected = _.include(test, this.id)
  } else {
    selected = test === this.id
  }
  if (selected) { return new Handlebars.SafeString(' selected="selected"') } else { return '' }
})

Handlebars.registerHelper('isRequired', function () {
  if (this.required) { return ' required' } else { return '' }
})

Handlebars.registerHelper('checkedIf', function (test) {
  if (test) { return ' checked' } else { return '' }
})

Handlebars.registerHelper('checkedIfEq', function (test, assertion) {
  if (test === assertion) { return ' checked' } else { return '' }
})
