export const DOCUMENT_TAG_CREATE = 'DOCUMENT_TAG_CREATE'
export const DOCUMENT_TAG_CREATE_SUBMIT = 'DOCUMENT_TAG_CREATE_SUBMIT'
export const DOCUMENT_TAG_CREATE_FAILURE = 'DOCUMENT_TAG_CREATE_FAILURE'

export const DOCUMENT_TAG_UPDATE = 'DOCUMENT_TAG_UPDATE'
export const DOCUMENT_TAG_UPDATE_SUBMIT = 'DOCUMENT_TAG_UPDATE_SUBMIT'
export const DOCUMENT_TAG_UPDATE_FAILURE = 'DOCUMENT_TAG_UPDATE_FAILURE'

export const DOCUMENT_TAG_DELETE = 'DOCUMENT_TAG_DELETE'
export const DOCUMENT_TAG_DELETE_SUBMIT = 'DOCUMENT_TAG_DELETE_SUBMIT'
export const DOCUMENT_TAG_DELETE_FAILURE = 'DOCUMENT_TAG_DELETE_FAILURE'
