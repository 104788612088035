/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (!window.Helpers) { window.Helpers = {} }

Helpers.fileTitle = function (title) {
  try {
    return decodeURI(title)
  } catch (err) {
    return title
  }
}

Handlebars.registerHelper('fileTitle', title => Helpers.fileTitle(title))
