import { PERSON_FETCH_SUCCESS } from 'People/constants'
import { conditionalFieldsValidation } from 'People/actions'

const personProfileConditionalFieldsUpdater = store => next => action => {
  if (action.type === PERSON_FETCH_SUCCESS) {
    const result = next(action)
    store.dispatch(conditionalFieldsValidation())
    return result
  }

  return next(action)
}

export default personProfileConditionalFieldsUpdater
