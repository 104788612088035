export const fieldTypesWithDropdownEntries = ['dropdown', 'multi_select']
export const fieldTypesWithReportBehavior = ['currency', 'forecast', 'numeric']
export const fieldTypes = {
  boolean: {
    definition: 'Boolean fields have values of True or False. These values can help you organize and filter your list views.',
    name: 'Boolean'
  },
  calculated: {
    definition: 'This field supports addition, subtraction, multiplication and division. System and Custom Fields can either be selected via the dropdown pickers below or by typing @ and then selecting the desired field.',
    name: 'Calculated'
  },
  currency: {
    definition: 'Currency fields track monetary values, such as Estimated Revenues, Market Capitalization, or Annual Sales.',
    name: 'Currency'
  },
  date: {
    definition: 'Use a date field to capture important dates, like Last Visit or Demo Date.',
    name: 'Date'
  },
  dropdown: {
    definition: 'Use a dropdown field to identify a list of options from which the user can select only one item. For example, you can use a dropdown to associate a single Industry or Territory with each Company.',
    name: 'Dropdown'
  },
  forecast: {
    definition: 'Forecast is an automatically generated calculation field. It provides a dollar value by multiplying the Deal Amount $ field by the Probability %.',
    name: 'Forecast'
  },
  multi_association: {
    definition: 'Use a multiple association field to link to multiple companies. This is best used to identify the subsidiaries of a particular company. The field creates a link to the linked companies’ profiles and is filterable in list views.',
    name: 'Multiple association'
  },
  numeric: {
    definition: 'This field is best used for capturing quantities (e.g. number of employees, number of locations, etc.). For other numeric values like phone numbers, the Text field type is a better option.',
    name: 'Numeric'
  },
  multi_select: {
    definition: 'Use a picklist field to specify a list of options from which the user can select multiple items. Picklists are great for tracking Competitors or Product Interest.',
    name: 'Picklist'
  },
  single_association: {
    definition: 'Use a single association field to link to a single company. This is best used to identify the parent or headquarters of a particular company. The field creates a link to the linked company profile and is filterable in list views.',
    name: 'Single association'
  },
  text: {
    definition: 'Text fields can capture any free-form text, such as Stock Ticker Symbol. Also, be sure to use a Text field instead of a Number field for phone numbers, SIC codes, or other numerical formats that do not capture quantities.',
    name: 'Text'
  }
}
export const itemTypes = {
  DROPDOWN_ENTRY: 'DROPDOWN_ENTRY',
  LABEL: 'LABEL'
}

export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS'
export const RECEIVE_CUSTOM_FIELDS = 'RECEIVE_CUSTOM_FIELDS'

export const DELETE_CUSTOM_FIELD_GROUP = 'DELETE_CUSTOM_FIELD_GROUP'
export const DELETE_CUSTOM_FIELD_GROUP_FAILURE = 'DELETE_CUSTOM_FIELD_GROUP_FAILURE'
export const DELETE_CUSTOM_FIELD_GROUP_SUCCESS = 'DELETE_CUSTOM_FIELD_GROUP_SUCCESS'

export const DELETE_CUSTOM_FIELD_LABEL = 'DELETE_CUSTOM_FIELD_LABEL'
export const DELETE_CUSTOM_FIELD_LABEL_FAILURE = 'DELETE_CUSTOM_FIELD_LABEL_FAILURE'
export const DELETE_CUSTOM_FIELD_LABEL_SUCCESS = 'DELETE_CUSTOM_FIELD_LABEL_SUCCESS'

export const EDIT_CUSTOM_FIELD_GROUP_FORM_FAILURE = 'EDIT_CUSTOM_FIELD_GROUP_FORM_FAILURE'
export const EDIT_CUSTOM_FIELD_GROUP_FORM_SUBMIT = 'EDIT_CUSTOM_FIELD_GROUP_FORM_SUBMIT'
export const EDIT_CUSTOM_FIELD_GROUP_FORM_SUCCESS = 'EDIT_CUSTOM_FIELD_GROUP_FORM_SUCCESS'

export const EDIT_CUSTOM_FIELD_LABEL_FORM_FAILURE = 'EDIT_CUSTOM_FIELD_LABEL_FORM_FAILURE'
export const EDIT_CUSTOM_FIELD_LABEL_FORM_SUBMIT = 'EDIT_CUSTOM_FIELD_LABEL_FORM_SUBMIT'
export const EDIT_CUSTOM_FIELD_LABEL_FORM_SUCCESS = 'EDIT_CUSTOM_FIELD_LABEL_FORM_SUCCESS'

export const MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_AS_NOT_NEW = 'MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_AS_NOT_NEW'
export const MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_FOR_DELETION = 'MARK_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY_FOR_DELETION'
export const MOVE_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY = 'MOVE_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRY'
export const SORT_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRIES = 'SORT_CUSTOM_FIELD_LABEL_DROPDOWN_ENTRIES'

export const MOVE_CUSTOM_FIELD_LABEL_CUSTOM_FIELD_GROUP = 'MOVE_CUSTOM_FIELD_LABEL_CUSTOM_FIELD_GROUP'

export const NEW_CUSTOM_FIELD_GROUP_FORM_FAILURE = 'NEW_CUSTOM_FIELD_GROUP_FORM_FAILURE'
export const NEW_CUSTOM_FIELD_GROUP_FORM_SUBMIT = 'NEW_CUSTOM_FIELD_GROUP_FORM_SUBMIT'
export const NEW_CUSTOM_FIELD_GROUP_FORM_SUCCESS = 'NEW_CUSTOM_FIELD_GROUP_FORM_SUCCESS'

export const NEW_CUSTOM_FIELD_LABEL_FORM_FAILURE = 'NEW_CUSTOM_FIELD_LABEL_FORM_FAILURE'
export const NEW_CUSTOM_FIELD_LABEL_FORM_SUBMIT = 'NEW_CUSTOM_FIELD_LABEL_FORM_SUBMIT'
export const NEW_CUSTOM_FIELD_LABEL_FORM_SUCCESS = 'NEW_CUSTOM_FIELD_LABEL_FORM_SUCCESS'

export const REPOSITION_CUSTOM_FIELD_LABEL = 'REPOSITION_CUSTOM_FIELD_LABEL'
export const REPOSITION_CUSTOM_FIELD_LABEL_FAILURE = 'REPOSITION_CUSTOM_FIELD_LABEL_FAILURE'
export const REPOSITION_CUSTOM_FIELD_LABEL_SUCCESS = 'REPOSITION_CUSTOM_FIELD_LABEL_SUCCESS'

export const UPDATE_CUSTOM_FIELD_LABEL = 'UPDATE_CUSTOM_FIELD_LABEL'
export const UPDATE_CUSTOM_FIELD_LABEL_SUCCESS = 'UPDATE_CUSTOM_FIELD_LABEL_SUCCESS'
export const UPDATE_CUSTOM_FIELD_LABEL_FAILURE = 'UPDATE_CUSTOM_FIELD_LABEL_FAILURE'
