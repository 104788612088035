export default [
  'changePage',
  'changePerPage',
  'changeTypeFilter',
  'clickAdvancedSearch',
  'clickBackToTop',
  'clickFilterToggle',
  'clickGlobalNavSearch',
  'clickGlobalSearch',
  'clickQuickSearch',
  'clickSearchTips',
  'clickSettings',
  'clickX',
  'filterChanged',
  'performGlobalSearch',
  'performQuickSearch',
  'performedGlobalSearch',
  'saveColumns'
]
