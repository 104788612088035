import React from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  Row,
  Left,
  Right
} from 'components/BusinessCard/components/StyledComponents'

import { useBusinessCard } from 'components/BusinessCard/context'

const SimpleSection = ({ field, value, label }) => {
  const { cardMeta: { showOnlyFields } } = useBusinessCard()

  if (!showOnlyFields.includes(field)) return null
  if (!value) return null

  return (
    <Section>
      <Row>
        <Left>{label}</Left>
        <Right>{value}</Right>
      </Row>
    </Section>
  )
}

SimpleSection.propTypes = {
  field: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string
}

export default SimpleSection
