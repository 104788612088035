const ACTIVITY = 'activity'
const COMMENT = 'comment'
const DUPLICATE_CHECK = 'duplicate_check'
const FILE = 'file'

export default (object, objectType) => {
  if (object === undefined || objectType === undefined) throw new Error(`${object} or ${objectType} are undefined`)

  switch (objectType.toLowerCase()) {
    case ACTIVITY:
      return object.created_by_user_id
    case COMMENT:
    case DUPLICATE_CHECK:
      return object.user_id
    case FILE:
      return object.owner_id
    default:
      return 0
  }
}
