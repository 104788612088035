import { takeEvery } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import fetcher from 'modules/fetcher'
import { PRELOAD_APP } from 'ducks'

import {
  accountUpdate,
  accountUpdateFailure
} from './actions'

import {
  ACCOUNT_UPDATE_SUBMIT
} from './constants'

export function * accountUpdateSaga ({ payload }) {
  try {
    const response = yield call(
      fetcher,
      'admin/account',
      {
        method: 'PATCH',
        body: JSON.stringify({
          account: {
            company_name: payload.companyName,
            technical_contact_email: payload.accountEmail,
            industry_id: payload.accountIndustry,
            address_autocompleter: payload.accountAddressAutocompleter
          }
        })
      }
    )
    yield call(window.Helpers.notify, 'Account', 'Your settings have been saved')
    accountUpdate(response)
    yield call(() => { window.Account.resync(false, false) })
    yield put({ type: PRELOAD_APP })
  } catch (exception) {
    accountUpdateFailure(exception.message)
  }
}

export function * watchAccountUpdateSaga () {
  yield call(takeEvery, ACCOUNT_UPDATE_SUBMIT, accountUpdateSaga)
}
